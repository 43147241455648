import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { ValueElementMixin } from '../../../../bo/vdml/ValueElementMixin'
import { BusinessModelMixin } from '../../../../bo/vdml/BusinessModelMixin'

/*define(["require","jquery","async","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueDeliveryModel","appbo/vdml/BusinessModel","appbo/tickets/User","typeahead","appbo/smm/GradeMeasure","appbo/smm/RankingMeasure","appbo/smm/Accumulator","appbo/vdml/MeasuredCharacteristic","appbo/vdml/BusinessModelMixin", "appbo/vdml/ValueElementMixin"],
function(require,$,async,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,ValueDeliveryModel,BusinessModel,User,typeahead,GradeMeasure,RankingMeasure,Accumulator,MeasuredCharacteristic,BusinessModelMixin,ValueElementMixin
){*/
    
    var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
    var importPath;
    
    export class BMValueDetailsViewModel {
        constructor(model,options){
			this.init(model, options);
		}
		//var alternative = DataManager.getDataManager().get('currentWorkspace').get("id");
    	getViewAlternativeModel(){
			var dataManager = DataManager.getDataManager();
			var phase = dataManager.get('currentPlan').get('phase');
			var tempAlt = dataManager.get('viewAlternative') ? dataManager.get('viewAlternative'): alternative;
			for(var j=0;j<phase.length;j++){
				var alts = phase.at(j).get('phaseAlternative');
				var viewAlternativeModel = alts.findWhere({'id':tempAlt});
				if(viewAlternativeModel){
					self.currentAlternativeModel = viewAlternativeModel;
					break;
				}
			}    		
    	};
    	
    	
    	
    	/*this.fetchValuesThatCanBeRefferedTo = function(callback){
    		var self = this;
    		if(self.valuesThatCanBeRefferedTo.length > 0){
    			callback(self.valuesThatCanBeRefferedTo);
    		}else {
    			DataManager.getDataManager().get('currentPlan').getValuesThatCanBeRefferedTo(function(values){
		        	self.valuesThatCanBeRefferedTo.add(values);
		        	callback(self.valuesThatCanBeRefferedTo);
		        });
    		}	
       };*/
		
		createRevisionBasedOnContext(revisionCheck,viewInstance,callback){
			if(window.checkContextForRevision() && revisionCheck) {
				window.utils.startSpinner('revisionSpinner', "Creating a copy...");
				function waitAndStopRevisionSpinner(){
					function stopRevisionSpinner(){
						window.utils.stopSpinner('revisionSpinner');
						callback();
					}
					setTimeout(stopRevisionSpinner,10);
				}
				function fetchBmModel(newVdmPackage){
						if(newVdmPackage) {
							var modelSuffix = self.id().substr(self.id().lastIndexOf('@')+1);
	    					var newId = DataManager.getDataManager().get('viewAlternative') + ''+ modelSuffix;
							self.model = newVdmPackage.get('businessModel').findWhere({'id':newId});
							var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
							var filterObservables = ['mainScenario','selectedProviderRole','selectedReceiverRole','selectedProvider','selectedBusinessRole','selectedAggregate','selectedPersued','selectedActivityProviderRole','selectedActivityProvider','selectedCompetencyActivities','selectedvalue'];
		        			window.utils.updateObservablesForRevision(viewInstance,oldAltIdReg,filterObservables);
                            DataManager.getDataManager().releaseSaveLock();
							DataManager.getDataManager().set('isActive',false);
							waitAndStopRevisionSpinner();
							/*DataManager.getDataManager().saveData({
								success:function(){
									waitAndStopRevisionSpinner();
								}, 
								error:function(){
									waitAndStopRevisionSpinner();
								}, 
								persist:true});	*/
						}
					}
				window.setTimeout(function(){
                    DataManager.getDataManager().acquireSaveLock(function () {
                        self.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                    });
	        	},100);	
        	}
        	else {
        		callback();
        	}
		};
		dispose(){
			var self = this;
			window.cleanViewModel(self);		
		};    	
    	getBMValues(alts,bm,callback){
			var self = this;
			var values = {};
			async.eachSeries(alts,function(alt,altHandled){
				values[alt.get('id')] = [];
				alt.getModelLinked(bm,function(model){
					if(model){
						self.getValues(model,values[alt.get('id')]);
						var uniqueAltValues = _.unique(values[alt.get('id')]);
						uniqueAltValues.sort(function(left, right) {
				          	return left.get('name').replace(/[^a-zA-Z0-9]/g, '').toLowerCase() === right.get('name').replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? 0 : (left.get('name').replace(/[^a-zA-Z0-9]/g, '').toLowerCase() < right.get('name').replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? -1 : 1) 
				    	});
				    	values[alt.get('id')] = uniqueAltValues;
					}
					altHandled();
				});
				//altHandled();
			},function(){
				//values = _.unique(values);
				callback(values);
			});
		};
		getValues(model,values){
			var bmVPValues = model.get('bmValueProposition');
			var bmVFValues = model.get('bmValueFormula');
			var bmRoles = model.getBusinessRoles();
			bmVPValues.each(function(bmVPValue){
				var vp = bmVPValue.get('valueProposition');
				if(vp){
					var vpProvider = vp.get('provider');
					if(_.indexOf(bmRoles,vpProvider) >=0){
						var vpComponents = vp.get('component');
						vpComponents.each(function(vpComp){
							var valMest = vpComp.get('valueMeasurement');
							values.push(valMest);
						});
					}
				}
				
			});
			bmVFValues.each(function(bmVFValue){
				var vp = bmVFValue.get('valueFormula');
				if(vp){
					var vpProvider = vp.get('provider');
					if(_.indexOf(bmRoles,vpProvider) >=0){
						var vpComponents = vp.get('component');
						vpComponents.each(function(vpComp){
							var valMest = vpComp.get('valueMeasurement');
							values.push(valMest);
						});
					}
				}
			});			
		}
		
		
		showValues(values,alts){
			var self = this;
			self.getValueMeasurmeents(values,alts,self,function(datasets){
				self.phaseAccordionList(self.phaseAccordionArray);
			});
		}
		
		checkPhaseAccordionArray(datasets){
			var self = this;
			for(var k=0;k<datasets.data.length;k++){
				for(var i=0;i<self.phaseAccordionArray.length;i++){
					var obj = self.phaseAccordionArray[i];
					var altList = obj.altAccordionList;
					for(var j=0;j<altList.length;j++){
						if(datasets.data[k][0] == altList[j].id){
							var altValues = altList[j].values;
							var mesaurementValue = datasets.data[k][2]?datasets.data[k][2]:' ';
							altValues.push({id:datasets.data[k][1],name:datasets.label,displayName:datasets.displayName,value:mesaurementValue,altId:altList[j].id,isCollective:datasets.isCollective, smileySrc: datasets.smileySrc, smileyColor: datasets.smileyColor, correctiveActions: datasets.correctiveActions, escapeId: window.utils.htmlEscape(datasets.data[k][1])});
							break;
						}
					}
				}
			}
		};
	
		fillDuplicatePath(valuesList,val,altId){
			var name = val.getMeasurand().get('name'),displayPath = "";
			var values = valuesList[altId];
			if(values) {
				for(var i=0;i<values.length;i++){
					if(values[i].getMeasurand().get('name')===name && values[i].getMeasurand().id !== val.getMeasurand().id && DataManager.getDataManager().getRepositoryId(values[i].get('id')) === altId){
						var valuePackagePath = val.getPackagePath();
						var valueName = valuePackagePath[2]? valuePackagePath[2].name : "";
						displayPath = displayPath.concat("["+valuePackagePath[0].name+"  /  "+valuePackagePath[1].name+"  /  "+valueName+"]");
						/*if( valuePackagePath[2] &&valuePackagePath[2].name !== duplicateValuePackagePath[2].name){
							displayPath = displayPath.concat(valuePackagePath[2].name);
						}
						else if(valuePackagePath[1] && valuePackagePath[1].name !== duplicateValuePackagePath[1].name){
							displayPath = displayPath.concat(valuePackagePath[1].name+"  /  "+valuePackagePath[2].name);
						}
						else{
							displayPath = displayPath.concat(valuePackagePath[0].name+"  /  "+valuePackagePath[1].name+"  /  "+valuePackagePath[2].name);
						}
			            displayPath = displayPath.concat("]");*/
			            return displayPath;
					}
				}
			}
			return displayPath;
		}
		getValueMeasurmeents(values,alts,self,callback){
			var dataSet = {};
			var valueData = {};
			async.eachSeries(alts,function(alt,altHandleCallback){
				var valList = values[alt.get('id')];
				var pack = valList[0] ? valList[0].getNestedParent():null;
				if(!pack){
					altHandleCallback();
					return;
				}
				DataManager.getDataManager().getMainScenario(pack,alt.get('id'),function(mainScenario){
					if(!mainScenario){
						altHandleCallback();
						return;
					}
					async.each(valList,function(val,valHandleCallback){
						var unit ="";
						valueData.label = val.getMeasurand().get('name');
						valueData.data = [];
						/*function pushEmpty(){
							var valData = [];
							valData.push(alt.get('phaseAlternativeOwner').get('name'));
							valData.push(null);
							altHandleCallback();
						}*/				
						//var pack = val.getNestedParent();
						
							var mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(val);
							var component = val.get('valueMeasurementOwner');
							var satisfaction = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(component.get('satisfactionLevel'));
							if(mest && mest.get('type') == "smm_CollectiveMeasurement"){
								valueData.isCollective = true;
							}
							else{
								valueData.isCollective = false;
							}
							var valData = [];
							valData.push(alt.get('id'));
							valData.push(val.get('valueMeasurementOwner').get('id'));
							if(mest && mest.get('observedMeasure').get('measure')){
								unit = mest.get('observedMeasure').get('measure').get('unit');
								var significantDecimals = unit ? unit.get('significantDecimals') : 2;
								var displayValue = '';
								if(mest.get('value') !== '') {
									displayValue = ''+ window.utils.thousandSeparator(window.utils.getRoundedValues(mest.get('value'),significantDecimals));
									if(unit){
										displayValue = displayValue.concat(' '+unit.get('name'));
									}
								}
								valData.push(displayValue);
							}
							else {
								if(component.get('aggregatedFrom').length > 0) {
									valueData.isCollective = true;
								}
								var compSatisfactionMeasure = satisfaction?satisfaction.get('observedMeasure').get('measure'):null;
								if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
									displayValue = '' + satisfaction.get('symbol');
									valData.push(displayValue);
								}
								else {
									valData.push(null);
								}
							}
							var smileySrc = '';
							var smileyColor = '';
							var correctiveActions = '';
							if (satisfaction && component.getParent().get('overAllSatisfaction') !== component) {
								var selectedInterval = self.getSatSelectedInterval(satisfaction);
								if(selectedInterval){
									smileySrc = self.fetchSmileySrc(selectedInterval.get('smiley'));
									smileyColor = selectedInterval.get('smileyColor');
									correctiveActions = selectedInterval.get('correctiveActions');
								}
							}
							valueData.smileySrc = smileySrc;
							valueData.smileyColor = smileyColor;
							valueData.displayName = self.fillDuplicatePath(values,val,alt.get('id'));
							valueData.correctiveActions = correctiveActions;
							valueData.data.push(valData);
							dataSet[val.get('id')] = valueData;
							if(unit){
								valueData.label = valueData.label;
							}
							self.checkPhaseAccordionArray(valueData);
							valHandleCallback();
					},function(){
						altHandleCallback();
					});	
				});
			},function(){
				callback(dataSet);
			});
		};
		
		getSatSelectedInterval(satisfactionMeasurement){
        	var selectedInterval;
			var satType = 'symbol';
			var satMeasure = satisfactionMeasurement.get('observedMeasure').get('measure');
			if(satisfactionMeasurement.get('value') && satMeasure && satMeasure.get('unit')){//Ranking
				satType = 'value';
			}
			if(satMeasure) {
				var intervals = satMeasure.get('interval');
				for(var i=0;i<intervals.length;i++){
					if(intervals.at(i).get(''+satType) === satisfactionMeasurement.get(''+satType)){
						selectedInterval = intervals.at(i);
						break;
					}
				}
				
			}
        	return selectedInterval;  	
        };
		
		fetchSmileySrc(smileyValue){
			var self = this;
       		//var smileyValue = smileyValue?smileyValue:'1';
			var smileySrc = '';
			for(var j=0;j<self.svgSmiliesList.length;j++){
				if(self.svgSmiliesList[j].value === smileyValue) {
					smileySrc = self.svgSmiliesList[j].src;
					break;
				}
			}
			return smileySrc;
       };
		
		getAlternatives(){
			var alts = [];
			var currentPlan = DataManager.getDataManager().get('currentPlan');
			var asIs = currentPlan.get('asIs');
			var phase = currentPlan.get('phase').findWhere({nextPhase:null});
			while(phase){
				var phaseAlts = phase.get('phaseAlternative');
				var masterAlt = phase.get('master');
				var altAccordionList = [];
				phaseAlts.each(function(phaseAlt){
					if(phaseAlt !== masterAlt){
						alts.unshift(phaseAlt);
						var altName = phaseAlt.get('name');
						if(phaseAlt == phase.get('primary')){
							altName = altName.concat(' (Primary)');
						}
						altAccordionList.push({id:phaseAlt.get('id'),encodeId:window.utils.htmlEscape(phaseAlt.get('id')),name:altName,values:[]});
					}
				});
				alts.unshift(masterAlt);
				var altName = masterAlt.get('name');
				if(masterAlt == phase.get('primary')){
					altName = altName.concat(' (Primary)');
				}
				altAccordionList.unshift({id:masterAlt.get('id'),encodeId:window.utils.htmlEscape(masterAlt.get('id')),name:altName,values:[]});
				var phaseName = phase.get('name');
				if(asIs == phase){
					phaseName = phaseName.concat(' (As-Is)');
				}
				this.phaseAccordionArray.unshift({id:phase.get('id'),name:phaseName,encodeId:window.utils.htmlEscape(phase.get('id')),altAccordionList:altAccordionList});
				phase = phase.get('previousPhase');
			}		
	
			return alts;
		};
		initialize(bm){
			var self = this;
			if(!self.model){
				self.model = bm;
			}
			self.phaseAccordionList([]);
			self.phaseAccordionArray = [];
			var alts = self.getAlternatives();
			self.getBMValues(alts,self.model,function(values){
				self.showValues(values,alts);
			});
			//self.fetchValuesThatCanBeRefferedTo();
		};
		
		fetchAlternativeModel(altId){
			var self=this;
			var altModel = null;
			var currentPlan = DataManager.getDataManager().get('currentPlan');
			var phase = currentPlan.get('phase').findWhere({nextPhase:null});
			
			function phaseCheck(phase) {
				var phaseAlts = phase.get('phaseAlternative');
				for(var i=0;i<phaseAlts.length;i++){
					if(phaseAlts.at(i).get('id') == altId){
						altModel = phaseAlts.at(i);
						break;
					}
				}
				if(!altModel) {
					phase = phase.get('previousPhase');
					phaseCheck(phase);
				}
			}
			phaseCheck(phase);
			return altModel;
		};
		
		editValueDetails(view,event){
			var self=this;
			var currentAltId = DataManager.getDataManager().get('viewAlternative');
			var altModel = self.fetchAlternativeModel(view.altId);
			var disableDelete = false;
			altModel.getModelLinked(self.model,function(model){
				if(model){
					var bmRoles = model.getBusinessRoles();
					for(var i=0;i<bmRoles.length;i++){
						var vps = bmRoles[i].get('providedProposition');
						for(var j=0;j<vps.length;j++){
							var vpsComp = vps.at(j).get('component');
							var modalEle = vpsComp.findWhere({'id':view.id});
							if(modalEle){
								break;
							}
						}
						if(modalEle){
							break;
						}
					}
					var readOnly = true;
					if(currentAltId == view.altId){
						readOnly = false;
					}
					if(window.checkContextForRevision()){
						disableDelete = true;
					}
		
					var overAllSatisfaction = modalEle.get('componentOwner').get('overAllSatisfaction');
					DataManager.getDataManager().getMainScenario(model.getNestedParent(),view.altId,function(mainScenario){
						var allValues = [];
						model.getValues(allValues,true);
						var aggregatedColl = new Backbone.Collection(allValues);
						self.model.fillPlanValues(aggregatedColl);
		    			var type = 'Activity';
		    			if(modalEle.get('type') === 'vdml_ValuePropositionComponent') {
							if(modalEle.get('componentOwner').get('isValueFormula')) {
								type = 'valueFormula';
							}
							else {
								type = 'valueProposition';
							}
						}
						//self.fetchValuesThatCanBeRefferedTo(function(valuesThatCanBeRefferedTo){
							var addOptions = {'type':type,'aggregatedColl':aggregatedColl,'mainScenario':mainScenario,'overAllSatisfaction':overAllSatisfaction,'readOnly':readOnly,'currentAlternativeModel':self.currentAlternativeModel,'disableDelete':disableDelete};
							window.getAndCreateModalDialog(self.model,window.utils.htmlEscape(modalEle.get('id')),BusinessModelMixin,modalEle,"ValueDetails",self.saveValueProposition,addOptions);
						//});
						
					});
				}
			});
		}
		showAggregationView(view) {
			var self =this;
            var id = "aggregation" + view.escapeId;
			var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: view.id });
			if(!valueModal){
				valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: view.id });
			}
			var alternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: view.altId });
			if(valueModal){
				var mest, pack = valueModal.getNestedParent();
				DataManager.getDataManager().getMainScenario(pack,view.altId,function(mainScenario){
					mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(valueModal.get('valueMeasurement'));
				});
			}
			if(!mest){
				mest = valueModal;
			}
            var addOptions = { 'currentAlternativeModel':alternative};
            window.getAndCreateModalDialog(true, id, ValueElementMixin, mest, "AggregationView", function(changeInValue){
		        	if(changeInValue) {
		        		window.utils.startSpinner('refreshValues','Refreshing ...');
						DataManager.getDataManager().invokeValueUpdates(function(){
							if(self.parentView) {
								self.parentView.refreshWidgetData(true);
						    	window.utils.stopSpinner('refreshValues');	
							}else {
								window.utils.stopSpinner('refreshValues');	
							}
						});
					}	
	        	}, addOptions);
        };
        
        correctiveActions(view){
			var self=this;
        	var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>"+view.correctiveActions+"</span></div></div>";
			var box = bootbox.dialog({
				message: htmlContent,
				title: "<div><img class='smallIcon' src='img/icons/icons_15.png' >&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('CorrectiveActions')+"</div>",
				buttons: {
					cancel : {
						label: "Close",
						className : 'btn vtn-default',
						callback : function(){
							
						}
					}
				}
			});
			box.show();
        };
		
		saveValueProposition(view,modalId,type,del){
			var self=this;
			window.utils.startSpinner('bmValues','Saving Value ...');
			window.setTimeout(function(){
				if(!self.currentAlternativeModel){
					self.currentAlternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
				}
				var context = self.currentAlternativeModel.getMainScenario(self.model.getNestedParent()); 
				if(del){
					view.model.get('componentOwner').deleteValue([view.id()],view.scenario);
					DataManager.getDataManager().invokeValueUpdates(function(){
						window.cleanDialogModel(modalId,view);
						if (self.parentView.resetVPData) {
							self.parentView.resetVPData();
			            	self.parentView.resetVFData();
						}						
						self.parentView.refreshWidgetData(true);
						window.utils.stopSpinner('bmValues');
					},true);
				}
				else{
					function saveValue(){
						view.saveData(self.model,view.model.get('componentOwner'),function(changeInValue){
							DataManager.getDataManager().invokeValueUpdates(function(){
								if(window.checkContextForRevision() && view.structuralChangeCount > 0) {
									DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/"+ self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version')+ "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});	                        						
									window.cleanDialogModel(modalId,view);
									window.utils.stopSpinner('bmValues');
								}else {
									if(changeInValue) {
										if(window.checkContextForRevision()) {
											context.set('localChange',true);
										}
										if(self.parentView.resetVPData){
											self.parentView.resetVPData();
							            	self.parentView.resetVFData();
							            	self.parentView.fillValuesTab();
										}
										self.parentView.refreshWidgetData(true);
									}
									window.cleanDialogModel(modalId,view);
									window.utils.stopSpinner('bmValues');
								}
								
							})
						});
					}
					if(view.structuralChangeCount > 0){
						self.createRevisionBasedOnContext(true,view,saveValue);
					}else {
						saveValue();
					}
				}
			},100);
		};
        
        /*this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['general'
        ,'version'
        ,'name'
        ,'description'
        ,'Role'
        ,'type'
        ,'Close'
        ,'Complete'
        ]);*/
       
    init(model, options){
		model.bmValueWidgetInstance = this;
        var self = this;
        this.BMValueDetailsViewModel = this;
        this.parentView = model;
        this.model = model.model;
		model=null;
		this.options =options;
        function htmlEscape(str) {
            return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
        }
        this.id = ko.observable(self.model ? self.model.get('id') : null);
        this.encodeId = htmlEscape(this.id());
        this.showVpList = ko.observableArray([]);
        this.phaseAccordionList = ko.observableArray([]);
        this.phaseAccordionArray = [];
        this.svgSmiliesList = window.utils.getSmiliesList();
		this.getViewAlternativeModel();
		this.editValueDetails =  _.bind(self.editValueDetails, self);
		this.fetchAlternativeModel =  _.bind(self.fetchAlternativeModel, self);
		this.saveValueProposition =  _.bind(self.saveValueProposition, self);
	}
        
    static getInstance(model,options){
        var view = new BMValueDetailsViewModel(model,options);
		//view.init(model, options);
		return view;
    };
}
path.BMValueDetailsViewModel = BMValueDetailsViewModel;