import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElement2Mixin} from './VdmlElement2Mixin'
import {Expression} from './Expression'
import {Alternative} from '../transformation/Alternative'
import {DataManager} from '../../../com/vbee/data/DataManager'

	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class ValueElementContextMixin {

defaults(){
    var ret = {
        type: "vdml_ValueElementContext"
    }
    return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
}
static getMixinRelations(){
    return _.union([
        {
            type :Backbone.HasOne,
            containingClass:"vdml_ValueElementContext",
            key:"formula",
            relatedModel:"vdml.Expression",
            reverseRelation: {
                key:"formulaOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
			type :Backbone.HasMany,
			containingClass:"vdml_ValueElementContext",
			key:"aggregatedFrom",
			relatedModel:"vdml.ValueElementContext",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_ValueElementContext",
			key:"aggregatedTo",
			relatedModel:"vdml.ValueElementContext",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
    ])
}

static getCumulativeMixinRelations(){
    if (!ValueElementContextMixin.cummulativeRelations) {
        ValueElementContextMixin.cummulativeRelations = _.union(ValueElementContextMixin.getMixinRelations()
            , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
        );
    }
    return ValueElementContextMixin.cummulativeRelations.slice();
}
static getSuperTypes(){
    return [
        "vdml_VdmlElement"
    ];
}
static getProperties(){
    return [
        { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
        { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
        {name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
        {name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
    ]
}

getParent(){
    var container;
    if(!container){
        container = this.get("contextOwner") ? this.get("contextOwner") : this.previousAttributes().contextOwner;
        if(container){
            return container;
        }
    }
}

getPackagePath(path){
    if(!path){
        path = [];
    }
    return VdmlElement.prototype.getPackagePath.apply(this, arguments);
}
getViewProperties(type){
    return {
        templatePath : "views/vdml/views/properties/ValueElementContextPropertiesTemplate.html",
        templateName : "ValueElementContextPropertiesTemplate",
        viewTypeStr : "appviews/vdml/views/properties/ValueElementContextViewModel",
        tabId : "ValueElementContextView",
        tabName: "ValueElementContext"
    }
}

    addBaseTuple(baseTuples,alt,measureChange){
        var self = this;
        for(var i=0;i<baseTuples.length;i++) {
            if(!baseTuples[i].old && baseTuples[i].new) {
                var component = baseTuples[i].new.component;
                this.get('aggregatedFrom').add(component);
                var context = component.getValueContext(alt);
                if(context && !context.get('aggregatedTo').findWhere({id:this.get('id')})) {
                    context.get('aggregatedTo').add(this);
                }
            }else if(baseTuples[i].old && !baseTuples[i].new){
                var component = baseTuples[i].old.component;
                var compCount = 0;
                for(var j=0;j<baseTuples.length;j++) {
                    if(baseTuples[j].old && baseTuples[j].old.component === component && baseTuples[j].new){
                        compCount++;
                    }
                }
                if(compCount === 0) {
                    this.get('aggregatedFrom').remove(component);
                    var context = component.getValueContext(alt);
                    if(context && context.get('aggregatedTo').findWhere({id:this.get('id')})) {
                        context.get('aggregatedTo').remove(this);
                    }
                }
            }else if(baseTuples[i].old && baseTuples[i].new){
                if(baseTuples[i].old.usePreviousAlternative != baseTuples[i].new.usePreviousAlternative){
                    var comp = baseTuples[i].new.component;
                    if(comp){
                        comp.onAggregatedFromAdded(comp);
                    }
                }
            }
        }
        if(measureChange) {
            var aggTo = this.get('aggregatedTo');
            var aggToConcat = aggTo.models.concat();
            _.each(aggToConcat,function(agg){
                self.get('aggregatedTo').remove(agg);
                agg.get('aggregatedFrom').remove(self);
            });
        }
    };

}
path.ValueElementContextMixin = ValueElementContextMixin;
