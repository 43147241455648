import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {UserPreferences} from '../../../../../version1/bo/preference/UserPreferences'
//import * as d3 from '../../../../../../libs/d3/d3.min'
import * as Highcharts from 'highcharts';
import {HighChartThemes} from '../../../../../com/vbee/utils/HighChartThemes'
import {ValueElementMixin} from '../../../../../version1/bo/vdml/ValueElementMixin'
import more from 'highcharts/highcharts-more';
more(Highcharts);
/*define(["require","jquery","jstree","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global","appbo/preference/UserPreferences","appbo/preference/Preference","d3","RadarChart", "appbo/vdml/ValueElementMixin","appcommon/com/vbee/utils/HighChartThemes", "highcharts-common", "bootstrap-multiselect", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize", "tick-positioner"],
function(require,$,jstree,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global,UserPreferences,Preference, d3, RadarChart,ValueElementMixin, HighChartThemes, Highcharts
){*/
    
    var path = DataManager.getDataManager().buildAppNsPath("vdml.views.chart",global.version);

    export class BMValuesChart{

//#startCustomMethods    

	dispose(){
		var self = this;
		if(!this.model){
			return;
		}		
		var preferences = this.getPreferenceValueToStore();
		function cleanViewData(self){
			if(preferences && preferences.selectedValues){
				preferences.selectedValues.length = 0;	
			}
			preferences = null;
			if(self.values){
				self.values.length = 0;	
			}			
			self.datasets = null;
			if(self.xAxisSelected){
				self.xAxisSelected.length = 0;			
			}
			window.cleanViewModel(self);
		}
		if(self.dataTable){//destroy previously created datatable
			self.dataTable.destroy(true);
		}
		$.each(Highcharts.charts, function(i, chart){
        	if(chart != undefined){
        		chart.destroy();
        	}
        });
        Highcharts.charts.length = 0;
		if(self.plot){
			//self.plot.shutdown();
		}
		$("#xaxis"+self.encodeId).jstree('destroy');
		$(window).unbind('resize'); 
		//$('#splitDiv').unbind('splitter.resize');
		var email = DataManager.getDataManager().get('email');
		if(this.savedPreference === true || this.savedPreference === undefined || !email || email === ""){
			this.savedPreference = true;
			cleanViewData(self);
			return;
		}
		this.savedPreference = true;
		var repModel = self.filterVP ? self.filterVP : self.model;
		var rep = DataManager.getDataManager().getRepositoryId(repModel.get('id'));
		if (self.chartDocument) {
		    self.chartDocument.set('configuration', JSON.stringify(preferences));
		}else if(self.filterVP){
			UserPreferences.setUserPreference(rep, 'VPValuesChart', repModel.get('id'), JSON.stringify(preferences));
		}else {
		    UserPreferences.setUserPreference(rep, 'BMValuesChart', repModel.get('id'), JSON.stringify(preferences));
		}
		self.chartDocument = null;
		cleanViewData(self);
	};

	getPreferenceValueToStore() {
	    var self = this;
		var preference = {};
		preference.xAxisSelected = this.xAxisSelected;
		var selectedValues = $("#yaxis" + self.encodeId + " option:selected");
		preference.selectedValues = [];
		_.each(selectedValues,function(valueOption){
			preference.selectedValues.push(valueOption.value);
		});
		preference.selectedChartType = self.selectedChartType();
		preference.selectedTheme = self.selectedTheme();
		//preference.yaxis1Value = $("#yaxis1").val();
		//preference.yaxis2Value = $("#yaxis2").val();
		return preference;
	};	
	
	getBMValues(alts,bm,callback){
		var self = this;
		async.eachSeries(alts,function(alt,altHandled){
			alt.getModelLinked(bm,function(model){
                if (model) {
                    if (self.filterVP && self.filterVP.get('type') === "vdml_Activity"){
                        self.getActivityValues(model);
                    } else {
                        self.getValues(model);
                    }
				}
				altHandled();
			})
		},function(){
			self.values = _.unique(self.values);
			callback();
		});
	};
	fiterValue(vp) {
	    var self = this;
	    if (self.filterVP) {
	        if (!self.filterVPSuffix) {
	            self.filterVPSuffix = self.filterVP.id.substr(self.filterVP.id.lastIndexOf('@') + 1);
	        }
	        var vpSUffix = vp.id.substr(vp.id.lastIndexOf('@') + 1);
	        if (vpSUffix === self.filterVPSuffix) {
	            return false;
	        } else {
	            return true;
	        }
	    }
	    return false;
    }
    getActivityValues(model) {
        var self = this;
        var bmActivities = model.get('bmActivity');
        bmActivities.each(function (bmAct) {
            var act = bmAct.get('activity');
            if (act) {
                if (self.fiterValue(act)) {
                    return;
                }
                act.get('containedPort').each(function (port) {
                    if (port.get('type') === "vdml_OutputPort") {
                        port.get('valueAdd').each(function (valueAdd) {
                            var valMest = valueAdd.get('valueMeasurement');
                            self.addValue(valMest);
                        });
                    }
                });
            }
        });
    }

	getValues(model) {
	    var self = this;
	    var bmVPValues = model.get('bmValueProposition');
	    var bmVFValues = model.get('bmValueFormula');
	    var bmRoles = model.getBusinessRoles();
	    if (self.allRoles) {
	        bmRoles = self.model.get('bmRole').models;
	    }
	    bmVPValues.each(function (bmVPValue) {
	        var vp = bmVPValue.get('valueProposition');
	        if (vp) {
	            if (self.fiterValue(vp)) {
	                return;
	            }
	            var vpProvider = vp.get('provider');
	            if (_.indexOf(bmRoles, vpProvider) >= 0) {
	                var vpComponents = vp.get('component');
	                vpComponents.each(function (vpComp) {
	                    var valMest = vpComp.get('valueMeasurement');
	                    //values.push(valMest);
	                    if(valMest){
							self.addValue(valMest);
						}
	                });
	            }
	        }
	    });
	    bmVFValues.each(function (bmVFValue) {
	        var vp = bmVFValue.get('valueFormula');
	        if (vp) {
	            if (self.fiterValue(vp)) {
	                return;
	            }
	            var vpProvider = vp.get('provider');
	            if (_.indexOf(bmRoles, vpProvider) >= 0) {
	                var vpComponents = vp.get('component');
	                vpComponents.each(function (vpComp) {
	                    var valMest = vpComp.get('valueMeasurement');
	                    //values.push(valMest);
						if(valMest){
							self.addValue(valMest);
						}
	                });
	            }
	        }
	    });

	};
	addValue(val){
		var self = this;
		var modelId = val.get('id');
		var valSuffix = modelId.substr(modelId.lastIndexOf('@')+1);
		var found = false;
		_.each(self.values,function(addedVal){
			var addedModelId = addedVal.get('id');
			var addedValSuffix = addedModelId.substr(addedModelId.lastIndexOf('@')+1);
			if(addedValSuffix === valSuffix){
				found = true;
			}			
		});
		if(!found){
			self.values.push(val);
		}
	}

	initialize(bm,refresh,parentView,filterVP,chartDocument,chartConfig){
		var self = this;
		self.parentView = parentView;
		self.savedPreference = false;
		self.filterVP = filterVP;
		if(!self.model){
			self.model = bm;
		}
		var preference;
		var xAxisData;
		var instance;
		if (chartDocument) {
		    self.chartDocument = chartDocument;
		}
		var selectedChartType = self.selectedChartType();
		if (!refresh) {
		    if (self.chartDocument && chartConfig) {
		        preference = JSON.parse(chartConfig);
		    } else {
            	var filterVPId = self.filterVP ? self.filterVP.get('id') : null;
            	var chartType = filterVPId ? 'VPValuesChart' : 'BMValuesChart';
            	preference = window.getSavedPreference(self.model.get('id'), filterVPId, chartType);
		    }
		    if(preference && preference.selectedTheme){
		    	self.selectedTheme(preference.selectedTheme);
		    }
		    // var container = $("#chart-container" + self.encodeId);
		    // container.resizable({
		    //     handles: 'n, s',
		    //     resize: function () {
		    //         self.plotAccordingToChoices();
		    //     }
		    // })
		}else{
		    instance = $.jstree.reference("#xaxis" + self.encodeId);
		}
		xAxisData = self.getXAxisData(preference,refresh);
		var yaxisNode = $("#yaxis" + self.encodeId);
		//var yaxis1Node = $("#yaxis1");
		//var yaxis2Node = $("#yaxis2");
		var xaxisNode = $("#xaxis" + self.encodeId);
		var reDraw = false;
		//self.showBars = false;
		self.showBars = selectedChartType === "Bars" || selectedChartType === "Stacked bars" || selectedChartType === "Columns" || selectedChartType === "Stacked columns"? true : false;
		self.maxUnitsPermitted = selectedChartType === "Stacked bars" || selectedChartType === "Stacked columns"? 1: 2;
		self.showRadar(false);
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({nextPhase:null});
		var phasesCount =0;
		while(phase){
			var alts = phase.get('phaseAlternative');
			for(var i=0;i<alts.length;i++){
				var alt = alts.at(i);
				if(preference && (_.indexOf(preference.xAxisSelected,alt.get('id')) >= 0)){
					phasesCount++;
					break;
				}
				if(!preference){
					if(refresh){
						if(instance.is_selected(alt.get('id'))){
							phasesCount++;
							break;
						}
					}else{
						phasesCount++;
						break;
					}
				}
			}
			phase = phase.get('previousPhase');
		}	
		self.alts = self.getAlternatives();
		var plan = DataManager.getDataManager().get('currentPlan');
		if(refresh){
		}else{
			$("#chartTooltip").css({
				position: "absolute",
				display: "none",
				border: "1px solid #fdd",
				padding: "2px",
				"background-color": "#fee",
				opacity: 0.80
			});					
			xaxisNode.jstree({ 'core' : {'data' : xAxisData,'themes':{"icons":false,"dots":false}},
			  "checkbox" : {
			    "three_state" : true
			  },		
			"plugins" : [ "wholerow", "checkbox" ]
			}).on("changed.jstree", function (e, data) {
				if(reDraw === true){
					self.xAxisSelected = data.selected;
					showValues();
					reDraw = false;				
				}else{
					self.xAxisSelected = data.selected;
				}
			}).on("select_node.jstree", function (e, data) {
				var currentNode = data.node.id;
				var parentNode = data.node.parent;
				if(parentNode === '#'){
					//self.showBars = true;
					self.showRadar(false);
					var phases = plan.get('phase');
					var deSelectedNodes = 0;
					phases.each(function(phase){
						if(phase.get('id') !== currentNode){
							data.instance.deselect_node(phase.get('id'));
							deSelectedNodes++;
							var phaseAlts = phase.get('phaseAlternative');
							phaseAlts.each(function(alt){
								data.instance.deselect_node(alt.get('id'));
							});
						}
					});
					if(deSelectedNodes >= 0){
						reDraw = true;
					}
				}else{
					var parentPhase = plan.get('phase').get(parentNode);
					if(parentPhase){
						var children = data.instance.get_node(parentNode).children;
						_.each(children, function (childNode) {
						    if (childNode !== currentNode) {
						        data.instance.deselect_node(childNode);
						    }
						});
						reDraw = true;
					}
					var phases = plan.get('phase');
					phases.each(function(phase){
						var selectedNodes = 0;
						if(phase.get('id') !== parentPhase){
							var children = data.instance.get_node(phase.get('id')).children;
							_.each(children, function (childNode) {
							    if (data.instance.is_selected(childNode)) {
							        if (selectedNodes > 0) {
							            data.instance.deselect_node(childNode);
							        }
							        selectedNodes++;
							        //self.showBars = false;
							    }
							});
						}
						if (selectedNodes > 2) {
                        	self.showRadar(true);
                        }
					});
					
					
				}
			}).on("deselect_node.jstree", function (e, data) {
						reDraw = true;
			}).on("open_node.jstree", function (e, data) {
				if(self.parentView && self.parentView.setResizeHeight){
					self.parentView.setResizeHeight();
				}
			}).on("close_node.jstree", function (e, data) {
				if(self.parentView && self.parentView.setResizeHeight){
					self.parentView.setResizeHeight();
				}
			});			
			if(self.parentView && self.parentView.setResizeHeight){
				self.parentView.setResizeHeight();
			}			
		}
		if(refresh){
			if(this.values){
				this.values.length = 0;
			}else{
				this.values = [];	
			}
		}		
		self.getBMValues(self.alts,self.model,function(){
			showValues(preference,!refresh);
		});
		function showValues(preference,initializeMultiselect){
			self.getValueMeasurements(self.alts,function(){
				
				if(initializeMultiselect){
					self.refreshValueMeasurements = false;
					$('#yaxis' + self.encodeId).multiselect({
		            	enableClickableOptGroups: false,
						numberDisplayed : 0,
		            	//includeSelectAllOption: true,
		            	onDropdownShow:function(){
							$('.multiselect-container').css({ 'left': "auto", 'right': "0"  });
		            		if(self.plot){
		            		    $("#placeholder" + self.encodeId).css('min-width', '10px');	            		
		            		}
		            	},
						optionClass: function () {
							return 'chartLiWidth';
						},
						onDropdownShown : function(){
							var newHeight =  $('#Progress').height() + $('.multiselect-container.dropdown-menu').height();
							$('#Progress').css('height', newHeight);
							if(self.parentView && self.parentView.setResizeHeight){
								self.parentView.setResizeHeight(false);
							}
						},
						onDropdownHidden : function(){
							$('#Progress').css('height', 'auto');
							if(self.parentView && self.parentView.setResizeHeight){
								self.parentView.setResizeHeight(false);
							}
						},
		            	onChange: function(option, checked) {
		            		if(self.refreshValueMeasurements){
		            			self.refreshValueMeasurements = false;
			            		self.getValueMeasurements(self.alts,function(){
									self.plotValues();
									self.plotAccordingToChoices();
							    });
		            		}else{
		            			self.plotValues();
		            			self.plotAccordingToChoices()
		            			var newHeight = $('#Progress').height() + $('div#Progress div#legend' + self.encodeId).height();
		            			if(checked) {
		            				newHeight = newHeight + 5;
		            			}else {
		            				newHeight = newHeight - 5;
		            			}
								$('#Progress').css('height', newHeight);
								if(self.parentView && self.parentView.setResizeHeight){
									self.parentView.setResizeHeight(true);
								}
		            		}		            	
		            		
						}
		        	});					
				}
				else{
					self.refreshValueMeasurements = true;
				}
				var i = 0;
				$.each(self.datasets, function(key, val) {
					val.color = i;
					++i;
				});
				//var oldYAxis1Val = yaxis1Node.val();
				//var oldYAxis2Val = yaxis2Node.val();
				var selectedValues = $("#yaxis" + self.encodeId + " option:selected");
				var oldSelectedValues = [];
				_.each(selectedValues,function(valueOption){
					oldSelectedValues.push(valueOption.value);
				});
		
				yaxisNode.empty();				
				//yaxis1Node.empty();
				//yaxis2Node.empty();
		
				var count =0;
				var count1Key;

				//yaxis2Node.append("<option value=''>Empty</option>");
				//sorting object key value pair
				var values = [];
				$.each(self.datasets, function(key, val) {
					values.push({'key':key, 'value':val});	
				});
				//2 times sortng needed(1 for name and another for unit)
				values.sort(function(a,b){
					if(a.value.unit.toLowerCase() < b.value.unit.toLowerCase()){
						return -1;
					}
					else if(a.value.unit.toLowerCase() > b.value.unit.toLowerCase()){
						return 1;
					}
					return 0;
				});
				values.sort(function(a,b){
					if(a.value.valueName.toLowerCase() < b.value.valueName.toLowerCase()){
						return -1;
					}
					else if(a.value.valueName.toLowerCase() > b.value.valueName.toLowerCase()){
						return 1;
					}
					return 0;
				});
				self.datasets = {};
                for(var i=0; i < values.length; i++){
                	 self.datasets[values[i].key] = values[i].value;
                }
				$.each(self.datasets, function(key, val) {
					var showValue = false;
					for(var i=0;i<val.data.length;i++){
						if(self.showBars && val.data[i][0].indexOf('Ph') > -1){
							var replacedString = val.data[i][0].replace('Ph','Alt');
							val.data[i][0] = replacedString;
						}
						if(val.data[i][1] != null){
							showValue = true;
							break;
						}
					}
					if(showValue) {
					    var multiselectNode = $('#yaxis' + self.encodeId);
					    var unitString = window.utils.restoreEscapeCharacters(val.unit);
						var unitOptionGroupNode = multiselectNode.children('optgroup[label="' + unitString + '"]');
						var optionGroupNode;
						if(unitOptionGroupNode.length === 0){
							multiselectNode.append('<optgroup'+ ' label="' +  unitString + '"></optgroup>');
							unitOptionGroupNode = multiselectNode.children('optgroup[label="' + unitString + '"]');
						}
						optionGroupNode = unitOptionGroupNode.first();
						var valSuffix = window.utils.restoreEscapeCharacters(key.substr(key.lastIndexOf('@') + 1));
						if(optionGroupNode.has('>option[value="' + valSuffix + '"]').length === 0){
							optionGroupNode.append('<option'+ ' value="' +  valSuffix + '"' + ' hoverLabel="'+val.hoverDisplayName+'" valueName = "' + val.valueName +'" label="' +  val.displayName +'">'+ val.displayName + '</option>');	
						}
						if(count === 1){
							count1Key = key;
						}
						count++;
					}
				});
				$('#yaxis' + self.encodeId).multiselect('rebuild');
				if(self.filterVP && !preference && !self.pageLength || (self.filterVP && preference && preference.selectedValues.length == 0 && !self.pageLength)){
					var defaultUnitsArray = [];
                    var units = $("#yaxis" + self.encodeId + ' option');
                    if (units && units.length > 0) {
                        for(var i=0;i<units.length;i++){
                        	defaultUnitsArray.push(units[i].value);
                        }
                	}
                	if (!preference) {
                        preference = {};
                    }
                    self.pageLength = preference.selectedChartPageLength ? Number(preference.selectedChartPageLength):Number('5');
                    preference.selectedValues = defaultUnitsArray;
                    preference.selectedChartType = "Table";
                    for (var i = 0; i < preference.selectedValues.length; i++) {
                        var label = window.utils.restoreEscapeCharacters(preference.selectedValues[i]);
                        if ($('#yaxis' + self.encodeId + ' option[value="' + label + '"]').length > 0) {
                            $('#yaxis' + self.encodeId).multiselect('select', label);
                        }
                    }
				}
				else if(preference && preference.selectedValues && preference.selectedValues.length > 0){
					for(var i=0;i<preference.selectedValues.length;i++){
					    if ($('#yaxis' + self.encodeId + ' option[value="' + preference.selectedValues[i] + '"]').length > 0) {
					        $('#yaxis' + self.encodeId).multiselect('select', preference.selectedValues[i]);
						}
						
					}
				}else if(oldSelectedValues.length > 0){
					for(var i=0;i<oldSelectedValues.length;i++){
					    if ($('#yaxis' + self.encodeId + ' option[value="' + oldSelectedValues[i] + '"]').length > 0) {
					        $('#yaxis' + self.encodeId).multiselect('select', oldSelectedValues[i]);
						}
					}					
				}else if(preference && preference.selectedValues && preference.selectedValues.length === 0 && !self.filterVP){
					var defaultUnitsArray = [];
					var units = $("#yaxis" + self.encodeId +' option');
					if(units.length>0){
						defaultUnitsArray.push(units[0].value);
					}
					if(units.length>1 && self.maxUnitsPermitted !== 1){
						defaultUnitsArray.push(units[1].value);
					}
					preference.selectedValues = defaultUnitsArray;
					for(var i=0;i<preference.selectedValues.length;i++){
						var label = window.utils.restoreEscapeCharacters(preference.selectedValues[i]);
					    if ($('#yaxis' + self.encodeId + ' option[value="' + label + '"]').length > 0) {
					        $('#yaxis' + self.encodeId).multiselect('select', label);
						}						
					}
				}
				oldSelectedValues.length = 0;
				if(self.parentView && self.parentView.ChartDetailsViewModel && preference){
					preference.selectedChartType = ""
				}
				if(preference && preference.selectedChartType){
                	self.selectedChartType(preference.selectedChartType);
                }
				self.plotValues();
				self.plotAccordingToChoices();			
			});
			$('#dropdown' + self.encodeId).off('click').on('click', function(e){
				$("#dropdownMenu" + self.encodeId).toggle();
			});
			$('#splitPanels').unbind('click');
			if(!self.filterVP) {
				$('#splitPanels').on('click', function (e) {
				    if (!e.target.closest('.dropdown-menu-right') && !(e.target.id ==='dropdown'+self.encodeId) && !e.target.closest('.ui-datepicker-header') && !e.target.closest('.ui-datepicker')){
				        $(".dropdown-menu-right").hide();
				    }
				});
			}else {
				$('#chart'+self.encodeId).on('click', function (e) {
				    if (!e.target.closest('.dropdown-menu-right') && !(e.target.id ==='dropdown'+self.encodeId)){
				        $(".dropdown-menu-right").hide();
				    }
				});
			}
		}
	};
	
	plotValues() {
		var self = this;
		var group1 = "";
		var group2 = "";
		var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
		selectedOptions.each(function() {
			if(group1 == ""){
					group1 = $(this).parent()[0].label;
			}
			else if(group2 == "" && $(this).parent()[0].label !== group1 && $(this).parent()[0].label != 'Grade only'){
					if(self.selectedChartType() === "Stacked bars" || self.selectedChartType() === "Stacked columns"){
						//unselect group2 if selected
						//$('#yaxis' + self.encodeId).multiselect('deselect', $(this)[0].value);
					}else{
						group2 = $(this).parent()[0].label;
					}
		    }
		    if(self.isSatisfactionOnly($(this).parent()[0].label)){
		    	var input = $('input[value="' + $(this).val() + '"]');
				//input.prop('checked', false);
				//$(this).removeAttr('selected');
				input.parent().css({'cursor' :"no-drop"});
				if (self.selectedChartType() === 'Table') {
					input.closest('li').addClass('active');
				}else {
					input.closest('li').removeClass('active');
				}
				//$('#yaxis' + self.encodeId).multiselect('updateButtonText');
			}
		});
		if (selectedOptions.length > 2) {
        	self.showRadar(true);
        } else {
		    self.showRadar(false);
		    if (self.selectedChartType() === 'Radar') {
		        self.selectedChartType('Curves');
		    }
        }
        
        function disableCheckBoxes(nonSelectedGroups){
        	nonSelectedGroups.each(function() {
				var children = $(this).children();
				children.each(function() {
						var input = $('input[value="' + $(this).val() + '"]');
						input.prop('disabled', true);
						input.parent().css({'cursor' :"no-drop"});
						input.parent('li').addClass('disabled');
				 });
			});
		}
							    
		//if two groups selected
		if (group1 !== "" && group2 !== "" && (self.selectedChartType() === 'Curves' || self.selectedChartType() === 'Bars' || self.selectedChartType() === 'Columns')) {
	        var allgroups = $('#yaxis'+ self.encodeId + ' optgroup');
							    	
			//filtering other groups for disabling childs
	        var nonSelectedGroups = $('#yaxis'+ self.encodeId + ' optgroup').filter(function() {
					return !(($(this)[0].label == group1) || ($(this)[0].label == group2));
				});
							        
			//disabling childs
			disableCheckBoxes(nonSelectedGroups);
		}
		else if(group1 !== "" && (self.selectedChartType() === 'Stacked bars' || self.selectedChartType() === 'Stacked columns' )){
			//disable other checkboxes
			var nonSelectedGroups = $('#yaxis' + self.encodeId + ' optgroup').filter(function () {
				return !($(this)[0].label == group1);
			});
			disableCheckBoxes(nonSelectedGroups);
		}
		else {
				 // Enable all checkboxes.
	        var dropdown = $('#yaxis' + self.encodeId).siblings('.multiselect-container');
	        $('#yaxis'+ self.encodeId + ' option').each(function() {
					var input = $('input[value="' + $(this).val() + '"]');
					if(self.isSatisfactionOnly($(this).closest('optgroup').attr('label'))){
						input.prop('disabled', true);
						input.parent().css({'cursor' :"no-drop"});
					}else {
						input.prop('disabled', false);
						input.parent().css({'cursor' :"pointer"});
						input.parent('li').addClass('disabled');
					}
				});
			}
	};
	
	isSatisfactionOnly(valParentName) {
		var self = this;
		if(self.selectedChartType() !== 'Table' && valParentName === 'Grade only'/*valName.indexOf('SatisfactionOnly') > -1*/){
			return true;
		}else {
			return false;
		}	
	}
	
	plotFlotChart() {
		var self = this;
		var selectedChartType = self.selectedChartType();
		var data = [];
		var yaxis = 1;
		var legendNode = $('#legend' + self.encodeId);
		legendNode.show();
		self.legendColumnCount = 1;
		if(self.datasets && Object.keys(self.datasets).length > 10){
			self.legendColumnCount = 2;
		}
		/*var options = {
			legend:{
				itemStyle:{
					fontWeight:'normal'	
				},
				show:true,
				noColumns:self.legendColumnCount,
				container:legendNode,
				sorted:function(a,b){
					var aLabel,aUnit;
					var bLabel,bUnit;
					aLabel = a.label.substr(0,a.label.indexOf('('));
					aUnit = a.label.substr(aLabel.length);
					bLabel = b.label.substr(0,b.label.indexOf('('));
					bUnit = b.label.substr(bLabel.length);
					if(aUnit === bUnit){
						if(aLabel < bLabel){
							return -1;
						}
						if(aLabel > bLabel){
							return 1;
						}
						return 0;
					}
					if(aUnit < bUnit){
						return -1;
					}
					if(aUnit > bUnit){
						return 1;
					}
					return 0;
				}							
			},
		    series: {
		        lines: { show: true, lineWidth: 2 },
		        points: { show: true, lineWidth: 2 }
		    },
			xaxis: {
				labels: {
					style :{
	            		fontSize: '12px'
	            	},
            	},
				mode: "categories",
				tickLength: 0,
				//rotateTicks: 140,
				autoscaleMargin: self.margin
			},
			yaxes:[{},{tickLength: 0,position:'right',alignTicksWithAxis:1}],
			grid: {
			    hoverable: true
			},
			colors: ["#d18b2c", "#2175d9", "#880000","#006666","#ff0066","#3d107b","#003f72","#ceaeff","#003366","#0066ff"]
		};

		if(self.showBars){
			delete options.series;
			$.each(self.datasets, function(key, val) {
				val.bars = {show: true,barWidth:0.2,fill:true,lineWidth:1,align:'center'};
			})					
		}*/
		var yaxis1Val;// = yaxis1Node.val();
		var yaxis2Val;
		var units = [];
		var selectedUnits = $("#yaxis" + self.encodeId + " option:selected").parent();
		for(var i=0;i<selectedUnits.length;i++){
			if(selectedUnits[i].label !== 'Grade only'){
				units.push(selectedUnits[i]);
			}			
		}
		if(units.length > 0){
		    yaxis1Val =  units[0].label;
		}
		if(units.length > 1 && self.maxUnitsPermitted !== 1){
		    yaxis2Val =  units[1].label;
		}					
		if(yaxis2Val === yaxis1Val){
			yaxis2Val = null;
		}
		var values = Object.getOwnPropertyNames(self.datasets);
		var color = 0;

		for(var i=0;i<values.length;i++){
			if (values[i].indexOf('Grade only') != -1 && self.selectedChartType() !== 'Table') {
	            continue;
	        }
			var valueData = self.datasets[values[i]];
			var valSuffix = window.utils.restoreEscapeCharacters(values[i].substr(values[i].lastIndexOf('@') + 1));
			if($('#yaxis'+ self.encodeId + ' option:selected[value="' + valSuffix +'"]').length===0){
				//var unit = $("#yaxis option:selected[value='" + valueData.label +"']").parent()[0].label;
				continue;
			}
			var unit = window.utils.restoreEscapeCharacters(valueData.unit);
			valueData.yAxis = undefined;
			if (self.selectedChartType() === 'Curves' || self.selectedChartType() === 'Bars' || self.selectedChartType() === 'Columns') {
			    if (yaxis1Val && yaxis1Val === unit) {
			        //valueData.yaxis = 1;
			        valueData.color = color++;
			        data.push(valueData);

			    }
			    if (yaxis2Val && yaxis2Val === unit) {
			        valueData.yAxis = 1;
			        valueData.color = color++;
			        data.push(valueData);
			    }
			} else {
			    data.push(valueData);
			}
		}
							
		if (data.length > 0) {
		    var placeholder = $("#placeholder" + self.encodeId);
			data.sort(function(a,b){
				if(a.yaxis === b.yaxis){
					if(a.label < b.label){
						return -1;
					}
					if(a.label > b.label){
						return 1;
					}
					return 0;
				}
				if(a.yaxis < b.yaxis){
					return -1;
				}
				if(a.yaxis > b.yaxis){
					return 1;
				}
				return 0;
			});
			$('#altLegend' + self.encodeId).show().empty();
			var altIndexes = Object.getOwnPropertyNames(self.altIndex);
			_.each(altIndexes,function(key){
				var altIndexVal = self.altIndex[key];
				//if(self.showBars){
				    $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
				/*}else{
				    $('#altLegend' + self.encodeId).append($("<div>Ph-" + altIndexVal + " : " + key + "</div>"));
				}*/
				
			});
			if (self.selectedChartType() === 'Table') {
			    self.displayTable(data);
			    $('#selectTheme'+self.encodeId).hide();
			} else {
				$('#selectTheme'+self.encodeId).show();
			    var unit1 = undefined, unit2 = undefined, chartData = [];
			    //var selectedCount = self.alts.length;

		        _.each(data, function(val, i){
		    		var newDataset = {};
		    		newDataset.name = val.label;
	    			newDataset.tooltip = {
		               headerFormat: '<b>'+val.label+'</b><br>',
		               pointFormat: "Value: {point.y:."+val.significantDecimals+"f} ("+val.unit + ')'
		            };
		    		if(val.yAxis === 1){
		    			unit2 = val.unit;
		    			newDataset.yAxis = val.yAxis;
		    		}else{
		    			unit1 = val.unit;
		    		}
		    		var newDataArray = [];
		    		//var altOrScenarioArray = [];
		    		_.each(val.data, function(valData){
		    			var newData = [];
		    			newData.push(valData[0]);
		    			//altOrScenarioArray.push(valData[0]);
						newData.push(valData[1] === "-"? null : valData[1]);
		    			
		    			newDataArray.push(newData);
		    		});
		    		/*if(selectedCount){
		    			var count = selectedCount;
		    			while(count > 0){
	    					var altName = "Alt-"+(count-1);
	    					if(altOrScenarioArray.indexOf(altName) === -1){
	    						var newData = [];
				    			newData.push(altName);
				    			newData.push(null);
				    			newDataArray.push(newData);
	    					}
		    				count--;
		    			}
		    		}*/
		    		newDataArray.sort();
		    		newDataset.data = newDataArray;
		    		chartData.push(newDataset);
		    	});

		    	var chartType;
		    	if(selectedChartType === "Bars" || selectedChartType === "Stacked bars"){
		    		chartType = 'bar'
		    	}else if(selectedChartType === "Columns" || selectedChartType === "Stacked columns"){
		    		chartType = 'column'
		    	}else if(selectedChartType === "Curves"){
		    		chartType = 'spline'
		    	}
		    	var xAxisObj = {
			    	tickInterval : 1,
			        labels: {
			        	style :{
			            		fontSize: '12px'
			            	},
			            enabled: true,
			            formatter: function(){ 
							var colName = chartData[0].data[this.value]? chartData[0].data[this.value][0] : null;
							if(!colName && chartData[i]){
								for(var i=1;i<chartData.length;i++){
									colName = chartData[i].data[this.value]? chartData[i].data[this.value][0] : null;
									if(colName){
										break;
									}
								}
							}
							return colName?colName:'';
						},
			        },
			        minRange:1
			    }
		    	var yaxesObj;
		    	if(units.length > 1){
				    yaxesObj = [{
				    	title: {
				            text: unit1
				        },
				    	labels: {
				    		style :{
			            		fontSize: '12px'
			            	},
				            format: '{value}'
				        },
        				gridLineWidth: 1

				    },
				    {
				    	title: {
				            text: unit2
				        },
				    	labels: {
				            format: '{value}'
				        },
    					opposite: true
				    }]
				}else{
					yaxesObj = {
				    	title: {
				            text: unit1
				        },
				    	labels: {
				    		style :{
			            		fontSize: '12px'
			            	},
				            format: '{value}'
				        },
        				gridLineWidth: 1
				    }
				}
				self.plot = new Highcharts.Chart({
		    		colors: self.chartColors,
					chart: {
                        type: chartType,
                        zoomEnabled: true,
                        renderTo: "placeholder"  + self.encodeId,
						zoomType: 'xy',
                        resetZoomButton: {
                            position: {
                                align: 'right', // by default
                                verticalAlign: 'top' // by default
                            }
                        },
						events:{
							load:function () {
							this.series.map((d)=>{
								self.disabledValues.map((val)=>{
									if(d.name===val){
										d.update({
											visible: false
										});
									}
								})
							})
								const updateChartHeight = () => {
									const newHeight = 300 + this.series.length * 50;
									document.getElementById(("chart-container" + self.encodeId)).style.height = newHeight+"px"
								};
								updateChartHeight();
								}
							}
                    },
		    		title: {
					    text: '',
					    style: {
					        display: 'none'
					    }
					},
					credits: {
				        enabled: false
				    },
					subtitle: {
					    text: '',
					    style: {
					        display: 'none'
					    }
					},
					legend: {
				        itemStyle: {
				            fontWeight: 'normal'
				        }
				    },
				    yAxis: yaxesObj,
				    xAxis: xAxisObj,
				    plotOptions: {
				        series: {
				            label: {
				                connectorAllowed: false
				            },
							events:{
								legendItemClick:function(e){
									if(!self.disabledValues.includes(e.target.name)){
										self.disabledValues.push(e.target.name)
									}else {
										const index = self.disabledValues.indexOf(e.target.name);
										if (index > -1) {
											self.disabledValues.splice(index, 1);
										}
									}
								},
								
							},
							// pointWidth: 40,
							pointPadding: 0,
							groupPadding: 0,
							borderWidth: 0,
				            stacking: selectedChartType === "Stacked bars" || selectedChartType === "Stacked columns"? 'normal' :  null
				        }
				    },

				    series: chartData,

				    responsive: {
				        rules: [{
				            condition: {
				                maxWidth: 500
				            },
				            chartOptions: {
				                legend: {
				                    layout: 'horizontal',
				                    align: 'center',
				                    verticalAlign: 'bottom'
				                }
				            }
				        }]
				    }

				});    		}
			data.length = 0;
		}else{
		    var placeholder = $("#placeholder" + self.encodeId);
		    self.plot = new Highcharts.Chart({
			            colors: self.chartColors,
						chart: {
							type: chartType,
                            zoomEnabled: true,
							renderTo: "placeholder"  + self.encodeId,
							zoomType: 'xy',
							resetZoomButton: {
								position: {
									align: 'right', // by default
									verticalAlign: 'top' // by default
								}
							}
						},
						title: {
					        text: '',
						    style: {
						        display: 'none'
						    }
						},
						credits: {
					        enabled: false
					    },
						subtitle: {
						    text: '',
						    style: {
						        display: 'none'
						    }
						},series:[]});
		    $('#altLegend' + self.encodeId).empty().hide();
		}
		//$('#splitDiv').unbind('splitter.resize');
		/*$('#splitDiv').bind('splitter.resize',handleResize);
		$(window).resize(handleResize);
		function handleResize(){
			$("#placeholder").css('min-width','10px');
            self.plot.resize();
            self.plot.setupGrid();
            self.plot.draw();
            $('.legend-container').width($('.chart-container').width());
		}*/
	};
	plotPolarChart() {
	    var self = this;
	    $('#altLegend' + self.encodeId).show().empty();
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    _.each(altIndexes, function (key) {
	        var altIndexVal = self.altIndex[key];
            $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
	    });	
        var values = Object.getOwnPropertyNames(self.datasets);

        var selectedValueList = [], polarData = [], categoriesArray = [];//creating new data to populate charts

        //var selectedCount = self.alts.length;
	    async.each(altIndexes, function (key, keyHandled) {
	    	var newDataset = {};
	    	var newDataArray = [];
	    	var altIndexVal = self.altIndex[key];
            newDataset.name = "Alt-" + altIndexVal;
         	newDataset.pointPlacement = 'on';
	    	async.each(values, function (value, valueHandled) {
	    		var dataset = self.datasets[value];
	    		var valueSuffix = window.utils.getSuffix(value);
	    		var val = undefined;
	    		async.each(dataset.data, function(valData, dataHandled){
	    			if(valData[0] === newDataset.name){
		    			val = valData[1] === "-"? null : valData[1];
		    			var valueIndex =_.filter(selectedValueList,function(obj){return obj.id === valueSuffix;});
		                if(valueIndex && valueIndex[0]){
		                	valueIndex[0].value.push(val);
		                }else {
		                	selectedValueList.push({'id':valueSuffix,value:[val],valId:dataset.valId});
		                }
		    			dataHandled();
		    			return;
	    			}else{
	    				dataHandled();
	    			}
	    		},function(){
	    			var valData = {};
	    			valData['valSuffix'] = valueSuffix;
	    			if(val != undefined){
	    				valData['value'] = val;
	    			}else{
	    				valData['value'] = null;
	    			}
	    			var indivisualDataWithTooltip = {};
	    			var selectedOptions =  $('#yaxis' + self.encodeId + ' option:selected');
	    			var selected = false;
	    			for(var i = 0; i < selectedOptions.length; i++){
	    				if(selectedOptions[i].value === valData.valSuffix){
	    					selected = true;
	    				}
	    			}
	    			indivisualDataWithTooltip['y'] = valData;
	    			indivisualDataWithTooltip['tooltip'] = window.utils.getRoundedValues(valData.value, dataset.significantDecimals, true);
	    			if(selected){
		    			newDataArray.push(indivisualDataWithTooltip);
		    			categoriesArray.push(dataset.label);
		    			categoriesArray = _.uniq(categoriesArray);
	    			}
	    			valueHandled();
	    		})
	    	}, function () {
	    		newDataset.data = newDataArray;
	    		polarData.push(newDataset);
	    		keyHandled();
	    	})
	    }, function () {
	    	for(var m=0;m<selectedValueList.length;m++){
	    		var min = Math.min.apply(Math,selectedValueList[m].value.map(function(o){return o;}));
		    	if(min < 0){
		    		selectedValueList[m].value = selectedValueList[m].value.map(function(o){
		    			return o = o - min;
		    		});
		    	}
		    	var max = Math.max.apply(Math,selectedValueList[m].value.map(function(o){return o;}));	    	
			    for(var i=0;i<polarData.length;i++){
			    	var valueList = polarData[i].data;
			    	var valueListIndex =_.filter(valueList,function(obj){return obj['y'] && obj['y'].valSuffix === selectedValueList[m].id;});
			    	if(min < 0 && valueListIndex && valueListIndex[0] && valueListIndex[0]['y'].value !== selectedValueList[m].value[i]){
			    		//Replacing polardata values when selectedvaluelist is changed
			    		valueListIndex[0]['y'].value = selectedValueList[m].value[i];
			    	}
			    	if(valueListIndex && valueListIndex[0] && valueListIndex[0]['y']){
			    		var oldValue = valueListIndex[0]['y'].value;
			    		if(max == 0 || isNaN(max)){
			    			max = 1;
			    		}
			    		if(oldValue !== '' || !isNaN(oldValue) || !isNaN(max)){
			    			var valuePercentage = oldValue/max * 100;
			    			if(isNaN(valuePercentage)){
			    				valuePercentage = 0
			    			}
				    		if(valuePercentage < -100){
				    			valuePercentage = -100;
				    		}
				    		var index = valueList.map(function (val) { return val['y'] && val['y'].valSuffix ; }).indexOf(selectedValueList[m].id);
				    		valueList[index]['y'] = window.utils.getRoundedValues(valuePercentage, 2, true);
			    		}
			    	}
			    }
			}
	    	var chartType;
	    	if(self.selectedChartType() === "Polar"){
	    		chartType =  'area'
	    	}else if(self.selectedChartType() === "Spiderweb"){
	    		chartType = 'line'
	    	}
	        new Highcharts.Chart({
			    colors: self.chartColors,
				chart: {
                    polar : true,
					type: chartType,
                    zoomEnabled: true,
                    renderTo: "placeholder"  + self.encodeId,
					zoomType: 'xy',
                    resetZoomButton: {
                        position: {
                            align: 'right', // by default
                            verticalAlign: 'top' // by default
                        }
                    },
					events:{
						load:function () {
						this.series.map((d)=>{
							self.disabledValues.map((val)=>{
								if(d.name===val){
									d.update({
										visible: false
									});
								}
							})
						})
						const updateChartHeight = () => {
							const newHeight = 300 + this.series.length * 50;
							document.getElementById(("chart-container" + self.encodeId)).style.height = newHeight+"px"
						};
						updateChartHeight();
						}
						}
                },
			    title: {
				    text: '',
				    style: {
				        display: 'none'
				    }
				},
				credits: {
				        enabled: false
				    },
				subtitle: {
				    text: '',
			    style: {
				        display: 'none'
				    }
				},
				legend: {
			        itemStyle: {
			            fontWeight: 'normal'
			        }
			    },
			    pane: {
			        size: '70%'
			    },
			    responsive: {
			        rules: [{
			            condition: {
			                maxWidth: 500
			            },
			            chartOptions: {
			                legend: {
			                    layout: 'horizontal',
			                    align: 'center',
			                    verticalAlign: 'bottom'
			                }
			            }
			        }]
			    },

			    xAxis: {
			    	categories: categoriesArray,
			        tickmarkPlacement: 'on',
			        labels: {
			        	style :{
			            		fontSize: '12px'
			            	},
			            enabled: true,
			            labels: {
			                align: 'center',
			                distance: 43
			            }
			        },
			        lineWidth: 0
			    },

			    yAxis: {
			    	gridLineInterpolation: 'polygon',
			    	lineWidth: 0,
			        endOnTick: true,
            		showLastLabel: true,
            		labels: {
		                enabled: false,
		            },
		            max: 100
			    },tooltip: {
				    formatter: function() {
				        return this.key+'<br>Value: '+this.point.tooltip;
				    }
				},
			    series: polarData,
				plotOptions: {
					series: {
						events:{
							legendItemClick:function(e){
								if(!self.disabledValues.includes(e.target.name)){
									self.disabledValues.push(e.target.name)
								}else {
									const index = self.disabledValues.indexOf(e.target.name);
									if (index > -1) {
										self.disabledValues.splice(index, 1);
									}
								}
							},
							
						},
					},
					
				},
			});
	    })
	}
	displayTable(data) {
	    var self = this;
	    //self.plot.shutdown();
	    $("#placeholder" + self.encodeId).empty();
	    $('#legend' + self.encodeId).empty().hide();
	    //var table = d3.select('#placeholder' + self.encodeId);
	    var columnNames = ["Values"];
	    var columns = ["valueName"];
	    if(data && data.length > 0) {
	    	for (var j = 0; j < data.length; j++) {
			   // if(data[j].data.length > columns.length-1){
			    	for (var i = 0; i < data[j].data.length; i++) {
			    		columnNames.push(data[j].data[i][0]);
			        	columns.push(data[j].data[i][0]);
			    	}
			    //}
		    }
		    columns = _.uniq(columns);
			columnNames = _.uniq(columnNames);
			var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
			selectedOptions.each(function() {
				if($(this).parent()[0].label === 'Grade only'){
			    	var input = $('input[value="' + $(this).val() + '"]');
					/*input.prop('checked', false);
					$(this).removeAttr('selected');
					input.closest('li').removeClass('active');*/
					input.parent().css({'cursor' :"pointer"});
					input.closest('li').addClass('active');
					//$('#yaxis' + self.encodeId).multiselect('updateButtonText');
				}
			    
			});
	    }
	    if(!data || !data.length){
	    	return;
	    }
	    var myTableDiv = $('#placeholder' + self.encodeId);
     
	    var table = document.createElement('TABLE');
	    table.id = 'table' + self.encodeId;
	    table.className="display";
	    //table.className="pageResize";
	    table.cellspacing="0";
	    table.width="100%";
	    
	    var tableHeader = document.createElement('THEAD');
	    table.appendChild(tableHeader);
	    var headerTR = document.createElement('TR');
	    tableHeader.appendChild(headerTR);
	    _.each(columnNames, function(header){
	    	   var th = document.createElement('TH');
	    	   th.appendChild(document.createTextNode(header));           
	           headerTR.appendChild(th);
	    });
	    
	    var tableBody = document.createElement('TBODY');
	    table.appendChild(tableBody);
	    for (var i=0; i<data.length; i++){
	       var tr = document.createElement('TR');
	       tableBody.appendChild(tr);
	       var value = data[i];
	       for (var j=0; j<columns.length; j++){
	       	   var column = columns[j];
               var td = document.createElement('TD');             
               if (column === "valueName") {
                   td.align = "left";
	           		td.appendChild(document.createTextNode(value.label));
               } else {
                   td.align = "right";
		           	var text = value.data[j-1]? window.utils.thousandSeparator(window.utils.getRoundedValues(value.data[j-1][1], value.significantDecimals, false)) : '-';
		           	var a = document.createElement('a');
		           	a.style.color='inherit';
		           	a.style.cursor = 'pointer';
		           	if(value.data[j-1] && value.data[j-1].length>4){
		           		a.setAttribute('mestId',value.data[j-1][4].mestId);
		           		a.setAttribute('type',value.data[j-1][4].type);
		           	}
		           	a.appendChild(document.createTextNode(text));
		           	$(a).click(function(event){
		           		var obj = {mestId:event.currentTarget.getAttribute('mestId'), type:event.currentTarget.getAttribute('type')};
		           		self.showAggregationView(obj);
		           	});
		           	td.appendChild(a);
		           	if(value.data[j-1] && value.data[j-1][2]){
		           		var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
						svg.setAttribute("width", 20);
						svg.setAttribute("height", 20);
						svg.setAttribute("style",'vertical-align:bottom;');
						svg.setAttribute("viewBox", "-2 -2 27 27");
						svg.setAttribute("fill", ""+value.data[j-1][3]);
						svg.setAttribute("correctiveActions",value.data[j-1][5]);
						svg.style.cursor = "pointer";
						$(svg).click(function(event){
							var correctiveActions = event.currentTarget.getAttribute('correctiveActions') ? event.currentTarget.getAttribute('correctiveActions') : '';
							var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>"+correctiveActions+"</span></div></div>";
							var box = bootbox.dialog({
								message: htmlContent,
								title: "<div><img class='smallIcon' src='img/icons/icons_15.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('CorrectiveActions')+"</div>",
								buttons: {
									cancel : {
										label: "Close",
										className : 'btn vtn-default',
										callback : function(){
											
										}
									}
								}
							});
							box.show();
						});
						var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
						link.setAttribute("d", value.data[j-1][2]);
						svg.appendChild(link);
		           		td.appendChild(svg);		           		
		           	}
	           }	           
	           tr.appendChild(td);
	       }
	    }
		myTableDiv.append(table);
		self.dataTable = $('#table' + self.encodeId).DataTable({
			"bLengthChange": false,
			"pageResize": true,
			"sDom": "Rlfrtip"
    	});	
	}

	/*plotRadarChart() {
	    var self = this;
	    var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
	    $('#legend' + self.encodeId).empty().hide();


	    var chartContainerNode = $('#chart-container' + self.encodeId);
	    var w = chartContainerNode.width();
	    var h = chartContainerNode.height();
	    var margin = { top: 60, right: 20, bottom: 60, left: 20 },
                        legendPosition = { x: 10, y: 25 },
                        width = Math.min(700, w - 10) - margin.left - margin.right,
                        height = Math.min(width, h - margin.top - margin.bottom - 20);
	
	    var color = d3.scale.ordinal()
                .range(["#EDC951", "#F08080", "#FFA07A","#4169E1","#98FB98","#EDC951","#87CEFA","#ceaeff","#003366","#0066ff"]);
                
	    var LegendOptions = [];
	    var values = Object.getOwnPropertyNames(self.datasets);
	    var radarData = [];
	    var noOfAlts = 0;
	    var selectedValueList = [];
	
	    for (var j = 0; j < values.length; j++) {
	        if (values[j].indexOf('Grade only') != -1) {
	            continue;
	        }
	        var val = self.datasets[values[j]];
	        for (var k = 0; k < selectedOptions.length; k++) {
                if (window.utils.getSuffix(values[j]) === selectedOptions[k].value && selectedOptions[k].label === self.datasets[values[j]].label) {
                    if (val.data && val.data.length > 0 && noOfAlts < val.data.length) {
			            LegendOptions = [];
			            for (var i = 0; i < val.data.length; i++) {
			            	if(val.data[i][1] !== null){
			            		LegendOptions.push(val.data[i][0]);
			            	}
			            }
			            if(noOfAlts < LegendOptions.length) {
			            	noOfAlts = LegendOptions.length;
			            }
			            //break;
			        }
                    //break;
                }
            }
	        
	    }
	    $('#altLegend' + self.encodeId).show().empty();
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    _.each(altIndexes, function (key) {
	        var altIndexVal = self.altIndex[key];
	        //if (self.showBars) {
	            $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
	        /*} else {
	            $('#altLegend' + self.encodeId).append($("<div>Ph-" + altIndexVal + " : " + key + "</div>"));
	        }//

	    });
	    legendPosition.y = h - altIndexes.length * 30;
	    var maxVal;
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    for (var i = 0; i < noOfAlts; i++) {
	        var valDataArr = [];
	        var altName;
	        var index = parseInt(LegendOptions[i].substr(LegendOptions[i].lastIndexOf('-') + 1));
	        _.each(altIndexes, function (key) {
	            var altIndexVal = self.altIndex[key];
	            if (altIndexVal === index) {
	                altName = key;
	            }
	        });
	        for (var j = 0; j < values.length; j++) {
	            var selected = false;
	            for (var k = 0; k < selectedOptions.length; k++) {
	            	var valueSuffix = window.utils.getSuffix(values[j]);
	                if (valueSuffix === selectedOptions[k].value && selectedOptions[k].label === self.datasets[values[j]].label && values[j].indexOf('Grade only') == -1) {
	                    selected = true;
	                    break;
	                }
	            }
	            if (selected) {
	            	var data = self.datasets[values[j]].data;
	            	var foundValue = false;
	            	for(var k = 0; k < data.length; k++){
	            		if(data[k][0] === LegendOptions[i]){
	            			foundValue = true;
	            			//var val = self.datasets[values[j]].data[i]?self.datasets[values[j]].data[i][1]:'';
	            			var val = data[k]?data[k][1]:'';
			                val = window.utils.getRoundedValues(val, self.datasets[values[j]].significantDecimals, true);
			                var valueIndex =_.filter(selectedValueList,function(obj){return obj.id === valueSuffix;});
			                if(valueIndex && valueIndex[0]){
			                	valueIndex[0].value.push(val);
			                }else {
			                	selectedValueList.push({'id':valueSuffix,value:[val]});
			                }
			                var radarHover = self.datasets[values[j]].valueName + '' + self.datasets[values[j]].path + ' ' + val + ' ' + self.datasets[values[j]].unit;
			                valDataArr.push({valueSuffix:valueSuffix, axis: self.datasets[values[j]].valueName, radarHover: radarHover, value: val, areaName: LegendOptions[i] });
			                if (!maxVal || maxVal < val) {
			                    maxVal = val;
			                }
	            			break;
	            		}
	            	}
	            	if(!foundValue){
	            		valDataArr.push({valueSuffix:valueSuffix, axis: self.datasets[values[j]].valueName, radarHover: self.datasets[values[j]].valueName, value: '', areaName: LegendOptions[i] });
	            	}
	            }
	        }
	        

	        radarData.push({ values: valDataArr, key: LegendOptions[i] });
	    }
	    for(var m=0;m<selectedValueList.length;m++){
	    	var max = _.max(selectedValueList[m].value);
		    for(var i=0;i<radarData.length;i++){
		    	var valueList = radarData[i].values;
		    	var valueListIndex =_.filter(valueList,function(obj){return obj.valueSuffix === selectedValueList[m].id;});
		    	if(valueListIndex && valueListIndex[0]){
		    		var oldValue = valueListIndex[0].value;
		    		if(max == 0){
		    			max = 1;
		    		}
		    		if(oldValue !== '' || !isNaN(oldValue) || !isNaN(max) /*|| oldValue == Number.MAX_VALUE || oldValue == Infinity//){
		    			var valuePercentage = oldValue/max * 100;
			    		if(valuePercentage < -100){
			    			valuePercentage = -100;
			    		}
			    		valueListIndex[0].value = window.utils.getRoundedValues(valuePercentage, 2, true);
		    		}
		    	}
		    }
		}
	    //Options for the Radar chart, other than default
	    var radarChartOptions = {
	        w: width,
	        h: height,
	        margin: margin,
	        legendPosition: legendPosition,
	        //maxValue: 0.5,
	        wrapWidth: 120,
	        levels: 5,
	        roundStrokes: true,
	        color: color,
	        axisName: "axis",
	        areaName: "areaName",
	        value: "value"
	    };
	    RadarChart.draw("#placeholder" + self.encodeId, radarData, radarChartOptions);
	}*/

	plotAccordingToChoices() {
	    var self = this;
	    var placeholder = $("#placeholder" + self.encodeId);
	    var selectedOptions = $('#yaxis'+ self.encodeId + ' option:selected');
	    var selectedChartType = self.selectedChartType();
	    if (selectedChartType != 'Table') {
	        placeholder.css('overflow', 'visible');
	    } else {
	    	
	    	if(selectedOptions && selectedOptions.length > 0) {
	    		placeholder.css('overflow', 'auto');
	    	}else {
	    		placeholder.css('overflow', '');
	    	}
	    }
		if(self.dataTable){//destroy previously created datatable
			self.dataTable.destroy(true);
		}
	    /*if (self.showRadar() && self.selectedChartType() === 'Radar') {
	    	//self.plot.shutdown();
	    	placeholder.empty();
	        self.plotRadarChart();
	        $('#selectTheme'+self.encodeId).hide();
	    }else*/ if(selectedChartType === 'Polar' || selectedChartType === "Spiderweb"){
	        placeholder.empty();
	        self.plotPolarChart();
	        $('#selectTheme'+self.encodeId).show();
 	    } else {
	        //d3.select("#placeholder" + self.encodeId + ' svg').remove();
	        self.plotFlotChart();
	        if(selectedOptions && selectedOptions.length == 0 && self.selectedChartType() === 'Table' && !self.filterVP) {
	        	self.displayTable([]);
        	}
	    }
	
	};
	fillDuplicatePath(values,val,alt){
		var name = val.getMeasurand().get('name'),displayPath = "";
		for(var i=0;i<values.length;i++){
			var valueMeasurand = values[i].getMeasurand();
			if(valueMeasurand && valueMeasurand.get('name')===name && valueMeasurand.id !== valueMeasurand.id){
				var valuePackagePath = val.getPackagePath();
				var valueName = valuePackagePath[2]? valuePackagePath[2].name : "";
				displayPath = displayPath.concat(" ["+valuePackagePath[0].name+"  /  "+valuePackagePath[1].name+"  /  "+valueName+"]");
				/*var duplicateValuePackagePath = values[i].getPackagePath();
				displayPath = displayPath.concat("   [");
				if(valuePackagePath[2] && duplicateValuePackagePath[2] && valuePackagePath[2].name !== duplicateValuePackagePath[2].name){
					displayPath = displayPath.concat(valuePackagePath[2].name);
					for(var j=i+1;j<values.length;j++){// for values with same vp name in different pn below the ith value
						var duplicateValuePackagePath1 = values[j].getPackagePath();
						if(valuePackagePath[2].name === duplicateValuePackagePath1[2].name && valuePackagePath[0].name !== duplicateValuePackagePath1[0].name){
							displayPath = displayPath.concat("] ["+valuePackagePath[0].name);
							break;
						}
					}
				}else if(valuePackagePath[2] && duplicateValuePackagePath[2] && valuePackagePath[2].name === duplicateValuePackagePath[2].name && valuePackagePath[0].name !== duplicateValuePackagePath[0].name){
					displayPath = displayPath.concat(" ["+valuePackagePath[0].name+"] ");
				}
				else if(valuePackagePath[1].name !== duplicateValuePackagePath[1].name && valuePackagePath[2]){
					displayPath = displayPath.concat(valuePackagePath[1].name+"  /  "+valuePackagePath[2].name);
				}
				else if(valuePackagePath[2].name && duplicateValuePackagePath[2].name && (valuePackagePath[2].name === duplicateValuePackagePath[2].name)){
					displayPath = displayPath.concat(alt.get('phaseAlternativeOwner').get('name'));
				}
				else{
					displayPath = displayPath.concat(valuePackagePath[0].name+"  /  "+valuePackagePath[1].name+"  /  "+valuePackagePath[2]?valuePackagePath[2].name:'');
				}
	            displayPath = displayPath.concat("]");*/
	            return displayPath;
			}
		}
		return displayPath;
	}
	getModelLinked(alt,model,callback){
		var self = this;
		var modelSuffix = model.id.substr(model.id.lastIndexOf('@')+1);
		var ret = self.altModels[alt.id + modelSuffix];
		if(ret){
			callback(ret);
		}else{
			alt.getModelLinked(model,function(altModel){
				self.altModels[alt.id + modelSuffix] = altModel;
				callback(altModel);	
			});
		}
	};
	getMainScenario(altPack,altId,callback){
		var self = this;
		var ret = self.mainScenarios[altPack.id + altId];
		if(ret){
			callback(ret);
		}else{
			DataManager.getDataManager().getMainScenario(altPack,altId,function(mainScenario){
				self.mainScenarios[altPack.id + altId] = mainScenario;
				callback(mainScenario);	
			});
		}
	};	
	getValueMeasurements(alts,callback){
		var self = this;
		var dataSet = {};
		var hasMoreThanOneValue = false;
		self.margin = 0.05;
		self.altIndex = {};
		var selectedChartType = self.selectedChartType();
		self.showBars = selectedChartType === "Bars" || selectedChartType === "Stacked bars" || selectedChartType === "Columns" || selectedChartType === "Stacked columns"? true : false;
		
		async.each(self.values,function(val,valHandleCallback){
			var measurandData = {};
			function addMeasurandData(unit){
				var valueData = {};
				valueData.label = val.getMeasurand().get('name');
				valueData.displayName = val.getMeasurand().get('name');
				valueData.valueName = val.getMeasurand().get('name');
				valueData.data = [];
				measurandData[unit] = valueData;
				return valueData;
			}
			
			async.eachSeries(alts,function(alt,altHandleCallback){
				var unit = "";
				function pushEmpty(valueData){
					var valData = [];
					var planName = alt.get('phaseAlternativeOwner').get('name');
					var altName = alt.get('name');
					valData.push(planName+ " / " +altName);
					valData.push(null);
					altHandleCallback();
				}				
				var pack = self.model.getNestedParent();
				self.getModelLinked(alt,pack,function(altPack){
					if(!altPack){
						pushEmpty();
						return;
					}
					self.getModelLinked(alt,val,function(altVal){
						if(!altVal){
							pushEmpty();
							return;
						}
						self.getMainScenario(altPack,alt.get('id'),function(mainScenario){
							if(!mainScenario){
								pushEmpty();
								return;
							}							
							var mests = mainScenario.getMeasurementsReferringToMeasuredCharteristic(altVal);
							if(mests.length == 0){
								var satMc = altVal.getParent().get('satisfactionLevel');
								mests = satMc?mainScenario.getMeasurementsReferringToMeasuredCharteristic(satMc):[];
							}
							_.each(mests, function (mest) {
							    var valData = [];
							    var planName = alt.get('phaseAlternativeOwner').get('name');
							    var altName = alt.get('name');
							    var altProp = planName + " / " + altName;
							    var whenObs = mest.get('observedMeasure').get('observation').get('whenObserved');
							    if (whenObs != null) {
							        altProp = altProp + "(" + whenObs + ")";
							    }
							    if (_.indexOf(self.xAxisSelected, alt.id + (whenObs != null ? whenObs : "")) < 0) {
							        return;
							    }
							    if (_.has(self.altIndex, altProp)) {
							        valData.push("Alt-" + self.altIndex[altProp]);
							    } else {
							        self.altIndex[altProp] = (_.keys(self.altIndex)).length;
							        valData.push("Alt-" + self.altIndex[altProp]);
							    }
							    //valData.push(planName+ " / " +altName);
							    if (mest && mest.get('observedMeasure').get('measure') && (mest.get('value') !== null || mest.get('symbol') !== null)) {
							        unit = mest.get('observedMeasure').get('measure').get('unit');
							        if (mest.get('value') === Number.MAX_VALUE || mest.get('value') === '') {
							            valData.push(null);
							        } else {
							            if(mest.get('value')) {
							        		valData.push(parseFloat(mest.get('value')));
							        	}else {
							        		valData.push(mest.get('symbol'));
							        	}
							        }
							        var selectedInterval = altVal.getParent().getSatisfactionInterval(mainScenario);
									var smileySrc = '';
									var smileyColor = '';
									var correctiveActions = '';
									if(selectedInterval && val.getParent()){
										smileySrc = val.getParent().fetchSmileySrc(selectedInterval.get('smiley'));
					                	smileyColor = selectedInterval.get('smileyColor');
					                	correctiveActions = selectedInterval.get('correctiveActions');
					                }
					                valData.push(smileySrc);
					                valData.push(smileyColor);
							    } else {
							        valData.push(null);
							    }
							    var valueData;
							    if (mest) {
							    	var unitName;
							        if (unit) {
							           unitName = window.utils.replaceEscapeCharacters(unit.get('name'));
							        }/*else {
							        	unitName = 'SatisfactionOnly';
							        }*/
							        //var unitSuffix = unitId.substr(unitId.lastIndexOf('@')+1);
							        valueData = measurandData[unitName];
							        if (!valueData) {
							            valueData = addMeasurandData(unitName);
							            valueData.hoverDisplayName = valueData.label.concat(self.fillDuplicatePath(self.values, val, alt));
							            var label = valueData.label;
							            if(unitName){
							            	label = label.concat(" (" + unitName + ")");
							            	valueData.hoverDisplayName = valueData.hoverDisplayName.concat(" (" + unitName + ")");
							            }
							            valueData.label = label;
							            valueData.unit = unitName?unitName:'Grade only';
							            valueData.significantDecimals = unit?unit.get('significantDecimals'):'2';
							            var displaylabel = valueData.displayName;
							            if(unitName){
							            	displaylabel = displaylabel.concat(" (" + unitName + ")");
							            }
							            valueData.displayName = displaylabel;

							            valueData.path = self.fillDuplicatePath(self.values, val, alt);
							        }
							        valData.push({mestId:mest.get('id'), type:mest.get('type')});
					                valData.push(correctiveActions);
							        valueData.data.push(valData);
							    } else {
							        valueData = addMeasurandData("");
							        valueData.data.push(valData);
							    }
							});
							//valueData.data.push(valData);
							altHandleCallback();							
						});					
					});
				});

			},function(){
				var emptyData = measurandData[""];
				if(emptyData){
					delete measurandData[""];
				}				
				
				Object.getOwnPropertyNames(measurandData).forEach(function(key) {
					var valueCount =0;
					var valueData = measurandData[key];
					if(emptyData){
					  	valueData.data = valueData.data.concat(emptyData.data);
					}
					for(var i=0;i<valueData.data.length;i++){
						if(valueData.data[i][1] != null){
							valueCount++;	
						}
					}
					var unitString = window.utils.replaceEscapeCharacters(valueData.unit);
				  	dataSet[val.get('id') + unitString ] = valueData;
					if(valueCount > 1){
						hasMoreThanOneValue = true;
					}					  	
				});	
				valHandleCallback();
			});	
		},function(){		
			if(self.showBars && !hasMoreThanOneValue){
				self.margin = 0.5;
			}
			self.datasets = dataSet;
			callback();
		});
	};
	getAlternatives(){
		var alts = [];
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({nextPhase:null});
		while(phase){
			var phaseAltsArray = [];
			var phaseAlts = phase.get('phaseAlternative');
			var masterAlt = phase.get('master');
			phaseAlts.each(function(phaseAlt){
				if(phaseAlt !== masterAlt){
					phaseAltsArray.push(phaseAlt);
				}
			});
			phaseAltsArray.unshift(masterAlt);
			alts = phaseAltsArray.concat(alts);
			phase = phase.get('previousPhase');
		}		
		return alts;
	};
    showConfig() {
        var self = this;
        if ($('#chartConfig' + self.encodeId).is(":visible")) {
            $("#chartConfig" + self.encodeId).hide();
            $('#showConfigButton')[0].title = 'Show Configuration';
	    } else {
            $("#chartConfig" + self.encodeId).show();
            $('#showConfigButton')[0].title = 'Hide Configuration';
        }
        if (self.parentView && self.parentView.setResizeHeight) {
            self.parentView.setResizeHeight();
        }
	};
	getXAxisData(preference,refresh){
		var self = this;
		var xAxisData = [];
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({nextPhase:null});
		while(phase){
			var phaseData = {};
			xAxisData.unshift(phaseData);
			phaseData.id = phase.get('id');
			phaseData.parent = '#';
			phaseData.text = phase.get('name');
			if(preference && (_.indexOf(preference.xAxisSelected,phaseData.id) >= 0)){
				phaseData.selected = true;
				self.xAxisSelected && self.xAxisSelected.push(phaseData.id);
			}			
			
			var phasePrimary = phase.get('primary');
			if(phasePrimary && !preference && !refresh){
				self.xAxisSelected.push(phasePrimary.get('id'));	
			}
			
			var phaseAlts = phase.get('phaseAlternative');
			phaseAlts.each(function(phaseAlt){
					var altData = {};
					altData.id = phaseAlt.get('id');
					altData.parent = phase.get('id');
					altData.text = phaseAlt.get('name');
					xAxisData.push(altData);
					
					if(!preference && (phasePrimary === phaseAlt)){
						altData.state = {opened:true,selected : true}; 
					}
					if(preference && (_.indexOf(preference.xAxisSelected,phaseAlt.get('id')) >= 0)){
						altData.state = {opened:true,selected : true};
						self.xAxisSelected.push(phaseAlt.get('id'));	
					}
					/*var phObjSet = phaseAlt.get('phaseObjectiveSet');
					if (phObjSet) {
					    var objObservations = phObjSet.get('objectiveObservation');
					    objObservations.each(function (objObs) {
					        if (objObs.get('whenObserved') != null) {
					            var altData = {};
					            altData.id = phaseAlt.get('id');
					            altData.parent = phase.get('id');
					            altData.text = phaseAlt.get('name') + "(" + objObs.get('whenObserved') + ")";
					            altData.timeStamp = objObs.get('whenObserved');
					            altData.id = altData.id + altData.timeStamp;
					            xAxisData.push(altData);
					            if (preference && (_.indexOf(preference.xAxisSelected, phaseAlt.get('id') + altData.timeStamp) >= 0)) {
					                altData.state = { opened: true, selected: true };
					                self.xAxisSelected.push(phaseAlt.get('id') + altData.timeStamp);
					            }
					        }
					    });
					    self.xAxisSelected = _.uniq(self.xAxisSelected);
					}*/
			});
			phase = phase.get('previousPhase');
		}		
		return xAxisData;
	};
	showAggregationView(view){
		var self = this;
		function htmlEscape(str) {
            return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
        }
        if (!view.type) {
            return;
        }
		var id = "aggregation" + htmlEscape(view.mestId);
		var mest = Backbone.Relational.store.getObjectByName(view.type.replace('_','.')).find({id:view.mestId});
		if(mest){
			var altId = DataManager.getDataManager().getRepositoryId(view.mestId);
			var alternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altId });
	        var addOptions = { 'currentAlternativeModel':alternative};
	        window.getAndCreateModalDialog(true, id, ValueElementMixin, mest, "AggregationView", function(changeInValue){
		        	if(changeInValue) {
		        		window.utils.startSpinner('refreshValues','Refreshing ...');
						DataManager.getDataManager().invokeValueUpdates(function(){
							if(self.parentView) {
								self.parentView.refreshWidgetData(true);
						    	window.utils.stopSpinner('refreshValues');	
							}else {
								window.utils.stopSpinner('refreshValues');	
							}
						});
					}	
	        	}, addOptions);
    	}	
	};
	init(model, filterVP, allRoles, encodeId){
		var self = this;
        this.datasets = {};
        this.values = [];
        this.BMValuesChart = this;
        this.allRoles = allRoles;
        this.model = model;
        this.altModels = {};
		this.disabledValues = []
        this.mainScenarios = {};
        this.editable = ko.observable(false);
        this.name = kb.observable(model, 'name');
        this.id = kb.observable(model, 'id');
        function htmlEscape(str) {
            return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
        }
        if (encodeId) {
            this.encodeId = encodeId;
        } else {
            this.encodeId = this.model ? htmlEscape(this.model.id) : null;
        }
        this.chartColors = ["#EDC951", "#F08080", "#FFA07A","#4169E1","#98FB98","#EDC951","#87CEFA","#ceaeff","#003366","#0066ff"]
        this.description = kb.observable(model, 'description');
        this.filterVP = filterVP;
        //this.business = ko.observable(self.model.get('business') ? self.model.get('business') : null);
        if (self.model) {
            this.businessName = ko.observable(self.model.get('business') ? self.model.get('business').get('name') : null);
        } else {
            this.businessName = ko.observable("");
        }
        
        self.xAxisSelected = [];
        this.selectedChartType = ko.observable();
        this.chartTypes = ko.observableArray(['Curves','Stacked bars','Stacked columns','Bars','Columns',/*'Radar',*/'Table','Polar','Spiderweb']);
        this.chartTypes.sort();
        this.selectedTheme = ko.observable();
        this.themesArray = ['Default', 'Dark Unica', 'Sand Signika', 'Grid Light'];
		this.selectedChartType.subscribe(function (selectedType) {
		  if($("#placeholder" + self.encodeId).length > 0) {
            var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
            self.maxUnitsPermitted = selectedType === "Stacked bars" || selectedType === "Stacked columns"? 1: 2;
            if (selectedType === 'Radar' && selectedOptions.length < 3) {
                bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
                self.selectedChartType('Curves');
            } else {
            	self.getValueMeasurements(self.alts, function(){
            		self.plotValues();
                	self.plotAccordingToChoices();
            	});
            }
          }
		});

        this.selectedTheme.subscribe(function (theme){
        	if(self.parentView){
				Highcharts.setOptions(HighChartThemes.getDefaultThemeFont());
			}
        	switch(theme){
        		case 'Default':
        			if(self.parentView){
						Highcharts.theme = self.parentView.highchartsDefaultTheme;
						Highcharts.setOptions(Highcharts.theme);
						window.utils.startSpinner('refreshCharts','Refreshing ...');
						self.parentView.setResizeHeight(true);
					}
        			break;
        			
        		case 'Dark Unica':
        			Highcharts.theme = HighChartThemes.DarkUnica();
					Highcharts.setOptions(Highcharts.theme);
					window.utils.startSpinner('refreshCharts','Refreshing ...');
					self.parentView.setResizeHeight(true);
        			break;
        			
        		case 'Sand Signika':
        			Highcharts.theme = HighChartThemes.SandSignika();
					Highcharts.setOptions(Highcharts.theme);
					window.utils.startSpinner('refreshCharts','Refreshing ...');
					self.parentView.setResizeHeight(true);
        			break;
        			
        		case 'Grid Light':
        			Highcharts.theme = HighChartThemes.GridLight();
					Highcharts.setOptions(Highcharts.theme);
					window.utils.startSpinner('refreshCharts','Refreshing ...');
					self.parentView.setResizeHeight(true);
        			break;
        	}
			if(window['refreshCharts']){
				window.utils.stopSpinner('refreshCharts');
			}
        });
        
        self.showRadar = ko.observable(false);
	}
//#endCustomMethods
    
    static getInstance(model, filterVP, allRoles, encodeId){
			var view = new BMValuesChart(model, filterVP, allRoles, encodeId);
			view.init(model, filterVP, allRoles, encodeId);
			return view;
	};
}
path.BMValuesChart = BMValuesChart;
