import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as  bootbox  from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import {BeepPackageMixin} from '../../../../bo/beeppackage/BeepPackageMixin'
import {InputPort} from '../../../../bo/vdml/InputPort'
import {BusinessItem} from '../../../../bo/vdml/BusinessItem'
import {ValueDeliveryModel} from '../../../../bo/vdml/ValueDeliveryModel'
import {ProcessDiscoveryDiagram} from '../../../../bo/vdml/ProcessDiscoveryDiagram'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "async", "bpmnmodeler", "appbo/beeppackage/BeepPackageMixin", "appbo/vdml/ProcessDiscoveryDiagram", "appbo/vdml/ValueDeliveryModel", "appbo/vdml/BusinessItem", "appbo/vdml/InputPort"],
    function (require, $, _, Backbone, ko, bootbox, DataManager, global, async, BpmnModeler, BeepPackageMixin, ProcessDiscoveryDiagram, ValueDeliveryModel, BusinessItem, InputPort) {*/

        var path = DataManager.getDataManager().buildAppNsPath("valueStreamMap.views.designer", global.version);
        export class ProcessTree {
            
        
        is(element, type) {
            var bo = this.getBusinessObject(element);
            return bo && (typeof bo.$instanceOf === 'function') && bo.$instanceOf(type);
        }
        getBusinessObject(element) {
            return (element && element.businessObject) || element;
        }
        initializeProcessTree(generateSubProcess,callback) {
            var self = this;
            self.elementRegistry = self.modeler.get('elementRegistry');
            var valueStreams = self.elementRegistry.filter(function (element) {
					if(!generateSubProcess){
						return self.is(element, 'vdml:ValueStream');
					}else{
						return self.is(element, 'vdml:ValueStream') || self.is(element, 'vdml:CapabilityContainer');
					}
            });
            async.each(valueStreams, function (valueStreamElement, handledValueStream) {
                var valueStream = self.getBusinessObject(valueStreamElement);
                var flowElements = valueStream.flowElements;
                if (!flowElements) {
                    handledValueStream();
                    return;
                }
                async.each(flowElements, function (flowElement, handledFlowElement) {
                    if (flowElement.mid && self.is(valueStreamElement, 'vdml:ValueStream')) {
                        var altId = DataManager.getDataManager().getRepositoryId(flowElement.mpid);
                        var version = DataManager.getDataManager().get('currentVDMVersion');
                        DataManager.getDataManager().fetchDocumentFromPackage(flowElement.mpid, "appbo/vdml/ValueDeliveryModel", version, flowElement.mid, "appbo/vdml/Activity", DataManager.getDataManager().getVDMStore(altId), {
                            success: function (activity) {
                                flowElement.activityBo = activity;
                                self.updateWithFlowElement(flowElement, activity);
                                handledFlowElement();
                            },
                            error: function (error) {
                                console.log('Unable to load selected Activity');
                                handledFlowElement();
                            }
                        });
                    } else {
					    /*var competenceType = window.vdmModelView.model.get('competence').get(flowElement.mid);
                        if((competenceType && competenceType.get('type') === "vdml_CapabilityOffer" && !self.generateFlow) || !flowElement.mid){
						    handledFlowElement();
						}else if((competenceType && competenceType.get('type') === "vdml_BusinessItem" && !self.generateSubProcess) || !flowElement.mid){
						   handledFlowElement();
						}else{*/
                        self.updateWithFlowElement(flowElement, null);
                        handledFlowElement();
						//}
                    }
                }, function (e) {
                    handledValueStream();
				})
            }, function () {
                self.addOutputGateways();
                self.addInputGateways();
                self.sortRoleActivities();
                self.updateCompetencyData(function () {
                    self.handleProcessCreation(callback);
                });
			});
        }
        initialize(generateSubProcess, callback) {
            var self = this;
			self.generateSubProcess = generateSubProcess;
			self.initializeProcessTree(self.generateSubProcess, callback);
			/*if(checkCompentence == "Resource"){
			  bootbox.confirm("Do you want to generate Flow from Datastore for Resource?", function (result) {
                if (result) {
				    self.generateSubProcess = result;
                    self.initializeProcessTree(result, null);
                }else{
				    self.generateSubProcess = result;
				    self.initializeProcessTree(result, null);
				}
              });
			}else if(checkCompentence == "Capability"){
			  bootbox.confirm("Do you want to generate Sub-Process for Competencies?", function (result) {
                if (result) {
				    self.generateFlow = result;
                    self.initializeProcessTree(null, result);
                }else{
				    self.generateFlow = result;
				    self.initializeProcessTree(null , result);
				}
              });
			}else{
			    bootbox.confirm("Do you want to generate Sub-Process for Competencies?", function (result) {
					if (result) {
					   self.generateSubProcess = result;
					}else{
					   self.generateSubProcess = result;
					}
					bootbox.confirm("Do you want to generate Flow from Datastore for Resource?", function (res) {
					       if(res){
						       self.generateFlow = res;
							   self.initializeProcessTree(self.generateSubProcess, res);
						   }else{
						   	   self.generateFlow = res;
							   self.initializeProcessTree(self.generateSubProcess, res);
						   }
					});
				});
			}*/
        }
		updateCompetencyData(callback) {
            var self = this;
            var activityKeys = Object.getOwnPropertyNames(self.activities);
            async.each(activityKeys, function (key, handledElement) {
                var element = self.activities[key];
                if (element.type == "vdml:Competency") {
                    if (element.mpid) {
                        var altId = DataManager.getDataManager().getRepositoryId(element.mpid);
                        var version = DataManager.getDataManager().get('currentVDMVersion');
                        DataManager.getDataManager().fetchDocumentFromPackage(element.mpid, "appbo/vdml/ValueDeliveryModel", version, element.mid, "appbo/vdml/Competence", DataManager.getDataManager().getVDMStore(altId), {
                            success: function (comp) {
                                var compActivity = self.getActivityWithCompFlowTo(element);
                                if (comp instanceof BusinessItem) {
                                    if (compActivity && compActivity.activity && compActivity.activity.activityBo) {
                                        var ports = compActivity.activity.activityBo.get('containedPort');
                                        ports.each(function (port) {
                                            if (port instanceof InputPort) {
                                                var inputFlow = port.get('input');
                                                var deliverable = inputFlow.get('deliverable');
                                                if (deliverable.id == comp.id) {
                                                    compActivity.flow.name = deliverable.get('name');
                                                    var store = inputFlow.get('provider').get('containedPortOwner');
                                                    self.activities[compActivity.flow.target].name = store.get('name');
                                                    self.activities[compActivity.flow.target].mid = store.get('id');
                                                    self.activities[compActivity.flow.target].mpid = store.getNestedParent().get('id');
                                                    compActivity.flow.mid = inputFlow.id;
                                                    compActivity.flow.mpid = inputFlow.getNestedParent().id;;
                                                    compActivity.flow.name = inputFlow.get('name');
                                                }
                                            }
                                        })
                                    }
                                } else {
                                    //TODO if we generate a subprocess
                                }
                                handledElement();
                            },
                            error: function (error) {
                                console.log('Unable to load mapped Competence');
                                handledElement();
                            }
                        });
                    } else {
                        handledElement();
                    }
                } else {
                    handledElement();
                }
            }, function () {
                if (callback) {
                    callback();
                }
            });

        }

        getActivityWithCompFlowTo(comp) {
            var self = this;
            var ret = {};
            var activityKeys = Object.getOwnPropertyNames(self.activities);
            for (var i = 0; i < activityKeys.length; i++) {
                var activity = self.activities[activityKeys[i]];
                if (activity.type == 'vdml:Activity') {
                    var compChildren = activity.compChildren;
                    if (compChildren) {
                        for (var j = 0; j < compChildren.length; j++) {
                            if (compChildren[j].target == comp.id) {
                                ret.activity = activity;
                                ret.flow = compChildren[j];
                                return ret;
                            }
                        }
                    }
                }
            }
        }
        buildBPMNDiagram(callback) {
            var self = this;
            self.bpmnModeler = window.vdmModelView.modeler;
            self.eventBus = self.bpmnModeler.get('eventBus');
            self.elementFactory = self.bpmnModeler.get('elementFactory');
            self.modeling = self.bpmnModeler.get('modeling');
            self.elementRegistry = self.bpmnModeler.get('elementRegistry');
            self.addContentToProcess(function(){
				callback();
			});
        }
        addContentToProcess(subCallback) {
            var self = this;
            var roleKeys = Object.getOwnPropertyNames(self.roles);
            var previousLane;
			var currentLane;
            var x = -500;
            var y = 0;
            var width = 1000;
            var height = 120;
            var count=-1;
            var currentExistingLane;
            var parent = self.elementRegistry.get(window.vdmModelView.model.id);
            var canvas = self.bpmnModeler.get('canvas');var startElement;
			var roleLength = roleKeys.length;
			/*if(roleLength  > 0){ // Only for shapes are mapped
			   for(var i=0;i<roleKeys.length;i++){
			   	   if(roleKeys[i] === self.nonPerformer){
				      roleLength = roleLength-1;
					  break;
				   }
			   }
			   if(roleLength >0 ){
			       var p = self.elementFactory.createParticipantShape(true);
		           var bmLane = self.modeling.createShape(p, { x: 0, y: 0, 'width': width/2, 'height': height +  2* roleLength * 60 }, canvas.getRootElement(), false);
			       self.modeling.splitLane(p,roleLength);
			   }
			}*/
            //Identify the orgnaziations involved with roles
            self.roleCollaborations = [];
            _.each(roleKeys, function (roleKey) {
                var role = self.roles[roleKey];
                if (roleKey != self.nonPerformer) {
                    var roleModel = Backbone.Relational.store.getObjectByName("vdml.Party").find({id:role.id});
                    var roleCollaboration = roleModel.get('collaborationRoleOwner');
                    var participantObj = self.roleCollaborations[roleCollaboration.get('id')];
                    if(!participantObj){
                        participantObj = {id:roleCollaboration.get('id'),roles:[{id:role.id,mid:role.mid,mpid:role.mpid,name:role.name}]};
                        self.roleCollaborations.push(participantObj);
                    }else{
                        participantObj.roles.push({id:role.id,mid:role.mid,mpid:role.mpid,name:role.name});
                    }
                }
            });
            //create org lanes with roles in it
            if(self.roleCollaborations.length > 0){
                var yp = 0;
                
                _.each(self.roleCollaborations,function(collObj){
                    var p;
                    var bmLane;
                    var participantLane = self.findElement('bpmn:Participant', collObj.roles[0].mid,collObj.roles[0].name);
                    if (!participantLane) {
                        var p = self.elementFactory.createParticipantShape(true);
                        bmLane = self.modeling.createShape(p, { x: 0, y: yp, 'width': width/2, 'height': height +  2* collObj.roles.length * 60 }, canvas.getRootElement(), false);
                        self.modeling.splitLane(p,collObj.roles.length);
                        var roleModel = Backbone.Relational.store.getObjectByName("vdml.Party").find({id:collObj.roles[0].id});
                        var roleAssignModel = roleModel.get('roleAssignment').models[0]
                        p.businessObject.mid = roleAssignModel.get('participant').get('id');
                        p.businessObject.mpid = roleAssignModel.get('participant').getNestedParent().get('id');
                        p.businessObject.name  = roleAssignModel.get('participant').get('name');
                        var count=0;
                        previousLane = null;
                        _.each( collObj.roles,function(role){
                            var currentLane = p.children[count];
                            currentLane.businessObject.mid = role.mid;
                            currentLane.businessObject.mpid = role.mpid;
                            currentLane.businessObject.name = role.name;
                            self.addLaneContent(currentLane, null, role.id,previousLane);
                            self.eventBus.fire('elements.changed', {
                                elements: [currentLane]
                            });
                            previousLane = currentLane;
                            role.roleShape = currentLane;
                        })
                    }else {
                        self.addLaneContent(participantLane, null, roleKey);
                    }
                    yp = bmLane.height + 5;
                })
            }//else{
                self.addLaneContent(null, previousLane, self.nonPerformer);
            //}
			/*_.each(roleKeys, function (roleKey) {
                var role = self.roles[roleKey];
				var activities = role.activities;
                if (roleKey != self.nonPerformer) {
                    count++;
                    currentExistingLane = self.findElement('bpmn:Participant', role.mid,role.name);
                    if (!currentExistingLane) {
                        if(roleLength === 0 || roleLength === 1){
							currentLane = p;
						}else{
						    var element = p.children[count];
							currentLane = element;
						}
						currentLane.businessObject.mid = role.mid;
						currentLane.businessObject.mpid = role.mpid;
						currentLane.businessObject.name = role.name;
						if(!p.businessObject.mid){
							var roleModel = Backbone.Relational.store.getObjectByName("vdml.Party").find({id:role.id});
							if(roleModel){
								var roleAssignModel = roleModel.get('roleAssignment').models[0];
								p.businessObject.mid = roleAssignModel.get('participant').get('id');
							    p.businessObject.mpid = roleAssignModel.get('participant').getNestedParent().get('id');
							    p.businessObject.name  = roleAssignModel.get('participant').get('name');
							}
						}
						self.addLaneContent(currentLane, null, roleKey,previousLane);
                        self.eventBus.fire('elements.changed', {
                            elements: [currentLane]
                        });
						previousLane = currentLane;
                    } else {
                        self.addLaneContent(currentExistingLane, null, roleKey);
                    }
                    role.roleShape = previousLane;
                } else {
                    if(roleLength > 0){
                        return;
                    }
                    self.addLaneContent(null, previousLane, self.nonPerformer);
                }
            });*/
            var activityKeys = Object.getOwnPropertyNames(self.activities);
            _.each(activityKeys, function (key) {
                var activity = self.activities[key];
                self.addActivityConnections(activity);
				var actModel = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: activity.id });
				if (actModel) {
					window.vdmModelView.model.get('activity').add(actModel);
				}
            })
            if (self.generateSubProcess || self.generateFlow) {
                self.createSubprocessForCompetencies(subCallback);
            } else {
                window.vdmModelView.saveDiagram(null, null, function () {
					subCallback();
                });
            }
        }
        findElement(type, mid, name) {
            var self = this;
            var elements = self.elementRegistry.filter(function (element) {
                if (!self.is(element, type)) {
                    return false;
                }
                if (mid) {
                    if (element.businessObject.mid == mid) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (name) {
                    if (element.businessObject.name == name) {
                        return true;
                    } else {
                        return false;
                    }
                } 
                return false;
            });
            if (elements.length > 0) {
                return elements[0];
            }
        }
		getSubProcessForActivity(target) {
			var self = this;
			var subActivities = [];
            var subActivityKeys = self.subProcessActivities;//Object.getOwnPropertyNames(self.subProcessActivities);
            for (var i = 0; i < subActivityKeys.length; i++) {
                if (subActivityKeys[i].valueStream == target) {
                    subActivities.push(subActivityKeys[i]);
                }
            }
			return subActivities;
		}
        createSubprocessForCompetencies(subCallback) {
            var self = this;
            var subProcessKeys = Object.getOwnPropertyNames(self.subProcessFlows);
			var createSubDiag = [];
            var subProcessInstances = {};
            for (var i = 0; i < subProcessKeys.length; i++) {
                var key = subProcessKeys[i];
                var flow = self.subProcessFlows[key];
                var sourceActivity = self.activities[flow.source];
				var subActProcess = self.getSubProcessForActivity(flow.target);
                var diagIns = self.generateSubProcessDiagramInstance(sourceActivity,subProcessInstances,subActProcess);
				if(diagIns) {
					subProcessInstances[key] = diagIns;
					createSubDiag.push(key);
				}
            }
            if (createSubDiag.length == 0) {
                window.vdmModelView.saveDiagram(null, null, function () {
					subCallback();
                });
            } else {
                async.eachSeries(createSubDiag, function (key, generatedSubProcess) {
                    var flow = self.subProcessFlows[key];
                    var sourceActivity = self.activities[flow.source];
                    //var targetCompetence = self.subProcessActivities[flow.target];
                    var targetCompetence;
                    var subActivities = [];
                    /*var subActivityKeys = Object.getOwnPropertyNames(self.subProcessActivities);
                    for (var i = 0; i < subActivityKeys.length; i++) {
                        if (self.subProcessActivities[subActivityKeys[i]].valueStream == flow.target) {
                            subActivities.push(self.subProcessActivities[subActivityKeys[i]]);
                        }
                    }*/
					subActivities = self.getSubProcessForActivity(flow.target);
                    window.vdmModelView.saveDiagram(null, null, function () {
                        function callGenerateSubProcess() {
                            self.generateSubProcessDiagram(subProcessInstances[key], sourceActivity, subActivities, generatedSubProcess);
                        }
                        setTimeout(callGenerateSubProcess, 500);
                    });

                }, function (e) {
                    function openMainDiagram() {
                        var altId = DataManager.getDataManager().getRepositoryId(self.processPackage.id);
                        DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.processPackage.get('id') + "/vdml_ValueDeliveryModel/" + self.processPackage.get('version') + "/" + self.diagramInstance.get('id') + "/vdml_ProcessDiscoveryDiagram/" + altId + "", { trigger: true });
						subCallback();
					}
                    setTimeout(openMainDiagram, 2000);
                });
            }
        }

        generateSubProcessDiagramInstance(sourceActivity,subProcessInstances,subActProcess) {
            var self = this;var isExist = true;
            if (sourceActivity.activityShape.businessObject.calledElement) {
                return Backbone.Relational.store.getObjectByName('vdml.ProcessDiscoveryDiagram').find({ id: sourceActivity.activityShape.businessObject.calledElement });
            }
			var reusedCapDiagram = false;
			var diagramInstance;
			/*for(var key in subProcessInstances){//TODO based on activity(capability method)
				if(subProcessInstances[key].get('competence').length == subActProcess.length){
					for(var cap in subActProcess){
						reusedCapDiagram = true;
						diagramInstance = subProcessInstances[key];
						if(!subProcessInstances[key].get('competence').findWhere({'id':subActProcess[cap].id})){
							reusedCapDiagram = false;
						}
					}
				}
			}*/
			if(reusedCapDiagram && diagramInstance){
				sourceActivity.activityShape.businessObject.calledElement = diagramInstance.id;
				var newName = diagramInstance.get('name').concat(','+sourceActivity.name);
				diagramInstance.set('name',newName);
				diagramInstance = null;
			}else {
                var altId = DataManager.getDataManager().getRepositoryId(self.processPackage.id);
                if (!sourceActivity.description) {
                    sourceActivity.description = sourceActivity.name;
                }
				for(var i=0;i<self.processPackage.get('diagrams').length;i++){
					var PMmodels = self.processPackage.get('diagrams');
					if(PMmodels.models[i].get('name') === sourceActivity.name){
					   diagramInstance = PMmodels.models[i];
					   isExist = false;
					   break;
					}
				}
				if(isExist){ 
				    diagramInstance = ProcessDiscoveryDiagram.getInstance(sourceActivity.name, sourceActivity.description, self.processPackage);
				}
				/*function getCompElementModel(id) {
					var capModel = Backbone.Relational.store.getObjectByName('vdml.BusinessItem').find({ id: id });
					if (!capModel) {
						capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityOffer').find({ id: id });
					}
					return capModel;
				}
				for(var cap in subActProcess){//TODO based on activity(capability method)
					diagramInstance.get('competence').add(getCompElementModel(subActProcess[cap].id));
				}*/
				sourceActivity.activityShape.businessObject.calledElement = diagramInstance.id;
			}
            return diagramInstance;
        }

        generateSubProcessDiagram(diagramInstance,sourceActivity,subActivities,callback) {
            var self = this;
            var altId = DataManager.getDataManager().getRepositoryId(self.processPackage.id);
            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.processPackage.get('id') + "/vdml_ValueDeliveryModel/" + self.processPackage.get('version') + "/" + diagramInstance.get('id') + "/vdml_ProcessDiscoveryDiagram/" + altId + "", { trigger: true });
            setTimeout(function(){
			           self.buildSubProcessContent(diagramInstance,sourceActivity, subActivities, callback)
			},400);
        }
		checkIsSubProcessExist(elements,activity){
			var existAct = false;
			for(var ele =0;ele <elements.length;ele++){
				if(elements[ele].bpmnElement.name === activity.name){
					existAct = true;
					break;
				}
				if(ele === elements.length-1){
					var xPos = elements[elements.length-1].bounds.x;
					var yPos = elements[elements.length-1].bounds.y;
					var height = elements[elements.length-1].bounds.height;
					var width = elements[elements.length-1].bounds.width;
				}
			}
			var array = {"exist" : existAct, "xpos" : xPos ,"ypos" : yPos,"height" :height,"width" : width};
			return array;
		}
        buildSubProcessContent(diagramInstance,sourceActivity, subActivities, callback) {
            var self = this;
            var firstActivity = true;
            var previousActivityShape;
            var previousActivity;
            var previousLane;
            var parent;
            self.bpmnModeler = window.vdmModelView.modeler;
            self.eventBus = self.bpmnModeler.get('eventBus');
            self.elementFactory = self.bpmnModeler.get('elementFactory');
            self.modeling = self.bpmnModeler.get('modeling');
            self.elementRegistry = self.bpmnModeler.get('elementRegistry');

            //if (roleKey === self.nonPerformer) {
                parent = self.bpmnModeler.get('canvas').getRootElement();
            //} else {
            //    parent = lane;
            //}
            
            var sourcePack = sourceActivity.activityBo ? sourceActivity.activityBo.getNestedParent() : "";
            if(sourcePack){
				var altId = DataManager.getDataManager().getRepositoryId(DataManager.getDataManager().get('viewAlternative'));
				var alt = DataManager.getDataManager().getAlternativeSync(altId);
				var scenario = alt.getMainScenario(sourcePack);
				var delContexts = sourceActivity.activityBo.get('delegationContext');
				var activityContext;
				delContexts.each(function (context) {
					if (context.getScenario() == scenario) {
						activityContext = context;
					}
				})
				var contextCollaboration;
				var collActivities;
				if (activityContext) {
					contextCollaboration = activityContext.get('contextCollaboration');
					collActivities = contextCollaboration.get('activity');
				}
			}

            for (var i = 0; i < subActivities.length; i++) {
                var activity = subActivities[i];
                var shape = self.elementFactory.createShape({ type: 'bpmn:Task' });

                if (activity.activityBo) {
					var subActMappedType = activity.activityBo.get('type');
					if(subActMappedType == "vdml_CapabilityDefinition" || subActMappedType == "vdml_CapabilityCategory"){
						shape.businessObject.mcid = activity.activityBo.id;
						shape.businessObject.mcpid = activity.activityBo.getNestedParent().id;
						window.vdmModelView.model.get('capability').add(activity.activityBo);
					}else {
						shape.businessObject.mid = activity.activityBo.id;
						shape.businessObject.mpid = activity.activityBo.getNestedParent().id;
						window.vdmModelView.model.get('competence').add(activity.activityBo);
					}
                } else if (activity.mid) {
                    shape.businessObject.mid = activity.mid;
                    shape.businessObject.mpid = activity.mpid;
                }

                if (collActivities) {
                    collActivities.each(function (activity) {
                        if (activity.get('appliedCapabilityOffer') && activity.get('appliedCapabilityOffer').id == shape.businessObject.mid) {
                            shape.businessObject.mid = activity.id;
                            shape.businessObject.mpid = activity.getNestedParent().id;
                        }
                    });
                }
				var xpos;
                var ypos;
				var diagrams = self.bpmnModeler._definitions ? self.bpmnModeler._definitions.diagrams : "";
				if(diagramInstance.get('data') && diagrams.length > 0){
					     var elements = diagrams[0].plane.planeElement;
						 var array = checkIsSubProcessExist(elements,activity);
						 if(array.exist){
						 	 continue;
						 }
						 xpos = array.xpos ? array.xpos + array.height + array.width : 150;
						 ypos = array.ypos ? array.ypos + array.height/2 : 100;
				}
                if (firstActivity) {
                    if (parent.x == undefined && !diagramInstance.get('data')) {
                        xpos = previousLane ? previousLane.x + 20 : 0;
                        ypos = previousLane ? previousLane.height + previousLane.height / 3 : 100;
                    } else if (previousLane) {
                        xpos = previousLane.x + 20;
                        ypos = previousLane.y + previousLane.height + previousLane.height / 3;
                    }if(parent.x || parent.y) {
                        xpos = parent.x ? parent.x + 20 : 20;
                        ypos = parent.y ? parent.y + 20 : 20;
                    }
                    previousActivityShape = self.modeling.createShape(shape, { 'x': xpos, 'y': ypos }, parent);
                    firstActivity = false;
                } else {
                    if (previousActivity.depth != activity.depth) {
                        previousActivityShape = self.modeling.createShape(shape, { 'x': previousActivityShape.x + 250, 'y': previousActivityShape.y + previousActivityShape.height / 2 }, parent);
                    } else {
                        previousActivityShape = self.modeling.createShape(shape, { 'x': previousActivityShape.x + previousActivityShape.width + previousActivityShape.height, 'y': previousActivityShape.y + previousActivityShape.height / 2 }, parent);
                    }
                }
				activity.activityShape = previousActivityShape;
				previousActivityShape.businessObject.name = activity.name;
				self.eventBus.fire('elements.changed', {
					elements: [previousActivityShape]
				});
				previousActivity = activity;
			}
            window.vdmModelView.saveDiagram(null, null, callback);
        }
        addOutputGateways() {
            var self = this;
            var activityKeys = Object.getOwnPropertyNames(self.activities);
            _.each(activityKeys, function (key) {
                var activity = self.activities[key];
                if (activity.name == 'gateway') {
                    return;
                }
                var children = activity.children;
                if (children && children.length > 1) {
                    if (!self.activities[key + '-nextgateway']) {
                        self.activities[key + '-nextgateway'] = { id: key + '-nextgateway', name: activity.name + '-out-gateway', type: 'bpmn:InclusiveGateway', valueStream: activity.valueStream, initialized: true };
                        self.activities[key + '-nextgateway'].depth = activity.depth + 1;
                        self.activities[key + '-nextgateway'].children = [];
                        self.activities[key + '-nextgateway'].roleId = activity.roleId;
                        if (activity.roleId) {
                            self.roles[activity.roleId].activities.push(self.activities[key + '-nextgateway']);
                        }
                        var lastflow; 
                        var childrenToRemove = [];
                        _.each(children, function (childflow) {
                            var gatewayFlow = { id: childflow.id + '-gateway', source: key + '-nextgateway', target: childflow.target};
                            self.activities[key + '-nextgateway'].children.push(gatewayFlow);
                            childflow.target = gatewayFlow.source;
                            lastflow = childflow;
                            childrenToRemove.push(childflow);
                            self.incrementDepthOfFlowElement(self.activities[key + '-nextgateway'], self.activities[gatewayFlow.target]);
                        })
                        activity.children = [];
                        activity.children.push(lastflow);
                    }
                }
            });
        }
        addInputGateways() {
            var self = this;
            self.determineParentsOfActivities();
            var self = this;
            var activityKeys = Object.getOwnPropertyNames(self.activities);
            _.each(activityKeys, function (key) {
                var activity = self.activities[key];
                if (activity.name == 'gateway') {
                    return;
                }
                var parents = self.activities[key].parents;
                var parentsCopy;
                if (parents) {
                    parentsCopy = parents.slice();
                    for (var i = 0; i < parentsCopy.length; i++) {
                        if (parentsCopy[i].source == self.start) {
                            parentsCopy.splice(i, 1);
                            break;
                        }
                    }
                }
                if (parentsCopy && parentsCopy.length > 1) {
                    if (!self.activities[key + '-prevgateway']) {
                        self.activities[key + '-prevgateway'] = { id: key + '-prevgateway', name: activity.name + '-in-gateway', type: 'bpmn:InclusiveGateway', valueStream: activity.valueStream, initialized: true };
                        self.activities[key + '-prevgateway'].depth = activity.depth;
                        self.activities[key + '-prevgateway'].roleId = activity.roleId;
                        self.activities[key + '-prevgateway'].children = [];
                        self.activities[key + '-prevgateway'].parents = [];
                        if (activity.roleId) {
                            self.roles[activity.roleId].activities.push(self.activities[key + '-prevgateway']);
                        }
                        var parentsTarget = parentsCopy[0].target;
                        
                        _.each(parentsCopy, function (parentflow) {
                            var gatewayFlow = { id: parentflow.id + '-gateway', source: parentflow.source, target: key + '-prevgateway' };
                            self.activities[parentflow.source].children.push(gatewayFlow);
                            var index = _.indexOf(self.activities[parentflow.source].children, parentflow);
                            self.activities[parentflow.source].children.splice(index, 1);
                            parentflow.target = gatewayFlow.target;
                            self.activities[key + '-prevgateway'].parents.push(gatewayFlow);
                        })
                        var targetFlow = { id: key + '-prevgateway' + '-nextgateway', source: key + '-prevgateway', target: parentsTarget };
                        self.activities[key + '-prevgateway'].children.push(targetFlow);
                        self.activities[parentsTarget].parents = [];
                        self.activities[parentsTarget].parents.push(targetFlow);
                    }
                    self.incrementDepthOfFlowElement(self.activities[key + '-prevgateway'],self.activities[key]);
                }
            });
        }
        determineParentsOfActivities() {
            var self = this;
            var activityKeys = Object.getOwnPropertyNames(self.activities);
            _.each(activityKeys, function (key) {
                var activity = self.activities[key];
                var children = activity.children;
                _.each(children, function (flow) {
                    var targetActivity = self.activities[flow.target];
                    if (targetActivity.parents == undefined) {
                        targetActivity.parents = [];
                    }
                    targetActivity.parents.push(flow);
                })
            });
        }
        addActivityConnections(activity) {
            var self = this;
            var children = activity.children;
            var delElements = [];
            if (children && children.length > 0) {
                /*var outgoingFlows = activity.activityShape.outgoing;
                _.each(outgoingFlows, function (flow) {
                    if (self.is(flow.target, 'bpmn:InclusiveGateway')) {
                        delElements.push(flow.target);
                    }
                })*/
				if(activity.activityShape){
					activity.activityShape.businessObject.outgoing = [];
				}
            }
            //self.modeling.removeElements(delElements);
            _.each(children, function (flow) {
                var targetActivity = self.activities[flow.target];
                var targetShape = targetActivity.activityShape;
                if (activity.activityShape && targetShape) {
                    var seqFlow;
                    if (activity.activityShape.parent != targetShape.parent) {
                        seqFlow = self.findElement('bpmn:MessageFlow', flow.mid, flow.name);
                     } else {
                        seqFlow = self.findElement('bpmn:SequenceFlow', flow.mid, flow.name);
                    }
                    if (!seqFlow) {
                        var parent = activity.activityShape.parent;
                        if (activity.activityShape.parent != targetShape.parent) {
                            seqFlow = self.modeling.connect(activity.activityShape, targetShape, {
                                type:  'bpmn:MessageFlow'
                            },parent);
                        }else{
                            seqFlow = self.modeling.connect(activity.activityShape, targetShape, {
                                type:  'bpmn:SequenceFlow'
                            },parent);
                        }
                        seqFlow.businessObject.mid = flow.mid;
                        seqFlow.businessObject.mpid = flow.mpid;
                    }
                    self.eventBus.fire('elements.changed', {
                        elements: [targetShape]
                    });
                }
            })
            var compChildren = activity.compChildren;
            _.each(compChildren, function (flow) {
                var targetActivity = self.activities[flow.target];
                var targetShape = targetActivity.activityShape;
                if (activity.activityShape && targetShape) {
                    var dataFlow;
                    if (flow.isOutput && flow.isOutput == "true") {
                        dataFlow = self.findElement('bpmn:DataOutputAssociation', flow.mid,flow.name);
                        if (!dataFlow) {
                            dataFlow = self.modeling.connect(activity.activityShape, targetShape, {
                                type: 'bpmn:DataOutputAssociation'
                            });
                        }
                    } else {
                        dataFlow = self.findElement('bpmn:DataInputAssociation', flow.mid, flow.name);
                        if (!dataFlow) {
                            dataFlow = self.modeling.connect(targetShape, activity.activityShape, {
                                type: 'bpmn:DataInputAssociation'
                            });
                        }
                    }
                    dataFlow.businessObject.mid = flow.mid;
                    dataFlow.businessObject.mpid = flow.mpid;
                    self.eventBus.fire('elements.changed', {
                        elements: [targetShape]
                    });
                    
                }
            })
        }
        addLaneContent (lane, previousLane, roleKey,activityLane) {
            var self = this;
            var role = self.roles[roleKey];
            var activities = [];
            if(role){
                activities = role.activities;
            }
            
            var parent; var activityList =[];
			var previousCompActId;
            if (roleKey === self.nonPerformer) {
                parent = self.bpmnModeler.get('canvas').getRootElement();
            } else {
                parent = lane;
            }
            var firstActivity = true;
            var previousActivityShape;
            var previousActivity;var elementList =[];
			if(!activityLane && roleKey === "noperformer"){
			   for(var i=0;i<activities.length;i++){
                   if(previousLane && previousLane.type==  "bpmn:Lane" && roleKey == "noperformer" && activities[i].type !== "vdml:Competency" ){
                       continue;
                   }
			   	   if((activities[i].type === "vdml:Activity")|| (activities[i].type === "vdml:Competency") || (activities[i].type.indexOf("bpmn:") >=0)){
				   	   elementList.push(activities[i]);
				   }
			   }
			   /*for(var j=activities.length-1;j>=0;j--){
			   	   if(activities[j].type === "vdml:Competency"){
				   	   elementList.push(activities[j]);
				   }
			   }*/
			}
            if(!(previousLane && previousLane.type ==  "bpmn:Lane" && roleKey == "noperformer")){
                elementList = elementList.length > 0 ? elementList : activities;
            }
			_.each(elementList, function (activity) {
                var shape;
                var existingShape = true;
                var mid = activity.activityBo ? activity.activityBo.id : activity.mid;
                var name = activity.name;
                if (activity.id === self.start) {
                    shape = self.findElement('bpmn:StartEvent', mid, name);
                    if (!shape) {
                        shape = self.elementFactory.createShape({ type: 'bpmn:StartEvent' });
                        existingShape = false;
                    }
                } else if (activity.id === self.end) {
                    shape = self.findElement('bpmn:EndEvent', mid, name);
                    if (!shape) {
                        shape = self.elementFactory.createShape({ type: 'bpmn:EndEvent' });
                        existingShape = false;
                    }
                } else if (activity.type.indexOf('bpmn:') >= 0) {
                    shape = self.findElement(activity.type, mid, name);
                    if (!shape) {
                        shape = self.elementFactory.createShape({ type: activity.type });
                        existingShape = false;
                    }
                } else {
                    if (activity.type == "vdml:Competency") {
                        shape = self.findElement('bpmn:DataStoreReference', mid, name);
                        if (!shape) {
                            shape = self.elementFactory.createShape({ type: 'bpmn:DataStoreReference' });
                            existingShape = false;
                        }
                    } else {
                        if (previousLane && !lane) {    //
                            console.log('Skipping activity:' + activity.name + " as there is no performer for it and there is a lane in the diagram.");
                            return;
                        }
                        if (!activity.delegated) {
                            shape = self.findElement('bpmn:Task', mid, name);
                            if (!shape) {
                                shape = self.elementFactory.createShape({ type: 'bpmn:Task' });
                                existingShape = false;
                            }
                        } else {
                            shape = self.findElement('bpmn:CallActivity', mid, name);
                            if (!shape) {
                                shape = self.elementFactory.createShape({ type: 'bpmn:CallActivity' });
                                existingShape = false;
                            }
                        }
                        
                    }
                }
				if (!existingShape) {
                    if (activity.activityBo) {
                        shape.businessObject.mid = activity.activityBo.id;
                        shape.businessObject.mpid = activity.activityBo.getNestedParent().id;
                    } else if (activity.mid) {
                        shape.businessObject.mid = activity.mid;
                        shape.businessObject.mpid = activity.mpid;
                    }
					if (firstActivity) {
                        var xpos;
                        var ypos;
                        if (parent.x == undefined) {
                            //xpos = previousLane ? previousLane.x * 2 : 20;
                            xpos = previousLane ? previousLane.x + 50 : 20;
                            ypos = previousLane ? previousLane.y + previousLane.height + previousLane.height / 3 : 20;
                        } else if (previousLane) {
                            xpos = previousLane.x + 20;
                            ypos = previousLane.y + previousLane.height + previousLane.height / 3;
                        }else if(activityLane){
						    var element = activityLane.businessObject.flowNodeRef ? activityLane.businessObject.flowNodeRef[activityLane.businessObject.flowNodeRef.length-1] : "";
                            var eleObj = window.vdmModelView.modeler.get('elementRegistry')._elements[element.id];
                            var dd = eleObj?eleObj.element:null;
							xpos = dd.x + 2 * dd.width;
							ypos = parent.y + dd.width/2 + 20;
						}
						else {
                            xpos = parent.x ? parent.x + 20 : 20;
                            ypos = parent.y ? parent.y + 20 : 20;
                        }
                        previousActivityShape = self.modeling.createShape(shape, { 'x': xpos, 'y': ypos }, parent);
                        firstActivity = false;
                    } else {
                        if(activity.type === "vdml:Competency" && activityList.length > 0){ // For Resource Positioning
							for(var i=activityList.length-1;i>=0;i--){
								if(activityList[i].delegated){
									activityList.splice(i,1);
									continue;
								}
								var compChild = activityList[i].compChildren;
								if(compChild){
									var isExist = false;
									for(var j=0;j<compChild.length;j++){
										if(compChild[j].target === activity.id){
										   if(!previousCompActId || previousCompActId != activityList[i].id){
											  var actPostion = activityList[i].activityShape;
											  previousActivityShape = self.modeling.createShape(shape, { 'x': actPostion.x - 150, 'y': 2 * (actPostion.y + actPostion.height) }, parent);
										   }else{
											  previousActivityShape = self.modeling.createShape(shape, { 'x': previousActivityShape.x, 'y': previousActivityShape.y + 150 }, parent);
										   }
										   previousCompActId = activityList[i].id;
										   isExist = true;
										   break;
										}
									}
									if(isExist){
										break;
									}
								}
							}
						}
						else if (previousActivity.depth != activity.depth) {
                            previousActivityShape = self.modeling.createShape(shape, { 'x': previousActivityShape.x + 250, 'y': previousActivityShape.y + previousActivityShape.height / 2 }, parent);
						} else {
                            previousActivityShape = self.modeling.createShape(shape, { 'x': previousActivityShape.x + previousActivityShape.width / 2, 'y': previousActivityShape.y + previousActivityShape.height * 2 }, parent);
                        }
                    }
                } else {
                    previousActivityShape = shape;
                    if (firstActivity) {
                        firstActivity = false;
                    }
                }
				activity.activityShape = previousActivityShape;
                previousActivityShape.businessObject.name = activity.name;
                self.eventBus.fire('elements.changed', {
                    elements: [previousActivityShape]
                });
                previousActivity = activity;
				if(activity.type === "vdml:Activity"){
					activityList.push(previousActivity)
				}
            })
        }
        htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '')
                .replace(/#/g, '');
        }
        onAddProcessMap(name, description, pack, phase, alternative, currentAlternative, type, moduleTypes, callback) {
            var self = this;
            if (pack !== null && pack.id) {
                var altId = DataManager.getDataManager().getRepositoryId(pack.id);
                DataManager.getDataManager().fetchDocumentFromPackage(pack.id, "appbo/vdml/ValueDeliveraryModel", pack.version, pack.id, "appbo/vdml/ValueDeliveryModel", DataManager.getDataManager().getVDMStore(altId), {
                    success: function (model) {
                        if (altId !== currentAlternative.get('id')) {
                            self.createRevisionBasedOnContext(model, function (newPackage) {
                                var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
                                var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
                                var ecoMap = newPackage.get('diagrams').findWhere({ 'id': altId + bmId });
                                var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
                                var diagrams = newPackage.get('diagrams');
                                for (var i = 0; i < diagrams.length; i++) {
                                    newPackage.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
                                }
                                //window.utils.startSpinner('revisionSpinner', "Creating a copy...");
                                //setTimeout(function(){

                                //model.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), function (newPackage) {
                                //window.utils.stopSpinner('generateProcessDiagramSpinner');
                                self.createProcessMap(name, description, newPackage, currentAlternative, type, moduleTypes, callback);
                                //});
                                //},10);
                            });
                        } else {
                            self.createProcessMap(name, description, model, currentAlternative, type, moduleTypes, callback);
                        }
                    },
                    error: function (error) {
                        console.log('Unable to load selected Package');
                    }
                });
            } else {
                self.createProcessMap(name, description, null, currentAlternative, type, moduleTypes, callback);
            }
        };
        createProcessMap(name, description, vdmPackage, currentAlternative, type, moduleTypes, callback) {
            var self = this;
            if (!vdmPackage) {
                var vdm = ValueDeliveryModel.createPackageWithName(name, description, currentAlternative);
                self.createProcessMapDiagram(vdm, name, description, null, type, callback);
            } else {
                self.createProcessMapDiagram(vdmPackage, name, description, null, type, callback);
            }
        }
        createProcessMapDiagram(pack, name, description, valternative, type, callback) {
            var self = this;
            var altId = DataManager.getDataManager().get('viewAlternative');
            if (altId) {
                DataManager.getDataManager().getAlternative(altId, function (alternative) {
                    self.processPackage = pack;
                    self.diagramInstance = ProcessDiscoveryDiagram.getInstance(name, description, pack);
                    window.vdmModelView.model.set('process', self.diagramInstance);
                    if (valternative && valternative !== alternative) {
						altId = valternative.get('id');
                    }
                    setTimeout(function () {//VDM-2107
                        DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + pack.get('id') + "/vdml_ValueDeliveryModel/" + pack.get('version') + "/" + self.diagramInstance.get('id') + "/vdml_ProcessDiscoveryDiagram/" + altId + "", { trigger: true });
                        setTimeout(self.buildBPMNDiagram.bind(self,callback), 500);
                    }, 300);
                });
            }
        }
        /*ProcessTree.prototype.handleNew = function () {
            var self = this;
            function savePackage(view, modalId, type, moduleTypes) {
                var currentWorkspace = DataManager.getDataManager().get('currentWorkspace');
                self.onAddProcessMap(view.name(), view.description(), view.selectedPackage(), null, null, self.currentAlternativeModel, type, moduleTypes);
                window.cleanDialogModel(modalId, view);
            }
            var alternative = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
            var addOptions = { 'alternative': alternative, 'BMs': [], 'packages': self.existingPackages};
            window.getAndCreateModalDialog(window.vdmModelView.model, self.htmlEscape(window.vdmModelView.model.id), BusinessModelMixin, window.vdmModelView.model, "CreatePackage", savePackage, addOptions);
        }
        ProcessTree.prototype.loadProcessPackages = function (callback) {
            var self = this;
            self.existingPackages = [];
            DataManager.getDataManager().getAllDocumentsCollectionOfType(self.currentAlt, 'vdml_ValueDeliveryModel', function (results) {
                for (var i = 0; i < results.length; i++) {
                    var type = results.at(i).get('type');
                    if (type === "vdml_ValueDeliveryModel") {
                        self.existingPackages.push(results.at(i));
                    }
                }
                callback();
            }, true, null, null, 'vbc:cmof_EObject-name');
        };*/
        handleProcessCreation(callback) {
            var self = this;
            var parentView = window.vdmModelView;
            function savePackage(view, modalId, type, moduleTypes) {
                setTimeout(function () {
					window.autoProcess = true;
                    window.utils.startSpinner('generateProcessDiagramSpinner', "Generating Process...");
                    self.onAddProcessMap(view.name(), view.description(), view.selectedPackage(), null, null, self.currentAlt, type, moduleTypes, callback);
                    window.cleanDialogModel(modalId, view);
                }, 100)
            }
            self.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
            //self.loadProcessPackages(function () {
                self.diagramInstance = parentView.model.get('process');
                if (self.diagramInstance) {
                    self.processPackage = self.diagramInstance.getNestedParent();
                    var altId = DataManager.getDataManager().get('viewAlternative');
                    DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.processPackage.get('id') + "/vdml_ValueDeliveryModel/" + self.processPackage.get('version') + "/" + self.diagramInstance.get('id') + "/vdml_ProcessDiscoveryDiagram/" + altId + "", { trigger: true });
                    setTimeout(self.buildBPMNDiagram.bind(self), 500);
                } else {
                    var mapTitle = DataManager.getDataManager().get('localeManager').get("ProcessModel");
                    var addOptions = { 'alternative': self.currentAlt, 'plusIconType': 'capabilityMethod', onlyMap: mapTitle };
                    window.getAndCreateModalDialog(window.vdmModelView.model, self.htmlEscape(window.vdmModelView.model.id), BeepPackageMixin, self.currentAlt.getNestedParent(), "Prototype", savePackage, addOptions);
                }
            //});
        };

        sortRoleActivities() {
            var self = this;
            var roleKeys = Object.getOwnPropertyNames(self.roles);
            _.each(roleKeys, function (roleKey) {
                var role = self.roles[roleKey];
                role.activities = _.uniq(role.activities);
                role.activities.sort(function (a1, a2) {
                    if (a1.parents == undefined || a1.parents.length == 0) {
                        return -1;
                    }
                    if (a2.parents == undefined || a2.parents.length == 0) {
                        return 1;
                    }
                    return a1.depth - a2.depth;
                });
                console.log('role:' + role.name);
                var maxDepthSize = 0;
                var lastDepth;
                var currentDepthSize = 0;
                _.each(role.activities, function (activity) {
                    console.log('activity:' + activity.name);
                    console.log('activity id:' + activity.id);
                    console.log('activity depth:' + activity.depth);
                    if (!lastDepth || lastDepth != activity.depth) {
                        lastDepth = activity.depth;
                        currentDepthSize = 0;
                    }
                    currentDepthSize++;
                    if (maxDepthSize < currentDepthSize) {
                        maxDepthSize = currentDepthSize;
                    }
                })
                role.maxDepthSize = maxDepthSize;
                console.log('role depth:' + role.maxDepthSize);
            });
        }

        incrementDepthOfFlowElement(source,target,cycleCheck) {
            var self = this;
            var cycleCheck = cycleCheck?cycleCheck:{};
            if (source.depth > target.depth) {
                target.depth = source.depth + 1;
            } else {
                target.depth++;
            }
            var children = target.children;
            _.each(children, function (flow) {
                var childActivity = self.activities[flow.target];
                if (childActivity.valueStream !== target.valueStream || (childActivity.depth < target.depth && childActivity.valueStream === target.valueStream && childActivity.x < target.x)) {
                    return;
                }
                if(!cycleCheck[childActivity.id]){
                    cycleCheck[childActivity.id] = true
                    self.incrementDepthOfFlowElement(target,childActivity,cycleCheck);
                }
            })
        }
        updateWithFlowElement(flowElement, activity) {
            var self = this;
            
            var elementId = flowElement.mid ? flowElement.mid : flowElement.id;
            if (flowElement.$type == "vdml:Competency" && (flowElement.mid || flowElement.mcid)) {
                var compBo;
                if (flowElement.mid) {
                    compBo = window.vdmModelView.model.get('competence').get(flowElement.mid);
                } else {
                    compBo = Backbone.Relational.store.getObjectByName('vdml.Capability').find({ id: flowElement.mcid});
                }
                
                if (self.generateSubProcess && compBo && !(compBo instanceof BusinessItem)) {
                    //if (!self.subProcessActivities[elementId]) {
                        var eleObj = window.vdmModelView.modeler.get('elementRegistry')._elements[flowElement.id];
                        var ele = eleObj?eleObj.element:null;
                        self.subProcessActivities.push({ id: elementId, name: flowElement.name, type: flowElement.$type, valueStream: flowElement.$parent.id, initialized: true, x: ele.x, activityBo: compBo });
                    //}
                    return;
                }
            }
            var isStartActivity = flowElement.isProcessStart;
            var isStopActivity = flowElement.isProcessEnd;

            function handleStartStop() {
                if (isStartActivity) {
                    if (!self.activities[self.start]) {
                        self.activities[self.start] = { id: self.start, name: self.start, type: 'bpmn:StartEvent', valueStream: flowElement.$parent.id, initialized: true };
                        self.activities[self.start].depth = -1;
                        self.activities[self.start].children = [];
                    }
                    if (!self.flows[elementId + '-start']) {
                        self.flows[elementId + '-start'] = { id: elementId + '-start', source: self.start, target: elementId };
                        self.activities[self.start].children.push(self.flows[elementId + '-start']);
                    }
                }
                if (isStopActivity) {
                    if (!self.activities[self.end]) {
                        self.activities[self.end] = { id: self.end, name: self.end, type: 'bpmn:EndEvent', valueStream: flowElement.$parent.id, initialized: true };
                        self.activities[self.end].depth = 200;
                    }
                    if (!self.flows[elementId + '-end']) {
                        self.flows[elementId + '-end'] = { id: elementId + '-end', source: elementId, target: self.end };
                        if (!self.activities[elementId].children) {
                            self.activities[elementId].children = [];
                        }
                        self.activities[elementId].children.push(self.flows[elementId + '-end']);
                    }
                }
            }
            function handleRole() {
                var role;
                if (activity) {
                    role = activity.get('performingRole');
                }
                if (role) {
                    if (!self.roles[role.id]) {
                        self.roles[role.id] = { id: role.id, name: role.get('name')};//To-do duplicate role name/* + "(" + role.get('collaborationRoleOwner').get('name') + ")"*/};
                    }
                    self.roles[role.id].mid = role.id;
                    self.roles[role.id].mpid = role.getNestedParent().id;
                    if (!self.roles[role.id]['activities']) {
                        self.roles[role.id]['activities'] = [];
                    }
                    self.roles[role.id]['activities'].push(self.activities[elementId]);
                    self.activities[elementId].roleId = role.id;
                    if (isStartActivity) {
                        self.roles[role.id]['activities'].push(self.activities[self.start]);
                    }
                    if (isStopActivity) {
                        self.roles[role.id]['activities'].push(self.activities[self.end]);
                    }
                    if (isStartActivity && !self.activities[self.start].roleId) {
                        self.activities[self.start].roleId = role.id;
                    }
                    if (isStopActivity && !self.activities[self.end].roleId) {
                        self.activities[self.end].roleId = role.id;
                    }
                } else {
                    if (!self.roles[self.nonPerformer]) {
                        self.roles[self.nonPerformer] = { id: self.nonPerformer, name: '' };
                    }
                    if (!self.roles[self.nonPerformer]['activities']) {
                        self.roles[self.nonPerformer]['activities'] = [];
                    }
                    self.roles[self.nonPerformer]['activities'].push(self.activities[elementId]);
                    self.activities[elementId].roleId = self.nonPerformer;
                    if (isStartActivity) {
                        self.roles[self.nonPerformer]['activities'].push(self.activities[self.start]);
                    }
                    if (isStopActivity) {
                        self.roles[self.nonPerformer]['activities'].push(self.activities[self.end]);
                    }
                    if (isStartActivity && !self.activities[self.start].roleId) {
                        self.activities[self.start].roleId = self.nonPerformer;
                    }
                    if (isStopActivity && !self.activities[self.end].roleId) {
                        self.activities[self.end].roleId = self.nonPerformer;
                    }
                }
            }
            if (!self.activities[elementId]) {
                var eleObj = window.vdmModelView.modeler.get('elementRegistry')._elements[flowElement.id];
                var ele = eleObj?eleObj.element:null;
                self.activities[elementId] = { id: elementId, name: flowElement.name, type: flowElement.$type, valueStream: flowElement.$parent.id, initialized: true, x: ele ? ele.x: 0, activityBo: flowElement.activityBo };
                self.activities[elementId].depth = 0;
                handleStartStop();
                handleRole();
            } else {
                if (self.activities[elementId].initialized) {
                    return;
                } else {
                    self.activities[elementId].initialized = true;
                    self.activities[elementId].activityBo = flowElement.activityBo;
                    handleStartStop();
                    handleRole();
                }
            }
            var outgoingFlows = flowElement.outgoing;
            _.each(outgoingFlows, function (flow) {
                var flowId = flow.mid ? flow.mid : flow.id;
                var targetId = flow.targetRef.mid ? flow.targetRef.mid : flow.targetRef.id;
                if (!(flow.targetRef.$type == 'vdml:Activity' || flow.targetRef.$type == 'vdml:CapabilityContainer') || self.flows[flowId] || self.competencyFlows[flowId] || self.subProcessFlows[flowId]) {
                    return;
                }
                if (flow.targetRef.$type == 'vdml:Activity') {
                    handleDeliverableFlow(flow);
                } else {
                    handleCompetencyFlow(flow);
                }
            })
            function handleCompetencyFlow(flow) {
                var hasCapabilityFlows = false;
                var compContainer = self.elementRegistry.get(flow.targetRef.id);
                
                var competencies = self.getBusinessObject(compContainer).flowElements;
                _.each(competencies, function (comp) {
                    var flowId = flow.id + "-" + comp.id;
                    var targetId = comp.mid ? comp.mid : comp.id;
                    var isCapability = false;
                    var compBo;
                    if (comp.mid || comp.mcid) {
                        if (comp.mid) {
                            compBo = window.vdmModelView.model.get('competence').get(comp.mid);
                        } else {
                            compBo = Backbone.Relational.store.getObjectByName('vdml.Capability').find({ id: comp.mcid });
                        }
                        
                        if (!(compBo instanceof BusinessItem)) {
                            isCapability = true;
                            hasCapabilityFlows = true;
                        }
                    }
                    if (self.generateSubProcess && isCapability) {
                        return;
                    }
                    self.competencyFlows[flowId] = { id: flowId, source: elementId, target: targetId, isOutput: comp.isOutput };
                    if (!self.activities[elementId].compChildren) {
                        self.activities[elementId].compChildren = [];
                    }
                    self.activities[elementId].compChildren.push(self.competencyFlows[flowId]);
                    var targetActivity = self.activities[targetId];
                    if (!targetActivity) {
                        var eleObj = window.vdmModelView.modeler.get('elementRegistry')._elements[flow.targetRef.id];
                        var ele = eleObj?eleObj.element:null;
                        self.activities[targetId] = { id: targetId, name: comp.name, type: comp.$type, capabilityContainer: flow.targetRef.id, initialized: false, x: ele.x };
                        if (comp.mid) {
                            self.activities[targetId].mid = comp.mid;
                            self.activities[targetId].mpid = comp.mpid;
                        }
                        self.activities[targetId].depth = self.activities[elementId].depth;
                    } else {
                        if (self.activities[targetId].depth < self.activities[elementId].depth) {
                            self.activities[targetId].depth = self.activities[elementId].depth;
                        }
                    }
                })
                if (self.generateSubProcess && hasCapabilityFlows) {
                    var flowId = flow.mid ? flow.mid : flow.id;
                    self.subProcessFlows[flowId] = { id: flowId, source: elementId, target: flow.targetRef.id };
                    self.activities[elementId].delegated = true;
                }
            }
            function handleDeliverableFlow(flow) {
                var flowId = flow.mid ? flow.mid : flow.id;
                var targetId = flow.targetRef.mid ? flow.targetRef.mid : flow.targetRef.id;
                if (!self.activities[elementId].children) {
                    self.activities[elementId].children = [];
                }
                self.flows[flowId] = { id: flowId, source: elementId, target: targetId };
                self.activities[elementId].children.push(self.flows[flowId]);
                var targetActivity = self.activities[targetId];
                if (!targetActivity) {
                    var eleObj = window.vdmModelView.modeler.get('elementRegistry')._elements[flow.targetRef.id];
                    var ele = eleObj?eleObj.element:null;
                    self.activities[targetId] = { id: targetId, name: flow.targetRef.name, type: flow.targetRef.$type, valueStream: flow.targetRef.$parent.id, initialized: false, x: ele.x };
                    self.activities[targetId].depth = self.activities[elementId].depth + 1;
                } else {
                    if (self.activities[targetId].depth <= self.activities[elementId].depth) {
                        self.incrementDepthOfFlowElement(self.activities[elementId], self.activities[targetId]);
                    }
                }
            }
        }

init(modeler, options){
    var self = this;
    self.nonPerformer = 'noperformer';
    self.start = 'start';
    self.end = 'end';
    self.modeler = modeler;
    self.roles = {};
    self.activities = {};
    self.options = options;
    self.flows = {};
    self.competencyFlows = {};
    self.generateSubProcess = false;
    self.generateFlow = false;
    self.subProcessFlows = {};
    self.subProcessActivities = [];
    self.processPackage;

}

        static getInstance(modeler, options) {
            var view = new ProcessTree(modeler, options);
            view.init(modeler, options);
            return view;
        };

}
path.ProcessTree = ProcessTree;
