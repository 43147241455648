import * as $ from "jquery";
import * as _ from "underscore";
import * as Backbone from "backbone";
import * as ko from "knockout";
import * as kb from "knockback";
import * as bootbox from "../../../../../../libs/bootbox/bootbox";
import * as async from "async";
import "moment/locale/es";
import { DataManager } from "../../../../../com/vbee/data/DataManager";
import { PhaseDetailsViewModel } from "./PhaseDetailsViewModel";

var path = DataManager.getDataManager().buildAppNsPath(
    "transformation.views.properties",
    global.version
);
export class PhaseDetails2ViewModel extends PhaseDetailsViewModel {
    init(model, options, detailedView) {
        super.init(model, options);
        var self = this;
        this.PhaseDetails2ViewModel = this;
    }

    deletePhaseObject = function (phaseModel, callback) {
        var self = this;
        const plan = phaseModel.get('phaseOwner');
        var dataManager = DataManager.getDataManager();
        dataManager.set('currentPlan', plan);
        function waitForPlanLoad() {
            setTimeout(function () {
                if (window.loadedPackages[plan.id]) {
                    phaseModel.deletePhaseAndStep(function () {
                        phaseModel.deletePhase(function () {
                            callback();
                        });
                    });
                } else {
                    waitForPlanLoad();
                }
            }, 100);
        }
        waitForPlanLoad();
    }

    checkIntermediatePhase(phase, intermediateCallback) {
        var self = this;
        var createIntermediatePhase = self.options.createNextPhase ? self.options.createNextPhase.model : null;
        if (!createIntermediatePhase) {
            createIntermediatePhase = self.options.createPreviousPhase ? self.options.createPreviousPhase.model : null;
        }
        if (createIntermediatePhase) {
            var createPreviousPhase = self.enableIsolation() ? false : true;
            phase.setPrevNextIntermediateLinks(createIntermediatePhase, createPreviousPhase, self.isolation(), function () {
                phase.createIntermediatePhaseStep(createIntermediatePhase, createPreviousPhase);
                intermediateCallback(phase);
            });
        } else {
            intermediateCallback(phase);
        }
    }

    static getInstance(model, options) {
        var view = new PhaseDetails2ViewModel(model, options);
        view.init(model, options);
        return view;
    }
}
path.PhaseDetails2ViewModel = PhaseDetails2ViewModel;
