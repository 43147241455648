import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { ValueElementMixin } from '../../../../../version1/bo/vdml/ValueElementMixin'
import { Observation } from '../../../../../version1/bo/smm/Observation'
import { CodeContainer } from '../../../../../version1/bo/beeppackage/CodeContainer'
//import { Analytics } from '../../../../../version1/bo/dashboard/Analytics'


/*define(["jquery", "underscore", "backbone", "knockout", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager","appbo/smm/Observation", "appbo/beeppackage/CodeContainer" , "app/global", "appbo/vdml/ValueElementMixin", "jquery.DataTable", "dataTable.PageResize" ],
    function ($, _, Backbone, ko, kb, bootbox, async, DataManager, Observation, CodeContainer, global,ValueElementMixin)
{*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class ScenarioDetailsViewModel {
	/*DataManager.getDataManager().get('currentPlan').get('phase').each(function(phase){
		self.phaseTypeAheadCollection.push({'name':phase.get('name'),'id':phase.get('id')});
	});*/
	
	/*if(self.phase!==null){
		_.each(self.phase.get('phaseAlternative').models,function(alt){
			self.alternativesList.push({'name':alt.get('name'),'id':alt.id});
		});
		var newObs = {'name': 'New', 'id':""};
		var observationModels = self.model.getOrCreatePhaseObjectiveSet().get('objectiveObservation');
		observationModels.each(function(observation){
			var timeStamp = observation.get('whenObserved');
			self.observations.push({'name':observation.get('name'),'id':observation.get('id'),'timeStamp':timeStamp});
		});
		self.observations.push(newObs);
	}*/
	
	/*this.selectedObs.subscribe(function(val){
		if(val && val.name === 'New' && val.id === ''){
			$('#observationName').show();
			self.enableComplete(false);
		}else {
			$('#observationName').hide();
			if(self.postShow && val) {
				self.loadTableData(self.model,val.timeStamp);
			}
		}
	});*/
	
	/*if(self.selectedObs()==="New"){
		$('#observationName').show();
	}*/
	
	/*this.selectedAlt.subscribe(function(val){
		self.cloneId = '';
		if(val && val.id) {
			var alt = DataManager.getDataManager().getAlternativeSync(val.id);
			if(alt.getParent().get('master') != alt){
				self.cloneId = alt.id;
			}
		}else {
			self.selectedPhase(null);
		}
		self.loadTableData(alt);
	});*/
	
	
	applyDefaultObservation(){
		var self = this;
		bootbox.confirm(''+DataManager.getDataManager().get('localeManager').get('ApplyScenario'), function(result) {
			if(result) {
				bootbox.confirm(''+DataManager.getDataManager().get('localeManager').get('ReplacedScenarioDelete'), function(delScenario) {
					var whenObserved = self.model.get('whenObserved');
					var plan = DataManager.getDataManager().get('currentPlan');
					window.utils.startSpinner('changingDefaultObsSpinner', "Applying Scenario...");
					setTimeout(function(){
						var ObservationMixin = Backbone.Relational.store.getObjectByName('smm.ObservationMixin');
                        ObservationMixin.applyDefaultObservation(whenObserved,delScenario,function(){
							self.parentView.fillAlternatives();
                            if(self.parentView.parentView){
                                self.parentView.parentView.setResizeHeight(true);
                            }
							self.parentView.openAccordion(self.phase.get('id'),self.altModel.get('id'));
							window.utils.stopSpinner('changingDefaultObsSpinner');
							self.enableApply(false);
							self.enableDelete(false);
							bootbox.alert(''+DataManager.getDataManager().get('localeManager').get('ApplyScenarioComplete'));
						});
					},100);
				});
			}	
		});
	};
	
	deleteScenario(){
		var self = this;
		bootbox.confirm(''+DataManager.getDataManager().get('localeManager').get('ScenarioDelete'), function(delScenario) {
			if(delScenario){
				return self.callback(self,self.encodeId,true);
			}
		});
	};
	custResize(){
		var self = this;
		this.timer = clearTimeout();
		this.timer = setTimeout(function(){
			var height = $('#editDetailsModalContent'+self.encodeId).height();
			$('#ScenarioDetailsTemplate'+self.encodeId).css('height', height);
			$('#valueTable'+self.encodeId).css('height', height - self.heightExcludingTable);
		}, 100);
	};
	valueChange(element){
		var self = this;
		var value = element.text();
		var tdID = element[0].id;
		if(!tdID){
			return;
		}
		var valueObj = _.find(self.valueArray, function(e){ return e.altValueComboId === tdID;});
		if(valueObj && value != valueObj.currentValue){
			var valueFound = false;
			for(var i=0;i<self.directValueChanges.length;i++){
				if(self.directValueChanges[i].id === valueObj.id && self.directValueChanges[i].altId === valueObj.altId){
					self.directValueChanges[i].value = value;
					valueFound = true;
					break;
				}
			}
			if(!valueFound){
				self.directValueChanges.push({'id':valueObj.id,'value':value,'altId':valueObj.altId});
			}
		}else if(!valueObj){
			var tdValId = element.attr("valId");
			valueObj = _.find(self.valueArray, function(e){ return e.id === tdValId;});
			var altObj = _.find(valueObj.phaseAltsValues, function(e){ return e.altValueComboId === tdID;});
			if(altObj && value != altObj.previousValue){
				valueFound = false;
				for(var i=0;i<self.directValueChanges.length;i++){
					if(self.directValueChanges[i].id === valueObj.id && self.directValueChanges[i].altId === altObj.id){
						self.directValueChanges[i].value = value;
						valueFound = true;
						break;
					}
				}
				if(!valueFound){
					self.directValueChanges.push({'id':valueObj.id,'value':value,'altId':altObj.id});
				}
			}
		}
	}
	getTableHeadings(phase,showAllPhases){
		var self = this;
		if(!showAllPhases){
			self.phaseHeadings.push({name:self.altModel.get('phaseAlternativeOwner').get('name'), colSpan: self.altModel.get('phaseAlternativeOwner').get('phaseAlternative').models.length, id:self.altModel.get('phaseAlternativeOwner').id});
			self.altHeadings.push({name:self.altModel.get('name'), id:self.altModel.id});
			if(phase) {
				var phaseAlts = phase.get('phaseAlternative');
				for(var i=0;i<phaseAlts.length;i++){
					if(phaseAlts.at(i) !== self.altModel){
						self.altHeadings.push({name:phaseAlts.at(i).get('name'), id:phaseAlts.at(i).id});
					}
				}
				phase=phase.get('nextPhase');
				while(phase){
					var phaseAlts = phase.get('phaseAlternative');
					self.phaseHeadings.push({name:phase.get('name'), colSpan: phaseAlts.models.length, id:phase.id});
					for(var i=0;i<phaseAlts.length;i++){
						self.altHeadings.push({name:phaseAlts.at(i).get('name'), id:phaseAlts.at(i).id});
					}
					phase=phase.get('nextPhase');
				}
			}/*else {
				self.phaseHeadings.push({name:inheritAlt.get('phaseAlternativeOwner').get('name'), colSpan: 1,id:inheritAlt.get('phaseAlternativeOwner').id});
				self.altHeadings.push(''+inheritAlt.get('name'));
			}*/
		}else{
			while(phase){
				phaseAlts = phase.get('phaseAlternative');
				self.phaseHeadings.push({name:phase.get('name'), colSpan: phaseAlts.models.length, id:phase.id});
				for(var i=0;i<phaseAlts.length;i++){
					self.altHeadings.push({name:phaseAlts.at(i).get('name'), id:phaseAlts.at(i).id});
				}
				phase=phase.get('nextPhase');
			}
		}
	};
	
	fillDuplicatePath(bmValues,val,values){
		var self = this;
		var displayPath = ' '; 
		var valFound = _.filter(bmValues,function(obj){if(obj.get('name').toLowerCase() === val.get('name').toLowerCase() && obj.id != val.get('id')){return obj};});
		if(valFound && valFound.length){
			var valuePackagePath = val.getPackagePath();
			var valueName = valuePackagePath[2]? valuePackagePath[2].name : "";
			displayPath = displayPath.concat(" ["+valuePackagePath[0].name+"  /  "+valuePackagePath[1].name+"  /  "+valueName+"]");
		}else {
			var valFound = _.filter(values,function(obj){return obj['name'].toLowerCase() === val.get('name').toLowerCase();});
			if(valFound && valFound.length){
				if(valFound[0].namePath.length < 2){
					var vp = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valFound[0].id });
					if(vp){
						var nestedValuePackagePath = vp.getPackagePath();
						var nestedValueName = nestedValuePackagePath[2]? nestedValuePackagePath[2].name : "";
						valFound[0].namePath = " ["+nestedValuePackagePath[0].name+"  /  "+nestedValuePackagePath[1].name+"  /  "+nestedValueName+"]";
					}
				}
				var valuePackagePath = val.getPackagePath();
				var valueName = valuePackagePath[2]? valuePackagePath[2].name : "";
				displayPath = displayPath.concat(" ["+valuePackagePath[0].name+"  /  "+valuePackagePath[1].name+"  /  "+valueName+"]");
			}	
		}
		return displayPath;
	};
	
	getSettingValues(){
		var self = this;
		var values = [];
		var settings = self.settings;
		if(settings){
			var obj = JSON.parse(settings);
			values = obj[0].values;
			/*for(var i =0;i<obj.length;i++){
				if(obj[i].altId===inheritingAlt.id){
					values = obj[i].values;
					break;
				}
			}*/
		}
		return values;
	};

	fetchValuesForCodeContainer(phaseAlts,timeStamp,showAllPhases,callback){
		var self = this;
		var phase = self.altModel?self.altModel.getParent():null;
		var values = [];
		var isTimestamp = false;
		if(timeStamp){
			isTimestamp = true;
		}
		if(self.altModel){
			values = self.getSettingValues(self.altModel);
		}/*else {
			values = self.getSettingValues(inheritingAlt);
		}*/
		var valArray =  values.length > 0?values.split(','):[];
		var valueColl = [];
		for(var i=0;i<valArray.length;i++){
			var valExits = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valArray[i] });
			if(!valExits) {
				valExits = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valArray[i] });
			}
			if(valExits){
				valueColl.push(valExits);
			}
		}
		async.eachSeries(valueColl, function(value, valHandled){
			var self = this;
			var phaseAltsValues = [];
			var currentValue = '';
			var previousValue = '-';
			var currentUnit = ' ';
			var previousUnit = ' ';
			if(self.altModel){
				var intervalArray = [];
				var altComp = self.altModel.getModelLinkedSync(value);
				var preAltScenario = altComp?self.altModel.getMainScenario(altComp.getNestedParent()):null;
				//TODO self.codeContainerList
				/*_.each(codeContainerList,function(container){
					dataStr = container.get('code');
					data = dataStr ? JSON.parse(dataStr) : null;
					// var valExits = Backbone.Relational.store.getObjectByName('vdml.MeasuredCharacteristic').find({ id: data.get('id') });
					for (temp in data) {
						var valExits = Backbone.Relational.store.getObjectByName('vdml.MeasuredCharacteristic').find({ id: temp });
						if (valExits) {
							// t.row.add([valExits.get("valueMeasurementOwner").get('name'), data[temp]]).draw(false);
							t.row.add([valExits.get('name'), data[temp]]).draw(false);
						}
					}
				})*/
				var observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('valueMeasurement'), isTimestamp, timeStamp):null;
				var currentMest = observedMeasure?observedMeasure.get('measurements').at(0):null;
				var disableCurrentValue = false;
				if(currentMest && currentMest.get('type') === 'smm_DirectMeasurement'){
					//showValue = true;
					var unit = currentMest.get('observedMeasure').get('measure').get('unit');
					currentUnit = unit?unit.get('name'):' ';
					var sigDecimal = unit?unit.get('significantDecimals'):'2';
					currentValue = window.utils.getRoundedValues(currentMest.get('value'),sigDecimal);
				}else {
					if(currentMest && currentMest.get('type') !== 'smm_DirectMeasurement'){
						var unit = currentMest.get('observedMeasure').get('measure').get('unit');
						currentUnit = unit?unit.get('name'):' ';
						var sigDecimal = unit?unit.get('significantDecimals'):'2';
						currentValue = window.utils.getRoundedValues(currentMest.get('value'),sigDecimal);
						disableCurrentValue = true;
						//showValue = true;
					}else{
						observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('satisfactionLevel'), isTimestamp, timeStamp):null;
						currentMest = observedMeasure?observedMeasure.get('measurements').at(0):null;
						if(currentMest){
							var compSatisfactionMeasure = currentMest?currentMest.get('observedMeasure').get('measure'):null;
							if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
								currentValue = '' + currentMest.get('symbol');
								currentUnit = 'Grade only';
								compSatisfactionMeasure.get('interval').each(function(interval){
									intervalArray.push(interval.get('symbol')?interval.get('symbol'): ' ');
								});
								//showValue = true;
							}
						}else{
							//showValue = false;
							disableCurrentValue = true;
						}
					}
						
				}
			}else {
				//showValue = true;
			}
			phaseAlts =  _.reject(phaseAlts, function(e){return e.id===self.altModel.id});
			if(phaseAlts) {	
				for(var i=0;i<phaseAlts.length;i++){
					//if(phaseAlts[i] !== self.altModel){
						var altComp = phaseAlts[i].getModelLinkedSync(value);
						var preAltScenario = altComp?phaseAlts[i].getMainScenario(altComp.getNestedParent()):null;
						var observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('valueMeasurement'), isTimestamp, timeStamp):null;
						var previousMest = observedMeasure?observedMeasure.get('measurements').at(0):null;
						var disableValue = false;
						if(preAltScenario && previousMest){
							var unit = previousMest.get('observedMeasure').get('measure').get('unit');
							previousUnit = unit?unit.get('name'):' ';
							/*if(currentUnit == previousUnit){
								previousUnit = ' ';
							}else if(currentUnit == ' '){
								currentUnit = previousUnit;
							}*/
							var sigDecimal = unit?unit.get('significantDecimals'):'2';
							previousValue = window.utils.getRoundedValues(previousMest.get('value'),sigDecimal);
							if(previousMest.get('type') !== 'smm_DirectMeasurement'){
								disableValue = true;	
							}	
							phaseAltsValues.push({id:phaseAlts[i].get('id'), previousValue:''+previousValue,previousUnit:previousUnit,altValueComboId:window.utils.htmlEscape(phaseAlts[i].get('id')+value.id),disableValue:disableValue});
						}else {
							observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('satisfactionLevel'), isTimestamp, timeStamp):null;
							previousMest = observedMeasure?observedMeasure.get('measurements').at(0):null;
							if(previousMest){
								var compSatisfactionMeasure = previousMest?previousMest.get('observedMeasure').get('measure'):null;
								if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
									previousValue = '' + previousMest.get('symbol');
									previousUnit = 'Grade only';
									phaseAltsValues.push({id:phaseAlts[i].get('id'),previousValue:''+previousValue,previousUnit:previousUnit,altValueComboId:window.utils.htmlEscape(phaseAlts[i].get('id')+value.id),disableValue:disableValue});
								}
							}else{
								phaseAltsValues.push({id:'-',previousValue:'-',previousUnit:'',disableValue:true});	
							}
						}
					//}
				}
			}
				
			//if(showValue) {
				var namePath = self.fillDuplicatePath([], value, self.valueArray);
				var obj = {id:value.get('id'),name:value.get('name'),namePath:namePath,phaseAltsValues:phaseAltsValues,previousValue:''+previousValue,currentValue:''+currentValue,previousUnit:previousUnit,currentUnit:currentUnit, intervalArray:intervalArray, altId:self.altModel.id, altValueComboId:window.utils.htmlEscape(self.altModel.id+value.get('id')),disableValue:disableCurrentValue};
				if(intervalArray.length > 0){
					obj.gradeOnly = true;
				}else{
					obj.gradeOnly = false;
				}
                obj.sourceName = self.getSourceName(value.id);
				self.valueArray.push(obj);
			//}
			valHandled();
		}, function(){
			self.loadAndSortData(phase,showAllPhases);
			callback && callback();
		});
	};
    getSourceName(valueId){
        var self = this;
        var valComp = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valueId });
	    if(!valComp){
		    valComp = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valueId });
		}
	    if(valComp){
            var sourceName = window.utils.getSourceName(valComp);
            return sourceName;
	    }
        return null;
    }
	
	fetchValuesForAlt(phaseAlts,timeStamp,showAllPhases, callback){
		var self = this;
		var phase = self.altModel?self.altModel.getParent():null;
		var values = [];
		var isTimestamp = false;
		if(timeStamp){
			isTimestamp = true;
		}
		if(self.altModel){
			values = self.getSettingValues(self.altModel);
		}/*else {
			values = self.getSettingValues(inheritingAlt);
		}*/
		var valArray =  values.length > 0?values.split(','):[];
		var valueColl = [];
		for(var i=0;i<valArray.length;i++){
			var valExits = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valArray[i] });
			if(!valExits) {
				valExits = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valArray[i] });
			}
			if(valExits){
				valueColl.push(valExits);
			}
		}
		async.eachSeries(valueColl, function(value, valHandled){
			var phaseAltsValues = [];
			var currentValue = '';
			var previousValue = '-';
			var currentUnit = ' ';
			var previousUnit = ' ';
			if(self.altModel){
				var intervalArray = [];
				var altComp = self.altModel.getModelLinkedSync(value);
				var preAltScenario = altComp?self.altModel.getMainScenario(altComp.getNestedParent()):null;
				var observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('valueMeasurement'), isTimestamp, timeStamp):null;
				var currentMest = observedMeasure?observedMeasure.get('measurements').at(0):null;
				var disableCurrentValue = false;
				if(currentMest && currentMest.get('type') === 'smm_DirectMeasurement'){
					//showValue = true;
					var unit = currentMest.get('observedMeasure').get('measure').get('unit');
					currentUnit = unit?unit.get('name'):' ';
					var sigDecimal = unit?unit.get('significantDecimals'):'2';
					currentValue = window.utils.getRoundedValues(currentMest.get('value'),sigDecimal);
				}else {
					if(currentMest && currentMest.get('type') !== 'smm_DirectMeasurement'){
						var unit = currentMest.get('observedMeasure').get('measure').get('unit');
						currentUnit = unit?unit.get('name'):' ';
						var sigDecimal = unit?unit.get('significantDecimals'):'2';
						currentValue = window.utils.getRoundedValues(currentMest.get('value'),sigDecimal);
						disableCurrentValue = true;
						//showValue = true;
					}else{
						observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('satisfactionLevel'), isTimestamp, timeStamp):null;
						currentMest = observedMeasure?observedMeasure.get('measurements').at(0):null;
						if(currentMest){
							var compSatisfactionMeasure = currentMest?currentMest.get('observedMeasure').get('measure'):null;
							if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
								currentValue = '' + currentMest.get('symbol');
								currentUnit = 'Grade only';
								compSatisfactionMeasure.get('interval').each(function(interval){
									intervalArray.push(interval.get('symbol')?interval.get('symbol'): ' ');
								});
								//showValue = true;
							}
						}else{
							//showValue = false;
							disableCurrentValue = true;
						}
					}
						
				}
			}else {
				//showValue = true;
			}
			phaseAlts =  _.reject(phaseAlts, function(e){return e.id===self.altModel.id});
			if(phaseAlts) {	
				for(var i=0;i<phaseAlts.length;i++){
					//if(phaseAlts[i] !== self.altModel){
						var altComp = phaseAlts[i].getModelLinkedSync(value);
						var preAltScenario = altComp?phaseAlts[i].getMainScenario(altComp.getNestedParent()):null;
						var observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('valueMeasurement'), isTimestamp, timeStamp):null;
						var previousMest = observedMeasure?observedMeasure.get('measurements').at(0):null;
						var disableValue = false;
						if(preAltScenario && previousMest){
							var unit = previousMest.get('observedMeasure').get('measure').get('unit');
							previousUnit = unit?unit.get('name'):' ';
							/*if(currentUnit == previousUnit){
								previousUnit = ' ';
							}else if(currentUnit == ' '){
								currentUnit = previousUnit;
							}*/
							var sigDecimal = unit?unit.get('significantDecimals'):'2';
							previousValue = window.utils.getRoundedValues(previousMest.get('value'),sigDecimal);
							if(previousMest.get('type') !== 'smm_DirectMeasurement'){
								disableValue = true;	
							}	
							phaseAltsValues.push({id:phaseAlts[i].get('id'), previousValue:''+previousValue,previousUnit:previousUnit,altValueComboId:window.utils.htmlEscape(phaseAlts[i].get('id')+value.id),disableValue:disableValue});
						}else {
							observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('satisfactionLevel'), isTimestamp, timeStamp):null;
							previousMest = observedMeasure?observedMeasure.get('measurements').at(0):null;
							if(previousMest){
								var compSatisfactionMeasure = previousMest?previousMest.get('observedMeasure').get('measure'):null;
								if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
									previousValue = '' + previousMest.get('symbol');
									previousUnit = 'Grade only';
									phaseAltsValues.push({id:phaseAlts[i].get('id'),previousValue:''+previousValue,previousUnit:previousUnit,altValueComboId:window.utils.htmlEscape(phaseAlts[i].get('id')+value.id),disableValue:disableValue});
								}
							}else{
								phaseAltsValues.push({id:'-',previousValue:'-',previousUnit:'',disableValue:true});	
							}
						}
					//}
				}
			}
				
			//if(showValue) {
				var namePath = self.fillDuplicatePath([], value, self.valueArray);
				var obj = {id:value.get('id'),name:value.get('name'),namePath:namePath,phaseAltsValues:phaseAltsValues,previousValue:''+previousValue,currentValue:''+currentValue,previousUnit:previousUnit,currentUnit:currentUnit, intervalArray:intervalArray, altId:self.altModel.id, altValueComboId:window.utils.htmlEscape(self.altModel.id+value.get('id')),disableValue:disableCurrentValue};
				if(intervalArray.length > 0){
					obj.gradeOnly = true;
				}else{
					obj.gradeOnly = false;
				}
                obj.sourceName = self.getSourceName(value.id);
				self.valueArray.push(obj);
			//}
			valHandled();
		}, function(){
			self.loadAndSortData(phase,showAllPhases);
			callback && callback();
		});
	};

	loadAndSortData(phase,showAllPhases){
		var self = this;
		if(self.valueArray.length > 0){
			self.valueArray.sort(function(left, right) {
				return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
			});
			if(!showAllPhases){
				self.getTableHeadings(phase,false);
			}else{
				var currentPlan = DataManager.getDataManager().get('currentPlan');
				var phase = currentPlan.get('phase').findWhere({previousPhase:null});
				self.getTableHeadings(phase,true);
			}
			_.each(self.valueArray, function(val){
				if(val.gradeOnly){
					var selectElement = $('#'+val.escapeId+self.encodeId);
					if(selectElement[0]){
						selectElement[0].value = val.currentValue;
					}
				}
			});
		}
	}

	loadTableData(inheritAlt,timeStamp,showAllPhases,callback){
		var self = this;
		self.values([]);
		self.valueArray = [];
		self.phaseHeadings.length = 0;
		//self.phaseHeadings.push({name:"Values",colSpan:1});
		self.altHeadings.length = 0;
		var phase;
		if(self.altModel){
			var phaseAlts = [];
			if(!showAllPhases){
				phase = self.altModel?self.altModel.getParent():null;
				while(phase){
					phaseAlts = phaseAlts.concat(phase.get('phaseAlternative').models);
					phase=phase.get('nextPhase');
				}
				if (timeStamp && !isNaN(timeStamp)) {
					fetchValuesForCodeContainer(phaseAlts,timeStamp,showAllPhases,callback);
				}else {
					self.fetchValuesForAlt(phaseAlts,timeStamp,showAllPhases,callback);
				}
			}else{
				var currentPlan = DataManager.getDataManager().get('currentPlan');
				phase = currentPlan.get('phase').findWhere({previousPhase:null});
				while(phase){
					phaseAlts = phaseAlts.concat(phase.get('phaseAlternative').models);
					phase=phase.get('nextPhase');
				}
				if (!isNaN(timeStamp)) {
					fetchValuesForCodeContainer(phaseAlts,timeStamp,showAllPhases,callback);
				}else {
					self.fetchValuesForAlt(phaseAlts,timeStamp,showAllPhases,callback);
				}
			}
		}
		/*else{
			if(!inheritAlt){
				if(self.selectedPhase()) {
					inheritAlt = self.selectedPhase().get('master');
				}else {
					var lastPhase = DataManager.getDataManager().get('currentPlan').get('phase').findWhere({nextPhase:null});
					inheritAlt = lastPhase.get('primary');
				}
				
			}
			self.fetchValuesForAlt(null,inheritAlt,timeStamp,showAllPhases,callback);
		}*/
	};
	
	displayTable(){
		var self = this;
		var myTableDiv = $('#valueTable' + self.encodeId);
		myTableDiv.empty();
		var table = document.createElement('TABLE');
		table.id = 'table' + self.encodeId;
		table.className="display";
		//table.className="pageResize";
		table.cellspacing="0";
		table.width="100%";
		//for header
		var tableHeader = document.createElement('THEAD');
		table.appendChild(tableHeader);
		var headerTR1 = document.createElement('TR');
		tableHeader.appendChild(headerTR1);
		//for phase headers
		var th = document.createElement('TH');
		th.appendChild(document.createTextNode(self.labels['InputValues']()));
		if(self.phaseHeadings.length > 0){
			th.rowSpan = 2;
			var headerTR2 = document.createElement('TR');
			tableHeader.appendChild(headerTR2);
		}
		headerTR1.appendChild(th);
		
		_.each(self.phaseHeadings, function(header){
			var th = document.createElement('TH');
			th.appendChild(document.createTextNode(header.name));
			if(header.name === "Values"){
				th.rowSpan = 2;
			}
			th.colSpan = header.colSpan;
			headerTR1.appendChild(th);
		});
		//for alt headers
		_.each(self.altHeadings, function(header){
			var th = document.createElement('TH');
			th.appendChild(document.createTextNode(header.name));
			th.className = "no-sort";
			headerTR2.appendChild(th);
		});		    
		//for tbody
		var tableBody = document.createElement('TBODY');
		table.appendChild(tableBody);
		var currentAltId = self.altModel?self.altModel.id:null;
		var index = self.altHeadings.map(function(e) { return e.id; }).indexOf(currentAltId);
		//index = index*2;//for measurement and unit sub columns
		
		for (var i=0; i<self.valueArray.length; i++){
			var value = self.valueArray[i];
			var tr = document.createElement('TR');
			tableBody.appendChild(tr);
			
			var td1 = document.createElement('TD');
			td1.align = "left";
			var a = document.createElement('a');
			
			//a.style.cursor = "pointer";
			a.style.color = "inherit";
			a.setAttribute('valId',value.id);
			a.setAttribute('altId',self.altModel.get('id'));
			a.setAttribute('obsId',self.model.id);
			if(value.gradeOnly){
				a.appendChild(document.createTextNode(value.name));
			}
			/*$(a).click(function(event){
				var obj = {id: event.currentTarget.getAttribute('valid'), altId:event.currentTarget.getAttribute('altid'), obsId: event.currentTarget.getAttribute('obsId')};
				self.showAggregationView(obj);
			});*/
			td1.appendChild(a);
			tr.appendChild(td1);
			
			function appendInputToTD(val,unit, tr, comboId, disableValue, sourceName){
				var td = document.createElement('TD');
				td.align = "right";
				td.setAttribute('contenteditable', true);
				if(value.gradeOnly){
					var selectList = document.createElement("select");
					for (var k = 0; k < value.intervalArray.length; k++) {
						var option = document.createElement("option");
						option.value = value.intervalArray[k];
						option.text = value.intervalArray[k];
						selectList.appendChild(option);
					}
					selectList.setAttribute("value", val);
					selectList.setAttribute("class", "form-control");
					var selectedIndex = _.findIndex(selectList.options, {value:val});
					selectList.selectedIndex = selectedIndex;
					if(!comboId){
						selectList.setAttribute('disabled', 'disabled');
					}
					td.appendChild(selectList);
				}else{
					var textField = document.createElement("SPAN");
					var valueName = "";
					if (!unit || unit.trim() == "") {
						valueName += (value.name + " ");
					} else if(!a.text){
						valueName += value.name + " (" + unit + ") ";
					}
					if(!a.text && sourceName){
						valueName += " [" + sourceName + "] ";
					}
					a.appendChild(document.createTextNode(valueName));
					textField.appendChild(document.createTextNode(val));
					if(!val || val.trim() === "" || !comboId || disableValue){
						//td.appendChild(document.createTextNode("-"));	
						td.setAttribute('contenteditable', !disableValue);
					} 
					td.appendChild(textField);
				}
				td.setAttribute("id", comboId);
				tr.appendChild(td);
				return td;
			}
			if(!self.showAllPhases){
				var tdCurrent = appendInputToTD(value.currentValue, value.currentUnit,tr, value.altValueComboId, value.disableValue, value.sourceName);
				for (var j=0; j<value.phaseAltsValues.length; j++){
					var column = value.phaseAltsValues[j];
					var td = appendInputToTD(column.previousValue,column.previousUnit,tr, column.altValueComboId, column.disableValue, value.sourceName);
					td.setAttribute("valId", value.id);
				}
			}else{
				//till starting point phase
				for (var j=0; j<index; j++){
					var column = value.phaseAltsValues[j];
					td = appendInputToTD(column.previousValue,column.previousUnit, tr, column.altValueComboId, column.disableValue, value.sourceName);
					td.setAttribute("valId", value.id);
				}//starting point
				var tdCurrent = appendInputToTD(value.currentValue,value.currentUnit, tr, value.altValueComboId, value.disableValue, value.sourceName);
				//after the starting point
				for (var j=index; j<value.phaseAltsValues.length; j++){
					var column = value.phaseAltsValues[j];
					var td = appendInputToTD(column.previousValue,column.previousUnit, tr, column.altValueComboId, column.disableValue, value.sourceName);
					td.setAttribute("valId", value.id);
				}
			}
		}
		myTableDiv.append(table);
		$('#table' + self.encodeId + " td").on('keyup keypress',function (event) {
			var allowChar = false;
			if(event.key == "." && $(this).text().indexOf('.') == -1){
				allowChar = true;
			} 
			if((!allowChar && isNaN(event.key)) || event.key == " "){
				event.preventDefault();
				//return;
			}
			if(event.keyCode == 8 && $(this).text().indexOf('.') == -1){
				self.valueChange($(this));
				self.checkComplete();
			}
			/*if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
				// Allow: Ctrl+A
				(event.keyCode == 65 && event.ctrlKey === true) ||
				// Allow: Ctrl+v
				(event.keyCode == 86 && event.ctrlKey === true) ||
				// Allow: Ctrl+c
				(event.keyCode == 67 && event.ctrlKey === true) ||
				// Allow: . 
				(((event.keyCode == 190 || event.keyCode == 110) && $(this).val().indexOf('.') == -1)) ||
				// Allow: home, end, left, right
				(event.keyCode >= 35 && event.keyCode <= 39)) {
				// let it happen, don't do anything
				return;
			}
			else {
				if (event.shiftKey || ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) ||
					!(parseInt(event.key) >= 0 || parseInt(event.key) <= 9) || (event.which != 8 && isNaN(String.fromCharCode(event.which))) || ((event.which != 46 || (event.which == 46 && $(this).val() == '')) ||
						$(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
					//if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (parseInt(event.key) >= 0 && parseInt(event.key) <= 9) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
			}*/
			if ($(this).text() && $(this).text().trim()) {
				self.valueChange($(this));
				self.checkComplete();
			} 
		});
		$(table).find('select').change(function (event) {
			self.checkComplete();
			self.valueChange($(this));
		});
		//to prevent -,+,e
		$(table).find('input').bind('keypress', function (event) {
			if (((event.which != 46 || (event.which == 46 && $(this).val() == '')) ||
				$(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57))
			{
				event.preventDefault();
			} 
		});
		$('#table' + self.encodeId + " td").bind('paste', function (e) {
			var clipboardData, pastedData;
			// Get pasted data via clipboard API
			clipboardData = e.originalEvent.clipboardData || window.clipboardData;
			pastedData = clipboardData.getData('Text');
			var regex = /^[0-9]+\.?[0-9]*$/;
			if(!pastedData.match(regex) || !/^\d+$/.test(pastedData)){
				e.stopPropagation();
				e.preventDefault();
			}
		});
		self.dataTable = $('#table' + self.encodeId).DataTable({
			"columnDefs": [
				{ "orderable": false, "targets": "no-sort"} 
			],
			"autoWidth": false,
			"pageResize": true,
			"bLengthChange": false,
			"fnDrawCallback": function( oSettings ) {
				$('#showAllPhases').remove();
				$("<div id=\"showAllPhases\" style=\"float:right\">"+
					"<label cursor:pointer\"><input id=\"showAllPhasesCheckBox\" style=\"cursor:pointer\" type=\"checkbox\">&nbsp;&nbsp;Show All Phases</label>"+
				"</div>").insertAfter('#table'+self.encodeId+'_filter');
				$('#showAllPhasesCheckBox').prop('checked',self.showAllPhases);
				$('#showAllPhasesCheckBox').click(function(event){
					self.showAllPhases = event.currentTarget.checked;
					if(self.model){
						self.loadTableData(null, self.model.get('whenObserved'),self.showAllPhases, function(){
							self.displayTable();
						});
						$('#showAllPhasesCheckBox').prop('checked',self.showAllPhases);
					}
				});
			}
		});
		$('#table'+self.encodeId+'_length').css('width','33%');
		$('#table'+self.encodeId+'_filter').css('float','left');
	};
	
	afterRenderView() {
		var self = this;
		if (self.settings && isNaN(self.model.get('whenObserved'))) {
			$('#customScenarioTable').hide();
			if(self.altModel){
				self.loadTableData(null, self.model.get('whenObserved'),self.showAllPhases, function(){
					//self.displayTable();
				});
			}
		}
		else {
			$('#showAllPhases').hide();
			$('#table').hide();
			$('button:contains("Apply")').hide()
		}
		//self.checkComplete();
		$('#observationName'+self.encodeId).keyup(function(){
			self.checkComplete();
		});
		$('#editDetailsModalContent' + self.encodeId).resize(function () {
			$('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
		})
		if(!self.timeStamp){
			$('#timeStamp').hide();
		}
		//self.postShow = true;
		/*if(self.valueArray.length===0){
			$('#editText').hide();
		}*/
	};
	cleanUp(){
		var self = this;
		self.parentView = null;
		if(self.dataTable){
			self.dataTable.destroy(true);
		}
	};
	
	cleanModal() {
		var self = this;
		self.parentView = null;
		window.cleanDialogModel(self.encodeId,self);		
	};
	
	saveSelection(){
		var self = this;
		return self.callback(self,self.encodeId);
	};
	
	checkComplete(){
		var self = this;
		var inputVal = $('#observationName'+self.encodeId).val().trim();
		var observationModels = self.model.getContext().get('objectiveObservation').models;
		var exists = _.find(observationModels, function(e){ return e.id !== self.id() && e.get('name') === inputVal;});
		if(exists){
			self.enableComplete(false);
			$('.warning').removeClass('hide');
		}else if(inputVal!=""){
			self.enableComplete(true);
			$('.warning').addClass('hide');
		}else{
			self.enableComplete(false);
		}
	};
	showAggregationView(view) {
		var self = this;
		var altModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: view.altId });
		var valueId = view.id;
		/*if(DataManager.getDataManager().getRepositoryId(view.id) !== window.plansKey){
			valueId = view.altId + window.utils.getSuffix(view.id);
		}*/
		var id = "aggregation" + window.utils.htmlEscape(valueId);
		
		var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valueId });
		if(!valueModal){
			valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valueId });
		}
		if(valueModal){
			valueModal = altModel.getModelLinkedSync(valueModal);
			if(!valueModal){
				bootbox.alert('Value does not exist');
			}else{
				var mest, pack = valueModal.getNestedParent();
				var obs = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: view.obsId });
				/*DataManager.getDataManager().getMainScenario(pack,view.altId,function(mainScenario){
					if(mainScenario){
						mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(valueModal.get('valueMeasurement'));
					}
				});*/
				var isTimestamp = false;
				var timeStamp = obs.get('whenObserved');
				if(timeStamp){
					isTimestamp = true;
				}
				var observedMeasure = obs.getContext().getObservedMeasureWithMeasurements(valueModal.get('valueMeasurement'), isTimestamp, timeStamp);
				mest = observedMeasure?observedMeasure.get('measurements').at(0):null;
				if(!mest){
					mest = valueModal;
				}
				var addOptions = { 'currentAlternativeModel':altModel};
				window.getAndCreateModalDialog(true, id, ValueElementMixin, mest, "AggregationView", null, addOptions);	
			}
			
		}else {
			bootbox.alert('Value does not exist');
		}
	};
	init(model, options){
		var self = this;
		this.ScenarioDetailsViewModel = this;
		this.afterRenderView = _.bind(this.afterRenderView,self);
		if(options){
			this.settings = options.settings;
			this.callback = options.callback;
			this.parentView = options.parentView;
		}
		this.model = model;
		this.altModel = options.alternative;
		this.id = ko.observable(self.model ? self.model.id : options.modalId);
		this.whenObserved = self.model ? self.model.get('whenObserved') : undefined;
		//this.name = ko.observable(self.model ? self.model.get('name') : "");
		this.showAllPhases = false;
		//		this.description = ko.observable(self.model ? self.model.get('description') : "");
		//		this.purpose = ko.observable(self.model ? self.model.get('purpose') : "");
		this.phaseHeadings = [];
		this.altHeadings = [];
		this.values = ko.observableArray();
		this.valueArray = [];
		//this.newObs = false;
		this.enableApply = ko.observable(false);
		this.enableDelete = ko.observable(self.model.get('whenObserved') ? true : false);
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name'
			, 'description'
			, 'scenario'
			, 'Purpose'
			, 'Plan'
			, 'BusinessModel'
			, 'Close'
			, 'Complete'
			, 'Delete'
			, 'Cancel'
			, 'type'
			, 'selectBusinessModel'
			, 'selectType'
			, 'ExportCSV'
			, 'ImportCSV'
			, 'InputValues'
			, 'timeStamp'
			, 'EditScenario'
		]);
		this.encodeId = window.utils.htmlEscape(self.id());
		//this.alternativesList = ko.observableArray();
		//this.observations = ko.observableArray();
		//this.selectedObs = ko.observable();
		this.directValueChanges = [];
		//this.selectedAlt = ko.observable();
		//this.selectedPhase = ko.observable();
		this.phase = self.altModel ? self.altModel.getParent() : null;
		this.enableEdit = ko.observable(self.altModel ? false : true);
		//this.phaseTypeAheadCollection = new Backbone.Collection();
		this.enableComplete = ko.observable(false);
		//this.editMode = ko.observable(self.altModel ?true:false);
		//this.postShow = false;
		this.obsName = ko.observable(self.model ? self.model.get('name') : "");
        if(self.whenObserved != null && !isNaN(self.whenObserved)){
		    var date = new Date(self.whenObserved);
		    this.timeStamp = !isNaN(self.whenObserved) ? date.toLocaleString() : null;
        }
		this.codeContainerList = [];
		if(self.altModel && self.phase.get('primary') === self.altModel){	
			var scenario = self.altModel.getOrCreatePhaseObjectiveSet();
			var obs = scenario.get('objectiveObservation');
			if(obs){
				var alreadyExists = _.filter(obs.models,function(obj){return obj.get('whenObserved') == null});
				if(alreadyExists[0] && alreadyExists[0] != self.model){
					self.enableApply(true);
				}	
			}
		}
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
			self.displayTable();
			self.heightExcludingTable = $('#ScenarioDetailsTemplate'+self.encodeId).height() - $('#valueTable'+self.encodeId).height();
			var autoCompAttr = 'new-password';
			if(window.browser == "chrome"){
				autoCompAttr = 'off';
			}
			$('input[type="text"]').attr('autocomplete',autoCompAttr);
			window.utils.focusElement("#newModal .focus-ele");
		});
	}
	static getInstance(model, options){
			var view = new ScenarioDetailsViewModel(model, options);
			view.init(model, options);
		return view;
	};
}
path.ScenarioDetailsViewModel = ScenarioDetailsViewModel;