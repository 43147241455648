import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as xlsx from 'xlsx'
import { DataManager } from '../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../libs/bootbox/bootbox'
import { ValueLibraryDetailsViewModel } from '../../../views/valueLibrary/properties/ValueLibraryDetailsViewModel'
import { BMGuidanceViewModel } from '../../../views/help/views/properties/BMGuidanceViewModel'
import { EcoMapDiagramMixin } from '../../../bo/vdml/EcoMapDiagramMixin'
import { ValueCategory } from '../../../bo/vdml/ValueCategory'
import { ValueDefinition } from '../../../bo/vdml/ValueDefinition'
import { ValueLibraryMixin } from '../../../bo/vdml/ValueLibraryMixin'


/*define(["require", "jquery", "jstree", "jqueryui", "underscore", "backbone", "async", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appviews/valueLibrary/properties/ValueLibraryDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/vdml/EcoMapDiagramMixin", "appbo/vdml/ValueCategory", "appbo/vdml/ValueDefinition", "summernote"],
	function (require, $, jstree, jqueryui, _, Backbone, async, ko, koMapping, kb, bootbox, DataManager, global, ValueLibraryDetailsViewModel, BMGuidanceViewModel, EcoMapDiagramMixin, ValueCategory, ValueDefinition) {*/
		var path = DataManager.getDataManager().buildAppNsPath("valueLibrary.views.properties", global.version);
export class ValueLibraryViewModel {
    constructor(model, options) {
        this.init(model, options);
    }
    init(model, options) {
        var self = this;
        this.ValueLibraryViewModel = this;
        this.model = model;
        this.jsTreeModelData = null;
        this.selectedName = ko.observable("");
        this.selectedDescription = ko.observable("");
        this.selectedType = ko.observable("");
        this.selectedDefinition = ko.observable("");
        this.selectedMapList = ko.observableArray([]);
        this.rowsDataList = {};
        this.mapList = [];
        this.showValElementDetails = ko.observable(false);
        this.showFormula = ko.observable(false);
        this.showDomain = ko.observable(false);
        this.importButton = ko.observable(true);
        if(self.model && self.model.get('value').length > 0){
            this.importButton(false);
        }
        this.enableCapComplete = ko.observable(false);
        this.selectedFormula = ko.observable();
        this.showUnit = ko.observable(false);
        this.selectedUnit = ko.observable();
        this.childrens = ko.observableArray([]);
        this.domains = ko.observableArray();
        this.mappingObject = {};
        this.mappingObject['vdml_ValueLibrary'] = { name: DataManager.getDataManager().get('localeManager').get("ValueLibrary"), type: "Value Library" };
        this.mappingObject['vdml_ValueCategory'] = { name: DataManager.getDataManager().get('localeManager').get("ValueCategory"), type: "Value Category" };
        this.mappingObject['vdml_ValueDefinition'] = { name: DataManager.getDataManager().get('localeManager').get("ValueDefinition"), type: "Value Definition" };
        this.mappingObject['vdml_StrategyMapDiagram'] = { name: DataManager.getDataManager().get('localeManager').get('StrategyMap'), type: "Strategy Map" };
        this.mappingObject['vdml_ValueStreamMapDiagram'] = { name: DataManager.getDataManager().get('localeManager').get('ValueStreamMap'), type: "Value Stream Map" };
        this.mappingObject['vdml_BusinessModelCompetence'] = { name: DataManager.getDataManager().get('localeManager').get('Competence'), type: "Business Model Competence" };
        this.mappingObject['vdml_BusinessModelActivity'] = { name: DataManager.getDataManager().get('localeManager').get('Activity'), type: "Business Model Activity" };
        this.mappingObject['vdml_BusinessModel'] = { name: DataManager.getDataManager().get('localeManager').get('BusinessModel'), type: "Business Map" };
        this.mappingObject['canvas_BMCanvasDiagram'] = { name: DataManager.getDataManager().get('localeManager').get('BusinessCanvas'), type: "BM Canvas" };
        this.mappingObject['vdml_Activity'] = { type: "Activity" };
        this.mappingObject['vdml_CapabilityOffer'] = { type: "Capability" };
        this.selectedDomain = ko.observable();
        this.minRange = ko.observable();
        this.maxRange = ko.observable();
        this.showBoolean = ko.observable(false);
        if (options && options.parentView) {
            this.encodeId = "" + options.modalId;
            this.callback = options.callback;
        }
        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        this.encodeId = htmlEscape(self.model.id);
        self.domains.push('Any', 'Binary', 'Boolean', 'Integers', 'NegativeIntegers', 'NegativeReals', 'NonNegativeIntegers', 'NonNegativeReals', 'NonPositiveIntegers', 'NonPositiveReals', 'PositiveIntegers', 'PositiveReals', 'Reals');
        /*window.utils.loadTemplate(function (name) {
        })*/
        /*if (!ko.components.isRegistered('ValueLibraryDetails')) {
            ko.components.register("ValueLibraryDetails", {
                viewModel: ValueLibraryDetailsViewModel,
                template: window.utils.getHtmlPage("ValueLibraryDetailsTemplate"),
                synchronous: true
            });
        }*/
        if (!ko.components.isRegistered('ValueLibraryGuidance')) {
            self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
            ko.components.register("ValueLibraryGuidance", {
                viewModel: { instance: self.guidanceViewInstance },
                template: '<div id="guidanceTab"></div>',
                synchronous: true
            });
        }
        // this.selectedName.subscribe(function (val) {
        //     if (val == '') {
        //         self.enableCapComplete(false);
        //     } else {
        //         self.enableCapComplete(true);
        //     }
        // });
        
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name'
            , 'description'
            , 'Complete'
            , 'Delete'
            , 'Guidance'
            , 'Collaboration'
            , 'Unit'
            , 'ValueDetails'
            , 'type'
            , 'formula'
            , 'children'
            , 'import'
        ]);
        this.getModelDataInJSTreeFormat = _.bind(this.getModelDataInJSTreeFormat, self);
        this.importFile = _.bind(this.importFile, self);
    }

    sortByName(left, right) {
        if (left.text?.match(/\d+/g) != null || right.text?.match(/\d+/g) != null) {
            return ('' + left.text?.toLowerCase()).localeCompare(('' + right.text?.toLowerCase()), 'en', { numeric: true });
        } else {
            return left.text?.toLowerCase() === right.text?.toLowerCase() ? 0 : (left.text?.toLowerCase() < right.text?.toLowerCase() ? -1 : 1);
        }
    }
    cleanUp() {
        var self = this;
        //window.utils.loadTemplate(null, true);
        /*if (ko.components.isRegistered('ValueLibraryDetails')) {
            ko.components.unregister('ValueLibraryDetails');
        }*/
        if (self.spliter) {
            self.spliter.enhsplitter('remove');
        }
        if ($("#summernoteDefinition" + self.encodeId).next('.note-editor').length !== 0) {
            $("#summernoteDefinition" + self.encodeId).summernote('destroy');
            $("#summernotecss").attr("href", "");
        }
    }
    showDefinitionSection(contentDefinition) {
        var self = this;
        var summernote = $('#summernoteDefinition' + self.encodeId);
        summernote.summernote('destroy');
        summernote.summernote(
            {
                airMode: true,
                height: null,                 // set editor height
                minHeight: null,             // set minimum height of editor
                maxHeight: null,              // set maximum height of editor
                focus: true,
                placeholder: 'write here...',
                disableResizeEditor: true, //disable resize 
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript']],
                    ['para', ['style', 'ul', 'ol', 'paragraph']],
                    ['height', ['height']],
                    ['cleaner',['cleaner']], // The Button
                    // ['insert', [ 'link', 'table', 'hr']]
                ],
                cleaner: {
                    action: 'button', // both|button|paste 'button' only cleans via toolbar button, 'paste' only clean when pasting content, both does both options.
                    newline: '<br>', // Summernote's default is to use '<p><br></p>'
                    notStyle: 'position:absolute;top:0;left:0;right:0', // Position of Notification
                    icon: '<i class="note-icon">Clear Formatting</i>',
                    keepHtml: false, // Remove all Html formats
                    keepOnlyTags: ['<p>', '<br>', '<ul>', '<li>', '<b>', '<strong>', '<i>', '<a>'], // If keepHtml is true, remove all tags except these
                    keepClasses: false, // Remove Classes
                    badTags: ['style', 'script', 'applet', 'embed', 'noframes', 'noscript', 'html'], // Remove full tags with contents
                    badAttributes: ['style', 'start'], // Remove attributes from remaining tags
                    limitChars: false, // 0/false|# 0/false disables option
                    limitDisplay: 'both', // text|html|both
                    limitStop: false // true/false
                }
            });
        summernote.find('.note-statusbar').hide();
        summernote.summernote('code', contentDefinition);
        summernote.summernote("editor.clearHistory", 'editor');
        $('.note-editable').css('max-height', '300px');
        $('.note-editable').css('overflow-y', 'auto');
        $('.note-editor').css({ "border-color": "#C1E0FF", "border-width": "1px", "border-style": "solid" });
        //$('.note-toolbar').hide();
        $("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
    }

    getValElementModal(id) {
        var self = this;
        var valModel = Backbone.Relational.store.getObjectByName('vdml.ValueCategory').find({ id: id });
        if (!valModel) {
            valModel = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: id });
        }
        if (!valModel) {
            valModel = Backbone.Relational.store.getObjectByName('vdml.ValueLibrary').find({ id: id });
        }
        return valModel;
    }

    deleteCapElement(view, event) {
        var self = view;
        var valModel = self.getValElementModal(self.selectedCapId);
        if(valModel != self.model){
            bootbox.confirm(DataManager.getDataManager().get('localeManager').get('ValueDelete'), function (result) {
                if (result) {
                    window.utils.startSpinner('deleteValElement', 'Deleting Value Library Element...');
                    setTimeout(function () {
                        if (valModel) {
                            valModel.destroy();
                            self.showValElementDetails(false);
                        }
                        self.clearAndReload();
                        window.utils.stopSpinner('deleteValElement');
                    }, 10);
                }
            });
        }
        else{
            bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('MapDeleteAlert','Value Library'), function(result) {
                if (result) {
                    window.utils.startSpinner('deleteValLib', 'Deleting Value Library...');
                    window.setTimeout(function () {	
                        self.model.destroy();
                        DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                        window.utils.stopSpinner('deleteValLib');
                    }, 100);
                }
            });
        }        
    };

    clearAndReload() {
        var self = this;
        self.selectedName('');
        self.selectedDescription('');
        self.selectedDefinition('');
        self.selectedCapId = null;
        $('#jstree_div'+self.encodeId).jstree('select_node', null);
        $('#jstree_div'+self.encodeId).jstree(true).settings.core.selected = [];
        $('#jstree_div'+self.encodeId).jstree('open_node', self.model.id);
        self.jsTreeModelData = self.getModelDataInJSTreeFormat([self.model.getParent().get('valueLibrary').slice()]);
        var obj = self.jsTreeModelData[0];
        $('#jstree_div'+self.encodeId).jstree(true).settings.core.data = obj;
        $('#jstree_div'+self.encodeId).jstree(true).refresh(false, true);
        $('#jstree_div'+self.encodeId).jstree('open_node', window.utils.htmlEscape(self.model.id));
        self.setResizeHeight();
    }
    setUnit(valModel,value){
        var self = this;
        if(valModel != self.model){
            valModel.set('unit', value);
        }
    }

    getUnit(valModel){
        return valModel.get('unit');
    }
    saveCapElement(view, event) {
        var self = view;
        var valModel = self.getValElementModal(self.selectedCapId);
        var refresh = false;
        if (valModel) {
            if (valModel.get('name') != self.selectedName()) {
                valModel.set('name', self.selectedName());
                valModel.set('description', self.selectedDescription());
                refresh = true;
            }
            //valModel.set('unit', self.selectedUnit());
            self.setUnit(valModel,self.selectedUnit())
            valModel.set('formula', self.selectedFormula());
            var summernote = $('#summernoteDefinition' + self.encodeId);
            var contentData = summernote.summernote('code');
            if (contentData == "") {
                contentData = null;
            }
            if (contentData != self.selectedDefinition()) {
                valModel.set('definition', contentData);
                //refresh = true;
            }
            if (self.showBoolean()) {
                valModel.set('domain', $('#type').val());
                valModel.set('bounds', $('input[name="boolean"]:checked').val());
            } else {
                if ($('#minRange' + self.encodeId).val() && $('#maxRange' + self.encodeId).val()) {
                    valModel.set('minRange', $('#minRange' + self.encodeId).val());
                    valModel.set('maxRange', $('#maxRange' + self.encodeId).val());
                    valModel.set('domain', $('#type').val());
                }
            }
        }
        if (refresh) {
            var newCap = self.selectedCapId;
            self.clearAndReload();
            setTimeout(function () {
                $('#jstree_div'+self.encodeId).jstree('select_node', (newCap));
                $('#jstree_div'+self.encodeId).jstree('open_node', (newCap));
                window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'), DataManager.getDataManager().get('viewAlternative'), self.ValueLibraryViewModel);
            }, 100);
            self.setResizeHeight();
        }
        self.enableCapComplete(false);

    };

    setResizeHeight(view, event) {
        var self = this;
        setTimeout(function () {
            var propertiesNode = 0;
            if ($('.propertiesArea').width() > 10) {
                propertiesNode = $('.propertiesArea').outerHeight();
            }
            var lhsHeight = $('#jstree_div'+self.encodeId).outerHeight() + 50;
            var rhsHeight = $('#showValueDetails'+self.encodeId).outerHeight()+50
            var windowHeight = window.innerHeight;
            var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight, rhsHeight]);
            if (self.spliter) {
                self.spliter.css('height', baseHeight + 300 + 'px');
                if ($('.propertiesArea').width() < 5 && Math.abs($(window).width() - $('.modeler').width()) < 30) {
                    var windowWidth = $(window).width() - 15;
                    $('.splitter_bar').css('left', windowWidth);
                }
                $("#splitPanels" + self.encodeId).enhsplitter('refresh');
            }
            var rightTab = $('#rightTab' + self.encodeId);
            if (rightTab.width() > 0) {
                if ($('#rightTab' + self.encodeId + ' .active a')[0]) {
                    var propDivId = $('#rightTab' + self.encodeId + ' .active a')[0].hash;
                    if (propDivId !== null && $('#rightTab .dropdown .active a')[0]) {
                        propDivId = $('#rightTab .dropdown .active a')[0].hash;
                    }
                    var propNode = $('' + propDivId);
                }
            }
            if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/) {
                var dropdownDiv = rightTab.children().last();
                var appendList = dropdownDiv.find('li');
                var removedList = [];
                for (var i = 0; i < appendList.length; i++) {
                    dropdownDiv.before(appendList[i]);
                    removedList.push(appendList[i]);
                }
                if (appendList.length === removedList.length) {
                    dropdownDiv.remove();
                }
            }
            if (rightTab.width() > 10 && rightTab.width() < 415 && rightTab.children().length > 2/* && rightTab.height() > 50*/) {
                var rightChildren = rightTab.children();
                var appendList = [];
                for (var i = rightChildren.length; i > 0; i--) {
                    if (!rightChildren[i - 1].classList.contains('dropdown')) {
                        appendList.push(rightChildren[i - 1]);
                        if (rightTab.width() < 375 && rightChildren[i - 2]) {
                            appendList.push(rightChildren[i - 2]);
                            break;
                        } else {
                            break;
                        }
                    }
                }
                if (!rightChildren.last()[0].classList.contains('dropdown')) {
                    rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
                }
                for (var i = 0; i < appendList.length; i++) {
                    rightTab.children().last().find('ul').prepend(appendList[i]);
                }
            }
            if (rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')) {
                var dropdownDiv = rightTab.children().last();
                var appendList = dropdownDiv.find('li');
                for (var i = 0; i < appendList.length; i++) {
                    appendList.eq(i).removeClass('active');
                }
            }
        }, 300);
    };
    importValueLibrary() {
        var self = this;
        //var allowedExt = ['vpk'];
        //var excelExt = ['xlsx'];
		document.getElementById("file1").click();
    }
	importFile(view,event) {
            var self = this;
			var reader = new FileReader();
			var fileObject = event.target.files[0];
            if(fileObject && fileObject.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                bootbox.alert(DataManager.getDataManager().get("localeManager").get("InvalidFileFormat", ".xlsx"));
                return;
            }
            reader.onload = function (ev) {
				var bookContent = reader.result;
                var data = new Uint8Array(reader.result);
                var wb = xlsx.read(data, { type: 'array' });
                var CoverSheet = wb.SheetNames[0];
                var sheet = wb.Sheets[CoverSheet];
                var range = xlsx.utils.decode_range(sheet['!ref']);
                var data = self.treeConstruction(sheet, range, wb);
                if(data){
                    if($('#jstree_div'+self.encodeId).jstree('select_node',self.model.id) != null){
                        $('#jstree_div'+self.encodeId).jstree().delete_node([self.model.id]);
                    }
                    $('#jstree_div'+self.encodeId).jstree(true).create_node(null, data[0]);
                    self.importButton(false);
                    bootbox.alert(DataManager.getDataManager().get('localeManager').get("ImportComplete","Value Library"));
                    $('#importbtn').hide();
                }
            }
                        if(fileObject){
                reader.readAsArrayBuffer(fileObject);
            }
        }
    getValueElementAndValueTypeJson(sheet, range, descriptionTypeCell, rubricTypeCell){
        var self =this;
        var valueElementAndValueTypeJson = {};
        for (var rowNum = 1; rowNum <= range.e.r; rowNum++) {
            var row = sheet[xlsx.utils.encode_cell({ r: rowNum, c: descriptionTypeCell })];
            if (row) {
                self.rowsDataList[row.h.trim()] = row;
                if(sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })] != null){
                    valueElementAndValueTypeJson[row.h] = sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })];
                }
            }
        }
        return valueElementAndValueTypeJson;
    }

    getValueType(sheet, row, rubricTypeCell, valueElementAndValueTypeJson){
        var indexes = [];
        for (var i = 0; i < row.h.length; i++) {
          if (row.h[i] === '.') {
            indexes.push(i);
          }
        }
        var valueType = null;
        for(var i=indexes.length-1;i >=0; i--){
            if(valueType == null && valueElementAndValueTypeJson[row.h.substring(0, indexes[i])]){
                valueType = valueElementAndValueTypeJson[row.h.substring(0, indexes[i])];
                break;
            }
        }
        return valueType;
    }
    
    treeConstruction(sheet, range, wb) {
                var self = this;
        var jsonData = {};
        var ret = [];
        ret.push(jsonData);
        jsonData.text = "Node";
        ret[0].icon = "img/object.png";
        function getCellByName(cellName){
            var cellNo;
            var formatCellName = cellName.replace(" ", "").toUpperCase();
            for (var colNum = 0; colNum <= range.e.c; colNum++) {
                var col = sheet[xlsx.utils.encode_cell({ r: 0, c: colNum })];
                if(col){
                    var colName = col.h ? col.h : col.v
                    if (colName && colName.replace(" ", "").toUpperCase() == formatCellName) {
                        cellNo = colNum;
                        break;
                    }
                }
            }
            return cellNo;
        }
        var formulaCell = getCellByName("FORMULA");
        var unitCell =  getCellByName("UNIT");
        var rubricTypeCell = getCellByName("VALUE TYPE");
        if(!rubricTypeCell){
            bootbox.alert("Column VALUE TYPE Missing");
            return;
        }
        var descriptionTypeCell = getCellByName("DESCRIPTION");
        if(!descriptionTypeCell){
            descriptionTypeCell = getCellByName("NAME");
        }
        var valueElementAndValueTypeJson = self.getValueElementAndValueTypeJson(sheet,range,descriptionTypeCell,rubricTypeCell);
        for (var rowNum = 1; rowNum <= range.e.r; rowNum++) {
            var row = sheet[xlsx.utils.encode_cell({ r: rowNum, c: descriptionTypeCell })];
            if (row) {
                var parent = null;
                var valueType = null;
                if(row.v && row.v != null){
                    row.h = row.v.trim();
                }else{
                    row.h = row.h.trim();
                }
                if(row.h.split('.').length != 0 && row.h.split('.').length > 2){
                    var rowExisted = self.rowsDataList[row.h.substring(0, row.h.lastIndexOf('.'))];
                    if(rowExisted == null){
                         bootbox.alert(DataManager.getDataManager().get('localeManager').get('MissingParent', row.h));
                         return;
                    }
                }
                if(sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })] == null){
                    valueType = self.getValueType(sheet, row, rubricTypeCell, valueElementAndValueTypeJson);
                    sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })] = valueType;
                }else{
                    valueType = sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })].v;
                    parent = sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })].v;
                }
                // if(valueType == null){
                //     bootbox.alert(DataManager.getDataManager().get('localeManager').get('ValueTypeEmpty'));
                //     return;
                // }              
                if (!parent) {
                    parent = sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })]?.h;
                }
                var textArr = row?.h?.split('.');
                var parentCat = self.model.get('value').findWhere({ name: parent });
                if (!parentCat) {
                    parentCat = ValueCategory.getInstance(parent, parent, self.model);
                }
                var formula = formulaCell?sheet[xlsx.utils.encode_cell({ r: rowNum, c: formulaCell })]:null;
                var unit = unitCell?sheet[xlsx.utils.encode_cell({ r: rowNum, c: unitCell })]:null;

                for (var i = 1; i < textArr.length; i++) {
                    var child;
                    if (i == 1) {
                        child = self.addChildDefinition(parent, self.model, textArr[i]);
                    } else {
                        child = self.addChildDefinition(textArr[i - 1], self.model, textArr[i]);
                    }
                    if (i === textArr.length - 1) {
                        var def = self.addDefinitionInfo(sheet, range, rowNum, child);
                        child.set('definition', def);
                        if (formula) {
                            child.set('formula', formula.w);
                        }
                        if (unit) {
                            self.setUnit(child,unit.w)
                        }
                    }
                }
            }
        }
        var data = self.getModelDataInJSTreeFormat([self.model.getParent().get('valueLibrary').slice()]);
        return data;
    }

    addChildDefinition(parentName, library, catName) {
        var self = this;
        var child = library.get('value').findWhere({ name: catName });
        if (!child) {
            child = ValueDefinition.getInstance(catName, catName, self.model)
        }
        var parentExist = child.get('parentValue').length;
        if (parentName && parentExist === 0) {
            var parent = library.get('value').findWhere({ name: parentName });
            parent.get("childValue").add(child);
            child.get("parentValue").add(parent)
        }
        return child;
    }

    addDefinitionInfo(sheet, range, row, child) {
        var self = this;
        var info = "";
        for (var col = 0; col < range.e.c; col++) {
            if (col === 1 || col === 2 || col === 10) {
                continue;
            }
            var leftAttr = sheet[xlsx.utils.encode_cell({ r: 0, c: col })] ? sheet[xlsx.utils.encode_cell({ r: 0, c: col })].h : "";
            var rightAttr = sheet[xlsx.utils.encode_cell({ r: row, c: col })] ? sheet[xlsx.utils.encode_cell({ r: row, c: col })].w : "";
            if (leftAttr === "EXPLANATION" && rightAttr) {
                rightAttr = rightAttr.replace(/\r\n/g, '<br>');
            }
            if(leftAttr != "" && rightAttr != "" && leftAttr != "NAME" && leftAttr != "UNIT" && leftAttr != "FORMULA" ){
                var urlRegex = /(https?:\/\/[^\s]+)/g;
                rightAttr = rightAttr.replace(urlRegex, function(url) {
                    return '<a href="' + url + '">' + url + '</a>';
                });
                info += "<b>" + leftAttr + ": </b>" + rightAttr + "<br/>"; 
            }
        }
        return info;
    }

    getUsedMapList(id) {
        var self = this;
        var tempId = id;
        var mapList = '';
        self.selectedMapList([]);
        var plan = DataManager.getDataManager().get('currentPlan');
        var modelNode = self.getValElementModal(tempId);
        DataManager.getDataManager().getReferencesToModel2(modelNode, function (data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].predicate.indexOf("ValueLibrary-value") != -1 || data[i].predicate.indexOf("ValueDefinition-childValue") != -1 || data[i].predicate.indexOf("Value-parentValue") != -1 || data[i].predicate.indexOf("Value-childValue") != -1 || data[i].predicate.indexOf("ValueDefinition-parentValue") != -1 || data[i].predicate.indexOf("vdml_valueMethod-value") != -1) {
                    continue;
                } else {
                    var altId = DataManager.getDataManager().getRepositoryId(data[i].id);
                    var alternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altId });
                    if (alternative) {
                        var alternativeName = alternative.get('name');
                        var phaseName = alternative.get('phaseAlternativeOwner').get('name');
                        data[i].namePath = phaseName + "/" + alternativeName + "/" + data[i].namePath;
                    }
                    var type = data[i].type.substring(data[i].type.lastIndexOf('_') + 1);
                    type = type.split(/(?=[A-Z])/).join(' ');
                    /*if (type == "Value Library") {
                        type = self.mappingObject[data[i].type].name
                    } else {
                        type = self.mappingObject[data[i].type] ? self.mappingObject[data[i].type].type : null;
                    }*/
                    if (type) {
                        self.selectedMapList.push({ 'name': data[i].name, 'type': "Value", 'shapeName': data[i].namePath });
                        document.getElementById('showType').customId = data[i].id;
                        document.getElementById('showType').id = window.utils.htmlEscape(data[i].id);
                        $("<span class='showMappedInfo glyphicon glyphicon-info-sign glyphicon-button' style='font-size: small; color: orange;left:5px' ></span>").appendTo($('#' + window.utils.htmlEscape(data[i].id)))
                    }
                }
            }
            $('.showMappedInfo').on('click', function (view, event) {
                var value; var options = {};
                value = self.fetchComponentById(view.currentTarget.parentElement.customId);

                if (value) {
                    options.mappedModal = value;
                }
                var id = window.utils.htmlEscape(window.guidGenerator());
                options.width = '800px';
                window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function (refresh) {
                }, options);
            });
        }, function () {
            self.selectedMapList.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                else if (a.name.toLowerCase() === b.name.toLowerCase()) {

                    if (a.shapeName.toLowerCase() < b.shapeName.toLowerCase()) {
                        return -1;
                    }
                    else if (a.shapeName.toLowerCase() > b.shapeName.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            });
        });

        if (self.mapList.length < 1) {
            plan.getAllDocumentsCollectionOfType(plan, 'ecomap_EcoMap,vdml_ValueDeliveryModel', function (results) {
                results.each(function (pack) {
                    self.mapList.push(pack);
                });
            }, null, null, null, null, true);
        } else {
        }
        return mapList;
    }

    fetchComponentById(aggregateId) {
        var self = this;
        var aggComp = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: aggregateId });
        if (!aggComp) {
            aggComp = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: aggregateId });
        }
        return aggComp;
    }

    setPropertyValues(parentsLength,selectedNode){
        var self = this;
        self.showValElementDetails(true);
        if (parentsLength > 1 && (selectedNode.original.icon == "img/object.png" || selectedNode.original.icon == "img/leaf.png")) {
            var valModel = self.getValElementModal(selectedNode.id);
            $("#capdef_div").show();
            var annotation = valModel.get('definition') ? valModel.get('definition') : "";
                                self.showDefinitionSection(annotation);
            self.getUsedMapList(selectedNode.id);
            var formula = valModel.get('formula') ? valModel.get('formula') : "";
            //var unit = valModel.get('unit') ? valModel.get('unit') : "";
            var unit = self.getUnit(valModel) ? self.getUnit(valModel) : "";
            if(unit){
                //self.showUnit(true);
                self.selectedUnit(unit);
            }
            if (formula) {
                //self.showFormula(true);
                self.selectedFormula(formula);
            }
            if(DataManager.getDataManager().get("debugMode")){
                if (self.selectedType() === "Value Category") {
                    $('.showDomain').hide();
                } else {
                    $('.showDomain').show();
                }
            }
            var children = valModel.get('childValue');
            if(children && children.length > 0){
                for(var i=0;i<children.length;i++){
                    self.childrens.push({'id':children.at(i).id,'name':children.at(i).get('name')});
                }
            }
            if ($("#summernoteDefinition" + self.encodeId)[0].innerHTML === "<p><br></p>") {
                $("#summernoteDefinition" + self.encodeId)[0].innerHTML = "";
            }
            if ($(".note-editable")[0].innerHTML === "<p><br></p>") {
                $(".note-editable")[0].innerHTML = "";
            }
            if(valModel.get('domain') === "Boolean") {
                self.showBoolean(true);
                self.showDomain(false);
                if (valModel.get("Bounds")) {
                    $('#boundsTrue').attr('checked', true)
                } else {
                    $('#boundsFalse').attr('checked', true)
                }
                $('#type').val(valModel.get('domain'));
            } else {
                self.showBoolean(false);
                if(DataManager.getDataManager().get("debugMode")){
                    self.showDomain(true);
                }
                $('#type').val("Any");
                if (valModel.get('domain')) {
                    $('#type').val(valModel.get('domain'));
                }
                if (valModel.get('minRange') && valModel.get('maxRange')) {
                    self.minRange(valModel.get('minRange'));
                    self.maxRange(valModel.get('maxRange'));
                    $('#minRange' + self.encodeId).val(valModel.get('minRange'));
                    $('#maxRange' + self.encodeId).val(valModel.get('maxRange'));
                } else {
                    $('#minRange' + self.encodeId).val("");
                    $('#maxRange' + self.encodeId).val("");
                    self.minRange("");
                    self.maxRange("");
                }
            }
        } else {
            var children = self.model.get('value');
            if(children && children.length > 0){
                for(var i=0;i<children.length;i++){
                    if(children.at(i).get('parentValue').length == 0){
                        self.childrens.push({'id':children.at(i).id,'name':children.at(i).get('name')});
                    }
                }
            }
        }
    }

    afterRenderView(node, view) {
        var self = view;
        self.jsTreeModelData = self.getModelDataInJSTreeFormat([self.model.getParent().get('valueLibrary').slice()]);
        var pluginList = ["search"];
        if(!DataManager.getDataManager().get("debugMode")){
            $('.showDomain').hide();
        }
        var tree = $('#jstree_div'+self.encodeId).on("select_node.jstree", function (e, data) {
            if (data.selected.length) {
                var selectedNode = data.instance.get_node(data.selected[0]);
                var parentsLength = selectedNode.parents.length;
                self.childrens([]);
                //self.showFormula(false);
                //self.showUnit(false);

                self.selectedFormula("");
                var summernote = $('#summernoteDefinition' + self.encodeId);
                summernote.summernote('code', '');
                summernote.summernote('destroy');
                $("#capdef_div").hide();
                self.selectedName(selectedNode.text);
                self.selectedDescription(selectedNode.original.text);
                self.selectedType(self.mappingObject[selectedNode.original.type].name);
                self.selectedCapId = selectedNode.id;
                //$('a .showValueDetails'+self.encodeId)[0].click();
                self.setPropertyValues(parentsLength,selectedNode);
                if(self.selectedType()==='Value Library'){
                    self.showUnit(false);
                    self.showFormula(false);
                } else {
                    self.showUnit(true);
                    self.showFormula(true);
                }
            }
        }).jstree({
            'core': {
                'check_callback': true,
                'data': function (obj, callback) {
                    callback.call(this, []);

                }
            },
            "plugins": pluginList,
            "search": {
                'case_sensitive': false
            }
        });
        $('#jstree_div'+self.encodeId).bind('loaded.jstree', function () {

            setTimeout(function(){
                if(self.model.id){
                    $('#jstree_div'+self.encodeId).jstree(true).select_node( self.jsTreeModelData);
                    $('#jstree_div'+self.encodeId).jstree(true).open_node( self.jsTreeModelData);
                }
            },20);
            
        });
        $('#jstree_div'+self.encodeId).jstree(true).create_node(null, self.jsTreeModelData[0]);
        $('#treeSearch').change(function () {
            window.utils.startSpinner('jsonSearch', 'Searching...');
            setTimeout(function () {
                var v = $('#treeSearch').val();
                $('#jstree_div'+self.encodeId).jstree(true).search(v);
                window.utils.applyFontSize($('#content'));
                window.utils.stopSpinner('jsonSearch');
                $('.jstree-anchor').css('text-overflow', 'ellipsis');
                $('.jstree-anchor').css('overflow', 'hidden');
                $('.jstree-anchor').css('max-width', '97%');
            }, 10);
        });

        $('#jstree_div'+self.encodeId).on('dblclick.jstree', function (e) {
            var text = e.target.text;
            if (text && text.length > 0) {
                var textSub = text.substr(text.indexOf(":") + 1);
                self.copyToClipboard(textSub);
            }
        });
        $('#jstree_div'+self.encodeId).on('open_node.jstree', function () {
            self.setResizeHeight();
        });
        $('#jstree_div'+self.encodeId).on('close_node.jstree', function () {
            self.setResizeHeight();
        });
        $('#type').on('change', function (view) {
            if ($('#type').val() === "Boolean") {
                self.showDomain(false);
                self.showBoolean(true);
            } else {
                self.showDomain(true);
                self.showBoolean(false)
            }
        })
        $('#minRange' + self.encodeId).on('keyup', function (view) {
            var value = view.currentTarget.value.trim();
            self.minRange(value);
            if (value && $('#type').val() === "Integers") {
                if (parseInt(self.minRange()) >= -2147483648 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            } else if (value && ($('#type').val() === "NegativeIntegers" || $('#type').val() === "NonPositiveIntegers")) {
                if (parseInt(self.minRange()) >= -2147483648 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            } else if (value && ($('#type').val() === "PositiveIntegers" || $('#type').val() === "NonNegativeIntegers")) {
                if (parseInt(self.minRange()) >= 0 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            }else {
                self.enableCapComplete(true);
            }
        })
        $('#maxRange' + self.encodeId).on('keyup', function (view) {
            var value = view.currentTarget.value.trim();
            self.maxRange(value);
            if (value && $('#type').val() === "Integers") {
                if (parseInt(self.maxRange()) <= 2147483647 && $("#selectedName" + self.encodeId).val().trim() && self.minRange() < self.maxRange()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            } else if (value && ($('#type').val() === "NegativeIntegers" || $('#type').val() === "NonPositiveIntegers") && self.minRange() < self.maxRange()) {
                if (parseInt(self.maxRange()) <= -1 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            } else if (value && ($('#type').val() === "PositiveIntegers" || $('#type').val() === "NonNegativeIntegers") && self.minRange() < self.maxRange()) {
                if (parseInt(self.maxRange()) <= 2147483647 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            }else {
                self.enableCapComplete(true);
            }
        })

        $("#selectedUnit" + self.encodeId).on('keyup', function (view) {
            self.enableCapComplete(true);
        });

        $("#selectedFormula" + self.encodeId).on('keyup', function (view) {          
            self.enableCapComplete(true);
        });

           

        $("#selectedName" + self.encodeId).on('keyup', function (view) {
            var name = view.currentTarget.value.trim();
            self.selectedDescription(name);
            if (name === "") {
                self.enableCapComplete(false);
            } else {
                self.enableCapComplete(true);
            }
        });

        $("#selectedDescription" + self.encodeId).on('keyup', function (view) {
            self.enableCapComplete(true);
        });

        $('#summernoteDefinition' + self.encodeId).on('summernote.keyup', function (we, e) {
            self.enableCapComplete(true);
        }
    )
        self.spliter = $("#splitPanels" + self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '50%', handle: 'block', splitterSize: '9px', height: $("#js-canvas" + self.encodeId).outerHeight() + $('.table-responsive').outerHeight() + 300, onDragEnd: self.setResizeHeight });
        $(window).resize(function (view, event) {
            this.timer = clearTimeout();
            this.timer = setTimeout(function () { self && self.setResizeHeight && self.setResizeHeight(); }, 200);
        });
        $('#rightTab' + self.encodeId + ' a').click(function (e) {
            e.preventDefault();
            if ($(this).text() === "Collaboration") {
                return false;
            }
            $(this).tab('show');
            $('.note-popover').hide()
            if ($(this).text() === "Guidance") {
                if ($('#guidanceTab').is(':empty')) {
                    $.get('js/app/version1/views/help/views/properties/ValueLibraryGuidanceTemplate.html', function(viewStr) {
                        self.countGuidanceClicks++;
                        $('#guidanceTab').empty();
                        $('#guidanceTab').append(viewStr);
                        if (self.configObj && self.configObj.guidance) {
                            $('#guidanceTab').append(self.configObj.guidance);
                        }
                        window.utils.applyFontSize($('#guidanceTab'));
                        self.setResizeHeight();
                    })
                } else {
                    self.setResizeHeight();
                }
            } else {
                self.setResizeHeight();
            }            
        });

        $('#jstree_div'+self.encodeId).bind('ready.jstree', function (e, data) {
            $('#jstree_div'+self.encodeId).jstree('open_node', (self.encodeId));
        });
        self.setResizeHeight();
        window.utils.stopSpinner('valueLibrarySpinner');
    };
    handlePublicMenus() {
		var self = this;
		DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench", "Show Object Explorer", $.proxy(this.showobjectExplorerTree, this));
	};
    showobjectExplorerTree() {
        var self = this;
        var vdm = self.model.getNestedParent();
        var addOptions = this;
        window.getAndCreateModalDialog(true, self.encodeId, ValueLibraryMixin, vdm, "explorerModelJson", closeView, addOptions);
        function closeView() {
            window.cleanModalDialog(self.encodeId,ValueLibraryMixin);
        }
    };
    copyToClipboard(text) {
        var self = this;
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(text).select();

        var succeed;
        try {
            succeed = document.execCommand("copy");
        } catch (e) {
            succeed = false;
        }
        $temp.remove();
        return succeed;
    }
    getModelDataInJSTreeFormat(valueLibs) {
        var self = this;
        var ret = [];
        if (!valueLibs.length && valueLibs instanceof Backbone.Model) {
            jsonData = {};
            ret.push(jsonData);
            jsonData.id = valueLibs.get('id');
            jsonData.text = valueLibs.get('name');
            jsonData.description = valueLibs.get('description');
            jsonData.type = valueLibs.get('type');
            jsonData.definition = valueLibs.get('definition');
            var typeMixinCls = DataManager.getDataManager().getModelType(valueLibs, true);
            jsonData.children = [];
            if (valueLibs instanceof Backbone.Model && valueLibs.get('type') !== "vdml_ValueLibrary" && valueLibs.get('childValue').length > 0) {
                jsonData.children = jsonData.children.concat(self.getModelDataInJSTreeFormat(valueLibs.get('childValue').models));
                ret[0].icon = "img/object.png";
            } else if (valueLibs instanceof Backbone.Model && valueLibs.get('type') === "vdml_ValueLibrary") {
                jsonData.children = jsonData.children.concat(self.getModelDataInJSTreeFormat(valueLibs.get('valueLibrary').models));
                ret[0].icon = "img/object.png";
            }
            else {
                ret[0].icon = "img/leaf.png";
            }
        } else {
            for (var i = 0; i < valueLibs.length; i++) {
                var jsonData = {};
                var capLibModels = valueLibs[i].models ? valueLibs[i].models : valueLibs[i];
                if ((!capLibModels.length && capLibModels instanceof Backbone.Model) || (capLibModels.length && capLibModels.length > 0)) {
                    ret.push(jsonData);
                }
                for (var j = 0; j < capLibModels.length; j++) {
                    var capabilityLib = capLibModels[j];
                    if (capabilityLib.get('type') == "vdml_ValueLibrary" && capabilityLib !== self.model) {
                        continue;
                    }
                    jsonData.id = capabilityLib.get('id');
                    jsonData.text = capabilityLib.get('name');
                    jsonData.description = capabilityLib.get('description');
                    jsonData.type = capabilityLib.get('type');
                    jsonData.definition = capabilityLib.get('definition');
                    var typeMixinCls = DataManager.getDataManager().getModelType(capabilityLib, true);
                    if (capabilityLib.get('type') !== "vdml_ValueLibrary") {
                        jsonData.children = [];
                        jsonData.children = jsonData.children.concat(this.addRelationsShipsForAppCls(capabilityLib, typeMixinCls));
                        if (!jsonData.children.text) {
                            jsonData.children = jsonData.children[0].children;
                        }
                    } else {
                        jsonData.children = [];
                        var caplist = capabilityLib.get('value').models;
                        var newcaplist = [];
                        for (var k = 0; k < caplist.length; k++) {
                            var addtolist = false;
                            _.each(caplist[k].get('parentValue').models, function (parentCap) {
                                if (parentCap.getParent() != capabilityLib) {
                                    addtolist = true;
                                }
                            });
                            if (caplist[k].get('parentValue').length == 0 || addtolist) {
                                newcaplist.push(caplist[k]);
                            }
                        }
                        jsonData.children = self.getModelDataInJSTreeFormat(newcaplist);
                        ret[j] ? ret[j].icon = "img/object.png" : '';
                    }
                    ret[i] ? ret[i].icon = "img/object.png" : '';
                }
                if (!capLibModels.length && capLibModels instanceof Backbone.Model) {
                    jsonData.id = capLibModels.get('id');
                    if (capLibModels.get('type') != "vdml_ValueLibrary" && self.model != capLibModels.get('valueOwner')) {
                        jsonData.text = capLibModels.get('name').concat('(from:' + capLibModels.get('valueOwner').get('name') + ')');
                    } else {
                        jsonData.text = capLibModels.get('name');
                    }
                    jsonData.description = capLibModels.get('description');
                    jsonData.type = capLibModels.get('type');
                    var typeMixinCls = DataManager.getDataManager().getModelType(capLibModels, true);
                    if (capLibModels instanceof Backbone.Model && capLibModels.get('childValue').length > 0) {
                        jsonData.children = self.getModelDataInJSTreeFormat(capLibModels.get('childValue').models);
                        ret[i].icon = "img/object.png";
                    }
                    else {
                        ret[i].icon = "img/leaf.png";
                    }
                }
            }

        }
                ret.sort(self.sortByName);
        return ret;
    };
    addRelationsShipsForAppCls(model, typeMixinCls) {
        var self = this;
        var ret = [];
        var value = model;
        addRelationShip(value);

        function addRelationShip(relationValue) {
            if (relationValue instanceof Backbone.Model) {
                ret.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
            } else {
                var relationValues = relationValue;
                var jsonData = {};
                ret.push(jsonData);
                if (relationValues && relationValues.length > 0) {
                    jsonData.children = [];
                }
                var valueCount = 0;
                function handleNextRelationshipValue(children) {
                    if (relationValues && valueCount < relationValues.length) {
                        var newRelationValue = relationValues.at(valueCount);
                        valueCount++;
                        //var children = jsonData.children ? jsonData.children : jsonData;
                        addRelationshipValue(newRelationValue, children);
                    }
                }
                function addRelationshipValue(relationValue, children) {
                    if (relationValue && relationValue.get('parentValue') && relationValue.get('parentValue').length == 0) {
                        if (typeof children.length == "undefined") {
                            children.children = [];
                            children.children.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
                        }
                        else {
                            children.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
                        }
                        handleNextRelationshipValue();
                    } else {
                        children.push(self.getModelDataInJSTreeFormat(relationValue));
                        handleNextRelationshipValue();
                    }
                }
                handleNextRelationshipValue(jsonData.children);
            }
        }
        return ret;
    };

    /*deleteChild(view,event){
        var self = this;
        debugger
        bootbox.confirm('Are you sure you want to delete the child?', function(result) { 
			if(result){
                var selVal = self.model.get('value').findWhere({ id: self.selectedCapId });
                if (selVal) {
                    selVal.destroy();
                }
            }
        });
    }*/

    addChild(view,event){
        var self = this;
        var selVal = self.model.get('value').findWhere({ id: self.selectedCapId });
        if (!selVal) {
            selVal = Backbone.Relational.store.getObjectByName('vdml.ValueLibrary').find({ id: self.selectedCapId });
            if (!selVal) {
                bootbox.alert("Selected Value does not exist");
                return;
            }
        }
        var autoCompAttr = 'new-password';
        if(window.browser == "chrome"){
            autoCompAttr = 'off';
        }
        bootbox.dialog({
            title: DataManager.getDataManager().get('localeManager').get("AddChild"),
            message: "<label>Name:&nbsp;</label><input id='valchildName' autocomplete = "+autoCompAttr+" type='text'>&nbsp;<label>Type:&nbsp;</label><select id='valType'><option value='def'>Value Definition</option> <option value='cat'>Value Category</option></select>",
            buttons: {
                success: {
                    label: "Complete",
                    className: "btn btn-complete complete-btn",
                    callback: function () {
                        var catName = $("#valchildName").val();
                        var selValue = $("#valType :selected").val();
                        var objType = Backbone.Relational.store.getObjectByName("vdml.ValueDefinition");
                        if(selValue == "cat"){
                            objType = Backbone.Relational.store.getObjectByName("vdml.ValueCategory");
                        }
                        if (catName.trim().length > 0) {
                            var valCreated = objType.getInstance(catName, catName, self.model);
                            if(self.model == selVal){
                                selVal.get('value').add(valCreated);
                            } else {
                                selVal.get("childValue").add(valCreated);
                                valCreated.get("parentValue").add(selVal);
                                //self.childrens.push({'id':valCreated.id,'name':valCreated.get('name')});
                            }
                            var newCap = self.selectedCapId;
                            self.enableCapComplete(true);
                            self.clearAndReload();
                            setTimeout(function () {
                                $('#jstree_div'+self.encodeId).jstree('select_node', (newCap));
                                $('#jstree_div'+self.encodeId).jstree('open_node', (newCap));
                            }, 500);
                        }                            
                    }
                }
            }
        })    
    }

    static getInstance(model, options) {
        var view = new ValueLibraryViewModel(model, options);
        //view.init(model, options);
        return view; 
    }
}
path.ValueLibraryViewModel = ValueLibraryViewModel;
