// Generated from ./ExprLang.g4 by ANTLR 4.13.2
// jshint ignore: start
import antlr4 from 'antlr4';


const serializedATN = [4,0,37,203,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,
4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,
12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,
2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,
27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,34,
7,34,2,35,7,35,2,36,7,36,1,0,1,0,1,1,1,1,1,2,1,2,1,3,1,3,1,4,1,4,1,5,1,5,
1,6,1,6,1,7,1,7,1,8,1,8,1,9,1,9,1,9,1,10,1,10,1,10,1,11,1,11,1,12,1,12,1,
13,1,13,1,13,1,14,1,14,1,14,1,15,1,15,1,15,1,16,1,16,1,16,1,17,1,17,1,18,
1,18,1,18,1,19,1,19,1,19,1,20,1,20,1,20,1,21,1,21,1,21,1,22,1,22,1,23,1,
23,1,24,1,24,1,25,1,25,1,26,1,26,1,26,1,26,1,26,1,27,1,27,1,27,1,28,1,28,
1,28,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,30,1,30,1,30,1,30,1,
30,1,30,1,31,1,31,1,32,1,32,1,33,1,33,5,33,170,8,33,10,33,12,33,173,9,33,
1,34,4,34,176,8,34,11,34,12,34,177,1,34,1,34,4,34,182,8,34,11,34,12,34,183,
3,34,186,8,34,1,35,1,35,5,35,190,8,35,10,35,12,35,193,9,35,1,35,1,35,1,36,
4,36,198,8,36,11,36,12,36,199,1,36,1,36,1,191,0,37,1,1,3,2,5,3,7,4,9,5,11,
6,13,7,15,8,17,9,19,10,21,11,23,12,25,13,27,14,29,15,31,16,33,17,35,18,37,
19,39,20,41,21,43,22,45,23,47,24,49,25,51,26,53,27,55,28,57,29,59,30,61,
31,63,32,65,33,67,34,69,35,71,36,73,37,1,0,4,3,0,65,90,95,95,97,122,4,0,
48,57,65,90,95,95,97,122,1,0,48,57,3,0,9,10,13,13,32,32,208,0,1,1,0,0,0,
0,3,1,0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,11,1,0,0,0,0,13,1,0,0,
0,0,15,1,0,0,0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,0,23,1,0,0,0,0,25,
1,0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,0,0,0,0,35,1,0,0,0,
0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,0,0,0,45,1,0,0,0,0,47,1,
0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,0,53,1,0,0,0,0,55,1,0,0,0,0,57,1,0,0,0,0,
59,1,0,0,0,0,61,1,0,0,0,0,63,1,0,0,0,0,65,1,0,0,0,0,67,1,0,0,0,0,69,1,0,
0,0,0,71,1,0,0,0,0,73,1,0,0,0,1,75,1,0,0,0,3,77,1,0,0,0,5,79,1,0,0,0,7,81,
1,0,0,0,9,83,1,0,0,0,11,85,1,0,0,0,13,87,1,0,0,0,15,89,1,0,0,0,17,91,1,0,
0,0,19,93,1,0,0,0,21,96,1,0,0,0,23,99,1,0,0,0,25,101,1,0,0,0,27,103,1,0,
0,0,29,106,1,0,0,0,31,109,1,0,0,0,33,112,1,0,0,0,35,115,1,0,0,0,37,117,1,
0,0,0,39,120,1,0,0,0,41,123,1,0,0,0,43,126,1,0,0,0,45,129,1,0,0,0,47,131,
1,0,0,0,49,133,1,0,0,0,51,135,1,0,0,0,53,137,1,0,0,0,55,142,1,0,0,0,57,145,
1,0,0,0,59,148,1,0,0,0,61,157,1,0,0,0,63,163,1,0,0,0,65,165,1,0,0,0,67,167,
1,0,0,0,69,175,1,0,0,0,71,187,1,0,0,0,73,197,1,0,0,0,75,76,5,59,0,0,76,2,
1,0,0,0,77,78,5,63,0,0,78,4,1,0,0,0,79,80,5,58,0,0,80,6,1,0,0,0,81,82,5,
40,0,0,82,8,1,0,0,0,83,84,5,41,0,0,84,10,1,0,0,0,85,86,5,43,0,0,86,12,1,
0,0,0,87,88,5,45,0,0,88,14,1,0,0,0,89,90,5,42,0,0,90,16,1,0,0,0,91,92,5,
47,0,0,92,18,1,0,0,0,93,94,5,124,0,0,94,95,5,124,0,0,95,20,1,0,0,0,96,97,
5,38,0,0,97,98,5,38,0,0,98,22,1,0,0,0,99,100,5,60,0,0,100,24,1,0,0,0,101,
102,5,62,0,0,102,26,1,0,0,0,103,104,5,60,0,0,104,105,5,61,0,0,105,28,1,0,
0,0,106,107,5,62,0,0,107,108,5,61,0,0,108,30,1,0,0,0,109,110,5,61,0,0,110,
111,5,61,0,0,111,32,1,0,0,0,112,113,5,33,0,0,113,114,5,61,0,0,114,34,1,0,
0,0,115,116,5,61,0,0,116,36,1,0,0,0,117,118,5,43,0,0,118,119,5,61,0,0,119,
38,1,0,0,0,120,121,5,45,0,0,121,122,5,61,0,0,122,40,1,0,0,0,123,124,5,42,
0,0,124,125,5,61,0,0,125,42,1,0,0,0,126,127,5,47,0,0,127,128,5,61,0,0,128,
44,1,0,0,0,129,130,5,38,0,0,130,46,1,0,0,0,131,132,5,33,0,0,132,48,1,0,0,
0,133,134,5,46,0,0,134,50,1,0,0,0,135,136,5,44,0,0,136,52,1,0,0,0,137,138,
5,115,0,0,138,139,5,101,0,0,139,140,5,108,0,0,140,141,5,102,0,0,141,54,1,
0,0,0,142,143,5,98,0,0,143,144,5,109,0,0,144,56,1,0,0,0,145,146,5,118,0,
0,146,147,5,112,0,0,147,58,1,0,0,0,148,149,5,97,0,0,149,150,5,99,0,0,150,
151,5,116,0,0,151,152,5,105,0,0,152,153,5,118,0,0,153,154,5,105,0,0,154,
155,5,116,0,0,155,156,5,121,0,0,156,60,1,0,0,0,157,158,5,118,0,0,158,159,
5,97,0,0,159,160,5,108,0,0,160,161,5,117,0,0,161,162,5,101,0,0,162,62,1,
0,0,0,163,164,5,91,0,0,164,64,1,0,0,0,165,166,5,93,0,0,166,66,1,0,0,0,167,
171,7,0,0,0,168,170,7,1,0,0,169,168,1,0,0,0,170,173,1,0,0,0,171,169,1,0,
0,0,171,172,1,0,0,0,172,68,1,0,0,0,173,171,1,0,0,0,174,176,7,2,0,0,175,174,
1,0,0,0,176,177,1,0,0,0,177,175,1,0,0,0,177,178,1,0,0,0,178,185,1,0,0,0,
179,181,5,46,0,0,180,182,7,2,0,0,181,180,1,0,0,0,182,183,1,0,0,0,183,181,
1,0,0,0,183,184,1,0,0,0,184,186,1,0,0,0,185,179,1,0,0,0,185,186,1,0,0,0,
186,70,1,0,0,0,187,191,5,34,0,0,188,190,9,0,0,0,189,188,1,0,0,0,190,193,
1,0,0,0,191,192,1,0,0,0,191,189,1,0,0,0,192,194,1,0,0,0,193,191,1,0,0,0,
194,195,5,34,0,0,195,72,1,0,0,0,196,198,7,3,0,0,197,196,1,0,0,0,198,199,
1,0,0,0,199,197,1,0,0,0,199,200,1,0,0,0,200,201,1,0,0,0,201,202,6,36,0,0,
202,74,1,0,0,0,7,0,171,177,183,185,191,199,1,6,0,0];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class ExprLangLexer extends antlr4.Lexer {

    static grammarFileName = "ExprLang.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE" ];
	static literalNames = [ null, "';'", "'?'", "':'", "'('", "')'", "'+'", 
                         "'-'", "'*'", "'/'", "'||'", "'&&'", "'<'", "'>'", 
                         "'<='", "'>='", "'=='", "'!='", "'='", "'+='", 
                         "'-='", "'*='", "'/='", "'&'", "'!'", "'.'", "','", 
                         "'self'", "'bm'", "'vp'", "'activity'", "'value'", 
                         "'['", "']'" ];
	static symbolicNames = [ null, null, null, null, null, null, null, null, 
                          null, null, null, null, null, null, null, null, 
                          null, null, null, null, null, null, null, null, 
                          null, null, null, null, null, null, null, null, 
                          null, null, "IDENTIFIER", "NUMBER", "STRING", 
                          "WS" ];
	static ruleNames = [ "T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "T__6", 
                      "T__7", "T__8", "T__9", "T__10", "T__11", "T__12", 
                      "T__13", "T__14", "T__15", "T__16", "T__17", "T__18", 
                      "T__19", "T__20", "T__21", "T__22", "T__23", "T__24", 
                      "T__25", "T__26", "T__27", "T__28", "T__29", "T__30", 
                      "T__31", "T__32", "IDENTIFIER", "NUMBER", "STRING", 
                      "WS" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.atn.PredictionContextCache());
    }
}

ExprLangLexer.EOF = antlr4.Token.EOF;
ExprLangLexer.T__0 = 1;
ExprLangLexer.T__1 = 2;
ExprLangLexer.T__2 = 3;
ExprLangLexer.T__3 = 4;
ExprLangLexer.T__4 = 5;
ExprLangLexer.T__5 = 6;
ExprLangLexer.T__6 = 7;
ExprLangLexer.T__7 = 8;
ExprLangLexer.T__8 = 9;
ExprLangLexer.T__9 = 10;
ExprLangLexer.T__10 = 11;
ExprLangLexer.T__11 = 12;
ExprLangLexer.T__12 = 13;
ExprLangLexer.T__13 = 14;
ExprLangLexer.T__14 = 15;
ExprLangLexer.T__15 = 16;
ExprLangLexer.T__16 = 17;
ExprLangLexer.T__17 = 18;
ExprLangLexer.T__18 = 19;
ExprLangLexer.T__19 = 20;
ExprLangLexer.T__20 = 21;
ExprLangLexer.T__21 = 22;
ExprLangLexer.T__22 = 23;
ExprLangLexer.T__23 = 24;
ExprLangLexer.T__24 = 25;
ExprLangLexer.T__25 = 26;
ExprLangLexer.T__26 = 27;
ExprLangLexer.T__27 = 28;
ExprLangLexer.T__28 = 29;
ExprLangLexer.T__29 = 30;
ExprLangLexer.T__30 = 31;
ExprLangLexer.T__31 = 32;
ExprLangLexer.T__32 = 33;
ExprLangLexer.IDENTIFIER = 34;
ExprLangLexer.NUMBER = 35;
ExprLangLexer.STRING = 36;
ExprLangLexer.WS = 37;



