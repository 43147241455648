// Generated from ./ExprLang.g4 by ANTLR 4.13.2
// jshint ignore: start
import antlr4 from 'antlr4';
import ExprLangListener from './ExprLangListener.js';
const serializedATN = [4,1,37,159,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,
4,2,5,7,5,2,6,7,6,2,7,7,7,1,0,4,0,18,8,0,11,0,12,0,19,1,0,1,0,1,1,1,1,3,
1,26,8,1,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,3,2,41,8,2,
1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,5,2,53,8,2,10,2,12,2,56,9,2,1,3,
1,3,1,4,1,4,1,5,1,5,1,5,3,5,65,8,5,1,5,1,5,1,5,1,5,1,5,1,5,3,5,73,8,5,1,
5,1,5,3,5,77,8,5,1,6,1,6,1,6,5,6,82,8,6,10,6,12,6,85,9,6,1,7,1,7,1,7,1,7,
1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,5,7,
108,8,7,10,7,12,7,111,9,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,
7,1,7,1,7,1,7,1,7,1,7,5,7,130,8,7,10,7,12,7,133,9,7,1,7,1,7,1,7,1,7,1,7,
1,7,1,7,5,7,142,8,7,10,7,12,7,145,9,7,1,7,1,7,1,7,1,7,1,7,5,7,152,8,7,10,
7,12,7,155,9,7,3,7,157,8,7,1,7,0,1,4,8,0,2,4,6,8,10,12,14,0,2,1,0,6,23,2,
0,7,7,24,24,171,0,17,1,0,0,0,2,23,1,0,0,0,4,40,1,0,0,0,6,57,1,0,0,0,8,59,
1,0,0,0,10,76,1,0,0,0,12,78,1,0,0,0,14,156,1,0,0,0,16,18,3,2,1,0,17,16,1,
0,0,0,18,19,1,0,0,0,19,17,1,0,0,0,19,20,1,0,0,0,20,21,1,0,0,0,21,22,5,0,
0,1,22,1,1,0,0,0,23,25,3,4,2,0,24,26,5,1,0,0,25,24,1,0,0,0,25,26,1,0,0,0,
26,3,1,0,0,0,27,28,6,2,-1,0,28,29,3,8,4,0,29,30,3,4,2,7,30,41,1,0,0,0,31,
32,5,4,0,0,32,33,3,4,2,0,33,34,5,5,0,0,34,41,1,0,0,0,35,41,3,10,5,0,36,41,
3,14,7,0,37,41,5,34,0,0,38,41,5,35,0,0,39,41,5,36,0,0,40,27,1,0,0,0,40,31,
1,0,0,0,40,35,1,0,0,0,40,36,1,0,0,0,40,37,1,0,0,0,40,38,1,0,0,0,40,39,1,
0,0,0,41,54,1,0,0,0,42,43,10,9,0,0,43,44,5,2,0,0,44,45,3,4,2,0,45,46,5,3,
0,0,46,47,3,4,2,10,47,53,1,0,0,0,48,49,10,8,0,0,49,50,3,6,3,0,50,51,3,4,
2,9,51,53,1,0,0,0,52,42,1,0,0,0,52,48,1,0,0,0,53,56,1,0,0,0,54,52,1,0,0,
0,54,55,1,0,0,0,55,5,1,0,0,0,56,54,1,0,0,0,57,58,7,0,0,0,58,7,1,0,0,0,59,
60,7,1,0,0,60,9,1,0,0,0,61,62,5,34,0,0,62,64,5,4,0,0,63,65,3,12,6,0,64,63,
1,0,0,0,64,65,1,0,0,0,65,66,1,0,0,0,66,77,5,5,0,0,67,68,3,14,7,0,68,69,5,
25,0,0,69,70,5,34,0,0,70,72,5,4,0,0,71,73,3,12,6,0,72,71,1,0,0,0,72,73,1,
0,0,0,73,74,1,0,0,0,74,75,5,5,0,0,75,77,1,0,0,0,76,61,1,0,0,0,76,67,1,0,
0,0,77,11,1,0,0,0,78,83,3,4,2,0,79,80,5,26,0,0,80,82,3,4,2,0,81,79,1,0,0,
0,82,85,1,0,0,0,83,81,1,0,0,0,83,84,1,0,0,0,84,13,1,0,0,0,85,83,1,0,0,0,
86,87,5,27,0,0,87,88,5,25,0,0,88,89,5,28,0,0,89,90,5,25,0,0,90,91,5,34,0,
0,91,92,5,25,0,0,92,93,5,29,0,0,93,94,5,25,0,0,94,95,5,34,0,0,95,96,5,25,
0,0,96,97,5,30,0,0,97,98,5,25,0,0,98,99,5,34,0,0,99,100,5,25,0,0,100,101,
5,31,0,0,101,102,5,25,0,0,102,109,5,34,0,0,103,104,5,32,0,0,104,105,3,4,
2,0,105,106,5,33,0,0,106,108,1,0,0,0,107,103,1,0,0,0,108,111,1,0,0,0,109,
107,1,0,0,0,109,110,1,0,0,0,110,157,1,0,0,0,111,109,1,0,0,0,112,113,5,27,
0,0,113,114,5,25,0,0,114,115,5,28,0,0,115,116,5,25,0,0,116,117,5,34,0,0,
117,118,5,25,0,0,118,119,5,29,0,0,119,120,5,25,0,0,120,121,5,34,0,0,121,
122,5,25,0,0,122,123,5,31,0,0,123,124,5,25,0,0,124,131,5,34,0,0,125,126,
5,32,0,0,126,127,3,4,2,0,127,128,5,33,0,0,128,130,1,0,0,0,129,125,1,0,0,
0,130,133,1,0,0,0,131,129,1,0,0,0,131,132,1,0,0,0,132,157,1,0,0,0,133,131,
1,0,0,0,134,135,5,27,0,0,135,136,5,25,0,0,136,143,5,34,0,0,137,138,5,32,
0,0,138,139,3,4,2,0,139,140,5,33,0,0,140,142,1,0,0,0,141,137,1,0,0,0,142,
145,1,0,0,0,143,141,1,0,0,0,143,144,1,0,0,0,144,157,1,0,0,0,145,143,1,0,
0,0,146,153,5,34,0,0,147,148,5,32,0,0,148,149,3,4,2,0,149,150,5,33,0,0,150,
152,1,0,0,0,151,147,1,0,0,0,152,155,1,0,0,0,153,151,1,0,0,0,153,154,1,0,
0,0,154,157,1,0,0,0,155,153,1,0,0,0,156,86,1,0,0,0,156,112,1,0,0,0,156,134,
1,0,0,0,156,146,1,0,0,0,157,15,1,0,0,0,14,19,25,40,52,54,64,72,76,83,109,
131,143,153,156];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.atn.PredictionContextCache();

export default class ExprLangParser extends antlr4.Parser {

    static grammarFileName = "ExprLang.g4";
    static literalNames = [ null, "';'", "'?'", "':'", "'('", "')'", "'+'", 
                            "'-'", "'*'", "'/'", "'||'", "'&&'", "'<'", 
                            "'>'", "'<='", "'>='", "'=='", "'!='", "'='", 
                            "'+='", "'-='", "'*='", "'/='", "'&'", "'!'", 
                            "'.'", "','", "'self'", "'bm'", "'vp'", "'activity'", 
                            "'value'", "'['", "']'" ];
    static symbolicNames = [ null, null, null, null, null, null, null, null, 
                             null, null, null, null, null, null, null, null, 
                             null, null, null, null, null, null, null, null, 
                             null, null, null, null, null, null, null, null, 
                             null, null, "IDENTIFIER", "NUMBER", "STRING", 
                             "WS" ];
    static ruleNames = [ "program", "statement", "vmpExpr", "binaryOp", 
                         "unaryOp", "func", "vmpExprList", "objAccess" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = ExprLangParser.ruleNames;
        this.literalNames = ExprLangParser.literalNames;
        this.symbolicNames = ExprLangParser.symbolicNames;
    }

    sempred(localctx, ruleIndex, predIndex) {
    	switch(ruleIndex) {
    	case 2:
    	    		return this.vmpExpr_sempred(localctx, predIndex);
        default:
            throw "No predicate with index:" + ruleIndex;
       }
    }

    vmpExpr_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 0:
    			return this.precpred(this._ctx, 9);
    		case 1:
    			return this.precpred(this._ctx, 8);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };




	program() {
	    let localctx = new ProgramContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, ExprLangParser.RULE_program);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 17; 
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        do {
	            this.state = 16;
	            this.statement();
	            this.state = 19; 
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        } while((((_la) & ~0x1f) === 0 && ((1 << _la) & 150995088) !== 0) || ((((_la - 34)) & ~0x1f) === 0 && ((1 << (_la - 34)) & 7) !== 0));
	        this.state = 21;
	        this.match(ExprLangParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	statement() {
	    let localctx = new StatementContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 2, ExprLangParser.RULE_statement);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 23;
	        this.vmpExpr(0);
	        this.state = 25;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===1) {
	            this.state = 24;
	            this.match(ExprLangParser.T__0);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


	vmpExpr(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new VmpExprContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 4;
	    this.enterRecursionRule(localctx, 4, ExprLangParser.RULE_vmpExpr, _p);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 40;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,2,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new UnaryvmpExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 28;
	            this.unaryOp();
	            this.state = 29;
	            this.vmpExpr(7);
	            break;

	        case 2:
	            localctx = new ParenvmpExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 31;
	            this.match(ExprLangParser.T__3);
	            this.state = 32;
	            this.vmpExpr(0);
	            this.state = 33;
	            this.match(ExprLangParser.T__4);
	            break;

	        case 3:
	            localctx = new FunctionCallContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 35;
	            this.func();
	            break;

	        case 4:
	            localctx = new ObjectAccessContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 36;
	            this.objAccess();
	            break;

	        case 5:
	            localctx = new VariableContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 37;
	            this.match(ExprLangParser.IDENTIFIER);
	            break;

	        case 6:
	            localctx = new NumberContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 38;
	            this.match(ExprLangParser.NUMBER);
	            break;

	        case 7:
	            localctx = new StringContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 39;
	            this.match(ExprLangParser.STRING);
	            break;

	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 54;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,4,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 52;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,3,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new TernaryExpressionContext(this, new VmpExprContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, ExprLangParser.RULE_vmpExpr);
	                    this.state = 42;
	                    if (!( this.precpred(this._ctx, 9))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 9)");
	                    }
	                    this.state = 43;
	                    this.match(ExprLangParser.T__1);
	                    this.state = 44;
	                    this.vmpExpr(0);
	                    this.state = 45;
	                    this.match(ExprLangParser.T__2);
	                    this.state = 46;
	                    this.vmpExpr(10);
	                    break;

	                case 2:
	                    localctx = new BinaryvmpExpressionContext(this, new VmpExprContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, ExprLangParser.RULE_vmpExpr);
	                    this.state = 48;
	                    if (!( this.precpred(this._ctx, 8))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 8)");
	                    }
	                    this.state = 49;
	                    this.binaryOp();
	                    this.state = 50;
	                    this.vmpExpr(9);
	                    break;

	                } 
	            }
	            this.state = 56;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,4,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}



	binaryOp() {
	    let localctx = new BinaryOpContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 6, ExprLangParser.RULE_binaryOp);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 57;
	        _la = this._input.LA(1);
	        if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 16777152) !== 0))) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	unaryOp() {
	    let localctx = new UnaryOpContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, ExprLangParser.RULE_unaryOp);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 59;
	        _la = this._input.LA(1);
	        if(!(_la===7 || _la===24)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	func() {
	    let localctx = new FuncContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 10, ExprLangParser.RULE_func);
	    var _la = 0;
	    try {
	        this.state = 76;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,7,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new SimpleFunctionCallContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 61;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 62;
	            this.match(ExprLangParser.T__3);
	            this.state = 64;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if((((_la) & ~0x1f) === 0 && ((1 << _la) & 150995088) !== 0) || ((((_la - 34)) & ~0x1f) === 0 && ((1 << (_la - 34)) & 7) !== 0)) {
	                this.state = 63;
	                this.vmpExprList();
	            }

	            this.state = 66;
	            this.match(ExprLangParser.T__4);
	            break;

	        case 2:
	            localctx = new MethodFunctionCallContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 67;
	            this.objAccess();
	            this.state = 68;
	            this.match(ExprLangParser.T__24);
	            this.state = 69;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 70;
	            this.match(ExprLangParser.T__3);
	            this.state = 72;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if((((_la) & ~0x1f) === 0 && ((1 << _la) & 150995088) !== 0) || ((((_la - 34)) & ~0x1f) === 0 && ((1 << (_la - 34)) & 7) !== 0)) {
	                this.state = 71;
	                this.vmpExprList();
	            }

	            this.state = 74;
	            this.match(ExprLangParser.T__4);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	vmpExprList() {
	    let localctx = new VmpExprListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 12, ExprLangParser.RULE_vmpExprList);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 78;
	        this.vmpExpr(0);
	        this.state = 83;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===26) {
	            this.state = 79;
	            this.match(ExprLangParser.T__25);
	            this.state = 80;
	            this.vmpExpr(0);
	            this.state = 85;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	objAccess() {
	    let localctx = new ObjAccessContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 14, ExprLangParser.RULE_objAccess);
	    try {
	        this.state = 156;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,13,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 86;
	            this.match(ExprLangParser.T__26);
	            this.state = 87;
	            this.match(ExprLangParser.T__24);
	            this.state = 88;
	            this.match(ExprLangParser.T__27);
	            this.state = 89;
	            this.match(ExprLangParser.T__24);
	            this.state = 90;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 91;
	            this.match(ExprLangParser.T__24);
	            this.state = 92;
	            this.match(ExprLangParser.T__28);
	            this.state = 93;
	            this.match(ExprLangParser.T__24);
	            this.state = 94;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 95;
	            this.match(ExprLangParser.T__24);
	            this.state = 96;
	            this.match(ExprLangParser.T__29);
	            this.state = 97;
	            this.match(ExprLangParser.T__24);
	            this.state = 98;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 99;
	            this.match(ExprLangParser.T__24);
	            this.state = 100;
	            this.match(ExprLangParser.T__30);
	            this.state = 101;
	            this.match(ExprLangParser.T__24);
	            this.state = 102;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 109;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,9,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 103;
	                    this.match(ExprLangParser.T__31);
	                    this.state = 104;
	                    this.vmpExpr(0);
	                    this.state = 105;
	                    this.match(ExprLangParser.T__32); 
	                }
	                this.state = 111;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,9,this._ctx);
	            }

	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 112;
	            this.match(ExprLangParser.T__26);
	            this.state = 113;
	            this.match(ExprLangParser.T__24);
	            this.state = 114;
	            this.match(ExprLangParser.T__27);
	            this.state = 115;
	            this.match(ExprLangParser.T__24);
	            this.state = 116;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 117;
	            this.match(ExprLangParser.T__24);
	            this.state = 118;
	            this.match(ExprLangParser.T__28);
	            this.state = 119;
	            this.match(ExprLangParser.T__24);
	            this.state = 120;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 121;
	            this.match(ExprLangParser.T__24);
	            this.state = 122;
	            this.match(ExprLangParser.T__30);
	            this.state = 123;
	            this.match(ExprLangParser.T__24);
	            this.state = 124;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 131;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,10,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 125;
	                    this.match(ExprLangParser.T__31);
	                    this.state = 126;
	                    this.vmpExpr(0);
	                    this.state = 127;
	                    this.match(ExprLangParser.T__32); 
	                }
	                this.state = 133;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,10,this._ctx);
	            }

	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 134;
	            this.match(ExprLangParser.T__26);
	            this.state = 135;
	            this.match(ExprLangParser.T__24);
	            this.state = 136;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 143;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,11,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 137;
	                    this.match(ExprLangParser.T__31);
	                    this.state = 138;
	                    this.vmpExpr(0);
	                    this.state = 139;
	                    this.match(ExprLangParser.T__32); 
	                }
	                this.state = 145;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,11,this._ctx);
	            }

	            break;

	        case 4:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 146;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 153;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,12,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 147;
	                    this.match(ExprLangParser.T__31);
	                    this.state = 148;
	                    this.vmpExpr(0);
	                    this.state = 149;
	                    this.match(ExprLangParser.T__32); 
	                }
	                this.state = 155;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,12,this._ctx);
	            }

	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


}

ExprLangParser.EOF = antlr4.Token.EOF;
ExprLangParser.T__0 = 1;
ExprLangParser.T__1 = 2;
ExprLangParser.T__2 = 3;
ExprLangParser.T__3 = 4;
ExprLangParser.T__4 = 5;
ExprLangParser.T__5 = 6;
ExprLangParser.T__6 = 7;
ExprLangParser.T__7 = 8;
ExprLangParser.T__8 = 9;
ExprLangParser.T__9 = 10;
ExprLangParser.T__10 = 11;
ExprLangParser.T__11 = 12;
ExprLangParser.T__12 = 13;
ExprLangParser.T__13 = 14;
ExprLangParser.T__14 = 15;
ExprLangParser.T__15 = 16;
ExprLangParser.T__16 = 17;
ExprLangParser.T__17 = 18;
ExprLangParser.T__18 = 19;
ExprLangParser.T__19 = 20;
ExprLangParser.T__20 = 21;
ExprLangParser.T__21 = 22;
ExprLangParser.T__22 = 23;
ExprLangParser.T__23 = 24;
ExprLangParser.T__24 = 25;
ExprLangParser.T__25 = 26;
ExprLangParser.T__26 = 27;
ExprLangParser.T__27 = 28;
ExprLangParser.T__28 = 29;
ExprLangParser.T__29 = 30;
ExprLangParser.T__30 = 31;
ExprLangParser.T__31 = 32;
ExprLangParser.T__32 = 33;
ExprLangParser.IDENTIFIER = 34;
ExprLangParser.NUMBER = 35;
ExprLangParser.STRING = 36;
ExprLangParser.WS = 37;

ExprLangParser.RULE_program = 0;
ExprLangParser.RULE_statement = 1;
ExprLangParser.RULE_vmpExpr = 2;
ExprLangParser.RULE_binaryOp = 3;
ExprLangParser.RULE_unaryOp = 4;
ExprLangParser.RULE_func = 5;
ExprLangParser.RULE_vmpExprList = 6;
ExprLangParser.RULE_objAccess = 7;

class ProgramContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_program;
    }

	EOF() {
	    return this.getToken(ExprLangParser.EOF, 0);
	};

	statement = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(StatementContext);
	    } else {
	        return this.getTypedRuleContext(StatementContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterProgram(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitProgram(this);
		}
	}


}



class StatementContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_statement;
    }

	vmpExpr() {
	    return this.getTypedRuleContext(VmpExprContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterStatement(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitStatement(this);
		}
	}


}



class VmpExprContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_vmpExpr;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class ParenvmpExpressionContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	vmpExpr() {
	    return this.getTypedRuleContext(VmpExprContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterParenvmpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitParenvmpExpression(this);
		}
	}


}

ExprLangParser.ParenvmpExpressionContext = ParenvmpExpressionContext;

class NumberContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	NUMBER() {
	    return this.getToken(ExprLangParser.NUMBER, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterNumber(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitNumber(this);
		}
	}


}

ExprLangParser.NumberContext = NumberContext;

class StringContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	STRING() {
	    return this.getToken(ExprLangParser.STRING, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterString(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitString(this);
		}
	}


}

ExprLangParser.StringContext = StringContext;

class FunctionCallContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	func() {
	    return this.getTypedRuleContext(FuncContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitFunctionCall(this);
		}
	}


}

ExprLangParser.FunctionCallContext = FunctionCallContext;

class VariableContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	IDENTIFIER() {
	    return this.getToken(ExprLangParser.IDENTIFIER, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterVariable(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitVariable(this);
		}
	}


}

ExprLangParser.VariableContext = VariableContext;

class ObjectAccessContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	objAccess() {
	    return this.getTypedRuleContext(ObjAccessContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterObjectAccess(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitObjectAccess(this);
		}
	}


}

ExprLangParser.ObjectAccessContext = ObjectAccessContext;

class TernaryExpressionContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	vmpExpr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VmpExprContext);
	    } else {
	        return this.getTypedRuleContext(VmpExprContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterTernaryExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitTernaryExpression(this);
		}
	}


}

ExprLangParser.TernaryExpressionContext = TernaryExpressionContext;

class UnaryvmpExpressionContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	unaryOp() {
	    return this.getTypedRuleContext(UnaryOpContext,0);
	};

	vmpExpr() {
	    return this.getTypedRuleContext(VmpExprContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterUnaryvmpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitUnaryvmpExpression(this);
		}
	}


}

ExprLangParser.UnaryvmpExpressionContext = UnaryvmpExpressionContext;

class BinaryvmpExpressionContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	vmpExpr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VmpExprContext);
	    } else {
	        return this.getTypedRuleContext(VmpExprContext,i);
	    }
	};

	binaryOp() {
	    return this.getTypedRuleContext(BinaryOpContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterBinaryvmpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitBinaryvmpExpression(this);
		}
	}


}

ExprLangParser.BinaryvmpExpressionContext = BinaryvmpExpressionContext;

class BinaryOpContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_binaryOp;
    }


	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterBinaryOp(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitBinaryOp(this);
		}
	}


}



class UnaryOpContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_unaryOp;
    }


	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterUnaryOp(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitUnaryOp(this);
		}
	}


}



class FuncContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_func;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class MethodFunctionCallContext extends FuncContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	objAccess() {
	    return this.getTypedRuleContext(ObjAccessContext,0);
	};

	IDENTIFIER() {
	    return this.getToken(ExprLangParser.IDENTIFIER, 0);
	};

	vmpExprList() {
	    return this.getTypedRuleContext(VmpExprListContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterMethodFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitMethodFunctionCall(this);
		}
	}


}

ExprLangParser.MethodFunctionCallContext = MethodFunctionCallContext;

class SimpleFunctionCallContext extends FuncContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	IDENTIFIER() {
	    return this.getToken(ExprLangParser.IDENTIFIER, 0);
	};

	vmpExprList() {
	    return this.getTypedRuleContext(VmpExprListContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterSimpleFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitSimpleFunctionCall(this);
		}
	}


}

ExprLangParser.SimpleFunctionCallContext = SimpleFunctionCallContext;

class VmpExprListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_vmpExprList;
    }

	vmpExpr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VmpExprContext);
	    } else {
	        return this.getTypedRuleContext(VmpExprContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterVmpExprList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitVmpExprList(this);
		}
	}


}



class ObjAccessContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_objAccess;
    }

	IDENTIFIER = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(ExprLangParser.IDENTIFIER);
	    } else {
	        return this.getToken(ExprLangParser.IDENTIFIER, i);
	    }
	};


	vmpExpr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VmpExprContext);
	    } else {
	        return this.getTypedRuleContext(VmpExprContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterObjAccess(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitObjAccess(this);
		}
	}


}




ExprLangParser.ProgramContext = ProgramContext; 
ExprLangParser.StatementContext = StatementContext; 
ExprLangParser.VmpExprContext = VmpExprContext; 
ExprLangParser.BinaryOpContext = BinaryOpContext; 
ExprLangParser.UnaryOpContext = UnaryOpContext; 
ExprLangParser.FuncContext = FuncContext; 
ExprLangParser.VmpExprListContext = VmpExprListContext; 
ExprLangParser.ObjAccessContext = ObjAccessContext; 
