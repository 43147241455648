import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as jszip from '../../../../../../libs/jszip/jszip.min'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import * as FileSaver from '../../../../../../libs/FileSaver/FileSaver'
import {Spinner} from '../../../../../../libs/spin/spin.min'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { BeepModule } from '../../../../../version1/bo/beeppackage/BeepModule'
import { BeepVocabulary } from '../../../../../version1/bo/concept/BeepVocabulary'
import { Plan } from '../../../../../version1/bo/transformation/Plan'
import { PlanMixin } from '../../../../../version1/bo/transformation/PlanMixin'
import * as ReactDOM from 'react-dom';
import * as React from 'react'
import MarketPlaceCloud from './MarketplaceCloud'

//define([/*"require",*/"jquery","underscore","jszip","backbone","knockout","knockoutMapping","knockback","bootbox","appbo/beeppackage/BeepModule","appbo/concept/BeepVocabulary","FileSaver","spin","async","appcommon/com/vbee/data/DataManager","app/global","appbo/transformation/Plan","appbo/transformation/PlanMixin"],
//function(/*require,*/$,_,jszip, Backbone,ko, koMapping,kb,bootbox,BeepModule,BeepVocabulary,FileSaver,Spinner,async,DataManager,global,Plan,PlanMixin)
//{
	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);
	//pdfjslib.GlobalWorkerOptions.workerSrc = pdfworker;
	export class ImportFromCloudViewModel {
        constructor(model, options) {
            var self = this;
            this.init(model, options);
        }

    afterRenderView(node, view) {
        var self = view;
		self.eventBus.emit("marketCloudProps", { component: MarketPlaceCloud, props:{workspaceName: self.workspaceName, id: self.id(), selectedOrg:self.selectedOrg, model:self.model} });
	};
	cleanUp(){
		delete ko.bindingHandlers.marketPlaceCloud;
	};

	init(model,options){
    	var self = this;
		this.ImportFromCloudViewModel = this;
		this.model = model;
		this.id = kb.observable(model, 'id');
		this.canvas = false;
		this.options = options;
		self.eventBus = {
			listeners: {},
			on: function (event, callback) {
			  if (!this.listeners[event]) {
			  this.listeners[event] = [];
			  }
			  this.listeners[event].push(callback);
			},
			emit: function (event, data) {
			  const listeners = this.listeners[event];
			  if (listeners) {
			  listeners.forEach((callback) => callback(data));
			  }
			},
		};

		self.MarketplaceCloudContainer = MarketPlaceCloud;

		
		this.encodeId = options.addOptions.viewId;
		this.planName = options.addOptions.planName;
		this.planDescription = options.addOptions.planDescription;
		this.workspaceName = options.addOptions.workspaceName;
		this.selectedOrg = options.addOptions.selectedOrg;
		if(options && options.parentView){
			this.parentView = options.parentView;
			this.encodeId = ""+options.modalId;
			this.parentModal = options.parentModal;
			this.canvas = options.canvas;
		}     

		ko.bindingHandlers.marketPlaceCloud = {
			init() {
			  return { controlsDescendantBindings: true };
			},
			update: function (element, valueAccessor) {
			  var component = ko.unwrap(valueAccessor().component);
			  var renderComponent = (props) => {React
			  ReactDOM.render(React.createElement(component,props), element);
			  };
			  const eventListener = (props) => {
			  renderComponent(props);
			  };			  
			  eventListener({workspaceName: self.workspaceName, id: self.planArtId, selectedOrg:self.selectedOrg, model:self.model, canvas: self.canvas});
			  ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
			  ReactDOM.unmountComponentAtNode(element);
			  });
			},
		  };
		
		//this.version = kb.observable(model, 'version');
		this.name = kb.observable(model, 'name');
		this.description = kb.observable(model, 'description');
		var planDoc = DataManager.getDataManager().get("artifactsDocuments")[this.id()];
        this.planArtId = planDoc ? planDoc.artifactId : this.id();
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['Close', 'Extensions','MarketPlace'
		]);
		this.title = self.canvas ? this.labels["Extensions"]() : this.labels["MarketPlace"]();
		if(self.canvas){
			this.imagePath ="img/icons/icons_20.png";
		}else {
			this.imagePath ="img/icons/38_channels.png";
		}
	};
	cleanModal() {
        var self = this;
        window.cleanDialogModel(self.encodeId, self);
    };
	static getInstance(model,options){
		var view = new ImportFromCloudViewModel(model,options);
		//view.init(model, options);
		return view;
	};
}
	
path.ImportFromCloudViewModel = ImportFromCloudViewModel;