import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as expressionBuilder from '../../../../../../libs/JQuery-expression-builder-2.1.1/src/expression-builder-v2'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import {ValueType} from '../../../../bo/vdml/ValueType'
import { BusinessModel2Mixin } from '../../../../bo/vdml/BusinessModel2Mixin'
import * as math from 'mathjs'
import { TrendModel } from '../../../../bo/vdml/TrendModel'
// import * as monaco from 'monaco-editor';
import { getYear} from "date-fns";
import antlr4 from 'antlr4'
import ExprLangLexer from '../../../../../com/vbee/antlr/ExprLangLexer.js'
import ExprLangParser from '../../../../../com/vbee/antlr/ExprLangParser.js'
import ExprLangToJuliaListener from '../../../../../com/vbee/antlr/ExprLangToJuliaListener.js'


var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties", global.version);

export class ValueDetails2ViewModel{
    constructor(model, options) {
        this.init(model, options);
    }
    htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
    cleanUp() {
        delete ko.bindingHandlers.weightNumeric;
        delete ko.bindingHandlers.numeric;
    }
    fillMeasurementUnits() {
        var self = this;
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        if (!currentPlan) {
            currentPlan = self.currentAlternativeModel.get('phaseAlternativeOwner').get('phaseOwner');
        }
        if (!currentPlan) {
            return;
        }
        var valueLibraries = currentPlan.get("valueLibrary").models;
        for(var i = 0;i< valueLibraries.length;i++){
            var library = valueLibraries[i];
            var units = library.get("unit").models;
            for(var j=0; j< units.length;j++){
                var unit = units[j];
                if (!self.measureUnits.findWhere({ 'name': unit.get('name') })) {
                    self.measureUnits.push({ id: '' +unit.get('id'), name: '' + unit.get('name'), roundingDigits: '' + unit.get('roundingDigits'), significantDecimals: '' + unit.get('significantDecimals') });
                }
            }
        }
    }
    static getInstance(model, options) {
        var view = new ValueDetails2ViewModel(model, options);
        return view;
    };

    getMeasurement(measuredChar, isCheckUncheckClicked) {
        var self = this;
        var mCharacteristic = null;
        if(measuredChar == "valueMeasurement"){
            mCharacteristic = self.model;
        }else{
            mCharacteristic = self.model.get(measuredChar);
        }

        var observedMeasure = self.scenario ? self.scenario.getObservedMeasureWithMeasurements(mCharacteristic, false, null) : null;
        if (!observedMeasure && isCheckUncheckClicked) {
            return null;
        }
        return  observedMeasure;
    };
    getJuliaExpr = function(){
        var self = this;
        const { ParseTreeWalker } = antlr4.tree;
        const input = self.formula();
        const chars = new antlr4.InputStream(input);
        const lexer = new ExprLangLexer(chars);
        const tokens = new antlr4.CommonTokenStream(lexer);
        const parser = new ExprLangParser(tokens);
        const tree = parser.program();
        // Create a generic parse tree walker that can trigger callbacks
        const walker = new ParseTreeWalker();

        // Walk the tree created during the parse and trigger callbacks
        const listener = new ExprLangToJuliaListener();
        walker.walk(listener, tree);

        // Get the result
        const juliaCode = listener.getResult();
        console.log(juliaCode); // Outputs the Julia code equivalent of the input
    }
    replaceIdsWithNames(expression, mappings) {
        mappings.forEach(mapping => {
            const regex = new RegExp(mapping.variableId, 'g');
            expression = expression.replace(regex, mapping.name);
        });
        return expression;
    }

    fillFormula(){
        var self = this;
        var valueDefinition = null;
        var formula = null;
        var context = self.model.getValueContext(self.currentAlternativeModel);
        if(context){
            formula = context.get("formula");
        }

        var valueDefinition = self.model.get("valueDefinition");
        if(valueDefinition && !formula){
            formula = valueDefinition.get("formula");
        }

        var exp = formula ? formula.get("expressionStr") : null;
        var agg  = self.expBuilderOptions.variables;

        const updatedExpression = self.replaceIdsWithNames(exp, agg);
        if(updatedExpression){
            self.formula(updatedExpression);
        }
    }

    fillQuantity() {
        var self = this;
        var valueDefinition = null;
        //var formula = null;
        self.showGradeOnly(false);
        /*var context = self.model.getValueContext(self.currentAlternativeModel);
        if(context){
            formula = context.get("formula");
        }*/

        var valueDefinition = self.model.get("valueDefinition");
        /*if(valueDefinition && !formula){
            formula = valueDefinition.get("formula");
        }
        var exp = formula ? formula.get("expressionStr") : null;
        if(exp){
            debugger
            self.expBuilderOptions.expression = exp;
            var valueInputExpression = self.expBuilder.getInput();
            self.formula(valueInputExpression);
        }*/
        
        var unit = self.model.get("unit");
        if(!unit && valueDefinition){
            unit = valueDefinition.get("unit");
        }
        

        var valUnit = valueDefinition ? valueDefinition.get('unit') : unit;
        if (valUnit) {
            self.measurementUnit(valUnit.get('name'));
            $('#measurementUnit' + self.encodeId).val(valUnit.get('name'));
            self.roundingDigits(valUnit.get('roundingDigits') ? valUnit.get('roundingDigits') : 4);
            self.significantDecimals(valUnit.get('significantDecimals'));
        }
        var valueMeasurement = self.periodDataset ? self.periodDataset.get(self.model.id): "";
        if(self.model.get("valueType") != ValueType.Atomic){
            valueMeasurement = self.calcPeriodDataset ? self.calcPeriodDataset.get(self.model.id): "";
        }
        self.measurementValue('' + window.utils.getRoundedValues(valueMeasurement, self.significantDecimals()));
        self.fullMeasurementValue = valueMeasurement;
    };

    checkCyclic(measurement, element, comp, del, usePrevious) {
        var self = this;
        if (!self.model) {
            return;
        }
        var cyclePath;
        if (measurement && self.getPrimedAggregationsCount() >= 1) {
            cyclePath = measurement.getCyclicPath();
            if (cyclePath && cyclePath.length > 0 && self.model.get('isIterative')) {
                self.isCyclic(true);
                self.maxChange(self.model.get('maxChange'));
            } else {
                self.isCyclic(false);
            }
        } else if (element && self.aggregateColl().length >= 1) {
            cyclePath = self.model.getCyclicPath(element);
            if (cyclePath && cyclePath.length > 0) {
                self.isCyclic(true);
                self.maxChange(self.model.get('maxChange'));
            } else {
                self.isCyclic(false);
            }
        }
    };
    clearValues(modelChange) {
        var self = this;
        self.satisfaction("");
        self.weight("");
        self.measurementValue("");
        if (modelChange) {
            self.aggregateColl([]);
            self.satisfactionIntervalColl([]);
            self.measurementUnit("");
            self.satisfactionUnitValue("");
            $('#satisfactionUnit' + self.encodeId).val('');
            $('#measurementUnit' + self.encodeId).val('');
            self.checkDimensional(true);
        }
    };
    getUniqueValue(val) {
        var self = this;
        var parentValCollection = self.options.addOptions ? self.options.addOptions.valuesColl : null;
        if (!parentValCollection && self.model) {
            var modalParent = self.model.getParent();
            var parentValBackboneCollection = modalParent.get('component') ? modalParent.get('component') : modalParent.get('valueAdd');
        }
        var activityId = null;
        if (self.selectedActivity()) {
            activityId = self.selectedActivity();
        }
        var id = self.options.modalId.split('_')[0];
        var unique = window.isUniqueName(val, parentValCollection, id, parentValBackboneCollection, activityId);
        var disallowed = false;
        if (unique && self.disallowedNames.includes(val)) {
            unique = false;
            disallowed = true;
        }
        var existedOneEdit = false;
        if (self.selectedActivity() && parentValCollection) {
            for (var i = 0; i < parentValCollection.length; i++) {
                if (parentValCollection[i].name === val.trim() && parentValCollection[i].modalId !== activityId) {
                    if (activityId) {
                        existedOneEdit = true;
                        break;
                    }

                }
            }
        }
        if (unique && self.selectedActivity() && !existedOneEdit) {
            var bm = self.options.parentView;
            if (!bm && self.options.addOptions.bm && self.options.addOptions.bm.get('type') === 'vdml_BusinessModel') {
                bm = self.options.addOptions.bm;
            }
            if(bm && bm.get('bmActivity')){
                bm.get('bmActivity').each(function (bmActivity) {
                    var activity = bmActivity.get('activity');
                    if (self.htmlEscape(activity.get('id')) == activityId) {
                        activity.get('containedPort').each(function (port) {
                            if (port.get('type') === "vdml_OutputPort") {
                                port.get('valueAdd').each(function (actValue) {
                                    if (actValue.get('name') === val.trim() && actValue.id === id) {
                                        unique = false;
                                    }
                                })
                            }
                        });
                    }
                });
            }
        }
        if (unique) {
            return false;
        }
        else {
            var valueType = "";
            if (self.options.addOptions.type.toLowerCase() === "valueProposition".toLowerCase()) {
                valueType = self.valuePropositionName;
            }
            else if (self.options.addOptions.type.toLowerCase() === "planValues".toLowerCase()) {
                valueType = "Plan";
            }
            else if (self.options.addOptions.type.toLowerCase() === "valueFormula".toLowerCase()) {
                valueType = self.myPropositionName;
            }
            else {
                valueType = self.activityName;
            }
            $('#message' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get('DuplicateValueMessage1', valueType));
            if (disallowed) {
                $('#message' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get('DuplicateValueMessage2'));
            }
            return true;
        }
    };
    clearTuples(type) {
        var self = this;
        var index;
        for (var j = 0; j < self.satisfactionIntervalColl().length; j++) {
            for (var i = 0; i < self.intervalTuples.length; i++) {
                if (self.intervalTuples[i].new) {
                    if (self.satisfactionIntervalColl()[j].seqNo == self.intervalTuples[i].new.seqNo) {
                        index = i;
                        var tempView = self.satisfactionIntervalColl()[j];
                        if (tempView) {
                            self.satisfactionIntervalColl.replace(self.satisfactionIntervalColl()[j], tempView);
                            self.satisfactionIntervalColl.destroy(self.satisfactionIntervalColl()[j]);
                        }
                        if (self.intervalTuples[index].new.id !== "") {
                            var obj = {};
                            if (type === "smm_GradeMeasure") {
                                obj.old = { seqNo: self.intervalTuples[index].old.seqNo, id: self.intervalTuples[index].old.id, value: self.intervalTuples[index].old.value, minimumEndPoint: self.intervalTuples[index].old.minimumEndPoint, maximumEndPoint: self.intervalTuples[index].old.maximumEndPoint, maximumOpen: self.intervalTuples[index].old.maximumOpen, minimumOpen: self.intervalTuples[index].old.minimumOpen };
                            }
                            else {
                                obj.old = { seqNo: self.intervalTuples[index].old.seqNo, id: self.intervalTuples[index].old.id, symbol: self.intervalTuples[index].old.symbol, minimumEndPoint: self.intervalTuples[index].old.minimumEndPoint, maximumEndPoint: self.intervalTuples[index].old.maximumEndPoint, maximumOpen: self.intervalTuples[index].old.maximumOpen, minimumOpen: self.intervalTuples[index].old.minimumOpen };
                            }
                            self.intervalTuples.push(obj);
                            self.intervalTuples.splice(index, 1);
                        }
                        else {
                            self.intervalTuples.splice(index, 1);
                        }
                    }
                }

            }
        }
        self.sortSatisfactionIntervals();
    };
    getComponentMeasurement(component, mc, useScenario) {
        var self = this;
        var measurement = null;
        var mCharacteristic = component.get(mc);
        var observedMeasure = useScenario.getObservedMeasureWithMeasurements(mCharacteristic, null);

        if (observedMeasure) {
            measurement = observedMeasure.get('measurements').at(0);
        }
        return measurement;
    };
    checkAggregationInBm(component) {
        var self = this;
        var compExists = true;
        var bm = self.parentView;
        if (self.options.addOptions.bm && self.options.addOptions.bm.get('type') === 'vdml_BusinessModel' && !bm.get('bmRole')) {
            bm = self.options.addOptions.bm;
        }
        if (component.get('type') === "vdml_ValueAdd") {
            var actValue = false;
            var activities = bm.get('bmActivity') ? bm.get('bmActivity') : [];
            for (var i = 0; i < activities.length; i++) {
                if (activities.at(i).get('activityValue').findWhere({ 'id': component.get('id') })) {
                    actValue = true;
                    break;
                }
            }
            if (!actValue) {
                compExists = false;
            }
        } else {
            var compOwner = component.get('componentOwner');
            var providerRole;
            if (compOwner) {
                providerRole = component.get('componentOwner').get('provider');
            }
            if (providerRole && !bm && !bm.get('bmRole').findWhere({ 'id': providerRole.get('id') })) {
                compExists = false;
            }
        }
        return compExists;
    };
    checkAggregationFiltering(type, component) {
        var self = this;
        var validAggregation = true;
        var bm = null;
        if (self.parentView && self.parentView.get('type') === 'vdml_BusinessModel') {
            bm = self.parentView;
        }
        if (!bm && self.options.addOptions && self.options.addOptions.bm && self.options.addOptions.bm.get('type') === 'vdml_BusinessModel') {
            bm = self.options.addOptions.bm;
        }
        if (!self.checkIsPlanValue(component.get('id'))) {
            if (!self.currentAlternativeModel.get('phaseDesignPart').findWhere({ 'beepReference': component.getNestedParent().get('id') })) {
                return false;
            }
            if (self.options.addOptions.collaboration && self.options.addOptions.mainScenario) {
                var currentPackage = self.options.addOptions.mainScenario.getNestedParent();
                var packageStoredAltId = window.utils.getPrefix(self.options.addOptions.collaboration.get('id'));
                if (packageStoredAltId !== DataManager.getDataManager().getRepositoryId(currentPackage.get('id'))) {
                    return false;
                }
            }

        }
        if (!self.planValue && !self.checkIsPlanValue(component.get('id'))) {
            validAggregation = self.checkAggregationInBm(component);
            if (!validAggregation) {
                return validAggregation;
            }
        }
        var activitiesValues = self.options.addOptions.activitiesValues;
        if (type === "contributeWhatValues") {
            var actValueExists = false;
            if (component.get('type') === 'vdml_ValueAdd') {
                for (var j = 0; j < activitiesValues.length; j++) {
                    if (activitiesValues[j].originalId === component.get('id')) {
                        actValueExists = true;
                        break;
                    }
                }
            }
            else {
                var activityValueProposition = self.options.addOptions.activityValueProposition;
                if (activityValueProposition && activityValueProposition.get('recipient')) {
                    var receivedProp = activityValueProposition.get('recipient').get('providedProposition');
                    for (var i = 0; i < receivedProp.length; i++) {
                        if (receivedProp.at(i).get('component').findWhere({ 'id': component.get('id') })) {
                            actValueExists = true;
                            break;
                        }
                    }
                }
            }
            validAggregation = actValueExists;
        }
        else if (type === "createWhatValues") {
            if (component.get('type') === 'vdml_ValuePropositionComponent') {
                if (self.checkIsPlanValue(component.get('id'))) {
                    validAggregation = true;
                }
                else if (component.get('componentOwner').get('isValueFormula')) {
                    validAggregation = false;
                }
                else {
                    var valueprops = bm ? bm.get('bmValueProposition') : [];
                    var vpInBm = false;
                    for (var i = 0; i < valueprops.length; i++) {
                        var providedProposition = valueprops.at(i).get('valueProposition');
                        if (providedProposition.get('component').findWhere({ 'id': component.get('id') })) {
                            vpInBm = true;
                            break;
                        }
                    }
                    if (!vpInBm) {
                        validAggregation = false;
                    }
                }
            }

        }
        else if (type === "valueProposition") {
            if (component.get('type') === 'vdml_ValuePropositionComponent') {
                if (component.get('componentOwner').get('isValueFormula')) {
                    validAggregation = false;
                }
            }
            else {
                var actValExists = false;
                var bmActs = bm ? bm.get('bmActivity') : [];
                for(var x=0; x < bmActs.length; x++){
                    var activityExists = bmActs.at(x).get('activityValue').findWhere({'id':component.get('id')});
                    if(activityExists){
                        actValExists = true;
                        break;
                    }
                }
                validAggregation = actValExists;
            }
        }
        else if (type === "valueFormula") {
            if (!self.checkIsPlanValue(component.get('id')) && component.get('type') === 'vdml_ValuePropositionComponent' && !component.get('componentOwner').get('isValueFormula')) {
                var compOwner = component.get('componentOwner');
                var businessRoles = bm ? bm.getBusinessRoles() : [];
                var businessValprop = false;
                for (var i = 0; i < businessRoles.length; i++) {
                    if (businessRoles[i] === compOwner.get('recipient') || businessRoles[i] === compOwner.get('provider')) {
                        businessValprop = true;
                        break;
                    }
                }
                validAggregation = businessValprop;
            } else if (component.get('type') === 'vdml_ValueAdd') {
                var actValExists = false;
                var bmActs = bm ? bm.get('bmActivity') : [];
                for(var x=0; x < bmActs.length; x++){
                    var activityExists = bmActs.at(x).get('activityValue').findWhere({'id':component.get('id')});
                    if(activityExists){
                        actValExists = true;
                        break;
                    }
                }
                validAggregation = actValExists;
            }
        }
        if (validAggregation) {
            validAggregation = !self.checkAggComponentSatisfactionOnly(component);
        }
        return validAggregation;
    };
    checkAggComponentSatisfactionOnly(component) {
        var self = this;
        var isCompSatisfaction = false;
        var scenario = self.currentAlternativeModel.getMainScenario(component.getNestedParent());
        if (scenario) {
            var compSatisfactionMeasurement = self.getComponentMeasurement(component, 'satisfactionLevel', scenario);
            if (compSatisfactionMeasurement && compSatisfactionMeasurement.get('observedMeasure')) {
                var compSatisfactionMeasure = compSatisfactionMeasurement.get('observedMeasure').get('measure');
                if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('measureRelationships').length > 0) {
                    isCompSatisfaction = true;
                }
            }
        }
        return isCompSatisfaction;
    };

    calculateValue(measurementValues, wtArray) {
        var self = this;
        var measurementValue = '';
        return measurementValue;
    }

    calculateMeasurentValues() {
        var self = this;
        if (self.postShow && !self.readOnly) {
            var measurementValueArray = [], wtValuesArray = [];
            var measurementValue = '';
            var measurement = null;
            var count = 0;
            for (var i = 0; i < self.aggregateColl().length; i++) {
                var component = self.fetchComponentById(self.aggregateColl()[i].aggregateid);
                if (!self.aggregateColl()[i]._destroy && component) {
                    count += 1;
                    var useScenario = self.scenario;
                    useScenario = self.currentAlternativeModel.getMainScenario(component.getNestedParent());
                    if (self.checkIsPlanValue(self.aggregateColl()[i].aggregateid)) {
                        useScenario = self.fetchPlanScenario();
                    }
                    var aggValueMeasurement = useScenario ? self.getComponentMeasurement(component, 'valueMeasurement', useScenario) : null;
                    var satMeasurement;
                    measurement = aggValueMeasurement;
                    var mValue = 0, satValue = 0;
                    if (measurement) {
                        mValue = measurement.get('value');
                        if (mValue !== '') {
                            measurementValueArray.push(mValue);
                        }
                    }
                } else {
                    var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                    if (tempValuesForAggregation) {
                        for (var j = 0; j < tempValuesForAggregation.length; j++) {
                            if (tempValuesForAggregation[j].id === self.aggregateColl()[i].aggregateid) {
                                mValue = tempValuesForAggregation[j].value;
                                if (mValue != '') {
                                    measurementValueArray.push(mValue);
                                }
                                break;
                            }
                        }
                    }
                }
            }
            if (self.aggregateColl().length > 0) {
                if (self.model) {
                    var mValue = self.calculateValue(measurementValueArray, wtValuesArray);
                    measurementValue = measurementValue + mValue;
                        count = window.utils.getObservableLength(self.aggregateColl());
                        self.tempMeasurementRoundingValue = window.utils.getRoundedValues(measurementValue, self.roundingDigits());
                        self.measurementValue('' + window.utils.getRoundedValues(measurementValue, self.significantDecimals()));
                }
                else if (!self.model) {
                    measurementValue = measurementValue ? measurementValue : "";
                        self.tempMeasurementRoundingValue = window.utils.getRoundedValues(measurementValue, self.roundingDigits());
                        self.measurementValue('' + window.utils.getRoundedValues(measurementValue, self.significantDecimals()));
                }
                if (count == 0) {
                    self.enableMeasurement(true);
                    self.trendModelDiv(true);
                }
                else {
                    self.enableMeasurement(false);
                    self.trendModelDiv(false);
                }
            }
        }
    }
    calculateSatisfaction() {
        var self = this;
        if (self.measurementValue() !== "") {
            var intervalFound = false;
            for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                var interval = self.satisfactionIntervalColl()[i];
                if (!interval._destroy) {
                    var frmInc = interval.fromInclusive ? parseFloat(interval.intervalFrom) <= parseFloat(self.measurementValue()) : parseFloat(interval.intervalFrom) < parseFloat(self.measurementValue());
                    var toInc = interval.toInclusive ? parseFloat(self.measurementValue()) <= parseFloat(interval.intervalTo) : parseFloat(self.measurementValue()) < parseFloat(interval.intervalTo);
                    if (frmInc && toInc) {
                        self.satisfaction(interval.intervalName);
                        $("#showSatisfaction" + self.encodeId).show();
                        $("#showSatisfaction" + self.encodeId).html('' + interval.intervalName);
                        var smileySrc = self.fetchSmileySrc(interval.smiley);
                        $("#showSatisfactionImg" + self.encodeId).attr('d', smileySrc);
                        var smileyColor = interval.smileyColor ? interval.smileyColor : 'rgb(0,0,0)';
                        $("#showSatisfactionImg" + self.encodeId).attr('fill', smileyColor);
                        intervalFound = true;
                        break;
                    }
                }
            }
            if (!intervalFound) {
                self.satisfaction('');
                $("#showSatisfaction" + self.encodeId).html('');
                $("#showSatisfactionImg" + self.encodeId).attr('d', '');
            }
        }
        if (self.satisfactionOnly()) {
            var smiley;
            var smileyColor;
            for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                if (self.satisfactionIntervalColl()[i].intervalName === self.gradeSatisfaction() && !self.satisfactionIntervalColl()[i]._destroy) {
                    smiley = self.satisfactionIntervalColl()[i].smiley;
                    smileyColor = self.satisfactionIntervalColl()[i].smileyColor;
                    break;
                }
            }
            var smileySrc = self.fetchSmileySrc(smiley);
            $("#showSelectSatisfactionImg" + self.encodeId).attr('d', smileySrc);
            $("#showSelectSatisfactionImg" + self.encodeId).attr('fill', smileyColor);
        }
    };
    constructBaseTuple(seqNo, originalId, component, existing) {
        var self = this;
        var obj = {};
        obj.new = { seqNo: seqNo, id: originalId, component: component, existing: existing};
        if (existing) {
            obj.old = { seqNo: seqNo, id: originalId, component: component, existing: existing };
        }
        self.baseTuples.push(obj);
    }
    editBaseTuple(seqNo, originalId, component, existing) {
        var self = this;
        for (var i = 0; i < self.baseTuples.length; i++) {
            if (existing && self.baseTuples[i].new && self.baseTuples[i].new.id == originalId) {

            }
            else if (self.baseTuples[i].new && self.baseTuples[i].new.seqNo == seqNo) {
            }
        }
    }
    deleteBaseTuple(seqNo, originalId, component, existing) {
        var self = this;
        for (var i = 0; i < self.baseTuples.length; i++) {
            if (existing && self.baseTuples[i].new && self.baseTuples[i].new.id == originalId) {
                var obj = {};
                obj.old = { seqNo: self.baseTuples[i].old.seqNo, id: self.baseTuples[i].old.id, component: self.baseTuples[i].old.component, existing: self.baseTuples[i].old.existing };
                self.baseTuples.push(obj);
                self.baseTuples.splice(i, 1);
            }
            else if (self.deleteTuple && self.baseTuples[i].new && self.baseTuples[i].new.seqNo == seqNo) {
                self.baseTuples.splice(i, 1);
            }
        }
    }

    constructSatisfactionIntervalTuple(seqNo, originalId, intervalName, intervalFrom, intervalTo, minOpen, maxOpen, smiley, smileyColor, correctiveActions) {
        var self = this;
        if (intervalFrom == '-Infinity') {
            intervalFrom = -Number.MAX_VALUE;
        }
        if (intervalTo == 'Infinity') {
            intervalTo = Number.MAX_VALUE;
        }
        if (self.satisfactionType() === "smm_GradeMeasure") { //Grade Measure
            var obj = {};
            obj.new = { seqNo: seqNo, id: originalId, symbol: intervalName, minimumEndPoint: intervalFrom, maximumEndPoint: intervalTo, maximumOpen: maxOpen, minimumOpen: minOpen, smiley: smiley, smileyColor: smileyColor, correctiveActions: correctiveActions };
            if (originalId !== "") {
                obj.old = { seqNo: seqNo, id: originalId, symbol: intervalName, minimumEndPoint: intervalFrom, maximumEndPoint: intervalTo, maximumOpen: maxOpen, minimumOpen: minOpen, smiley: smiley, smileyColor: smileyColor, correctiveActions: correctiveActions };
            }
            self.intervalTuples.push(obj);
        }
        else { //Ranking Measure
            var obj = {};
            obj.new = { seqNo: seqNo, id: originalId, value: intervalName, minimumEndPoint: intervalFrom, maximumEndPoint: intervalTo, maximumOpen: maxOpen, minimumOpen: minOpen, smiley: smiley, smileyColor: smileyColor, correctiveActions: correctiveActions };
            if (originalId !== "") {
                obj.old = { seqNo: seqNo, id: originalId, value: intervalName, minimumEndPoint: intervalFrom, maximumEndPoint: intervalTo, maximumOpen: maxOpen, minimumOpen: minOpen, smiley: smiley, smileyColor: smileyColor, correctiveActions: correctiveActions };
            }
            self.intervalTuples.push(obj);
        }
    };

    editSatisfactionIntervalTuple(seqNo, originalId, intervalName, intervalFrom, intervalTo, minOpen, maxOpen, smiley, smileyColor, correctiveActions) {
        var self = this;
        for (var i = 0; i < self.intervalTuples.length; i++) {
            if (intervalFrom == '-Infinity') {
                intervalFrom = -Number.MAX_VALUE;
            }
            if (intervalTo == 'Infinity') {
                intervalTo = Number.MAX_VALUE;
            }
            if (self.satisfactionType() === "smm_GradeMeasure" && self.intervalTuples[i].new) { //Grade Measure
                // Already Existed tuple edit
                if (originalId !== "" && self.intervalTuples[i].new.id === originalId && (self.intervalTuples[i].new.smiley !== smiley || self.intervalTuples[i].new.smileyColor !== smileyColor || self.intervalTuples[i].new.correctiveActions !== correctiveActions || self.intervalTuples[i].new.symbol !== intervalName || self.intervalTuples[i].new.minimumEndPoint !== intervalFrom || self.intervalTuples[i].new.maximumEndPoint !== intervalTo || self.intervalTuples[i].new.minimumOpen !== minOpen || self.intervalTuples[i].new.maximumOpen !== maxOpen)) {
                    self.intervalTuples[i].new.symbol = intervalName;
                    self.intervalTuples[i].new.minimumEndPoint = intervalFrom;
                    self.intervalTuples[i].new.maximumEndPoint = intervalTo;
                    self.intervalTuples[i].new.maximumOpen = maxOpen;
                    self.intervalTuples[i].new.minimumOpen = minOpen;
                    self.intervalTuples[i].new.smiley = smiley;
                    self.intervalTuples[i].new.smileyColor = smileyColor;
                    self.intervalTuples[i].new.correctiveActions = correctiveActions;
                }
                //New Tuple edit
                else if ((self.intervalTuples[i].new.seqNo == seqNo) && (self.intervalTuples[i].new.smiley !== smiley || self.intervalTuples[i].new.smileyColor !== smileyColor || self.intervalTuples[i].new.correctiveActions !== correctiveActions || self.intervalTuples[i].new.symbol !== intervalName || self.intervalTuples[i].new.minimumEndPoint !== intervalFrom || self.intervalTuples[i].new.maximumEndPoint !== intervalTo || self.intervalTuples[i].new.minimumOpen !== minOpen || self.intervalTuples[i].new.maximumOpen !== maxOpen)) {
                    self.intervalTuples[i].new.symbol = intervalName;
                    self.intervalTuples[i].new.minimumEndPoint = intervalFrom;
                    self.intervalTuples[i].new.maximumEndPoint = intervalTo;
                    self.intervalTuples[i].new.maximumOpen = maxOpen;
                    self.intervalTuples[i].new.minimumOpen = minOpen;
                    self.intervalTuples[i].new.smiley = smiley;
                    self.intervalTuples[i].new.smileyColor = smileyColor;
                    self.intervalTuples[i].new.correctiveActions = correctiveActions;
                }
            }
            else if (self.intervalTuples[i].new) { //Ranking Measure
                // Already Existed tuple edit
                if (originalId !== "" && (self.intervalTuples[i].new.id === originalId) && (self.intervalTuples[i].new.smiley !== smiley || self.intervalTuples[i].new.smileyColor !== smileyColor || self.intervalTuples[i].new.correctiveActions !== correctiveActions || self.intervalTuples[i].new.value !== intervalName || self.intervalTuples[i].new.minimumEndPoint !== intervalFrom || self.intervalTuples[i].new.maximumEndPoint !== intervalTo || self.intervalTuples[i].new.minimumOpen !== minOpen || self.intervalTuples[i].new.maximumOpen !== maxOpen)) {
                    self.intervalTuples[i].new.value = intervalName;
                    self.intervalTuples[i].new.minimumEndPoint = intervalFrom;
                    self.intervalTuples[i].new.maximumEndPoint = intervalTo;
                    self.intervalTuples[i].new.maximumOpen = maxOpen;
                    self.intervalTuples[i].new.minimumOpen = minOpen;
                    self.intervalTuples[i].new.smiley = smiley;
                    self.intervalTuples[i].new.smileyColor = smileyColor;
                    self.intervalTuples[i].new.correctiveActions = correctiveActions;
                }
                //New Tuple edit
                else if ((self.intervalTuples[i].new.seqNo == seqNo) && (self.intervalTuples[i].new.smiley !== smiley || self.intervalTuples[i].new.smileyColor !== smileyColor || self.intervalTuples[i].new.correctiveActions !== correctiveActions || self.intervalTuples[i].new.value !== intervalName || self.intervalTuples[i].new.minimumEndPoint !== intervalFrom || self.intervalTuples[i].new.maximumEndPoint !== intervalTo || self.intervalTuples[i].new.minimumOpen !== minOpen || self.intervalTuples[i].new.maximumOpen !== maxOpen)) {
                    self.intervalTuples[i].new.value = intervalName;
                    self.intervalTuples[i].new.minimumEndPoint = intervalFrom;
                    self.intervalTuples[i].new.maximumEndPoint = intervalTo;
                    self.intervalTuples[i].new.maximumOpen = maxOpen;
                    self.intervalTuples[i].new.minimumOpen = minOpen;
                    self.intervalTuples[i].new.smiley = smiley;
                    self.intervalTuples[i].new.smileyColor = smileyColor;
                    self.intervalTuples[i].new.correctiveActions = correctiveActions;
                }
            }
        }
    }
    deleteSatisfactionIntervalTuple(seqNo, originalId, intervalName, intervalFrom, intervalTo, minOpen, maxOpen) {
        var self = this;
        for (var i = 0; i < self.intervalTuples.length; i++) {
            if (self.satisfactionType() === "smm_GradeMeasure" && self.intervalTuples[i].new) { //Grade Measure
                // Already Existed tuple delete
                if (originalId !== "" && self.intervalTuples[i].new.id === originalId) {
                    var obj = {};
                    obj.old = { seqNo: self.intervalTuples[i].old.seqNo, id: self.intervalTuples[i].old.id, symbol: self.intervalTuples[i].old.symbol, minimumEndPoint: self.intervalTuples[i].old.minimumEndPoint, maximumEndPoint: self.intervalTuples[i].old.maximumEndPoint, maximumOpen: self.intervalTuples[i].old.maximumOpen, minimumOpen: self.intervalTuples[i].old.minimumOpen };
                    self.intervalTuples.push(obj);
                    self.intervalTuples.splice(i, 1);
                }
                else if (self.intervalTuples[i].new && self.intervalTuples[i].new.seqNo === seqNo) {//New Tuple delete
                    self.intervalTuples.splice(i, 1);
                }
            }
            else if (self.intervalTuples[i].new) { //Ranking Measure
                // Already Existed tuple delete
                if (originalId !== "" && self.intervalTuples[i].new.id === originalId) {
                    var obj = {};
                    obj.old = { seqNo: self.intervalTuples[i].old.seqNo, id: self.intervalTuples[i].old.id, value: self.intervalTuples[i].old.value, minimumEndPoint: self.intervalTuples[i].old.minimumEndPoint, maximumEndPoint: self.intervalTuples[i].old.maximumEndPoint, maximumOpen: self.intervalTuples[i].old.maximumOpen, minimumOpen: self.intervalTuples[i].old.minimumOpen };
                    self.intervalTuples.push(obj);
                    self.intervalTuples.splice(i, 1);
                }
                else if (self.intervalTuples[i].new && self.intervalTuples[i].new.seqNo === seqNo) {//New Tuple edit
                    self.intervalTuples.splice(i, 1);
                }
            }
        }
    }
    checkComponentPrimed(component) {
        var self = this;
        //var scenario = self.currentAlternativeModel.getMainScenario(component.getNestedParent());
        //var obsMeasure = scenario ? scenario.getObservedMeasureWithMeasurements(component.get('valueMeasurement'), null) : null;
        if (self.periodDataset && self.periodDataset.get(component.id)) {
            return true;
        } else {
            return false;
        }
    };

    fetchSmileySrc(smileyValue) {
        var self = this;
        //var smileyValue = smileyValue?smileyValue:'1';
        var smileySrc = '';
        for (var j = 0; j < self.smilies().length; j++) {
            if (self.smilies()[j].value === smileyValue) {
                smileySrc = self.smilies()[j].src;
                break;
            }
        }
        return smileySrc;
    };

    fillValues(valueMeasurement, satisfactionMeasurement, weightMeasurement, recipientMeasurement, showAggreagations) {
        var self = this;
        self.fillQuantity();
        if (!valueMeasurement && !satisfactionMeasurement) {
            //self.clearValues();
        }
        else {
            //valueMeasurement && self.fillQuantity(valueMeasurement);
            valueMeasurement && self.fillValueMeasurement(valueMeasurement);
            if (self.options.addOptions.type !== "createWhatValues" && satisfactionMeasurement) {
                self.visibleSatisfactionType(true);
                self.satisfactionMeasure = satisfactionMeasurement.get('observedMeasure').get('measure');
                self.satisfactionType(self.satisfactionMeasure.get('type'));
                if (self.satisfactionMeasure.get('type') === "smm_GradeMeasure") {
                    self.isDimensional = self.satisfactionMeasure.get('measureRelationships').length > 0;
                    if (!self.isDimensional) {
                        self.checkDimensional(false);
                        self.showDimensional(true);

                    }
                }
                else {
                    if (self.satisfactionMeasure.get('type') === "smm_RankingMeasure") {
                        var unitName = "";
                        var unit = self.satisfactionMeasure.get('unit');
                        if (unit) {
                            unitName = unit.get('name');
                            self.satRoundingDigits(unit.get('roundingDigits') ? unit.get('roundingDigits') : 4);
                            self.satSignificantDecimals(unit.get('significantDecimals') ? unit.get('significantDecimals') : 2);
                        }
                        self.satisfactionUnitValue(unitName);
                        $('#satisfactionUnit' + self.encodeId).val(unitName);
                        self.isDimensional = !!(self.satisfactionMeasure.get('rankingTo'));
                        if (self.isDimensional) {
                            self.checkDimensional(true);
                            self.showDimensional(false);
                        }
                    }
                }
                if (satisfactionMeasurement.get('symbol')) {
                    self.satisfaction(satisfactionMeasurement.get('symbol'));
                } else {
                    self.satisfaction(window.utils.getRoundedValues(satisfactionMeasurement.get('value'), self.satSignificantDecimals()));
                }

                self.satisfactionIntervalColl([]);
                self.satisfactionMeasure.get('interval').each(function (interval) {
                    var name = interval.get('symbol') ? interval.get('symbol') : window.utils.getRoundedValues(interval.get('value'), self.satSignificantDecimals());
                    var satValue = name;
                    if (self.measurementValue() === "") {
                        satValue = "";
                        self.satisfaction("");
                    }
                    var intervalFrom = interval.get('minimumEndPoint');
                    var intervalTo = interval.get('maximumEndPoint');
                    if (intervalTo == Number.MAX_VALUE) {
                        intervalTo = 'Infinity';
                    }
                    if (intervalFrom == -Number.MAX_VALUE) {
                        intervalFrom = '-Infinity';
                    }
                    var smiley = interval.get('smiley') ? interval.get('smiley') : '';
                    var smileyColor = interval.get('smileyColor') ? interval.get('smileyColor') : 'rgb(0,0,0)';
                    var smileySrc = self.fetchSmileySrc(smiley);
                    var correctiveActions = interval.get('correctiveActions') ? interval.get('correctiveActions') : '';
                    self.satisfactionIntervalColl.push({ seqNo: '' + self.satisfactionIntervalColl().length, originalId: interval.get('id'), intervalName: name, satisfactionValue: satValue, intervalFrom: intervalFrom, intervalTo: intervalTo, fromInclusive: interval.get('minimumOpen'), toInclusive: interval.get('maximumOpen'), smiley: smiley, smileyColor: smileyColor, smileySrc: smileySrc, correctiveActions: correctiveActions, uniqueId: window.utils.htmlEscape(interval.get('id')) });
                    self.constructSatisfactionIntervalTuple('' + self.satisfactionIntervalColl().length - 1, interval.get('id'), name, interval.get('minimumEndPoint'), interval.get('maximumEndPoint'), interval.get('minimumOpen'), interval.get('maximumOpen'), smiley, smileyColor, correctiveActions);
                });
                self.sortSatisfactionIntervals();
                if (self.postShow) {
                    self.calculateSatisfaction();
                }
            }
            if (self.options.addOptions.type !== "createWhatValues" && weightMeasurement) {
                var weight;
                if (weightMeasurement) {
                    weight = weightMeasurement.get('value');
                }

                if (weight && weight !== "") {
                    self.weight(parseFloat(weight));
                }
                else {
                    self.weight(weight);
                }
            }
            if (recipientMeasurement) {
                if (self.checkDimensional()) {
                    var recipientVal = recipientMeasurement.get('value');
                    self.recipientValue(window.utils.getRoundedValues(recipientVal, self.significantDecimals()));
                }
                else {
                    self.recipientSatValue(recipientMeasurement.get('symbol'));
                }
            }
            if (!self.isDimensional && self.satisfactionMeasure.get('type') === "smm_GradeMeasure") {
                self.gradeSatisfaction(satisfactionMeasurement.get('symbol'));
            }
            self.enableCommon(true);
        }
        if (showAggreagations) {
            var valueModal = self.model;
            if (self.existingValueModal && !valueModal) {
                valueModal = self.existingValueModal;
            }
            var components = valueModal.get('aggregatedFrom');
            var componentAddedCount = 0;
            //var primedComponentCount = 0;
            components.each(function (component) {
                var showComp = false;
                if (self.checkIsPlanValue(component.get('id'))) {
                    showComp = true;
                } else if (self.currentAlternativeModel.get('phaseDesignPart').findWhere({ 'beepReference': component.getNestedParent().get('id') })) {
                    showComp = true;
                }
                if (self.readOnly) {//for R.H.S
                    showComp = true;
                }
                if ((valueModal === component && !self.currentAlternativeModel.getPreviousPhasePrimary()) || (valueMeasurement && valueMeasurement.get('type') == 'smm_DirectMeasurement')) {
                    showComp = false;
                }
                if (showComp) {
                    componentAddedCount++;
                    var componentAdded = false;
                    var componentPrimed = self.checkComponentPrimed(component);
                    if (componentPrimed) {
                        self.aggregateColl.push({ seqNo: '' + self.aggregateColl().length, escapeAggId: self.htmlEscape(component.id), originalId: component.id, aggregateid: component.id, aggregateValue: component.get('name'), aggregateSource: self.getSourceName(component), aggregateType: self.getSourceType(component), componentPrimed: componentPrimed });
                        self.expBuilderOptions.variables.push({variableId: component.id, name:component.get('name')})
                        self.constructBaseTuple('' + self.aggregateColl().length - 1, component.id, component, true);
                        componentAdded = true;
                    }
                    /*else {
                        var parentValueMeasure;
                        var scenario;
                        var obsMeasure;
                        var measure;
                        if (valueMeasurement) {
                            scenario = self.currentAlternativeModel.getMainScenario(component.getNestedParent());
                            obsMeasure = scenario ? scenario.getObservedMeasureWithMeasurements(component.get('valueMeasurement'), null) : null;
                            measure = obsMeasure ? obsMeasure.get('measure') : null;
                            parentValueMeasure = valueMeasurement.get('observedMeasure').get('measure');
                        } else {
                            var prevAlt = self.currentAlternativeModel.getPreviousPhasePrimary();
                            if (prevAlt) {
                                scenario = prevAlt.getMainScenario(valueModal.getNestedParent());
                                var childScenario = prevAlt.getMainScenario(component.getNestedParent());
                                if (childScenario) {
                                    obsMeasure = childScenario.getObservedMeasureWithMeasurements(component.get('valueMeasurement'), null);
                                }
                                measure = obsMeasure ? obsMeasure.get('measure') : null;
                                if (scenario) {
                                    var prevObsMes = scenario.getObservedMeasureWithMeasurements(valueModal.get('valueMeasurement'));
                                }
                                if (prevObsMes) {
                                    parentValueMeasure = prevObsMes.get('measure');
                                }
                            }
                        }
                        var componentPrimed = self.checkComponentPrimed(component);
                        if (componentPrimed) {
                            componentAddedCount--;
                            return;//no relationship created (phase 1 special condition with use previous)
                        }
                        self.aggregateColl.push({ seqNo: '' + self.aggregateColl().length, escapeAggId: self.htmlEscape(component.id), originalId: component.id, aggregateid: component.id, aggregateValue: component.get('name'), aggregateSource: self.getSourceName(component), aggregateType: self.getSourceType(component), componentPrimed: componentPrimed });
                        self.expBuilderOptions.variables.push({variableId: component.id, name:component.get('name')})
                        self.constructBaseTuple('' + self.aggregateColl().length - 1, component.id, component, measure, true);
                        //self.filterAggregateOptions(component.id);
                        componentAdded = true;
                    }*/
                    if (!componentAdded) {
                        self.aggregateColl.push({ seqNo: '' + self.aggregateColl().length, escapeAggId: self.htmlEscape(component.id), originalId: component.id, aggregateid: component.id, aggregateValue: component.get('name'), aggregateSource: self.getSourceName(component), aggregateType: self.getSourceType(component), componentPrimed: false });
                        self.expBuilderOptions.variables.push({variableId: component.id, name:component.get('name')})
                        self.constructBaseTuple('' + self.aggregateColl().length - 1, component.id, component, true);
                    }
                }
            });
            self.aggregateColl.sort(function (left, right) {
                return left.aggregateValue.toLowerCase() == right.aggregateValue.toLowerCase() ? 0 : (left.aggregateValue.toLowerCase() < right.aggregateValue.toLowerCase() ? -1 : 1)
            });
            if (componentAddedCount > 0) {
                self.enableMeasurement(false);
                self.enableSatisfactionOnly(false);
                self.trendModelDiv(false);
                self.showTable(false);
            }
        }
        if (satisfactionMeasurement && satisfactionMeasurement.get('type') === "smm_RankingMeasurement") {
            var baseNMeasurementSatRelationship = satisfactionMeasurement.get('baseNMeasurementRelationship');
            baseNMeasurementSatRelationship.each(function (relationship) {
                var toCompMeasurement = relationship.get('from');
                var toComp = null;
                if (toCompMeasurement.get('measuredCharacteristic') != null) {
                    toComp = toCompMeasurement.get('measuredCharacteristic').getParent();
                }
                var type = self.getSourceType(toComp) ? self.getSourceType(toComp) : "";
                var aggregateToValue = toComp.get('name');
                var usePreviousRelation = false;
                var altId = null;
                self.aggregateToColl.push({ seqNo: '' + self.aggregateToColl.length, originalId: toComp.get('id'), aggregateToValue: aggregateToValue, aggregateToSource: self.getSourceName(toComp), aggregateToType: type, usePreviousRelation: usePreviousRelation, componentPrimed: true, altId: altId });
            });
        }
        var baseNMeasurementRelationship = (valueMeasurement && valueMeasurement.get) ? valueMeasurement.get('baseNMeasurementRelationship') : null;
        if (baseNMeasurementRelationship && baseNMeasurementRelationship.length > 0) {
            var aggregateToCollArray = [];
            baseNMeasurementRelationship.each(function (relationship) {
                var toCompMeasurement = relationship.get('from');
                var usePreviousRelation = false;
                var toMc = toCompMeasurement.get('measuredCharacteristic');
                var toComp = toMc?toMc.getParent():null;
                if(toComp){
                    var altId = null;
                    var type = self.getSourceType(toComp) ? self.getSourceType(toComp) : "";
                    var aggregateToValue = toComp.get('name');
                    var found = _.filter(aggregateToCollArray, function (val) { if (val.originalId === toComp.get('id') && val.usePreviousRelation == usePreviousRelation && val.altId === altId) { return val } });
                    if (found && found.length == 0) {
                        aggregateToCollArray.push({ seqNo: '' + self.aggregateToColl.length, originalId: toComp.get('id'), aggregateToValue: aggregateToValue, aggregateToSource: self.getSourceName(toComp), aggregateToType: type, usePreviousRelation: usePreviousRelation, componentPrimed: true, altId: altId });
                        //self.aggregateToColl.push({seqNo:''+self.aggregateToColl.length, originalId: toComp.get('id'), aggregateToValue:aggregateToValue, aggregateToSource:self.getSourceName(toComp), aggregateToType: type, usePreviousRelation: usePreviousRelation, componentPrimed:true, altId:altId});
                    }
                }
            });
            self.aggregateToColl(aggregateToCollArray);
        }

        var aggToColl = valueModal.get('aggregatedTo');
        aggToColl.each(function (agg) {
            //var type = getSourceType(agg) ? getSourceType(agg).replace(/ /g,'') : "";
            var showComp = false;
            if (self.checkIsPlanValue(agg.get('id'))) {
                showComp = true;
            } else if (self.currentAlternativeModel.get('phaseDesignPart').findWhere({ 'beepReference': agg.getNestedParent().get('id') })) {
                showComp = true;
            }
            if (showComp) {
                var type = self.getSourceType(agg) ? self.getSourceType(agg) : "";
                var componentPrimed = self.checkComponentPrimed(agg);
                if (!componentPrimed || (!valueMeasurement && componentPrimed)) {
                    self.aggregateToColl.push({ seqNo: '' + self.aggregateToColl.length, originalId: agg.get('id'), aggregateToValue: agg.get('name'), aggregateToSource: self.getSourceName(agg), aggregateToType: type, usePreviousRelation: false, componentPrimed: componentPrimed });
                }
            }

        });
        if (self.aggregateToColl().length > 0) {
            self.aggregateToColl.sort(function (left, right) {
                var leftStr = left.aggregateToValue.toLowerCase().replace(/[^a-z0-9-]/g, '');
                var rightStr = right.aggregateToValue.toLowerCase().replace(/[^a-z0-9-]/g, '');
                return left.aggregateToValue.toLowerCase() == right.aggregateToValue.toLowerCase() ? 0 : (leftStr < rightStr ? -1 : 1)
            });
            self.enableSatisfactionOnly(false);
        }
        self.checkCyclic(valueMeasurement, valueModal);
    };
    getPrimedAggregationsCount() {
        var self = this;
        var compPrimed = 0;
        for (var i = 0; i < self.aggregateColl().length; i++) {
            if (self.aggregateColl()[i].componentPrimed && !self.aggregateColl()[i]._destroy) {
                compPrimed++;
            }
        }
        return compPrimed;
    }
    filterAggregateOptions(componentId) {
        var self = this;
        var len = self.aggregateOptions().length;
        while (len--) {
            if (self.aggregateOptions()[len].id === componentId) {
                self.aggregateOptions().splice(len, 1);
                if ($('#selectaggregateOptions' + self.encodeId)) {
                    $("#selectaggregateOptions" + self.encodeId + " option[value= '" + componentId + "']").remove();
                }
                break;
            }
        }
    }
    sortSatisfactionIntervals() {
        var self = this;
        self.satisfactionIntervalColl.sort(function (left, right) {
            return parseFloat(left.intervalFrom) === parseFloat(right.intervalFrom) ? 0 : (parseFloat(left.intervalFrom) < parseFloat(right.intervalFrom) ? -1 : 1);
        });
    }
    fillAggregateUIDetails(aggregate) {
        var self = this;
        if (self.aggregateEdit && aggregate) {
            var aggComp = self.fetchComponentById(aggregate);
            if (aggComp) {
                $('#aggregateSource' + self.encodeId).text(self.getSourceName(aggComp));
                $('#aggregateSourceType' + self.encodeId).text(self.getSourceType(aggComp));

                self.calculateAggregateValue();
                if (self.showValue()) {
                    self.aggregateEdit = false;
                }
            } else {
                var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                if (tempValuesForAggregation) {
                    for (var i = 0; i < tempValuesForAggregation.length; i++) {
                        if (tempValuesForAggregation[i].id === aggregate) {
                            $('#aggregateSource' + self.encodeId).text('' + tempValuesForAggregation[i].source);
                            $('#aggregateSourceType' + self.encodeId).text('' + tempValuesForAggregation[i].sourceType);
                            $('#aggregateMeasurementValue' + self.encodeId).text("" + tempValuesForAggregation[i].value + ' ' + tempValuesForAggregation[i].unit);
                            break
                        }
                    }
                }
            }
        }
    }
    showSatisfactionOnly() {
        var self = this;
        $("#showSelectSatisfaction" + self.encodeId).show();
        $("#showSatisfaction" + self.encodeId).hide();
        $("#showSatisfactionImg" + self.encodeId).attr('d', '');
        $("#satisfactionUnitMark" + self.encodeId).hide();
    }
    getSourceType(component) {
        var self = this;
        var type = "";
        if (component.get('type') === "vdml_ValueAdd") {
            type = self.activityName;
        }
        else {
            var componentOwner = component.get('componentOwner');
            if (componentOwner.get('isValueFormula')) {
                type = self.myPropositionName;
            }
            else {
                if (componentOwner.get('planCriterionSetOwner')) {
                    type = 'Plan';
                }
                else {
                    type = self.valuePropositionName;
                }
            }
        }
        return type;
    }
    getSourceName(component) {
        var self = this;
        var name;
        if (component.get('type') == "vdml_ValueAdd") {
            name = component.get('valueAddOwner').get('containedPortOwner').get('name');
        }
        else {
            var componentOwner = component.get('componentOwner');
            name = componentOwner.get('name');
            var valueProvider = componentOwner.get('provider');
            if (!valueProvider) {
                name = componentOwner.getNestedParent().get('name');
            }

        }
        return name;
    }
    /*previousPhaseValueExists(aggComp) {
        var self = this;
        if (!self.currentAlternativeModel.get('phaseAlternativeOwner').get('previousPhase')) {
            return false;
        } else {
            var previousAlt = self.currentAlternativeModel.getPreviousPhasePrimary();
            if (previousAlt.get('phaseDesignPart').findWhere({ 'beepReference': aggComp.getNestedParent().get('id') })) {
                var useScenario = previousAlt ? previousAlt.getMainScenario(aggComp.getNestedParent()) : null;
                var compValueMeasurement = useScenario ? self.getComponentMeasurement(aggComp, 'valueMeasurement', useScenario) : null;
                if (compValueMeasurement) {
                    return true;
                } else {
                    return false;
                }
            } else {//revision
                var aggCompmodel = previousAlt.getModelLinkedSync(aggComp);
                if (aggCompmodel) {
                    var useScenario = previousAlt ? previousAlt.getMainScenario(aggCompmodel.getNestedParent()) : null;
                    var compValueMeasurement = useScenario ? self.getComponentMeasurement(aggCompmodel, 'valueMeasurement', useScenario) : null;
                    if (compValueMeasurement) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        }
    }*/
    fillAggregatedValues() {
        var self = this;
        var aggname;
        self.aggregateOptions([]);
        var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
        var aggregatedColl = self.options.addOptions.aggregatedColl;
        if (aggregatedColl) {
                var dupList = [];
                for (var i = 0; i < aggregatedColl.length; i++) {
                    if (self.model) {
                        if (self.model.get('id') === aggregatedColl.at(i).get('id')) {
                            var cond = false;//self.previousPhaseValueExists(aggregatedColl.at(i));
                            if (cond) {
                                self.aggregateOptions.push({ id: self.model.get('id'), name: self.model.get('name') });
                            }
                        } else {
                            var aggregationValid = self.checkAggregationFiltering(self.options.addOptions.type, aggregatedColl.at(i));
                            if (aggregationValid) {
                                aggname = self.checkAggregateDuplicates(aggregatedColl, aggregatedColl.at(i), tempValuesForAggregation, dupList);
                                self.aggregateOptions.push({ id: aggregatedColl.at(i).id, name: aggname });
                            }
                        }
                    }
                    else {
                        var aggregationValid = self.checkAggregationFiltering(self.options.addOptions.type, aggregatedColl.at(i));
                        if (aggregationValid) {
                            aggname = self.checkAggregateDuplicates(aggregatedColl, aggregatedColl.at(i), tempValuesForAggregation, dupList);
                            self.aggregateOptions.push({ id: aggregatedColl.at(i).id, name: aggname });
                        }
                    }
                }
            self.calculateMeasurentValues();
        }
        function checkDuplicateNameForTempAggregations(name, originalId) {
            var aggregatedColl = self.options.addOptions.aggregatedColl;
            for (var i = 0; i < aggregatedColl.length; i++) {
                if (aggregatedColl.at(i).get('name') == name && aggregatedColl.at(i).get('id') !== originalId) {
                    return true;
                }
            }
            return false;
        }
        if (tempValuesForAggregation) {
            for (var i = 0; i < tempValuesForAggregation.length; i++) {
                var aggName = tempValuesForAggregation[i].name;
                var isNameAlreadyExist = checkDuplicateNameForTempAggregations(aggName, tempValuesForAggregation[i].originalId);
                if (isNameAlreadyExist) {
                    aggName = aggName.concat(" [" + tempValuesForAggregation[i].source + "]");
                }
                self.aggregateOptions.push({ id: tempValuesForAggregation[i].id, name: aggName });
                self.addTempValuesUnits(tempValuesForAggregation[i].unit);
            }
        }


        function getValueColls() {
            var valueCollections = self.options.addOptions.valuesColl;
            var vallColl = new Backbone.Collection();
            for (var i = 0; i < valueCollections.length; i++) {
                var val = aggregatedColl.findWhere({ 'id': valueCollections[i].id });
                vallColl.add(val);
            }
            return vallColl;
        }
        self.aggregateOptions.sort(function (left, right) {
            return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1)
        });
    }
    checkAggregateDuplicates(models, element, tempModels, dupList) {
        var self = this;
        var path = element.get('name');
        var duplicateWithTempValueExist = false;
        if (tempModels) {
            for (var i = 0; i < tempModels.length; i++) {
                if (tempModels[i].name == path) {
                    duplicateWithTempValueExist = true;
                }
            }
        }
        function checkPath(path, fixDupPath) {
            var pathExists = _.find(self.aggregateOptions(), function (agg) { return agg.name == path + "]" });
            if (pathExists) {
                var dupModal = models.findWhere({ 'id': pathExists.id });
                if (dupModal && fixDupPath) {
                    dupList.push(path + "]");
                    var dupAggregatePath = dupModal.getPackagePath();
                    if (dupAggregatePath.length === 4) {
                        var newpath = dupModal.get('name') + "   [" + dupAggregatePath[0].name + "  /  " + dupAggregatePath[1].name + "  /  " + dupAggregatePath[2].name + "]";
                        pathExists.name = newpath;
                    }
                }
                return false;
            } else {
                if (dupList.indexOf(path + "]") != -1) {
                    return false;
                } else {
                    return true;
                }
            }
        }
        var phaseDesignPart = self.currentAlternativeModel.get('phaseDesignPart');
        for (var i = 0; i < models.length; i++) {
            if (duplicateWithTempValueExist || (element.get('name') === models.at(i).get('name') && (element.get('id') !== models.at(i).get('id'))) && phaseDesignPart.findWhere({ 'beepReference': models.at(i).getNestedParent().get('id') })) {
                var duplicateAggregatePath = models.at(i).getPackagePath();
                var aggregatePath = element.getPackagePath();
                if (element.get('type') === "vdml_ValueAdd" && element.get('valueAddOwner') && aggregatePath[1] && element.get('valueAddOwner').get('containedPortOwner')) {//To handle Activity values dupliates
                    aggregatePath[2].name = aggregatePath[1].name;
                    aggregatePath[1].name = element.get('valueAddOwner').get('containedPortOwner').get('performingRole').get('name');
                }
                path = path.concat("   [");
                if (aggregatePath.length === 2) {//to handle plan component values
                    if (aggregatePath[0].name.indexOf("Plan Criterion Set") == -1) {
                        path = path.concat(aggregatePath[0].name);
                    }
                    else {
                        path = path.concat(element.get('componentOwner').get('planCriterionSetOwner').get('name'));
                    }
                }
                else if (duplicateAggregatePath.length === 2 && aggregatePath[2]) {//to handle plan component values
                    path = path.concat(aggregatePath[2].name);
                }
                else {//to handle BM values
                    var pathSatisfied = false;
                    var pathAdded = false;
                    if (aggregatePath[2] && duplicateAggregatePath[2] && aggregatePath[2].name !== duplicateAggregatePath[2].name) {
                        path = path.concat(aggregatePath[2].name);
                        pathSatisfied = checkPath(path, !pathAdded);
                        pathAdded = true;
                    }
                    if (!pathSatisfied && aggregatePath[1].name !== duplicateAggregatePath[1].name) {
                        if (aggregatePath[2]) {
                            if (pathAdded) {
                                path = element.get('name') + "   [" + aggregatePath[1].name + "  /  " + aggregatePath[2].name;
                            } else {
                                path = path.concat(aggregatePath[1].name + "  /  " + aggregatePath[2].name);
                            }

                            pathSatisfied = checkPath(path, !pathAdded);
                            pathAdded = true;
                        }
                    }
                    if (!pathSatisfied && aggregatePath[0].name !== duplicateAggregatePath[0].name) {
                        if (aggregatePath[2]) {
                            if (pathAdded) {
                                path = element.get('name') + "   [" + aggregatePath[0].name + "  /  " + aggregatePath[1].name + "  /  " + aggregatePath[2].name;
                            } else {
                                path = path.concat(aggregatePath[0].name + "  /  " + aggregatePath[1].name + "  /  " + aggregatePath[2].name);
                            }
                            pathSatisfied = checkPath(path, !pathAdded);
                        }
                    }
                    if (!pathAdded && !pathSatisfied) {
                        path = path.concat('' + element.getNestedParent().get('name'));
                    }
                }
                if (!checkPath(path)) {
                    path = element.get('name');
                } else {
                    path = path.concat("]");
                    break;
                }
            }
        }
        return path;
    }
    addTempValuesUnits(unitName) {
        var self = this;
        if (unitName && !self.measureUnits.findWhere({ 'name': unitName })) {
            self.measureUnits.push({ name: '' + unitName, roundingDigits: '4', significantDecimals: '2' });
        }
    }
    checkIsPlanValue(aggId) {
        var self = this;
        var planType = false;
        if (DataManager.getDataManager().getRepositoryId(aggId) == window.plansKey) {
            planType = true;
        }
        return planType;
    }
    fetchPlanScenario() {
        var self = this;
        var useScenario = null;
        var phase = DataManager.getDataManager().get('currentPlan').get('phase');
        var tempAlt = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative') : self.currentAlternativeModel.id;
        if(tempAlt == window.plansKey){
            tempAlt = self.currentAlternativeModel.id;
        }
        for (var j = 0; j < phase.length; j++) {
            var phaseAlts = phase.at(j).get('phaseAlternative');
            var alt = phaseAlts.findWhere({ 'id': tempAlt })
            if (alt) {
                useScenario = alt.getOrCreatePhaseObjectiveSet();
                break;
            }
        }
        return useScenario;
    }
    saveAggregateDetails() {
        var self = this;
        var aggregate;
        if (self.aIndex == null && self.selectedAggregate()) {
            aggregate = self.fetchComponentById(self.selectedAggregate());
            var useScenario = self.scenario;
            if (aggregate) {
                //var mc = aggregate.get('valueMeasurement');
                if (self.checkIsPlanValue(self.selectedAggregate())) {
                    useScenario = self.fetchPlanScenario(false);
                } else {
                    useScenario = self.currentAlternativeModel ? self.currentAlternativeModel.getMainScenario(aggregate.getNestedParent()) : null;
                }
                var satMeasurement = aggregate.getMeasurement('satisfactionLevel', useScenario);
                var satisfactionType = satMeasurement ? satMeasurement.get('type') : null;
                var componentPrimed = self.checkComponentPrimed(aggregate);
                self.aggregateColl.push({ seqNo: '' + self.aggregateColl().length, escapeAggId: self.htmlEscape(self.selectedAggregate()), originalId: '', aggregateid: self.selectedAggregate(), aggregateValue: aggregate.get('name'), aggregateSource: $('#aggregateSource' + self.encodeId).text(), aggregateType: $('#aggregateSourceType' + self.encodeId).text(), satisfactionType: satisfactionType,  componentPrimed: componentPrimed });
                self.expBuilderOptions.variables.push({variableId: self.selectedAggregate(), name:aggregate.get('name')})
                self.constructBaseTuple('' + self.aggregateColl().length - 1, aggregate.id, aggregate, false);
            }
            else {
                var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                if (tempValuesForAggregation) {
                    for (var i = 0; i < tempValuesForAggregation.length; i++) {
                        if (tempValuesForAggregation[i].id === self.selectedAggregate()) {
                            self.aggregateColl.push({ seqNo: '' + self.aggregateColl().length, escapeAggId: self.htmlEscape(self.selectedAggregate()), originalId: '', aggregateid: self.selectedAggregate(), aggregateValue: tempValuesForAggregation[i].name, aggregateSource: tempValuesForAggregation[i].source, aggregateType: tempValuesForAggregation[i].sourceType, satisfactionType: tempValuesForAggregation[i].satisfactionType, componentPrimed: tempValuesForAggregation[i].prime });
                            self.expBuilderOptions.variables.push({variableId: self.selectedAggregate(), name:tempValuesForAggregation[i].name})
                            self.constructBaseTuple('' + self.aggregateColl().length - 1, self.selectedAggregate(), tempValuesForAggregation[i].id,  false);
                            self.tempValuesAggregated.push(self.selectedAggregate());
                            break;
                        }
                    }
                }
            }
        }
        else {
            if (self.selectedAggregate() || (self.tempAggregateId && self.tempAggregateId !== "")) {
                if (self.showValue()) {
                    aggregate = self.fetchComponentById(self.tempAggregateId);
                }
                else {
                    aggregate = self.fetchComponentById(self.selectedAggregate());
                }
                var aggName = aggregate ? aggregate.get('name') : '';
                var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                if (!aggregate && tempValuesForAggregation) {
                    for (var i = 0; i < tempValuesForAggregation.length; i++) {
                        if (tempValuesForAggregation[i].id === self.tempAggregateId) {
                            aggName = tempValuesForAggregation[i].name;
                            break;
                        }
                    }
                }
                self.aggregateColl()[self.aIndex].aggregateValue = aggName;
                $("#aggregateValue" + self.aggregateColl()[self.aIndex].seqNo).text('' + self.aggregateColl()[self.aIndex].aggregateValue);
                $("#aggregateSource" + self.aggregateColl()[self.aIndex].seqNo).text('' + self.aggregateColl()[self.aIndex].aggregateSource);
                $("#aggregateType" + self.aggregateColl()[self.aIndex].seqNo).text('' + self.aggregateColl()[self.aIndex].aggregateType);
                var seqNo = self.aggregateColl()[self.aIndex].seqNo;
                for (var i = 0; i < self.baseTuples.length; i++) {
                    if (self.baseTuples[i].new && self.baseTuples[i].new.seqNo == seqNo) {
                        self.editBaseTuple(self.baseTuples[i].new.seqNo, self.baseTuples[i].new.id, self.baseTuples[i].new.component, self.baseTuples[i].new.existing);
                    }
                }
                self.aIndex = null;
            }
        }
        //self.aggregateDiv(true);
        if (self.showValue()) {
            $("#aggregateSource" + self.encodeId).text("");
            $("#aggregateSourceType" + self.encodeId).text("");
        }
        else {
            self.tempAggregateId = self.selectedAggregate();
        }
        self.calculateMeasurentValues();
        self.tempAggregateId = "";
        self.aggregateEdit = true;
        self.showValue(false);
        self.showAggregateDiv(false);
        if (window.utils.getObservableLength(self.aggregateColl())) {
            self.fillaggregationAndUnit();
        }
    }
    fillaggregationAndUnit() {
        var self = this;
        if (!self.measurementUnit()) {
            var aggModel = self.fetchComponentById(self.aggregateColl()[0].aggregateid);
            if (aggModel) {
                var useScenario = self.scenario;
                if (self.checkIsPlanValue(self.aggregateColl()[0].aggregateid)) {
                    useScenario = self.currentAlternativeModel.getOrCreatePhaseObjectiveSet();
                }
                //var compValueMeasurement = self.periodDataset[aggModel.id];//aggModel.getMeasurement('valueMeasurement', useScenario);
                //if (compValueMeasurement) {
                    var valueUnit = aggModel.get('unit');
                    var compUnitName = valueUnit ? valueUnit.get('name') : "";
                    self.measurementUnit(compUnitName);
                    $('#measurementUnit' + self.encodeId).val(compUnitName);
                    if ($('#measurementUnit' + self.encodeId).val() !== "" && valueUnit) {
                        self.roundingFactor(true);
                        self.roundingDigits(valueUnit.get('roundingDigits'));
                        self.significantDecimals(valueUnit.get('significantDecimals'));
                    }
                //}
            } else {
                var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                if (tempValuesForAggregation) {
                    for (var j = 0; j < tempValuesForAggregation.length; j++) {
                        if (tempValuesForAggregation[j].id === self.aggregateColl()[0].aggregateid) {
                            $('#measurementUnit' + self.encodeId).val(tempValuesForAggregation[j].unit);
                            self.measurementUnit(tempValuesForAggregation[j].unit);
                            break;
                        }
                    }
                }
            }
        }
    }
    saveChildData(view) {
        var self = this;
        var childExists = _.filter(self.valChildren, function (child) { if (child.view.model === view.model) { return child.view } });
        if (childExists.length > 0) {
            childExists[0].view = view;
        } else {
            if (view.options.addOptions.type !== "createWhatValues") {
                self.valChildren.push({ view: view, type: view.options.addOptions.type, bm: view.options.addOptions.bm, vp: view.options.parentView, aggregatedColl: view.options.addOptions.aggregatedColl });
            } else {
                self.valChildren.push({ view: view, type: view.options.addOptions.type, bm: view.options.addOptions.bm, activities: view.options.addOptions.activities, aggregatedColl: view.options.addOptions.aggregatedColl });
            }
        }
    };
    getAlternativeModel(altId) {
        var valueAlternativeModel;
        if (!DataManager.getDataManager().get('currentPlan')) {
            var phase = this.currentAlternativeModel.get('phaseAlternativeOwner').get('phaseOwner').get('phase');
        } else {
            phase = DataManager.getDataManager().get('currentPlan').get('phase');
        }
        this.currentAlternativeModel.get('phaseAlternativeOwner').get('phaseOwner')
        for (var j = 0; j < phase.length; j++) {
            var alts = phase.at(j).get('phaseAlternative');
            var viewAlternativeModel = alts.findWhere({ 'id': altId });
            if (viewAlternativeModel) {
                valueAlternativeModel = viewAlternativeModel;
                break;
            }
        }
        return valueAlternativeModel;
    };
    openValueHandler(view, event) {
        var self = this;
        var parentValType = self.options.addOptions.type;
        var id = self.htmlEscape(view.originalId) + parentValType;
        var aggregatedColl = self.options.addOptions.aggregatedColl;
        var bm;
        if (self.parentView.get('type') === 'vdml_BusinessModel' && !self.checkIsPlanValue(self.model.get('id'))) {
            bm = self.parentView;
        }
        else if (self.options.addOptions && self.options.addOptions.bm && self.options.addOptions.bm.get('type') === 'vdml_BusinessModel' && !self.checkIsPlanValue(self.model.get('id'))) {
            bm = self.options.addOptions.bm;
        }
        var aggToCompModel = self.fetchComponentById(view.originalId);
        var valueAlternativeModel = self.currentAlternativeModel;
        var valueAltId = self.currentAlternativeModel.id;
        if (aggToCompModel.getNestedParent() !== DataManager.getDataManager().get('currentPlan')) {
            valueAltId = DataManager.getDataManager().getRepositoryId(view.originalId);
        }
        var readOnly;
        if (self.currentAlternativeModel.id != valueAltId || view.usePreviousRelation || self.disableAggr) {
            var altId = view.altId ? view.altId : valueAltId;
            valueAlternativeModel = self.getAlternativeModel(altId);
            readOnly = true;
        }
        var scenario = valueAlternativeModel.getMainScenario(aggToCompModel.getNestedParent());
        var addOptions;
        var aggTo = true;

        if (aggToCompModel.get('type') == "vdml_ValueAdd") {
            var actModel = aggToCompModel.get('valueAddOwner').get('containedPortOwner');
            var actObj = [{ originalId: actModel.get('id'), modalId: self.htmlEscape(actModel.get('id')), name: actModel.get('name') }];
            addOptions = { 'type': 'createWhatValues', 'aggTo': aggTo, 'activities': actObj, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, view: self, 'bm': bm, 'currentAlternativeModel': valueAlternativeModel, 'readOnly': readOnly };
        } else {
            var valType = 'valueProposition';
            if (aggToCompModel.getParent().get('isValueFormula')) {
                valType = 'valueFormula';
            }
            else if (aggToCompModel.getNestedParent() == DataManager.getDataManager().get('currentPlan')) {
                valType = 'planValues';
            }
            addOptions = { 'type': valType, 'aggTo': aggTo, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': false, 'bm': bm, 'view': self, 'currentAlternativeModel': valueAlternativeModel, 'readOnly': readOnly };
        }
        if ($.find("#modal" + id).length > 0) {
            $("#modal" + id).modal('show');
        }
        else {
            window.getAndCreateModalDialog(self.model, id, BusinessModel2Mixin, aggToCompModel, "ValueDetails", _.bind(self.saveChildData, self), addOptions);
        }
    }
    editAggregateDetailsHandler(view, event) {
        var self = this;
        $("#aggregateValue" + self.encodeId).text(view.aggregateValue);
        self.aggregateEdit = true;
        self.showValue(true);
        for (var i = 0; i < self.aggregateColl().length; i++) {
            if (self.aggregateColl()[i].seqNo === view.seqNo) {
                self.tempAggregateId = view.aggregateid;
                self.aIndex = i;
                self.fillAggregateUIDetails(self.tempAggregateId);
                self.showAggregateDiv(true);
                self.componentPrimed(true);
                //self.aggregateDiv(false);
                break;
            }
        }
    };
    deleteAggregateDetailsHandler(view) {
        var self = this;
        for (var i = 0; i < self.aggregateColl().length; i++) {
            if (self.aggregateColl()[i].seqNo === view.seqNo) {
                var tempView = self.aggregateColl()[i];
                if (tempView) {
                    var found = false;
                    self.aggregateColl.replace(self.aggregateColl()[i], tempView);
                    self.aggregateColl.destroy(self.aggregateColl()[i]);
                    self.aggregateOptions.sort(function (left, right) {
                        return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1)
                    });
                    for (var j = 0; j < self.baseTuples.length; j++) {
                        if (self.baseTuples[j].new && self.baseTuples[j].new.seqNo == view.seqNo) {
                            self.deleteTuple = true;
                            self.deleteBaseTuple(self.baseTuples[j].new.seqNo, self.baseTuples[j].new.id, self.baseTuples[j].new.component, self.baseTuples[j].new.existing);
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                }
            }
        }
        self.calculateMeasurentValues();
    }

    saveValueDetailsHandler() {
        var self = this;
        if (self.parentView) {
            if (self.name() !== null && self.name().trim() !== "" && $('#message' + self.encodeId).text().trim().length < 1) {
                self.name(self.name().trim());
                return self.options.callback(this, self.encodeId, self.options.addOptions.type, null, self.options.addOptions.disableDelete);
            }
        }
    };
    saveValChildrenData(valChildren, callback) {
        var self = this;
        async.eachSeries(self.valChildren, function (child, saveChildCallback) {
            if (child.type !== "createWhatValues" && child.view.createValue) {
                child.view.createValue(child.bm, child.vp, function () {
                    window.cleanDialogModel(child.view.encodeId, child.view);
                    saveChildCallback();
                });
            } else {
                if (child.view.saveWhatValuesData) {
                    child.view.saveWhatValuesData(child.bm, child.activities, [], function () {
                        window.cleanDialogModel(child.view.encodeId, child.view);
                        saveChildCallback();
                    });
                }
                else {
                    saveChildCallback();
                }
            }
        }, function (err) {
            callback();
        });
    }
    saveWhatValuesData(bm, activityColl, activityValueColl, callback, tempValuesForAggregation) {
        var self = this;
        self.saveValChildrenData(self.valChildren, function () {
            var activityModal;
            var bmActivityModal;
            var activityId;
            if (bm.get('type') === "vdml_Activity") {
                activityModal = bm;
            }
            else {
                for (var j = 0; j < activityColl.length; j++) {
                    if (activityColl[j].modalId === self.selectedActivity() && !activityColl[j]._destroy) {
                        activityId = activityColl[j].originalId;
                        break;
                    }
                }
                var activities = bm.get("bmActivity");
                for (var i = 0; i < activities.models.length; i++) {
                    if (activities.models[i].get('activity').get('id') === activityId) {
                        bmActivityModal = activities.models[i];
                        activityModal = activities.models[i].get('activity');
                        break;
                    }
                }
            }
            var activityValue;
            self.setComponentforBaseTuples(tempValuesForAggregation, self.baseTuples);
            if (!self.model && !self.existingValueModal) {
                var currentAlternativeModel = self.currentAlternativeModel;
                var activityValueName = self.name().trim();
                var measurementValue = self.measurementValue();
                if (self.enableMeasurement() && !isNaN(self.measurementValue())) {
                    measurementValue = self.fullMeasurementValue ? self.fullMeasurementValue : self.measurementValue();
                }
                var measurementUnit = self.measurementUnit();
                var roundingDigits = self.roundingDigits();
                var significantDecimals = self.significantDecimals();
                var formula = self.formula();
                var maxChange = self.maxChange();
                var baseTuples = self.baseTuples;
                self.baseTuples = null;
                var projectionProfileObj = {trendModel : self.selectedTrendModel(), rateFactor:self.saveRateFactor(),polynomialDegree:self.degree(),polynomialData:self.polynomialCoefficient(),seasonalFactorData:self.seasonalFactors()}


                var outputPort = bmActivityModal ? bmActivityModal.get('defaultOutput') : null;
                var activity = bmActivityModal ? bmActivityModal.get('activity') : activityModal;
                var outputPortName = '';
                if (!outputPort) {
                    outputPortName = activity.getUniqueProperty('name', activity.get('name'), 'containedPort') + " port";
                } else {
                    outputPortName = outputPort.get('name');
                }
                var collaboration = activity.getParent();
                var vdml = collaboration.getNestedParent();
                var namePath = vdml.get('name') + '>' + collaboration.get('name') + '>' + activity.get('name') + '>' + outputPortName + '>' + activityValueName;
                vdml.getDocumentWithNamePath(namePath, "vdml_ValueAdd", self.currentAlternativeModel, true, vdml, function (results) {
                    if (results && results[0]) {
                        var doc = results[0].doc;
                        var parent = results[0].parent;
                        //var newValueAddIdSuffix = window.utils.getSuffix(doc);
                        var altId = DataManager.getDataManager().getRepositoryId(doc);
                        var vdmStore = DataManager.getDataManager().getVDMStore(altId);
                        var version = DataManager.getDataManager().get('currentVDMVersion');
                        DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValueAdd", vdmStore, {
                            success: function (valModel) {
                                createActivityValue(valModel, activityValueName);
                            },
                            error: function (error) {
                                console.log('Unable to load Value document');
                                createActivityValue(null, activityValueName);
                            }
                        });
                    } else {
                        createActivityValue(null, activityValueName);
                    }
                });
                function createActivityValue(oldValModel, activityValueName) {
                    activityValue = activityModal.createWhatValues(self.year,self.period,bm, currentAlternativeModel, activityValueName, true, measurementValue, { unitName: measurementUnit, roundingDigits: roundingDigits, significantDecimals: significantDecimals }, formula, null, baseTuples, maxChange, bmActivityModal, oldValModel, null,projectionProfileObj);
                    if (self.usedValue) {
                        var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                        if (valueDef) {
                            activityValue.set('valueDefinition', valueDef);
                        }
                    } else {
                        activityValue.set('valueDefinition', null);
                    }
                    if (self.minRange() && self.maxRange()) {
                        var measure = activityValue.get('valueMeasurement').get('measurement');
                        if (measure && measure.length > 0) {
                            measure.at(0).set('minRange', self.minRange());
                            measure.at(0).set('maxRange', self.maxRange());
                        }
                    }
                    self.setComponentforTempAgg(tempValuesForAggregation, activityValue);
                    updateUI(null, null, null, null, true);
                }
            }
            else {
                var valMeasurement;
                if (self.existingValueModal) {
                    self.model = self.existingValueModal;
                    self.setComponentforTempAgg(tempValuesForAggregation);
                    if (bmActivityModal) {
                        bmActivityModal.get('activityValue').add(self.existingValueModal);
                    }

                    var scenario = self.currentAlternativeModel.getMainScenario(self.model.getNestedParent());
                    valMeasurement = self.getComponentMeasurement(self.model, 'valueMeasurement', scenario);
                } else {
                    valMeasurement = self.getMeasurement('valueMeasurement', true);
                }

                activityValue = self.model;
                if (self.usedValue) {
                    var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                    if (valueDef) {
                        self.model.set('valueDefinition', valueDef);
                    }
                } else {
                    self.model.set('valueDefinition', null);
                }
                if (self.minRange() && self.maxRange()) {
                    var measure = self.model.get('valueMeasurement').get('measurement');
                    if (measure && measure.length > 0) {
                        measure.at(0).set('minRange', self.minRange());
                        measure.at(0).set('maxRange', self.maxRange());
                    }
                }
                var valueExpression = self.expBuilder.getExpression() ? self.expBuilder.getExpression().replace(/\[|\]/g, ""):"";
                if (!valMeasurement) {
                    self.model.prime(self.year,self.period,self.measurementValue(), { unitName: self.measurementUnit(), roundingDigits: self.roundingDigits(), significantDecimals: self.significantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.measurementUnit() }) }, valueExpression, null, self.baseTuples, null, self.currentAlternativeModel, self.maxChange(), bm, null, function(){
                        updateUI(null, null, null, null, true);
                    });
                } else {
                    self.model.addBaseTuple(self.baseTuples);
                    self.model.update(self.period, self.year, self.name().trim(), self.measurementValue(), { unitName: self.measurementUnit(), roundingDigits: self.roundingDigits(), significantDecimals: self.significantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.measurementUnit() }) }, valueExpression, null, self.baseTuples, false, self.currentAlternativeModel, self.maxChange(), bm, null, updateUI);

                }
            }
            function updateUI(obsMesScenario, mestVM, measureVM, calculateSat, changeInValue) {
                for (var k = 0; k < activityValueColl.length; k++) {
                    if (activityValueColl[k].modalId === self.encodeId) {
                        activityValueColl[k].originalId = activityValue.get("id");
                        break;
                    }
                }
                bmActivityModal && bmActivityModal.get('activityValue').add(activityValue);
                callback(activityValue, changeInValue);
            }
        });
    }
    saveData(bm, vp, callback, tempValuesForAggregation) {
        var self = this;
        self.saveValChildrenData(self.valChildren, createValue, tempValuesForAggregation);
        function createValue() {
            return self.createValue(bm, vp, callback, tempValuesForAggregation);
        }
    }
    setComponentforBaseTuples(tempValuesForAggregation, baseTuples) {
        var self = this;
        var len = self.baseTuples.length;
        while (len--) {
            if (self.baseTuples[len].new && typeof self.baseTuples[len].new.component === 'string') {
                if (tempValuesForAggregation) {
                    for (var j = 0; j < tempValuesForAggregation.length; j++) {
                        if (tempValuesForAggregation[j].id === self.baseTuples[len].new.component) {
                            if (!tempValuesForAggregation[j].component) {
                                self.baseTuples.splice(len, 1);
                            } else {
                                self.baseTuples[len].new.component = tempValuesForAggregation[j].component;
                            }
                            break;
                        }
                    }
                }
            }
        }
    }
    setComponentforTempAgg(tempValuesForAggregation, actModel) {
        var self = this;
        if (tempValuesForAggregation) {
            var component = actModel ? actModel : self.model;
            for (var i = 0; i < tempValuesForAggregation.length; i++) {
                if (tempValuesForAggregation[i].id === self.encodeId) {
                    tempValuesForAggregation[i].component = component;
                    break;
                }
            }
        }
    }
    fetchNamePathValue(count, results, component, valueName, callback) {
        var self = this;
        var doc = results[count].doc;
        var parent = results[count].parent;
        var altId = DataManager.getDataManager().getRepositoryId(doc);
        var vdmStore = DataManager.getDataManager().getVDMStore(altId);
        var version = DataManager.getDataManager().get('currentVDMVersion');
        DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValuePropositionComponent", vdmStore, {
            success: function (valModel) {
                if (window.utils.getSuffix(valModel.getParent().id) === window.utils.getSuffix(component.id)) {
                    callback(component, valModel, valueName);
                } else {
                    count++;
                    if (results[count]) {
                        self.fetchNamePathValue(count, results, component, valueName, callback);
                    } else {
                        callback(component, null, null, valueName);
                    }
                }
            },
            error: function (error) {
                console.log('Unable to load Value document');
                callback(null, null, valueName);
            }
        });
    }
    
    createValue(bm, vp, callback, tempValuesForAggregation) {
        var self = this;
        var measurementValue = self.measurementValue();
        if (self.enableMeasurement() && !isNaN(self.measurementValue())) {
            measurementValue = self.fullMeasurementValue ? self.fullMeasurementValue : self.measurementValue();
        }
        self.setComponentforBaseTuples(tempValuesForAggregation, self.baseTuples);
        if (!self.model) {
            var valueName = self.name().trim();
            var providerRole = vp.get('provider');
            if (providerRole) {
                var collaboration = providerRole.getParent();
                var vdml = collaboration.getNestedParent();
                var namePath = vdml.get('name') + '>' + collaboration.get('name') + '>' + providerRole.get('name') + '>' + vp.get('name') + '>' + valueName;
                vdml.getDocumentWithNamePath(namePath, "vdml_ValuePropositionComponent", self.currentAlternativeModel, true, vdml, function (results) {
                    if (results && results[0]) {
                        self.fetchNamePathValue(0, results, vp, valueName, createVPC);
                    } else {
                        createVPC(vp, null, valueName);
                    }
                });
            } else {
                createVPC(vp, null, valueName);
            }
            function createVPC(vp, oldVpc, valueName) {
                var newVPCIdSuffix = oldVpc ? window.utils.getSuffix(oldVpc.id) : null;
                self.model = vp.createValuePropositionComponent(valueName, valueName, false, newVPCIdSuffix);
                var vdm = vp.getNestedParent();
        
                vdm.createValueDetails(self.model, oldVpc, self.satisfactionIntervalColl().length > 0, self.weight(), self.recipientValue());
                if (self.usedValue) {
                    var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                    if (valueDef) {
                        self.model.set('valueDefinition', valueDef);
                    }
                } else {
                    self.model.set('valueDefinition', null);
                }
        
                self.setComponentforTempAgg(tempValuesForAggregation);
                self.model.addBaseTuple(self.baseTuples);
                var valUnitObj = { unitName: self.measurementUnit(), roundingDigits: self.roundingDigits(), significantDecimals: self.significantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.measurementUnit() }) };
                var satUnitObj = { unitName: self.satisfactionUnitValue(), roundingDigits: self.satRoundingDigits(), significantDecimals: self.satSignificantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.satisfactionUnitValue() }) };
                var projectionProfileObj = {trendModel : self.selectedTrendModel(), rateFactor:self.saveRateFactor(),polynomialDegree:self.degree(),polynomialData:self.polynomialCoefficient(),seasonalFactorData:self.seasonalFactors()}
                var valueExpression = self.expBuilder.getExpression() ? self.expBuilder.getExpression().replace(/\[|\]/g, ""):"";
                self.model.prime(self.year, self.period, measurementValue, valUnitObj, valueExpression, self.satisfaction(), self.satisfactionType(), self.checkDimensional(), satUnitObj, self.weight(), self.checkDimensional() ? self.recipientValue() : self.recipientSatValue(), null, self.baseTuples, self.intervalTuples, null, self.currentAlternativeModel, self.maxChange(), bm, null,projectionProfileObj, function (value) {
                    if (self.minRange() && self.maxRange()) {
                        var measure = self.model.get('valueMeasurement').get('measurement');
                        if (measure && measure.length > 0) {
                            measure.at(0).set('minRange', self.minRange());
                            measure.at(0).set('maxRange', self.maxRange());
                        }
                    }
                    updateAggregation(value);
                });
        
            }
        }
        else {
            //self.model.set('maxChange',this.maxChange());
            var measureChange = false;
            if (self.usedValue) {
                var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                if (valueDef) {
                    self.model.set('valueDefinition', valueDef)
                }
            } else {
                self.model.set('valueDefinition', null);
            }
            var satisfactionMeasurement = self.getMeasurement('satisfactionLevel');
            if ((self.satisfactionType() === 'smm_GradeMeasure' && satisfactionMeasurement && satisfactionMeasurement.get('type') === "smm_RankingMeasurement") || (self.satisfactionOnly())) {
                measureChange = true;
            }
            self.model.addBaseTuple(self.baseTuples, measureChange);
            var valUnitObj = { unitName: self.measurementUnit(), roundingDigits: self.roundingDigits(), significantDecimals: self.significantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.measurementUnit() }) };
            var satUnitObj = { unitName: self.satisfactionUnitValue(), roundingDigits: self.satRoundingDigits(), significantDecimals: self.satSignificantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.satisfactionUnitValue() }) };
            var projectionProfileObj = {trendModel : self.selectedTrendModel(), rateFactor:self.saveRateFactor(),polynomialDegree:self.degree(),polynomialData:self.polynomialCoefficient(),seasonalFactorData:self.seasonalFactors()}
            var valueExpression = self.expBuilder.getExpression() ? self.expBuilder.getExpression().replace(/\[|\]/g, ""):"";
            self.model.update(self.period, self.year, self.name(), measurementValue, valUnitObj, valueExpression, self.satisfactionType(), self.checkDimensional(), self.satisfaction(), satUnitObj, self.weight(), self.checkDimensional() ? self.recipientValue() : self.recipientSatValue(), null, self.baseTuples, self.intervalTuples, false, self.currentAlternativeModel, self.maxChange(), bm, null,projectionProfileObj, function (value) {
                if (self.minRange() && self.maxRange()) {
                    var measure = self.model.get('valueMeasurement').get('measurement');
                    if (measure && measure.length > 0) {
                        measure.at(0).set('minRange', self.minRange());
                        measure.at(0).set('maxRange', self.maxRange());
                    }
                }
                updateAggregation(value)
            });
        }
        function updateAggregation(changeInValue) {
            if (callback) {
                callback(changeInValue);
            }
        }
    }
    cleanModalHandler() {
        var self = this;
        self.valChildren = [];
        if (!self.enableDelete()) {
            window.cleanDialogModel(self.encodeId, this);
        }
        if (self.planValue) {
            window.cleanDialogModel(self.encodeId, this);
        }
        if (self.dialogOpenCount == 1 && self.model) {
            window.cleanDialogModel(self.encodeId, this);
        }
    }
    disableUIComponents(aggTo) {
        var self = this;
        self.enableMeasurement(false);
        self.enableSatisfaction(false);
        self.enableSatisfactionOnly(false);
        self.enableSatisfactionType(false);
        $('#satisfactionUnit' + self.encodeId).prop('disabled', true);
        $('#measurementUnit' + self.encodeId).prop('disabled', true);
        self.roundingFactor(false);
        $('#satisfactionIntervalAddIconDiv' + self.encodeId).hide();
        $("icon[id*='satisfactionIntervalDeleteIcon']").hide();
        $('#aggregateAddIconDiv' + self.encodeId).hide();
        $('#valueName' + self.encodeId).prop('disabled', true);
        if (!aggTo) {
            self.enableWeight(false);
            self.enableDelete(false);
            $('#formula' + self.encodeId).prop('disabled', true);
            $('#recipientVal' + self.encodeId).prop('disabled', true);
        }
    }
    editSatisfactionIntervalHandler(view, event) {
        var self = this;
        for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
            if (self.satisfactionIntervalColl()[i].seqNo === view.seqNo) {
                $("#intervalName" + self.encodeId).val(view.intervalName);
                $("#intervalFrom" + self.encodeId).val(view.intervalFrom);
                $("#intervalTo" + self.encodeId).val(view.intervalTo);
                self.checkFromInclusive(view.fromInclusive);
                self.checkToInclusive(view.toInclusive);
                if (view.intervalFrom == "-Infinity" || view.intervalFrom == -Number.MAX_VALUE) {
                    self.negativeInfinity(true);
                    $('#intervalFromShow' + self.encodeId).text('-Infinity');
                    self.showIntervalFromUI(false);
                }
                else {
                    self.showIntervalFromUI(true);
                    self.negativeInfinity(false);
                }
                if (view.intervalTo == "Infinity" || view.intervalTo == Number.MAX_VALUE) {
                    self.positiveInfinity(true);
                    $('#intervalToShow' + self.encodeId).text('Infinity');
                    self.showIntervalToUI(false);
                }
                else {
                    self.positiveInfinity(false);
                    self.showIntervalToUI(true);
                }
                var smileyValue = view.smiley;
                var smileySrc = self.fetchSmileySrc(smileyValue);
                if (smileySrc) {
                    $('#intervalSmiley' + self.encodeId).parent().show();
                }
                $("#intervalSmileyPath" + self.encodeId).attr('d', smileySrc);
                $("#intervalSmiley" + self.encodeId).attr('value', smileyValue);
                $("#intervalSmiley" + self.encodeId).attr('fill', view.smileyColor);
                self.correctiveActions('' + view.correctiveActions);
                self.addColorsToPalette();
                //$('#cp'+self.encodeId).find( "i" ).css('background-color',view.smileyColor);
                //    self.constructInterval(view.intervalName,view.intervalFrom,view.intervalTo);   TODO
                self.satisfactionIntervalDiv(true);
                //self.showIntervals(false);
                self.calculateSatisfaction();
                self.sIndex = i;
                break;
            }
        }
    };
    fetchComponentById(aggregateId) {
        var self = this;
        var aggComp = window.utils.getElementModel(aggregateId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
        return aggComp;
    }
    calculateAggregateValue() {
        var self = this;
        var cond = false;
        var id = self.selectedAggregate();
        if (self.aIndex !== null) {
            id = self.aggregateColl()[self.aIndex].aggregateid;
        }
        if (id) {
            var compQuantity = null;
            var component = self.fetchComponentById(id);
            if (self.model === component) {
                cond = true;
            }
            if (component) {
                var compAltId = DataManager.getDataManager().getRepositoryId(component.get('id'));
                var compAlt = self.currentAlternativeModel;
                if (self.readOnly && compAltId != window.plansKey && compAltId != self.currentAlternativeModel.id) {
                    compAlt = self.getAlternativeModel(compAltId);
                }
            }
            if (component && cond) {
                var useScenario = self.scenario;
                if (self.checkIsPlanValue(id)) {
                    useScenario = self.fetchPlanScenario();
                }
                var previousAlt = compAlt.getPreviousPhasePrimary();
                if (!previousAlt) {
                    return;
                }
                if (previousAlt.get('phaseDesignPart').findWhere({ 'beepReference': component.getNestedParent().get('id') })) {
                    useScenario = previousAlt ? previousAlt.getMainScenario(component.getNestedParent()) : null;
                    compQuantity = useScenario ? self.getComponentMeasurement(component, 'valueMeasurement', useScenario) : null;
                } else {
                    var aggCompmodel = previousAlt.getModelLinkedSync(component);
                    if (aggCompmodel) {
                        var useScenario = previousAlt ? previousAlt.getMainScenario(aggCompmodel.getNestedParent()) : null;
                        compQuantity = useScenario ? self.getComponentMeasurement(aggCompmodel, 'valueMeasurement', useScenario) : null;
                    }
                }
            }
            else if (component) {
                /*var scenario = self.currentAlternativeModel.getMainScenario(component.getNestedParent());
                if (scenario) {
                    compValueMeasurement = scenario.getTSLessMeasurementReferringToMeasuredCharteristic(component.get('valueMeasurement'));
                }*/
                compQuantity = self.periodDataset?self.periodDataset.get(component.id):null;
            }
            if (compQuantity) {
                var unit = component.get('unit') ? component.get('unit') : null;
                var unitName = unit ? unit.get('name') : "";
                var compSignificantDecimal = unit ? unit.get('significantDecimals') : self.significantDecimals();
                var roundValue = window.utils.getRoundedValues(compQuantity, compSignificantDecimal);
                if (roundValue != '') {
                    $('#aggregateMeasurementValue' + self.encodeId).text("" + roundValue + " " + unitName);
                } else {
                    $('#aggregateMeasurementValue' + self.encodeId).text("");
                }
            }
            else {
                $('#aggregateMeasurementValue' + self.encodeId).text("");
            }
        }
    }
    addColorsToPalette() {
        var self = this;
        $("#cp" + self.encodeId + ' input').val(self.previouslyUsedColor);
        $('#colors-wrapper1' + self.encodeId).empty();
        var smileyColor = $("#intervalSmiley" + self.encodeId).attr('fill');
        self.colorsList.push(smileyColor);
        $.unique(self.colorsList);
        $.each(self.colorsList, function (it, value) {
            var wrapper_id = 'w' + it + self.encodeId;
            if (value === smileyColor) {
                $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '" active="active"> </div>').appendTo('#colors-wrapper1' + self.encodeId);
            } else {
                $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '"> </div>').appendTo('#colors-wrapper1' + self.encodeId);
            }
            $('<div class="dcolor" style="background-color:' + value + '"> </div>').appendTo('#' + wrapper_id);
        });
    };
    showCycle(){
        var self = this;
        if (!self.model) {
            return;
        }
        var msg = "Cyclic Paths:";
        var valueMeasurement = self.getMeasurement('valueMeasurement', true);
        var cyclicPaths = valueMeasurement.getCyclicPath();
        for(var k=0;k<cyclicPaths.length;k++){
            var cyclicPath = cyclicPaths[k];
            for(var j=0;j<cyclicPath.length;j++){
                var cyclicElement = cyclicPath[j];
                var name = cyclicElement.get('name');
                name = name.replace("Measurement","");
                msg = msg.concat("<br/>"+name);
            }
            msg = msg.concat("<br/>");
        }
        bootbox.alert(msg);
    }
    setOptionsHandler(tempValues) { //used in businessModelViewModel
        var self = this;
        if (tempValues) {
            function checkTempCycle(valueId) {
                var cyclefound = false;
                for (var j = 0; j < tempValues.length; j++) {
                    if (self.encodeId != tempValues[j].id && valueId == tempValues[j].id) {
                        var tempValuesAggregated = tempValues[j].tempValuesAggregated;
                        function checkInAggregation(aggs, encodeId) {
                            for (var k = 0; k < aggs.length; k++) {
                                if (aggs[k] === encodeId) {
                                    cyclefound = true;
                                    break;
                                } else {
                                    var aggValueaggregations;
                                    for (var m = 0; m < tempValues.length; m++) {
                                        if (aggs[k] === tempValues[m].id) {
                                            aggValueaggregations = tempValues[m].tempValuesAggregated
                                            if (aggValueaggregations) {
                                                checkInAggregation(aggValueaggregations, encodeId);
                                            }
                                            if (cyclefound) {
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        checkInAggregation(tempValuesAggregated, self.encodeId);
                        if (cyclefound) {
                            break;
                        }
                    }
                }
                return cyclefound;
            }

            for (var i = 0; i < tempValues.length; i++) {
                self.addTempValuesUnits(tempValues[i].unit);
                var valueAlreadyAdded = false;
                if (tempValues[i].id === self.encodeId) {
                    valueAlreadyAdded = true;
                } else if (tempValues[i].tempValuesAggregated.indexOf(self.encodeId) != -1) {
                    valueAlreadyAdded = true;
                    self.filterAggregateOptions(tempValues[i].id);
                } else {
                    for (var j = 0; j < self.aggregateColl().length; j++) {//value already exists in collection
                        if (self.aggregateColl()[j].aggregateid === tempValues[i].id) {
                            valueAlreadyAdded = true;
                            if (checkTempCycle(tempValues[i].id)) {
                                self.filterAggregateOptions(tempValues[i].id);
                            }
                            break;
                        }
                    }
                    for (var k = self.aggregateOptions().length; k--;) {//value already exists in select box
                        if (self.aggregateOptions()[k].id === tempValues[i].id) {
                            valueAlreadyAdded = true;
                            if (checkTempCycle(tempValues[i].id)) {
                                self.filterAggregateOptions(tempValues[i].id);
                            }
                            break;
                        }
                    }
                }
                if (!valueAlreadyAdded) {
                    if (checkTempCycle(tempValues[i].id)) {
                        break;
                    }
                    self.aggregateOptions.push({ id: tempValues[i].id, name: tempValues[i].name });
                    var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                    if (tempValuesForAggregation) {
                        tempValuesForAggregation.push(tempValues[i]);
                    }
                }
            }
        }
        if (self.aggregateOptions().indexOf('' + self.selectedAggregate()) == -1) {
            self.selectedAggregate(null);
        }
    }

    textAreaHeight() {
        var self=this;
        // var vh_60 = Math.round(window.innerHeight / (100 / 60));
        // setTimeout(function(){
        //     if($('#formula'+ self.encodeId).prop('scrollHeight') > vh_60)
        //         $('#formula'+ self.encodeId).height(vh_60+"px");
        //     else if($('#formula'+ self.encodeId).prop('scrollHeight')>54)
        //         $('#formula'+ self.encodeId).height($('#formula'+ self.encodeId).prop("scrollHeight")+"px");
        // },300);
        if(self.formula()){
            if(self.formula().length >650 )
                $('#formula'+ self.encodeId).height(300+"px");
            else if(self.formula().length>300)
                $('#formula'+ self.encodeId).height(200+"px");
            else if(self.formula().length>200)
                $('#formula'+ self.encodeId).height(100+"px");
        }
    }
    initializeSeasonalPatternTable(){
        var self = this;
        var plan = DataManager.getDataManager().get("currentPlan");
        var periodKind = plan.get('periodKind');
        var currentYear = getYear(new Date(self.defaultScenario.get('startTime')))
        var periodLabels = [];
        var periodMap = {
            'Quarterly': 4,
            'Monthly': 12,
            '4-Weekly': 13,
            'Weekly': 52
        };
        if (periodKind === 'Yearly') {
            $('#seasonalPatternChk').prop('disabled', true);
            return;
        }
        var numPeriods = periodMap[periodKind] || 0;

        for (var i = 1; i <= numPeriods; i++) {
            var period = getPeriodKinds(currentYear, i, periodKind, { week: self.labels['week'](), quarter: self.labels['Quarter']() });
            periodLabels.push(currentYear + ' - ' + period.text);
        }
        const projectionProfile = self.model ? self.model.get('projectionProfile') : null;
        const seasonalFactorsFromModel = projectionProfile ? projectionProfile.get('seasonalFactors').models : [];
    
        var data = periodLabels.map((label, index) => {
            const factorData = seasonalFactorsFromModel[index];
            return { month: label, factor: factorData ? factorData.get('quantity') : 1 };
        });

        self.seasonalFactors(data);

        self.patternDataTable = $('#seasonalPattern').DataTable({
            data: self.seasonalFactors(),
            columns: [
                { data: 'month', title: 'Period', className: 'col-xs-5' },
                {
                    data: 'factor',
                    title: 'Factor',
                    className: 'editable col-xs-5',
                    render: function(data, type, row, meta) {
                        if (type === 'display') {
                            return '<input type="number" class="form-control" value="' + (data || 1) + '" step="0.1">';
                        }
                        return data;
                    }
                }
            ],
            scrollY: "200px",
            scrollCollapse: true,
            paging: false,
            width: "100%",
            ordering: false,
            searching: false,
            info: false
        });
    
        $('#seasonalPattern').on('change', 'input', 'keyup', function() {
            var rowIndex = self.patternDataTable.row($(this).parents('tr')).index();
            self.seasonalFactors()[rowIndex].factor = $(this).val();
        });
        if(seasonalFactorsFromModel.length > 0){
            $('#seasonalPattern').find('input').prop('disabled', true);
        }
        self.patternDataTable.columns.adjust().draw();
        
        function getPeriodKinds(year, period, periodKind, labels) {
            var periodKindValue;
            if (periodKind === 'Monthly') {
                const date = new Date(year, period - 1, 1);
                periodKindValue = date.toLocaleString("default", { month: "long" });
            } else if (['4-Weekly', 'Weekly'].includes(periodKind)) {
                periodKindValue = labels.week + "-" + period;
            } else if (periodKind === 'Quarterly') {
                periodKindValue = labels.quarter + "-" + period;
            }
            return { text: periodKindValue, value: +period };
        }
    }
    updatePolynomialTable(degree){
        var self = this;
        if (self.polynomialDataTable) {
            self.polynomialDataTable.destroy();
            $('#polynomialPattern').empty();
            self.polynomialDataTable = null;
        }
        const projectionProfile = self.model ? self.model.get('projectionProfile') : null;
        const coefficientsFromModel = projectionProfile ? projectionProfile.get('coefficients').models : [];
    
        var data = [];
        for (var i = 1; i <= degree; i++) {
            const coefficientData = coefficientsFromModel.find(model => model.get('sequence') === i);
            data.push({ coefficient: 'c' + i, value: coefficientData ? coefficientData.get('coefficient') : 0 });
        }
        self.coefficients(data);
        self.polynomialDataTable = $('#polynomialPattern').DataTable({
            data: self.coefficients(),
            columns: [
                { data: 'coefficient', title: 'Coefficient', className: 'col-xs-5' },
                {
                    data: 'value', title: 'Value', className: 'editable col-xs-5',
                    render: function(data) {
                        return '<input type="number" class="form-control" value="' + data + '" step="0.1">';
                    }
                }
            ],
            scrollY: "150px",
            scrollCollapse: true,
            paging: false,
            ordering: false,
            searching: false,
            info: false
        });

        $('#polynomialPattern').on('change', 'input', 'keyup', function() {
            var rowIndex = self.polynomialDataTable.row($(this).parents('tr')).index();
            self.coefficients()[rowIndex].value = $(this).val();
        });
        self.polynomialDataTable.columns.adjust().draw();
    }
    editSeasonalPattern(){
        var self = this;
        self.showTable(!self.showTable());
        if (!self.patternDataTable) {
            self.initializeSeasonalPatternTable();
        }
    }
    toggleTrendModel(){
        var self = this;
        self.isContentVisible(!self.isContentVisible());
        if(self.polynomialDataTable){
            self.polynomialDataTable.columns.adjust().draw();
        }
    }
    populateTrendModels() {
        var self = this;
        var projectionProfile = self.model ? self.model.get('projectionProfile') : null;
        if (projectionProfile) {
            var trendModel = projectionProfile.get('trendModel');
            if(trendModel){
                self.selectedTrendModel(trendModel);
                var rateFactor = projectionProfile.get('rateFactor');
                var polynomialDegree = projectionProfile.get('polynomialDegree');
                if (trendModel === 'Exponential' || trendModel.name === 'Exponential') {
                    self.expGrowthRate(rateFactor);
                } else if (trendModel === 'Linear' || trendModel.name === 'Linear') {
                    self.growthRate(rateFactor); 
                } else if (trendModel === 'Power' || trendModel.name === 'Power') {
                    self.exponent(rateFactor); 
                } else if (trendModel === 'Logarithmic' || trendModel.name === 'Logarithmic') {
                    self.scalingFactor(rateFactor);
                } else if (trendModel === 'Polynomial' || trendModel.name === 'Polynomial') {
                    self.degree(polynomialDegree);
                }
                self.rateFactor(rateFactor);
            }
            var seasonalFactors = projectionProfile.get('seasonalFactors');
            if (seasonalFactors && projectionProfile.get('seasonalFactors').models.length > 0) {
                self.seasonalPatternCheck(true);
            } else {
                self.seasonalPatternCheck(false);
            }
        }
    };

    init(model, options) {
        var self = this;
        this.ValueDetails2ViewModel = this;
        this.model = model;
        model = null;
        this.options = options;
        options = null;
        this.dialogOpenCount = 0;
        this.showValueDefinition = ko.observable(false);
        this.usedValueDefName = ko.observable();
        this.expBuilder = null;
        this.expBuilderOptions = {
            variables: [],
            expression: ""
          };
        this.valueLibs = [];
        this.usedValue = null;
        this.id = ko.observable(self.model ? self.model.get('id') : null);
        this.encodeId = self.htmlEscape(this.id());
        this.selectableValueNames = new Backbone.Collection();
        this.planValue = self.options.addOptions.planValue;
        this.currentAlternativeModel = self.options.addOptions.currentAlternativeModel;
        if (!this.currentAlternativeModel) {
            this.currentAlternativeModel = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: DataManager.getDataManager().get('viewAlternative') });
        }
        this.disableAggr = self.options.addOptions.disableAggr;
        if (self.options && self.options.parentView) {
            this.parentView = self.options.parentView;
            this.encodeId = "" + self.options.modalId;
            this.scenario = self.options.addOptions.mainScenario;
            if (this.planValue && this.currentAlternativeModel) {
                this.scenario = this.currentAlternativeModel.getOrCreatePhaseObjectiveSet();
            }
            this.readOnly = self.options.addOptions.readOnly;
            this.period = self.options.addOptions.period;
            this.year = self.options.addOptions.year;
            var plan = DataManager.getDataManager().get('currentPlan');
            this.defaultScenario = DataManager.getDataManager().get('defaultScenario');
            var defaultExecutionScenaroId = self.defaultScenario.get("defaultExecutionScenario");
            var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
            this.periodDataset = defaultExecutionScenario.get("input").findWhere({period: self.period, periodKind : plan.get("periodKind"), year:self.year});
            this.calcPeriodDataset = defaultExecutionScenario.get("result").findWhere({period: self.period, periodKind : plan.get("periodKind"), year:self.year});
        }
        this.maxChange = ko.observable(DataManager.getDataManager().get('currentPlan') ? DataManager.getDataManager().get('currentPlan').get('maxChange') : 0.0001);
        this.postShow = false;
        this.showPythonModal = ko.observable(false);
        if (DataManager.getDataManager().get('debugMode')) {
            this.showPythonModal(true);
        }
        this.gradeSatisfaction = ko.observable();
        this.gradeSatisfaction.subscribe(function (value) {
            self.satisfaction(value);
            if (self.satisfactionOnly()) {
                for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                    if (self.satisfactionIntervalColl()[i].intervalName === value) {
                        var smileySrc = self.fetchSmileySrc(self.satisfactionIntervalColl()[i].smiley);
                        $("#showSelectSatisfactionImg" + self.encodeId).attr('d', smileySrc);
                        $("#showSelectSatisfactionImg" + self.encodeId).attr('fill', self.satisfactionIntervalColl()[i].smileyColor);
                        break;
                    }
                }
            }
        });
        var planPackages = DataManager.getDataManager().get('currentPlan').getPlanPackageList('vdml_ValueDeliveryModel',true);
        var hasLib = planPackages.filter(b=>b.beepReference.indexOf('Common@') != -1 && b.beepType == 'vdml_ValueDeliveryModel' && b.beepReference.indexOf('CommonPackage') == -1);
        if (hasLib && hasLib.length > 0) {
            self.showValueDefinition(true);
        }
        this.valChildren = [];
        this.tempMeasurementRoundingValue;
        this.name = ko.observable(self.model ? self.model.get('name') : "");
        this.originalId = ko.observable();
        this.showAggregateDiv = ko.observable(false);
        this.measurementValue = ko.observable("");
        this.formula = ko.observable("");
        this.visibleSatisfactionType = ko.observable(false);
        this.enableExpr = ko.observable(false);
        this.recipientValue = ko.observable("");
        this.recipientSatValue = ko.observable("");
        this.enableDelete = ko.observable(false);
        this.satisfactionIntervalDiv = ko.observable(false);
        this.enableName = ko.observable(true);
        this.showSource = ko.observable(false);
        this.weightRecipientVisible = ko.observable(true);
        this.showGradeOnly = ko.observable(true);
        this.showValues = ko.observable(false);
        this.enableCommon = ko.observable(false);
        this.enableSatisfactionOnly = ko.observable(false);
        this.visibleComplete = ko.observable(true);
        this.componentPrimed = ko.observable(false);
        this.visibleMove = ko.observable(false);
        if (!self.planValue) {
            this.checkCommon = ko.observable(false);
        }
        this.showOverall = ko.observable(true);
        this.showDimensional = ko.observable(false);
        this.checkDimensional = ko.observable(true);
        this.satisfactionOnly = ko.observable(false);
        this.showValuePath = ko.observable(false);
        this.aggregateToColl = ko.observableArray([]);
        this.showIntervalFromUI = ko.observable(true);
        this.showIntervalToUI = ko.observable(true);
        this.roundingFactor = ko.observable(false);
        this.minRange = ko.observable();
        this.maxRange = ko.observable();

        self.disallowedNames = [];
        if (self.model) {
            var parentType = "component";
            if (self.model.get('type') === "vdml_ValueAdd") {
                parentType = "valueAdd";
            }
            self.disallowedNames = self.currentAlternativeModel.getDisallowedNames(self.model, parentType);

            if (self.parentView && (self.parentView.get('type') === "vdml_BusinessModel" || self.parentView.get('type') === "transformation_Plan")) {
                self.visibleMove(true);
            }
        }
        this.hideDelete = false;
        if (self.parentView && (self.parentView.get('type') == "vdml_ValueAdd" || self.parentView.get('type') == "vdml_ValuePropositionComponent")) {
            this.hideDelete = true;
        }

        this.roundingDigits = ko.observable(4);
        this.significantDecimals = ko.observable(2);
        this.satRoundingDigits = ko.observable(4);
        this.satSignificantDecimals = ko.observable(2);
        this.activityOptions = ko.observableArray([]);
        this.selectedActivity = ko.observable();
        this.valueOptions = ko.observableArray([]);
        this.selectedvalue = ko.observable();
        this.enableValueOptions = ko.observable(true);
        this.enableActivityOptions = ko.observable(true);
        this.existingValueModal = null;
        this.contributeWhatValuesAggregations = [];
        this.tempValuesAggregated = [];
        this.aggregateOptions = ko.observableArray([]);
        this.aggregateColl = ko.observableArray([]);
        this.selectedAggregate = ko.observable();
        this.enableMeasurement = ko.observable(true);
        this.checkPrime = ko.observable(true);
        this.satisfaction = ko.observable();
        this.enableSatisfaction = ko.observable(false);
        this.enableSatisfactionType = ko.observable(false);
        this.enableWeight = ko.observable(true);
        this.weight = ko.observable();
        this.myPropositionName = DataManager.getDataManager().get('localeManager').get('MyProposition');
        this.valuePropositionName = DataManager.getDataManager().get('localeManager').get('ValueProposition');
        this.activityName = DataManager.getDataManager().get('localeManager').get('Activity');
        this.satisfactionIntervalArray = [];
        this.satisfactionIntervalColl = ko.observableArray(this.satisfactionIntervalArray);
        var SatisfactionType = function (name, type, typeStr) {
            this.name = name;
            this.type = type;
            this.typeStr = typeStr;
        };
        var GradeMeasure = Backbone.Relational.store.getObjectByName("smm.GradeMeasure");
        var RankingMeasure = Backbone.Relational.store.getObjectByName("smm.RankingMeasure");
        var satisTypeArray = [new SatisfactionType("Grade", GradeMeasure, "smm_GradeMeasure"),
        new SatisfactionType("Ranking", RankingMeasure, "smm_RankingMeasure")
        ];
        this.satisfactionTypeOptions = ko.observableArray(satisTypeArray);
        this.satisfactionType = ko.observable();
        this.enableSatisfaction = ko.observable(false);
        this.sIndex = null;
        this.aIndex = null;
        this.measurementUnit = ko.observable("");
        this.satisfactionUnitValue = ko.observable("");
        this.intervalTuples = [];
        this.baseTuples = [];
        this.deleteTuple = false;
        var valueMeasurement = null;
        this.showValue = ko.observable(false);
        this.aggregateEdit = true;
        this.tempAggregateId;
        this.changeInSigValue = false;
        this.measureUnits = new Backbone.Collection();
        this.checkFromInclusive = ko.observable(false);
        this.checkToInclusive = ko.observable(false);
        this.negativeInfinity = ko.observable(false);
        this.positiveInfinity = ko.observable(false);
        this.correctiveActions = ko.observable("");
        this.svgSmiliesList = window.utils.getSmiliesList();
        this.colorsList = ["rgb(250,174,11)", "rgb(30,166,224)", "rgb(245,35,45)", "rgb(209,223,18)", "rgb(0,0,0)"];
        this.previouslyUsedColor = "rgb(0,0,0)";

        this.defaultSmiley = ko.observable(self.svgSmiliesList[0].src);
        this.smilies = ko.observableArray(self.svgSmiliesList);
        this.trendModelOptions = TrendModel.symbols();
        this.selectedTrendModel = ko.observable(null);
        this.growthRate = ko.observable(null);
        this.expGrowthRate = ko.observable(null);
        this.scalingFactor = ko.observable(null);
        this.degree = ko.observable(null);
        this.exponent = ko.observable(null);
        this.coefficients = ko.observableArray([]);
        this.seasonalPatternCheck = ko.observable(false);
        this.showTable = ko.observable(false);
        this.patternDataTable = null;
        this.seasonalFactors = ko.observableArray([]);
        this.showPolynomialTable = ko.observable(false);
        this.polynomialDataTable = null;
        this.trendModelDiv = ko.observable(true);
        this.rateFactor = ko.observable();
        this.isContentVisible = ko.observable(false);
        this.polynomialDegree = ko.observable();
        const trendModelVisibility = (modelName) => this.selectedTrendModel()?.name === modelName;
        this.showExponential = ko.computed(() => trendModelVisibility('Exponential'));
        this.showLogarithmic = ko.computed(() => trendModelVisibility('Logarithmic'));
        this.showPolynomial = ko.computed(() => trendModelVisibility('Polynomial'));
        this.showPower = ko.computed(() => trendModelVisibility('Power'));
        this.showLinear = ko.computed(() => trendModelVisibility('Linear'));

        this.selectedTrendModel.subscribe(newValue => {
            self.rateFactor(null)
            self.degree(null);
            if (self.polynomialDataTable) {
                self.polynomialDataTable.destroy();
                $('#polynomialPattern').empty();
                self.polynomialDataTable = null;
            }
            if (trendModelVisibility('Polynomial')) {
                self.updatePolynomialTable(self.degree());
                self.showPolynomialTable(true);
            }
        });
        this.degree.subscribe(newValue => { self.updatePolynomialTable(newValue) });
        this.seasonalPatternCheck.subscribe(newValue => {
            if (!newValue) {
                if (self.patternDataTable) {
                    self.patternDataTable.destroy();
                    $('#seasonalPattern').empty();
                    self.patternDataTable = null;
                }
            }
        });
        this.saveRateFactor = function() {
            var self = this;
            if (self.showExponential()) {
                return self.expGrowthRate();
            } else if (self.showLinear()) {
                return self.growthRate();
            } else if (self.showPower()) {
                return self.exponent();
            } else if (self.showLogarithmic()) {
                return self.scalingFactor();
            }
            return null;
        };
        this.polynomialCoefficient = function() {
            var self = this;
            if (this.showPolynomial()) {
                var polynomialData = {
                    polynomialDegree: self.degree(),
                    coefficients: self.coefficients().map(function(coefficient) {
                        return parseFloat(coefficient.value) || 0;
                    })
                };
                return polynomialData;
            }
            return null;
        };
        this.saveSeasonalFactors = function() {
            if (self.seasonalPatternCheck()) {
                return self.seasonalFactors().map(function(month) {
                    return parseInt(month.factor) || 1;
                });
            }
            return [];
        }

        this.isCyclic = ko.observable(false);
        this.showValueMappedInfo = function (){
            var id = window.utils.htmlEscape(window.guidGenerator());
            var currentAltId = DataManager.getDataManager().get('viewAlternative');
            var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
            var currentAlt = docTypeObj.find({
                id: currentAltId
            });
            var valModel = window.utils.getElementModel(self.usedValue, ['vdml.ValueCategory', 'vdml.ValueDefinition']);
            if (!valModel) {
                bootbox.alert("No such Element");
                return;
            }
            var scenario = currentAlt.getMainScenario(valModel.getNestedParent());
            var options = {};
            options.mappedModal = valModel;
            options.context = scenario;
            options.width = '800px';
            window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function(refresh) {}, options);
        };
        this.deleteValueDef = function () {
            $("#mapValueDef" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
            self.usedValueDefName("");
            $('#deleteValueDef' + self.encodeId).hide();
            $('#showValueMappedInfo' + self.encodeId).hide();
            self.usedValue = null;
        };
        this.mapValueDef = function () {
            var id = self.htmlEscape(window.guidGenerator());
            var currentPlan = DataManager.getDataManager().get('currentPlan');
            currentPlan.loadValueLibraries(function (valLibraries) {
                if (valLibraries) {
                    var options = {
                        valueLibs: valLibraries, callback: function (valId, valName) {
                            if (!valId) {
                                return;
                            }
                            self.usedValue = valId[0];
                            var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                            if (valueDef) {
                                self.usedValueDefName(valueDef.get('name'));
                                $("#mapValueDef" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                                $('#deleteValueDef' + self.encodeId).show();
                                $('#showValueMappedInfo' + self.encodeId).show();
                                if ($('#valueName' + self.encodeId).val() === "") {
                                    self.name(valueDef.get('name'));
                                    $('#valueName' + self.encodeId).val(valueDef.get('name'))
                                }
                                if ($('#measurementUnit' + self.encodeId).val() === "" && self.enableMeasurement()) {
                                    $('#measurementUnit' + self.encodeId).val(valueDef.get('unit'));
                                    self.measurementUnit(valueDef.get('unit'));
                                }
                            }
                        }
                    };
                    if (self.usedValue) {
                        options.showValueElementId = self.usedValue;
                    }
                    options.showPhases = true;
                    options.hideCheck = true;
                    var ValueDeliveryModelMixin = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModelMixin");
                    window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
                }
            })
        };

        this.selectedSmiley = function (view, event) {
            for (var i = 0; i < self.smilies().length; i++) {
                if (self.smilies()[i].src === this.src) {
                    $("#intervalSmileyPath" + self.encodeId).attr('d', self.smilies()[i].src);
                    -       			$("#intervalSmiley" + self.encodeId).attr('value', self.smilies()[i].value);
                    break;
                }
            }
        };

        this.selectedActivity.subscribe(function (value) {
            if (!self.model) {
                fillTypeAheadNames(value);
            }
            var name = self.name();
            self.name("");
            self.name(name);
            self.getUniqueValue(self.name());
        });
        this.measurementValue.subscribe(function (value) {
            if (value && self.checkDimensional()) {
                self.calculateSatisfaction();
            }
            if (!value) {
                $("#showSatisfaction" + self.encodeId).html('');
                self.measurementValue("");
            }
        });
        this.satisfactionOnly.subscribe(function (checked) {
            if (checked) {
                function setSatisfactionOnly() {
                    self.checkDimensional(false);
                    if (self.matchField) {
                        self.matchField.hide();
                    }
                    for (var i = 0; i < self.aggregateColl().length; i++) {
                        var view = { 'seqNo': self.aggregateColl()[i].seqNo };
                        self.deleteAggregateDetails(view);
                    }
                    self.aggregateToColl.removeAll();
                    $('#aggregateAddIconDiv' + self.encodeId).hide();
                    $('#aggregatedFromLabel' + self.encodeId).hide();
                    self.weightRecipientVisible(false);
                    fillDefaultSatisfactionInterval();
                    if (self.satisfactionIntervalColl()[0] && self.postShow) {
                        self.satisfaction(self.satisfactionIntervalColl()[0].intervalName);
                    }
                }
                if (window.utils.getObservableLength(self.aggregateColl()) > 0 || self.aggregateToColl().length > 0) {
                    bootbox.confirm(DataManager.getDataManager().get('localeManager').get('SatisfactionOnlyMessage'), function (result, event) {
                        if (result) {
                            setSatisfactionOnly();
                        } else {
                            self.satisfactionOnly(false);
                        }
                    });
                } else {
                    setSatisfactionOnly();
                }

            }
            else {
                self.checkDimensional(true);
                $('#aggregateAddIconDiv' + self.encodeId).show();
                $('#aggregatedFromLabel' + self.encodeId).show();
                $('#measurementUnit' + self.encodeId).prop('disabled', false);
                if ($('#measurementUnit' + self.encodeId).val() !== "") {
                    self.roundingFactor(true);
                }
                else {
                    self.roundingFactor(false);
                }
                $('#recipientVal' + self.encodeId).prop('disabled', false);
                $('#satisfactionUnit' + self.encodeId).prop('disabled', false);
                self.weightRecipientVisible(true);
            }
        });


        if (!self.planValue) {
            this.checkCommon.subscribe(function (cond) {
                if (cond) {
                    self.scenario = DataManager.getDataManager().get('currentCommonScenario');
                }
                else {
                    self.scenario = self.options.addOptions.mainScenario;
                }
                if (self.postShow && self.model) {
                    valueMeasurement = self.getMeasurement('valueMeasurement', true);
                    var satisfactionMeasurement = self.getMeasurement('satisfactionLevel', true);
                    var weightMeasurement = self.getMeasurement('percentageWeight', true);
                    var recipientMeasurement = self.getMeasurement('recipientOpinion');
                    if (valueMeasurement) {
                        self.clearValues();
                        self.fillValues(valueMeasurement, satisfactionMeasurement, weightMeasurement, recipientMeasurement, false);
                    } else {
                        self.clearValues();
                    }
                }
            });
        }

        this.setOptions = _.bind(self.setOptionsHandler, self);

        this.satisfactionType.subscribe(function (type) {
            if (type === "smm_GradeMeasure") {
                if (self.isDimensional) {//Ranking
                    if (self.model) {
                        $("#satisfactionUnit" + self.encodeId).hide();
                        $("#satisfactionUnitMark" + self.encodeId).hide();
                        self.clearTuples(type);
                    } else {
                        $("#satisfactionUnit" + self.encodeId).hide();
                        $("#satisfactionUnitMark" + self.encodeId).hide();
                        self.clearTuples(type);
                    }
                }
            } else {
                if (!$("#satisfactionUnitMark" + self.encodeId).is(":visible")) {
                    $("#intervalFromDiv" + self.encodeId).show();
                    $("#intervalToDiv" + self.encodeId).show();
                    $("#showSelectSatisfaction" + self.encodeId).hide();
                    $("#satisfactionUnit" + self.encodeId).show();
                    $("#satisfactionUnitMark" + self.encodeId).show();
                    self.clearTuples(type);
                }
            }

        });

        this.checkDimensional.subscribe(function (cond) {
            if (!cond) {
                $("#intervalFromDiv" + self.encodeId).hide();
                $("#intervalToDiv" + self.encodeId).hide();
                $("#showSelectSatisfaction" + self.encodeId).show();
                $("#showSatisfaction" + self.encodeId).hide();
                $("#showSatisfactionImg" + self.encodeId).attr('d', '');
                $("#showSelectSatisfactionImg" + self.encodeId).attr('d', '');
                self.enableMeasurement(cond);
                self.measurementValue("");
                self.measurementUnit("");
                $('#measurementUnit' + self.encodeId).val("");
                self.enableSatisfactionType(cond);
                self.satisfactionType("smm_GradeMeasure");
            }
            else {
                $("#intervalFromDiv" + self.encodeId).show();
                $("#intervalToDiv" + self.encodeId).show();
                $("#showSelectSatisfaction" + self.encodeId).hide();
                self.calculateSatisfaction();
                self.enableMeasurement(cond);
                self.enableSatisfactionType(cond);
                $('#formula' + self.encodeId).prop('disabled', !cond);
            }
        });

        this.fillValueDefinition = function () {
            var valueDef;
            if (self.model) {
                valueDef = self.model.get('valueDefinition');
            }
            if (self.usedValue) {
                valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
            }
            if (valueDef) {
                self.usedValue = valueDef.id;
                self.usedValueDefName(valueDef.get('name'))
                $("#mapValueDef" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                $('#deleteValueDef' + self.encodeId).show();
                $('#showValueMappedInfo' + self.encodeId).show();
                /*
                if (value.get('unit') != valueDef.get('unit')) {
                    value.set('unit', valueDef.get('unit'))
                }*/
                if ($('#measurementUnit' + self.encodeId).val() === "" && self.enableMeasurement()) {
                    $('#measurementUnit' + self.encodeId).val(valueDef.get('unit'));
                    self.measurementUnit(valueDef.get('unit'));
                }
            } else {
                self.usedValue = null;
                self.usedValueDefName("")
                $("#mapValueDef" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
                $('#deleteValueDef' + self.encodeId).hide();
                $('#showValueMappedInfo' + self.encodeId).hide();
            }
        }

        this.selectedvalue.subscribe(function (value) {
            var comp = self.options.addOptions.vpValue.findWhere({ 'id': value });
            self.model = comp;
            if (self.model) {
                self.name(self.model.get('name'));
                self.id(self.model.get('id'));
                if (!this.planValue) {
                    self.checkCommon(false);
                }
                if (!self.planValue) {
                    self.scenario = self.currentAlternativeModel.getMainScenario(comp.getNestedParent());
                }

                valueMeasurement = self.getMeasurement('valueMeasurement');
                var satisfactionMeasurement = self.getMeasurement('satisfactionLevel');
                var weightMeasurement = self.getMeasurement('percentageWeight');
                var recipientMeasurement = self.getMeasurement('recipientOpinion');
                self.clearValues(true);
                self.fillValues(valueMeasurement, satisfactionMeasurement, weightMeasurement, recipientMeasurement, true);
                self.fillAggregatedValues();
                self.disableUIComponents(true);
                $('#aggregateAddIconDiv' + self.encodeId).show();
                self.visibleComplete(true);
                $('#primeManditorySymbol' + self.encodeId).hide();
                $('#satisfactionIntervalManditiorySymbol' + self.encodeId).hide();
                for (var i = 0; i < self.model.get('aggregatedFrom').length; i++) {
                    self.filterAggregateOptions(self.model.get('aggregatedFrom').at(i).get('id'));
                }
                for (var i = 0; i < self.aggregateOptions().length; i++) {
                    if (self.aggregateOptions()[i].id === value) {
                        self.aggregateOptions().splice(i, 1);
                        break;
                    }
                }
            }
            else {
                self.clearValues(true);
            }
        });


        self.fillMeasurementUnits();
        self.fillAggregatedValues();

        if (self.options.addOptions.activities) {
            for (var i = 0; i < self.options.addOptions.activities.length; i++) {
                self.activityOptions.push(self.options.addOptions.activities[i]);
            }
        }

        this.isDimensional = true;
        this.satisfactionMeasure;
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name'
            , 'description'
            , 'Role'
            , 'type'
            , 'Close'
            , 'Complete'
            , 'Delete'
            , 'addAnother'
            , 'source'
            , 'sourceType'
            , 'custom'
            , 'Cancel'
            , 'selectAValue'
            , 'SelectRecipient'
            , 'Maxchange'
            , 'GradeOnly'
            , 'recipientOpinion'
            , 'Weight'
            , 'AggregatedFrom'
            , 'FromInclusive'
            , 'PositiveInfinity'
            , 'IntervalFrom'
            , 'IntervalTo'
            , 'NegativeInfinity'
            , 'value'
            , 'AggregatePreviousPhase'
            , 'satisfaction'
            , 'SatisfactionType'
            , 'Level'
            , 'ToInclusive'
            , 'SatisfactionIntervals'
            , 'AggregatedTo'
            , 'ValueFormula'
            , 'AggregationView'
            , 'PickSmiley'
            , 'CorrectiveActions'
            , 'Definitions'
            , 'ValueDefinition'
            , 'Move'
            , 'Quantity'
            , 'week'
            , 'Quarter'
            , 'Growthrate'
            , 'TrendModel'
            , 'ScalingFactor'
            , 'Degree'
            , 'Exponent'
            , 'SeasonalPattern'
            , 'Period'
            , 'BaselineQuantity'
            , 'SeasonalFactors'
            ,'JuliaExpr'
        ]);

        if (self.model) {
            if (self.options.addOptions.type === "contributeWhatValues") {
                self.selectedvalue(self.model.get('id'));
                self.enableValueOptions(false);
            }
            else {
                //valueMeasurement = self.getMeasurement('valueMeasurement');
                var satisfactionMeasurement = self.getMeasurement('satisfactionLevel');
                var weightMeasurement = self.getMeasurement('percentageWeight');
                var recipientMeasurement = self.getMeasurement('recipientOpinion');
                self.fillValues(null, satisfactionMeasurement, weightMeasurement, recipientMeasurement, true);
                var satisfactionMeasurements = self.model.get('satisfactionLevel') ? self.model.get('satisfactionLevel').get('measurement') : [];
                if (satisfactionMeasurements.length > 1 || (satisfactionMeasurements.length == 1 && satisfactionMeasurements.at(0) != satisfactionMeasurement)) {
                    var type = self.model.get('satisfactionLevel').get('measurement').at(0).get('observedMeasure').get('measure').get('type');
                    self.satisfactionType(type);
                    self.enableSatisfactionType(false);
                    self.enableSatisfactionOnly(false);//since this value exists in more than one alternative
                    /*if (self.model.get('valueMeasurement').get('measurement').length == 0) {
                        self.satisfactionOnly(true);
                    }*/
                }
                if (self.options.addOptions.type === "createWhatValues") {
                    self.selectedActivity(self.htmlEscape(self.model.get('valueAddOwner').get('containedPortOwner').get('id')));
                    self.enableActivityOptions(false);
                }
            }
        }
        else {
            self.calculateMeasurentValues();
        }

        if (self.options.addOptions.vpValue) {
            var valueProp = self.options.addOptions.vpValue;
            for (var i = 0; i < valueProp.length; i++) {
                    if (valueProp.at(i).get('satisfactionLevel') && valueProp.at(i).get('satisfactionLevel').get('measurement').length >= 1 && valueProp.at(i).get('valueMeasurement').get('measurement').length == 0) {
                        continue;//not allowing grade only
                    }
                    self.valueOptions.push({ id: valueProp.at(i).get('id'), name: valueProp.at(i).get('name') });
            }
            self.valueOptions.sort(function (left, right) {
                return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1)
            });
        }

        this.selectedAggregate.subscribe(function (aggregate) {
            self.fillAggregateUIDetails(aggregate);
        });

        this.openValue = _.bind(this.openValueHandler, self);
        this.editAggregateDetails = _.bind(this.editAggregateDetailsHandler, self);
        this.editSatisfactionInterval = _.bind(self.editSatisfactionIntervalHandler, self);
        this.deleteAggregateDetails = _.bind(self.deleteAggregateDetailsHandler, self);

        ko.bindingHandlers.numeric = {
            init: function (element, valueAccessor) {
                $(element).on("keydown", function (event) {
                    $(element).prop('oldValue', $(element).val());
                    window.utils.checkNumericValue(event, element);
                });
                $(element).on("keyup", function (event) {
                    if (!validate($(event.target).val())) {
                        $(event.target).val($(element).prop('oldValue'));
                    }
                    function validate(s) {
                        var rgx = /\-?[0-9]*\.?[0-9]*$/;
                        if (s.match(rgx) == s) {
                            return true;
                        }
                        return false;
                    }
                });
            }
        };

        ko.bindingHandlers.weightNumeric = {
            init: function (element) {
                $(element).on("keydown", function (event) {
                    // Allow: backspace, delete, tab, escape, and enter
                    if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
                        // Allow: Ctrl+A
                        (event.keyCode == 65 && event.ctrlKey === true) ||
                        // Allow: Ctrl+v
                        (event.keyCode == 86 && event.ctrlKey === true) ||
                        // Allow: Ctrl+c
                        (event.keyCode == 67 && event.ctrlKey === true) ||
                        /*// Allow: . ,
                        (event.keyCode == 188 || event.keyCode == 190 || event.keyCode == 110) ||*/
                        // Allow: home, end, left, right
                        (event.keyCode >= 35 && event.keyCode <= 39)) {
                        // let it happen, don't do anything
                        return;
                    }
                    else {
                        // Ensure that it is a number and stop the keypress
                        //if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                        if (event.shiftKey || ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) ||
                            !(parseInt(event.key) >= 0 || parseInt(event.key) <= 9)) {
                            event.preventDefault();
                        }
                    }
                });
            }
        };
        this.cleanModal = _.bind(self.cleanModalHandler, self);

        this.deleteModal = function () {
            return self.options.callback(this, self.encodeId, self.options.addOptions.type, true);
        }


        $('#modal' + self.encodeId).on('shown.bs.modal', function () {
            window.utils.focusElement("#newModal .focus-ele");
            $("#valueName" + self.encodeId).focus();
            if (self.name() != null && self.name() !== '') {
                if (!self.hideDelete && (self.showOverall())) {
                    self.enableDelete(true);
                }
                else {
                    self.enableDelete(false);
                }
            }
            if(self.satisfactionOnly()){
                $('#aggregateAddIconDiv' + self.encodeId).hide();
                $('#aggregatedFromLabel' + self.encodeId).hide();
            }
            if (self.options.addOptions.type === "contributeWhatValues") {
                if (self.dialogOpenCount === 0) {
                    self.enableDelete(false);
                }
                else {
                    self.enableValueOptions(false);
                }
            }
            if (self.options.addOptions.disableDelete) {//for R.H.S(disabling revision process)
                self.enableDelete(false);
            }
            //for component
            if (self.readOnly) {
                self.disableUIComponents();
                self.visibleComplete(false);
            }
            if (self.options.addOptions.aggTo) {
                self.disableUIComponents(true);
                $("icon[id*='deleteAggregate" + self.encodeId + "']").hide();
            }
            if (self.options.addOptions.aggregationView) {//preventing addition of aggregated from in aggregation value edit mode
                $('#aggregateAddIconDiv' + self.encodeId).hide();
                if (!self.model) {
                    $('#aggregatedFromLabel' + self.encodeId).hide();
                }
            }
            //for modal window close functionality
            $('#valueName' + self.encodeId).val(self.name());
            $('#measurementUnit' + self.encodeId).val(self.measurementUnit());
            $('#satisfactionUnit' + self.encodeId).val(self.satisfactionUnitValue());
            self.dialogOpenCount++;
        });


        this.saveValueDetails = _.bind(self.saveValueDetailsHandler, self);

        function fillTypeAheadNames(modalId) {
            self.selectableValueNames.reset();
            if (self.options.addOptions.activities) {
                for (var i = 0; i < self.options.addOptions.activities.length; i++) {
                    if (self.options.addOptions.activities[i].modalId === modalId) {
                        var activityPort;
                        if (self.options.addOptions.activities[i].existingActivity) {
                            activityPort = self.options.addOptions.activities[i].existingActivity.get('containedPort');
                        }
                        else {
                            var actId = self.options.addOptions.activities[i].originalId;
                            if (actId) {
                                var bmActivities = self.parentView.get('bmActivity');

                                if (bmActivities) {
                                    for (var j = 0; j < bmActivities.length; j++) {
                                        if (bmActivities.at(j).get('activity') && bmActivities.at(j).get('activity').get('id') == actId) {
                                            activityPort = bmActivities.at(j).get('activity').get('containedPort');
                                            break;
                                        }
                                    }
                                } else if (!bmActivities && self.options.bm && self.parentView.getNestedParent().get('type') === "ecomap_EcoMap") {
                                    activityPort = self.options.bm.get('containedPort');
                                }
                            }
                        }
                        if (activityPort) {
                            for (var j = 0; j < activityPort.length; j++) {
                                if (activityPort.at(j).get('type') == 'vdml_OutputPort' && activityPort.at(j).get('valueAdd')) {
                                    self.selectableValueNames.add(activityPort.at(j).get('valueAdd').models);
                                }
                            }
                        }
                    }
                }
                var valColl = self.options.addOptions.valuesColl;
                for (var i = 0; i < valColl.length; i++) {
                    if (valColl[i].originalId != null || valColl[i].existingValue != null) {
                        var checkId = valColl[i].originalId;
                        if (valColl[i].existingValue) {
                            checkId = valColl[i].existingValue.get('id');
                        }
                        var valExists = self.selectableValueNames.findWhere({ 'id': checkId });
                        if (valExists) {
                            self.selectableValueNames.remove(valExists);
                        }
                    }
                }
            }
            self.selectableValueNames.models.sort(function (left, right) {
                return left.get('name').toLowerCase() == right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1)
            });
        }
        this.hideValueDiv = function () {
            self.showAggregateDiv(false);
            //self.aggregateDiv(true);
            //$('#editDetailsModalContent'+self.encodeId).css("height",$("#editDetailsModalContent"+self.encodeId).actual('outerHeight')-$("#aggregatedDetailsDiv"+self.encodeId).actual('outerHeight'));
        };
        this.showValueDiv = function () {
            if ($('#aggregateValue' + self.encodeId) && $('#aggregateValue' + self.encodeId).val() === "") {
                self.fillAggregateUIDetails(self.selectedAggregate());
            }
            self.showAggregateDiv(true);
            //self.aggregateDiv(false);
            //$('#editDetailsModalContent'+self.encodeId).css("height",$("#editDetailsModalContent"+self.encodeId).actual('outerHeight')+$("#aggregatedDetailsDiv"+self.encodeId).actual('outerHeight'));
        };
        this.moveValue = function (view) {
            var id = "moveValue" + view.encodeId;
            var addOptions = {};
            window.getAndCreateModalDialog(self, id, BusinessModel2Mixin, self.model, "moveValue", null, addOptions);
        }
        this.editRoundingFactor = function (view) {
            var id = "valueUnit" + view.encodeId;
            var addOptions = { roundingDigits: self.roundingDigits(), significantDecimals: self.significantDecimals() };
            var valUnit;
            if (self.model) {
                valUnit = self.model.get('unit');
            }
            window.getAndCreateModalDialog(self.model, id, BusinessModel2Mixin, valUnit, "ValueUnitDetails", self.saveUnitDetails, addOptions);
        }
        this.editSatisfactionRoundingFactor = function (view) {
            var id = "SatisfactionUnit" + view.encodeId;
            var addOptions = { roundingDigits: self.satRoundingDigits(), significantDecimals: self.satSignificantDecimals() };
            var satUnit;
            if (self.model) {
                var satMeasurement = self.getMeasurement('satisfactionLevel');
                if (satMeasurement) {
                    satUnit = satMeasurement.get('unit');
                }
            }
            window.getAndCreateModalDialog(self.model, id, BusinessModel2Mixin, satUnit, "ValueUnitDetails", self.saveSatisfactionUnitDetails, addOptions);
        }
        this.saveUnitDetails = function (roundingVal, sigDigits) {
            if (self.significantDecimals() !== sigDigits) {
                self.changeInSigValue = true;
            } else {
                self.changeInSigValue = false;
            }
            self.roundingDigits(roundingVal);
            self.significantDecimals(sigDigits);
            if (self.enableMeasurement() && !isNaN(self.measurementValue())) {
                self.measurementValue(window.utils.getRoundedValues(self.fullMeasurementValue, sigDigits));
            } else {
                self.calculateMeasurentValues();
            }
            if (!isNaN(self.recipientValue())) {
                self.recipientValue(window.utils.getRoundedValues(self.recipientValue(), sigDigits));
            }
        }
        this.saveSatisfactionUnitDetails = function (roundingVal, SigDigits) {
            self.satRoundingDigits(roundingVal);
            self.satSignificantDecimals(SigDigits);
        }
        this.showValuePathDivRow = function () {
            var partcipantNetwork = "", valuePathRole = "", valuePathSource = "", receiverValuePathRole = "", ProviderRole = "", receiverRole = "", PN = "Participant Network";
            if (self.model && self.readOnly != undefined && !self.planValue) {
                fillValuePath(self.model);
            }
            if (valuePathSource === "") {
                var componentOwner = self.model.get('componentOwner');
                if (componentOwner && componentOwner.get('planCriterionSetOwner')) {
                    valuePathSource = componentOwner.get('planCriterionSetOwner').get('name');
                    PN = "";
                    partcipantNetwork = "";
                }
            }
            var msg = "<div>\
				<div class=\"row\">\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+DataManager.getDataManager().get('localeManager').get('source')+"</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ valuePathSource + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div><br>\
				<div class=\"row\">\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+DataManager.getDataManager().get('localeManager').get('sourceType')+"</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ self.getSourceType(self.model) + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

            var pnDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ PN + "</span></span></div>\
					<div class=\"col-xs-6\"><span>"+ partcipantNetwork + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

            var providerDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ ProviderRole + "</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ valuePathRole + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

            var recieverDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ receiverRole + "</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ receiverValuePathRole + "</div><br>\
					<div class=\"col-xs-1\"></div><br>\
				</div>";

            if (PN !== "") {
                msg = msg.concat(pnDiv);
            }
            if (ProviderRole !== "") {
                msg = msg.concat(providerDiv);
            }
            if (receiverRole !== "") {
                msg = msg.concat(recieverDiv);
            }
            msg = msg.concat("</div>");
            const box = bootbox.dialog({
                message: msg,
                title: DataManager.getDataManager().get('localeManager').get('SourceDetails'),
                buttons: {
                    main: {
                        label: "Close",
                        className: "btn btn-default",
                    }
                }
            });
            if(document.fullscreenElement){
                box.appendTo(`#${document.fullscreenElement.id}`)
              }else{
                box.show();
              }
            function fillValuePath(value) {
                var packPath = value.getPackagePath()
                for (var k = 0; k < packPath.length - 1; k++) {
                    if (k === 0) {
                        partcipantNetwork = packPath[k].name;
                    }
                    if (value.get('type') === "vdml_ValueAdd" && k === 1 && value.get('valueAddOwner')) {
                        var performingRole = value.get('valueAddOwner').get('containedPortOwner').get('performingRole');
                        valuePathRole = performingRole.get('roleAssignment').models[0].get('participant').get('name') + "  <b><i>(" + performingRole.get('name') + ")</b></i>";
                        if (packPath[k]) {
                            valuePathSource = packPath[k].name;
                        }
                        ProviderRole = "Performer";
                        k++;
                    }
                    else if (k === 1) {
                        valuePathRole = value.get('componentOwner').get('provider').get('roleAssignment').models[0].get('participant').get('name') + "  <b><i>(" + packPath[k].name + ")</i></b>";
                        if (value.get('componentOwner').get('recipient')) {
                            var participantName = '';
                            if (value.get('componentOwner').get('recipient').get('roleAssignment').models[0]) {
                                participantName = value.get('componentOwner').get('recipient').get('roleAssignment').models[0].get('participant').get('name');
                            }
                            receiverValuePathRole = participantName + "  (" + value.get('componentOwner').get('recipient').get('name') + ")";
                            receiverRole = "To (Role)";
                        }
                        k++;
                        if (packPath[k]) {
                            valuePathSource = packPath[k].name;
                        }
                        ProviderRole = "From (Role)";
                    }
                }
            }
        };

        this.showSatisfactionIntervalDiv = function () {
            self.visibleSatisfactionType(true);
            self.satisfactionIntervalDiv(true);
            self.checkFromInclusive(true);
            self.checkToInclusive(true);
            self.addColorsToPalette();
        };

        this.addSatisfactionInterval = function (view) {
            if ($('#intervalNameDuplicate' + self.encodeId).html() != '') {
                return;
            }
            if (self.sIndex == null && $("#intervalName" + self.encodeId).val() !== "") {
                var fromInc = self.checkFromInclusive() ? true : false;
                var toInc = self.checkToInclusive() ? true : false;
                var intervalFrom = $("#intervalFrom" + self.encodeId).val();
                var intervalTo = $("#intervalTo" + self.encodeId).val();
                var createInterval = true;
                //:VDM-719
                if (intervalFrom === "" || intervalTo === "") {
                    createInterval = self.checkDimensional() ? false : true;
                }
                else {
                    if (intervalFrom === "-Infinity" && createInterval) {
                        createInterval = intervalFrom === "-Infinity" ? true : false;
                    }
                    if (intervalTo === "Infinity" && createInterval) {
                        createInterval = intervalTo === "Infinity" ? true : false;
                    }
                    if (intervalFrom !== "" && intervalFrom !== "-Infinity" && createInterval) {
                        createInterval = intervalFrom !== "" ? true : false;
                    }
                    if (intervalTo !== "" && intervalTo !== "Infinity" && createInterval) {
                        createInterval = intervalTo !== "" ? true : false;
                    }
                }
                if (createInterval) {
                    var satValue = $("#intervalName" + self.encodeId).val();
                    if (self.measurementValue() === "") {
                        satValue = "";
                    }
                    var uniqueId = window.utils.htmlEscape(window.guidGenerator());
                    var smileySrc = self.fetchSmileySrc($("#intervalSmiley" + self.encodeId).attr('value'));
                    self.satisfactionIntervalColl.push({ seqNo: '' + self.satisfactionIntervalColl().length, originalId: '', satisfactionValue: satValue, intervalName: $("#intervalName" + self.encodeId).val(), intervalFrom: $("#intervalFrom" + self.encodeId).val(), intervalTo: $("#intervalTo" + self.encodeId).val(), fromInclusive: fromInc, toInclusive: toInc, smiley: $("#intervalSmiley" + self.encodeId).attr('value'), smileyColor: $("#intervalSmiley" + self.encodeId).attr('fill'), smileySrc: smileySrc, correctiveActions: self.correctiveActions(), uniqueId: uniqueId });
                    self.constructSatisfactionIntervalTuple('' + self.satisfactionIntervalColl().length - 1, '', $("#intervalName" + self.encodeId).val(), $("#intervalFrom" + self.encodeId).val(), $("#intervalTo" + self.encodeId).val(), fromInc, toInc, $("#intervalSmiley" + self.encodeId).attr('value'), $("#intervalSmiley" + self.encodeId).attr('fill'), self.correctiveActions());
                }
                self.satisfactionIntervalDiv(false);
                //self.showIntervals(true);
                self.sortSatisfactionIntervals();
            }
            else if ($("#intervalName" + self.encodeId).val() !== "") {
                var newIntervalName = $("#intervalName" + self.encodeId).val();
                if (self.satisfactionOnly()) {
                    var oldIntervalName = self.satisfactionIntervalColl()[self.sIndex].intervalName;
                    if ($("#selectSatisfaction" + self.encodeId + " option:selected").text() === oldIntervalName) {
                        self.satisfaction(newIntervalName);
                    }
                    $("#selectSatisfaction" + self.encodeId + " option[value='" + oldIntervalName + "']").attr("val", newIntervalName);
                    $("#selectSatisfaction" + self.encodeId + " option[value='" + oldIntervalName + "']").text(newIntervalName);
                }
                self.satisfactionIntervalColl()[self.sIndex].intervalName = newIntervalName;
                self.satisfactionIntervalColl()[self.sIndex].intervalFrom = $("#intervalFrom" + self.encodeId).val();
                self.satisfactionIntervalColl()[self.sIndex].intervalTo = $("#intervalTo" + self.encodeId).val();
                var fromInc = $("#fromInclusive" + self.encodeId).is(':checked') ? true : false;
                var toInc = $("#toInclusive" + self.encodeId).is(':checked') ? true : false;
                self.satisfactionIntervalColl()[self.sIndex].fromInclusive = fromInc;
                self.satisfactionIntervalColl()[self.sIndex].toInclusive = toInc;
                $("#intervalName" + self.satisfactionIntervalColl()[self.sIndex].seqNo + "" + self.encodeId).text('' + self.satisfactionIntervalColl()[self.sIndex].intervalName);
                $("#intervalFrom" + self.satisfactionIntervalColl()[self.sIndex].seqNo + "" + self.encodeId).text('' + self.satisfactionIntervalColl()[self.sIndex].intervalFrom);
                $("#intervalTo" + self.satisfactionIntervalColl()[self.sIndex].seqNo + "" + self.encodeId).text('' + self.satisfactionIntervalColl()[self.sIndex].intervalTo);
                self.satisfactionIntervalColl()[self.sIndex].smiley = $("#intervalSmiley" + self.encodeId).attr('value');
                self.satisfactionIntervalColl()[self.sIndex].smileyColor = $("#intervalSmiley" + self.encodeId).attr('fill');

                var smileySrc = self.fetchSmileySrc($("#intervalSmiley" + self.encodeId).attr('value'));
                var uniqueId = self.satisfactionIntervalColl()[self.sIndex].uniqueId;
                self.satisfactionIntervalColl()[self.sIndex].smileySrc = smileySrc;
                self.satisfactionIntervalColl()[self.sIndex].correctiveActions = self.correctiveActions();
                var seqNo = self.satisfactionIntervalColl()[self.sIndex].seqNo;
                for (var i = 0; i < self.intervalTuples.length; i++) {
                    if (self.intervalTuples[i].new && self.intervalTuples[i].new.seqNo == seqNo) {
                        self.editSatisfactionIntervalTuple('' + self.intervalTuples[i].new.seqNo, self.intervalTuples[i].new.id, $("#intervalName" + self.encodeId).val(), $("#intervalFrom" + self.encodeId).val(), $("#intervalTo" + self.encodeId).val(), fromInc, toInc, $("#intervalSmiley" + self.encodeId).attr('value'), $("#intervalSmiley" + self.encodeId).attr('fill'), self.correctiveActions());
                        break;
                    }
                }

                self.sIndex = null;
                self.satisfactionIntervalDiv(false);
                //self.showIntervals(true);
                self.sortSatisfactionIntervals();
                $('#editSmiley' + uniqueId).find('path').attr('d', smileySrc);
                $('#editSmiley' + uniqueId).attr('fill', $("#intervalSmiley" + self.encodeId).attr('fill'));
            }
            else {
                self.sIndex = null;
                self.satisfactionIntervalDiv(false);
                //self.showIntervals(true);
            }
            $("#intervalName" + self.encodeId).val("");
            $("#intervalFrom" + self.encodeId).val("");
            $("#intervalTo" + self.encodeId).val("");
            self.correctiveActions('');
            self.negativeInfinity(false);
            self.positiveInfinity(false);
            self.calculateSatisfaction();
            self.showIntervalToUI(true);
            self.showIntervalFromUI(true);
            self.removeSmiley();
            //$('#cp'+self.encodeId).find( "i" ).css('background-color','rgb(0, 0, 0)');
        };



        this.deleteSatisfactionInterval = function (view) {
            for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                if (self.satisfactionIntervalColl()[i].seqNo === view.seqNo) {
                    var tempView = self.satisfactionIntervalColl()[i];
                    if (tempView) {
                        self.satisfactionIntervalColl.replace(self.satisfactionIntervalColl()[i], tempView);
                        self.satisfactionIntervalColl.destroy(self.satisfactionIntervalColl()[i]);
                        var seqNo = view.seqNo;
                        for (var j = 0; j < self.intervalTuples.length; j++) {
                            if (self.intervalTuples[j].new && self.intervalTuples[j].new.seqNo == seqNo) {
                                self.deleteTuple = true;
                                self.deleteSatisfactionIntervalTuple(self.intervalTuples[j].new.seqNo, self.intervalTuples[j].new.id, self.intervalTuples[j].new.symbol, self.intervalTuples[j].new.minimumEndPoint, self.intervalTuples[j].new.maximumEndPoint, self.intervalTuples[j].new.minimumOpen, self.intervalTuples[j].new.maximumOpen);
                            }
                        }
                    }
                    else {
                        self.satisfactionIntervalColl.splice(i, 1);
                    }
                }
            }
            self.calculateSatisfaction();
            self.sortSatisfactionIntervals();
        };


        function fillDefaultSatisfactionInterval() {
            self.visibleSatisfactionType(true);
            if (window.utils.getObservableLength(self.satisfactionIntervalColl()) == 0) {
                var fromInc = false;
                var toInc = false;
                var intervalName = "fair";
                var satValue = "fair";
                self.satisfactionIntervalColl([]);
                if (self.satisfactionType() == "smm_RankingMeasure") {
                    $("#showSatisfaction" + self.encodeId).html('');
                    intervalName = "0";
                    satValue = "0";
                }
                self.satisfactionIntervalColl.push({ seqNo: '' + self.satisfactionIntervalColl().length, originalId: '', intervalName: intervalName, intervalFrom: '-Infinity', intervalTo: 'Infinity', fromInclusive: fromInc, toInclusive: toInc, satisfactionValue: satValue, smiley: '', smileyColor: 'rgb(0,0,0)', smileySrc: '', correctiveActions: '', uniqueId: window.utils.htmlEscape(window.guidGenerator()) });
                self.constructSatisfactionIntervalTuple('' + self.satisfactionIntervalColl().length - 1, '', intervalName, -Number.MAX_VALUE, Number.MAX_VALUE, fromInc, toInc, '', 'rgb(0,0,0)', '');
                if (self.measurementValue() === "") {
                    self.satisfaction("");
                    $("#showSatisfaction" + self.encodeId).html('');
                }
            }
        }
        this.onClickNegativeInfinity = function (view, event) {
            if (event.currentTarget.checked) {
                $('#intervalFrom' + self.encodeId).val('-Infinity');
                $('#intervalFromShow' + self.encodeId).text('-Infinity');
                self.showIntervalFromUI(false);
                self.checkFromInclusive(false);
            }
            else {
                self.showIntervalFromUI(true);
                $('#intervalFrom' + self.encodeId).val("");
            }
        }
        this.onClickPositiveInfinity = function (view, event) {
            if (event.currentTarget.checked) {
                $('#intervalTo' + self.encodeId).val('Infinity');
                $('#intervalToShow' + self.encodeId).text('Infinity');
                self.showIntervalToUI(false);
                self.checkToInclusive(false);
            }
            else {
                self.showIntervalToUI(true);
                $('#intervalTo' + self.encodeId).val("");
            }
        }
        this.checkComplete = ko.computed(function () {
            var complete = false;
            if (self.name().trim() !== "" && $('#message' + self.encodeId).text() == "") {
                if (self.measurementUnit() !== "") {
                    complete = true;
                    if (self.satisfactionType() == "smm_RankingMeasure") {
                        if (self.satisfactionUnitValue() !== "" && $('#message' + self.encodeId).text().trim().length < 1) {
                            complete = true;
                        }
                        else {
                            complete = false;
                        }
                    }
                    else {
                        if ($('#message' + self.encodeId).text().trim().length < 1) {
                            complete = true;
                        }
                        else {
                            complete = false;
                        }
                    }
                }
                else {
                    complete = false;
                    if (!self.checkDimensional()) {
                        complete = true;
                        if (self.satisfactionOnly() && window.utils.getObservableLength(self.satisfactionIntervalColl()) < 1) {
                            complete = false;
                        }
                    }
                }
                if (self.options.addOptions.type === "contributeWhatValues") {
                    var activitFound = false;
                    var removeAgg = [];
                    self.contributeWhatValuesAggregations = [];
                    for (var i = 0; i < self.aggregateColl().length; i++) {
                        if (self.aggregateColl()[i].aggregateType === 'Activity') {
                            if (!self.aggregateColl()[i]._destroy) {
                                activitFound = true;
                                self.contributeWhatValuesAggregations.push(self.aggregateColl()[i].aggregateid);
                            } else {
                                removeAgg.push(self.aggregateColl()[i].aggregateid);
                            }
                        }
                    }
                    self.contributeWhatValuesAggregations = _.uniq(self.contributeWhatValuesAggregations);
                    for (var i = 0; i < removeAgg.length; i++) {
                        for (var j = self.contributeWhatValuesAggregations.length; j--;) {
                            if (self.contributeWhatValuesAggregations[j] === removeAgg[i]) {
                                self.contributeWhatValuesAggregations.splice(j, 1);
                            }
                        }
                    }
                    complete = activitFound;
                }
            }
            else {
                complete = false;
            }
            //for component
            if (self.readOnly) {
                self.enableDelete(false);
                complete = false;
            }
            return complete;
        }, this);
        function setSatisfactionIntervalAddIconCSS() {
            var satisfactionIntervalAddIcon = document.getElementById("satisfactionIntervalAddIcon" + self.encodeId);
            if (satisfactionIntervalAddIcon) {
                satisfactionIntervalAddIcon.style.cursor = "pointer";
            }
        }

        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        this.removeSmiley = function (view, event) {
            //var satId = event.currentTarget.id.replace('removeSmiley', "");
            $("#intervalSmiley" + self.encodeId).attr('src', '');
            $("#intervalSmiley" + self.encodeId).attr('value', '');
            $("#intervalSmileyPath" + self.encodeId).attr('d', '');
            $("#intervalSmiley" + self.encodeId).attr('fill', 'rgb(0,0,0)');
            $('#intervalSmiley' + self.encodeId).parent().hide();
            $('#colors-wrapper1' + self.encodeId).hide();
            $('.colorPickerComp').removeClass('show');
            $('.colorPickerComp').hide();
            $('#intervalSmiley' + self.encodeId).attr('value', '');
        };


        this.afterRenderView = function () {
            $('#deleteValueDef' + self.encodeId).hide()
            $('#showValueMappedInfo' + self.encodeId).hide()
            var valueNameExtended = Backbone.Typeahead.extend({
                template: '<input type="text" value ="' + self.name() + '" id="valueName' + self.encodeId + '" placeholder="Name" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            $('#editDetailsModalContent' + self.encodeId).resize(function () {
                $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
            })
            var valueNameTypeahead = new valueNameExtended({ collection: self.selectableValueNames });
            valueNameTypeahead.setElement('#valueNameDiv' + self.encodeId).render();
            valueNameTypeahead.on('selected', function (fetchmodel) {
                self.existingValueModal = fetchmodel;
                $('#message' + self.encodeId).text("");
                //duplicateName = false;
                self.name("");
                self.name(fetchmodel.get('name'));
                self.aggregateColl([]);
                self.baseTuples = [];
                var scenario = self.currentAlternativeModel.getMainScenario(fetchmodel.getNestedParent());
                var observedMeasure = scenario.getObservedMeasureWithMeasurements(fetchmodel.get('valueMeasurement'), null);
                var fetchmodelValMeasurement = null;
                if (observedMeasure) {
                    fetchmodelValMeasurement = observedMeasure.get('measurements').at(0);
                }
                self.fillValues(fetchmodelValMeasurement, null, null, null, true);
                self.filterAggregateOptions(fetchmodel.get('id'));
            });
            if (self.disableAggr) {
                $("icon[id*='deleteAggregate" + self.encodeId + "']").hide();
            }

            $("#valueName" + self.encodeId).change(function (view) {
                var name = view.target.value;
                self.existingValueModal = null;
                self.name(name);
                if (self.existingValueModal == null) {
                    self.getUniqueValue(name);
                }
                if (name.trim().length > 1 && $('#message' + self.encodeId).text().trim().length < 1 && ((self.previousPhaseValName && self.previousPhaseValName != self.name().trim()) || (self.nextPhaseValName && self.nextPhaseValName != self.name().trim()))) {
                    bootbox.confirm({
                        message: "Warning: Name will change in other Phases too. Do you want to continue ?",
                        buttons: {
                            confirm: {
                                label: 'Ok',
                                className: 'btn-primary'
                            },
                            cancel: {
                                label: 'Cancel',
                                className: 'btn-default'
                            }
                        },
                        callback: function (result, name) {
                            if (!result) {
                                name = self.model.get('name');
                                self.name(name);
                            }

                        }
                    });


                }
            });
            $("#measurementValue" + self.encodeId).change(function (view) {
                if (self.enableMeasurement() && !isNaN(self.measurementValue())) {
                    self.fullMeasurementValue = self.measurementValue();
                    var roundedValue = window.utils.getRoundedValues(self.fullMeasurementValue, self.significantDecimals());
                    if (!isNaN(roundedValue)) {
                        self.measurementValue(roundedValue);
                    }
                }
            });

            $("#valueName" + self.encodeId).keyup(function (view) {
                var name = view.target.value;
                if (self.existingValueModal == null) {
                    self.getUniqueValue(name);
                }
            });
            function validate(s) {
                var rgx = /\-?[0-9]*\.?[0-9]*$/;
                if (s.match(rgx) == s) {
                    return true;
                }
                return false;
            }

            $("#intervalName" + self.encodeId).keyup(function (view) {
                var name = view.target.value;
                var found = false;
                if (self.satisfactionType() !== "smm_GradeMeasure" && !validate(name)) {
                    $("#intervalName" + self.encodeId).val("");
                    return;
                }
                for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                    if (self.satisfactionIntervalColl()[i].intervalName === name && i !== self.sIndex && !self.satisfactionIntervalColl()[i]._destroy) {
                        found = true;
                        break
                    }
                }
                if (found) {
                    $('#intervalNameDuplicate' + self.encodeId).html('Name Already Exists');
                } else {
                    $('#intervalNameDuplicate' + self.encodeId).html('');
                }
            });
            var diglogType = self.options.addOptions.type.toLowerCase();
            var lcManager = DataManager.getDataManager().get('localeManager');
            var lab = "";
            if (diglogType === "valueProposition".toLowerCase()) {
                lab = self.model ? lcManager.get('editVpValue') : lcManager.get('enterVpValue');
            }
            if (diglogType === "planValues".toLowerCase()) {
                lab = self.model ? lcManager.get('editPlanValue') : lcManager.get('enterPlanValue');
            }
            if (diglogType === "valueFormula".toLowerCase()) {
                lab = self.model ? lcManager.get('editMyPropositionValue') : lcManager.get('enterMyPropositionValue');
            }
            if (diglogType === "createWhatValues".toLowerCase()) {
                self.showSource(true);
                self.showGradeOnly(false);
                lab = self.model ? lcManager.get('editActivityValue') : lcManager.get('enterActivityValue');
            }
            if (diglogType === "contributeWhatValues".toLowerCase()) {
                self.showValues(true);
                lab = self.model ? lcManager.get('editAggActivityValue') : lcManager.get('enterAggActivityValue');
            }
            $("#titleLabel" + self.encodeId).text(lab);
            var Extended = Backbone.Typeahead.extend({
                template: '<input type="text" value ="' + self.measurementUnit() + '" id="measurementUnit' + self.encodeId + '" placeholder="'+DataManager.getDataManager().get('localeManager').get('Unit')+'" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.measureUnits.models.sort(function (left, right) {
                return left.get('name').toLowerCase() == right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1)
            });
            self.measurementUnittypeahead = new Extended({ collection: self.measureUnits });
            self.measurementUnittypeahead.setElement('#measurementUnitDiv' + self.encodeId).render();
            var cond = valueMeasurement ? false : true;
            if (!cond && $('#measurementUnit' + self.encodeId).val() !== "") {
                self.roundingFactor(true);
            }
            else {
                self.roundingFactor(false);
            }
            self.measurementUnittypeahead.on('selected', function (fetchmodel) {
                self.measurementUnit(fetchmodel.get('name'));
                self.roundingFactor(true);
                self.roundingDigits(fetchmodel.get('roundingDigits'));
                self.significantDecimals(fetchmodel.get('significantDecimals'));
                $('#recipientUnitDiv' + self.encodeId).text(fetchmodel.get('name'));
                if (window.utils.getObservableLength(self.aggregateColl()) > 1) {
                    self.calculateMeasurentValues();
                }
                self.matchField[0].innerHTML = "Match";
                self.matchField.show();
            });
            $('#measurementUnit' + self.encodeId).change(function (view) {
                var newValue = view.target.value.trim();
                if (self.model) {
                    setTimeout(function () {
                        var unitName;
                        var unitNodeVal = $('#measurementUnit' + self.encodeId).val();
                        if (self.model && self.model.getPreviousValueUnit) {
                            unitName = self.model.getPreviousValueUnit(self.currentAlternativeModel, self.model.get('valueMeasurement'));
                        }
                        if (unitName && unitNodeVal !== "" && unitNodeVal !== unitName) {
                            bootbox.alert(DataManager.getDataManager().get('localeManager').get('PreviousUnit', unitName));
                        }
                    }, 300);
                }
                if (newValue !== "") {
                    self.roundingFactor(true);
                }
                else {
                    self.roundingFactor(false);
                }
                self.measurementUnit(newValue);
                $('#recipientUnitDiv' + self.encodeId).text(newValue);
            });
            $('#measurementUnit' + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if (!view.target.value.trim() == "") {
                    var unitMatch = self.measureUnits.findWhere({ name: view.target.value });
                    if (unitMatch) {
                        self.matchField[0].innerHTML = 'Match';
                        self.matchField.show();
                        $('#measurementUnit' + self.encodeId).next().find('li').filter(function () { return $(this).text() === view.target.value; }).click();
                    }
                    else if (!view.target.value == "") {
                        self.matchField[0].innerHTML = 'New';
                        self.matchField.show();
                    }
                    self.measurementUnit(view.target.value);
                }
                else {
                    self.matchField.hide();
                }
            });
            $('#measurementUnit' + self.encodeId).dblclick(function (view) {
                $('#measurementUnit' + self.encodeId).trigger('keyup');
                $('#measurementUnit' + self.encodeId).focus();
            });
            $('#recipientUnitDiv' + self.encodeId).text(self.measurementUnit());
            if (self.model && self.options.addOptions.type === "contributeWhatValues") {
                $('#measurementUnit' + self.encodeId).prop('disabled', true);
                self.roundingFactor(false);
                $('#recipientVal' + self.encodeId).prop('disabled', true);
                $('#satisfactionIntervalAddIconDiv' + self.encodeId).hide();
                $("icon[id*='satisfactionIntervalDeleteIcon']").hide();
            }
            var Extended = Backbone.Typeahead.extend({
                template: '<input type="text" value ="' + self.satisfactionUnitValue() + '" id="satisfactionUnit' + self.encodeId + '" placeholder="'+DataManager.getDataManager().get('localeManager').get('Unit')+'" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            var satisfactionUnittypeahead = new Extended({ collection: self.measureUnits });
            satisfactionUnittypeahead.setElement('#satisfactionUnitDiv' + self.encodeId).render();
            $('#satisfactionUnit' + self.encodeId).prop('disabled', cond);
            $('#satisfactionUnit' + self.encodeId).hide();
            // $('#formula' + self.encodeId).prop('disabled', cond);
            satisfactionUnittypeahead.on('selected', function (fetchmodel) {
                self.satisfactionUnitValue(fetchmodel.get('name'));
                self.satRoundingDigits(fetchmodel.get('roundingDigits'));
                self.satSignificantDecimals(fetchmodel.get('significantDecimals'));
                self.matchField[0].innerText = "Match";
                self.matchField.show();
            });
            $('#satisfactionUnit' + self.encodeId).change(function (view) {
                self.satisfactionUnitValue(view.target.value.trim());
            });
            if (!self.isDimensional && self.satisfactionMeasure && self.satisfactionMeasure.get('type') == "smm_GradeMeasure") {
                self.satisfactionOnly(false);
                self.satisfactionOnly(true);
                self.showGradeOnly(false);
                self.enableSatisfactionType(false);
                self.weightRecipientVisible(false);
            }
            else {
                if (self.satisfactionMeasure && self.satisfactionMeasure.get('type') == "smm_RankingMeasure") {
                    $('#satisfactionUnit' + self.encodeId).show();
                    $("#satisfactionUnitMark" + self.encodeId).show();
                }
                else {
                    $("#satisfactionUnitMark" + self.encodeId).hide();
                }
                $("#showSelectSatisfaction" + self.encodeId).hide();

            }
            if (self.satisfactionOnly()) {
                self.showSatisfactionOnly()
            }
            if (self.satisfactionType() === "smm_RankingMeasure") {
                $('#satisfactionUnit' + self.encodeId).show();
                $("#satisfactionUnitMark" + self.encodeId).show();
            }
            self.fillAggregateUIDetails(self.selectedAggregate());
            $("#valueName" + self.encodeId).keyup(function (view) {
                var newValue = view.currentTarget.value;
                self.name(newValue);
            });
            $("#weight" + self.encodeId).keyup(function (view) {
                var weightValue = view.target.value;
                if (weightValue > 100) {
                    $("#weight" + self.encodeId).val(100);
                    self.weight(100);
                }
            });

            $("#satisfactionUnit" + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                var newValue = view.target.value.trim();
                self.satisfactionUnitValue(newValue);
                if (newValue != "") {
                    var unitMatch = self.measureUnits.findWhere({ name: view.target.value });
                    if (unitMatch) {
                        self.matchField[0].innerHTML = 'Match';
                        self.matchField.show();
                        $('#satisfactionUnit' + self.encodeId).next().find('li').filter(function () { return $(this).text() === view.target.value; }).click();
                    }
                    else {
                        self.matchField[0].innerHTML = 'New';
                        self.matchField.show();
                    }
                    self.satisfactionUnitValue(view.target.value);
                }
                else {
                    self.matchField.hide();
                }
            });
            self.calculateSatisfaction();
            attachMouseDown();
            function attachMouseDown() {
                var eleID = ('modal' + self.encodeId);
                $('#' + eleID).mouseup(function (event) {
                    var satisfactionDivId = "#satisfactionDetailsDiv" + self.encodeId;
                    var aggregatedDivId = "#aggregatedDetailsDiv" + self.encodeId;
                    var seasonalPatternDiv = $("#seasonalPatternDiv");
                    if (!($(satisfactionDivId).has($(event.target)).length > 0) && $(satisfactionDivId + ':hidden').length == 0) {
                        self && self.addSatisfactionInterval ? self.addSatisfactionInterval() : null;
                    }
                    if (!($(aggregatedDivId).has($(event.target)).length > 0) && $(aggregatedDivId + ':hidden').length == 0) {
                        self && self.saveAggregateDetails ? self.saveAggregateDetails() : null;
                    }
                    if (!seasonalPatternDiv.is(event.target) && !$.contains(seasonalPatternDiv[0], event.target)) {
                        self.showTable(false);
                    }              
                });
            }
            setSatisfactionIntervalAddIconCSS();
            if (self.model && self.readOnly != undefined && !self.planValue) {
                self.showValuePath(true);
            }

            self.postShow = true;
            //self.addColorsToPalette();
            $('#addAnotherColor' + self.encodeId).on('click', function () {
                $('#intervalSmiley' + self.encodeId).parent().show();
                $('.colorPickerComp').removeClass('hide');
                $('.colorPickerComp').css('display', 'block');
                if ($('#intervalSmiley' + self.encodeId).attr('value') == '' || $('#intervalSmiley' + self.encodeId).attr('value') == '0') {
                    $('#intervalSmileyPath' + self.encodeId).attr('d', self.defaultSmiley());
                    $('#intervalSmiley' + self.encodeId).attr('value', '1');
                }
                $('#colors-wrapper1' + self.encodeId).show();
                //$('#cp'+self.encodeId).colorpicker();
            });
            $('#cp' + self.encodeId).colorpicker();
            $("#cp" + self.encodeId).on('changeColor', function (ev) {
                var alpha = ev.color.toHex();
                var $svg = $('#intervalSmiley' + self.encodeId);
                $svg.attr('fill', alpha);
            });
            $('#colors-wrapper1' + self.encodeId).on('click', '.dwrapper', function () {
                //event.stopPropagation();
                var colorBoxes = $('.dwrapper');
                for (var i = 0; i < colorBoxes.length; ++i) {
                    if (colorBoxes[i] !== this) {
                        colorBoxes[i].removeAttribute("active");
                    }
                }
                if (!this.active) {
                    this.setAttribute("active", "active");
                }
                var color = $(this).children()[0].style.backgroundColor;
                color = color.replace(/ /g, '');
                var $svg = $('#intervalSmiley' + self.encodeId);
                $svg.attr('fill', color);
            });
            if (self.model && self.model.get('valueMeasurement') && DataManager.getDataManager().get('debugMode')) {
                var measure = self.model.get('valueMeasurement').get('measurement');
                if (measure && measure.length === 1) {
                    self.minRange(measure.at(0).get('minRange'));
                    self.maxRange(measure.at(0).get('maxRange'));
                } else if (measure && measure.length > 1) {
                    for (var m = 0; m < measure.length; m++) {
                        var observation = measure.at(m).get('observedMeasure').get('observation');
                        if (window.utils.getPrefix(observation.id) == DataManager.getDataManager().get('viewAlternative')) {
                            self.minRange(measure.at(m).get('minRange'));
                            self.maxRange(measure.at(m).get('maxRange'));
                        }
                    }
                }
                
            }
            if (self.model && self.model.get('valueDefinition')) {
                self.fillValueDefinition();
            } else {
                self.usedValue = null;
                self.usedValueDefName("")
                $("#mapValueDef" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
                $('#deleteValueDef' + self.encodeId).hide();
                $('#showValueMappedInfo' + self.encodeId).hide();
            }

            self.textAreaHeight();
            self.expBuilder = $("#formula"+self.encodeId).expressionBuilder(self.expBuilderOptions);
            if(self.model){
                self.fillFormula();
            }
            //self.initialiseMonaco();
            self.populateTrendModels();
            if (self.model) {
                var projectionProfile = self.model.get('projectionProfile');
                var seasonalFactors = projectionProfile ? projectionProfile.get('seasonalFactors') : null;
                if (projectionProfile) {
                    var trendModel = projectionProfile.get('trendModel');
                    if (trendModel && trendModel.name !== '') {
                        $('#trendModel').prop('disabled', true);
                        $('#degree').prop('disabled', true);
                        $('#scalingFactor').prop('disabled', true);
                        $('#exponent').prop('disabled', true);
                        $('#growthRate').prop('disabled', true);
                        $('#expGrowthRate').prop('disabled', true)
                        $('#polynomialPattern').find('input').prop('disabled', true);
                    }
                }
                if (seasonalFactors && seasonalFactors.models.length > 0) {
                    $('#seasonalPatternChk').prop('disabled', true);
                    $('#seasonalPattern').find('input').prop('disabled', true);
                }
            }
            
        };
    }

    // initialiseMonaco = function(){
    //     var self = this;
    //     window.MonacoEnvironment = {
    //         getWorker: function (moduleId, label) {
    //             if (label === 'json') {
    //                 return new Worker(new URL('monaco-editor/esm/vs/language/json/json.worker', import.meta.url));
    //             }
    //             if (label === 'css' || label === 'scss' || label === 'less') {
    //                 return new Worker(new URL('monaco-editor/esm/vs/language/css/css.worker', import.meta.url));
    //             }
    //             if (label === 'html' || label === 'handlebars' || label === 'razor') {
    //                 return new Worker(new URL('monaco-editor/esm/vs/language/html/html.worker', import.meta.url));
    //             }
    //             if (label === 'typescript' || label === 'javascript') {
    //                 return new Worker(new URL('monaco-editor/esm/vs/language/typescript/ts.worker', import.meta.url));
    //             }
    //             return new Worker(new URL('monaco-editor/esm/vs/editor/editor.worker', import.meta.url));
    //         }
    //     };
    //     var pack = self.parentView.get('type') === "vdml_BusinessModel" ? self.parentView.getNestedParent() : DataManager.getDataManager().get('currentPlan')
    //     monaco.languages.registerCompletionItemProvider('json', {
    //         triggerCharacters: ['.'], // Trigger only when typing a dot (.)
    //         provideCompletionItems: function (model, position) {
    //             const word = model.getWordUntilPosition(position);
    //             const range = new monaco.Range(position.lineNumber, word.startColumn, position.lineNumber, word.endColumn);

    //             // Example Backbone objects
    //             const backboneObjects = {
    //                 package: pack,
    //                 plan: DataManager.getDataManager().get('currentPlan')
    //             };

    //             const suggestions = [];

    //             // Populate suggestions based on Backbone objects
    //             for (let key in backboneObjects) {
    //                 if (backboneObjects.hasOwnProperty(key)) {
                        
    //                     const obj = backboneObjects[key];
    //                     var typeMixinCls = DataManager.getDataManager().getModelType(obj, true);

    //                     var attributes = Object.getOwnPropertyNames(obj.attributes);
    //                     for(var i=0;i<attributes.length;i++){
    //                         var attr = attributes[i];
    //                         var value = obj.get(attributes[i]);
    //                         var exists= _.find(typeMixinCls.getProperties(), function(a){return a.name === attributes[i];})
    //                         if((exists || attributes[i]==='id' || attributes[i]==='type') && value){
    //                             if(value instanceof Backbone.Model || value instanceof Backbone.Collection || attributes[i] === 's' || attributes[i] === 'label' || attributes[i] === 'definition'){
    //                                 continue;
    //                             }
    //                             if(typeof(value) === 'object' ){
    //                                 value = value.name;
    //                             }
    //                             suggestions.push({
    //                                 label: `${key}.get('${attr}')`,
    //                                 kind: monaco.languages.CompletionItemKind.Property,
    //                                 documentation: `Attribute: ${attr} - ${attributes[attr]}`,
    //                                 insertText: `${key}.get('${attr}')`,
    //                                 range: range
    //                             });
    //                         }
    //                     }

    //                     // Add model methods to suggestions
    //                     // for (let method in obj) {
    //                     //     if (typeof obj[method] === 'function' && method !== 'constructor') {
    //                     //         suggestions.push({
    //                     //             label: `${key}.${method}()`,
    //                     //             kind: monaco.languages.CompletionItemKind.Method,
    //                     //             documentation: `Method: ${method}`,
    //                     //             insertText: `${key}.${method}()`,
    //                     //             range: range
    //                     //         });
    //                     //     }
    //                     // }
    //                 }
    //             }

    //             return { suggestions: suggestions };
    //         }
    //     });
    //     const value = /* set from `myEditor.getModel()`: */ ``;
    //     monaco.editor.create(document.getElementById('builder'), {
    //         value,
    //         automaticLayout: true,
    //         lineNumbers: 'off',  
    //         language: 'json'
    //     });
    // }

}



path.ValueDetails2ViewModel = ValueDetails2ViewModel;