import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {AlternativeMixin} from './AlternativeMixin'
import {Alternative2Mixin} from './Alternative2Mixin'
import {PhaseObjectiveSet} from './PhaseObjectiveSet'
import {ScenarioProxy} from './ScenarioProxy'
import {PackageReference} from './PackageReference'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/AlternativeMixin"],
/*function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,AlternativeMixin
, PhaseObjectiveSet
, ScenarioProxy
, PackageReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!PhaseObjectiveSet){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.PhaseObjectiveSet){
			PhaseObjectiveSet = importPath.PhaseObjectiveSet;
		}
        else {
            import('./PhaseObjectiveSet').then(loadedModule => {
                PhaseObjectiveSet = loadedModule;
            });
			/*require(["appbo/transformation/PhaseObjectiveSet"],function(loadedModule){
				PhaseObjectiveSet = loadedModule;
			});*/
		}
	}
	if(!ScenarioProxy){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.ScenarioProxy){
			ScenarioProxy = importPath.ScenarioProxy;
		}
        else {
            import('./ScenarioProxy').then(loadedModule => {
                ScenarioProxy = loadedModule;
            });
			/*require(["appbo/transformation/ScenarioProxy"],function(loadedModule){
				ScenarioProxy = loadedModule;
			});*/
		}
	}
	if(!PackageReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.PackageReference){
			PackageReference = importPath.PackageReference;
		}
        else {
            import('./PackageReference').then(loadedModule => {
                PackageReference = loadedModule;
            });
			/*require(["appbo/transformation/PackageReference"],function(loadedModule){
				PackageReference = loadedModule;
			});*/
		}
	}
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? Alternative2Mixin : AlternativeMixin;
	}
	var altMixinClass = getMixinClass();
	var Alternative = NamedPlanElement.extend(utils.customExtends({
		relations:altMixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
			NamedPlanElement.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handleAlternativeNameChange);
		}
	}
		, new altMixinClass()
	));
    Alternative["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging && previousName != newName) {
			this.handleAlternativeNameChange(model, newName);
		}
    }
	Alternative["change:phaseDesignPart"] = function (model, newName) {
		var previousData = model._previousAttributes ? model._previousAttributes.data:"";
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(previousData != newData && suppressChangeLogging) {
			utils.updatePhaseView(model, false);
		}
    }
	Alternative["add:phaseDesignPart"] = function (model, newName) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updatePhaseView(model,false);
		}
    }
	Alternative["destroy:phaseDesignPart"] = function (model, newName) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updatePhaseView(model,true);
		}
    }
	Alternative.abstract = false;
	path.Alternative = Alternative;
	//return Alternative;
	export {Alternative};
//});