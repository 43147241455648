import React from 'react'
import { DataManager } from "../../../../../com/vbee/data/DataManager";
import { MarketPlace as MarketPlaceContainer} from '@marketplace/marketplacecontainer';
import * as bootbox from '../../../../../../libs/bootbox/bootbox'


const MarketPlace = () => {
  var accountId = DataManager.getDataManager().get('gapiId');
  var myMSALObj = DataManager.getDataManager().get('mSALObj');
  let dataManager = DataManager.getDataManager();
  var protectedResources = dataManager.get('protectedResources');
  const account = {account:myMSALObj.getAccountByHomeId(accountId), instance:myMSALObj}
  var roles = dataManager.get('serverRoles');
  const purchasedProducts = dataManager.get("products") && dataManager.get("products").map((d) => d.productId);
  const userDetails = {email: dataManager.get("email")};
  const typeList = [1, 2, 5, 7].toString();

  const updatePurchasedProducts = function () {
    dataManager.getLicenses({
      success: function (response) {
        dataManager.onLicenseUpdate(response);
      },
      error: function (err) {
        console.log(err);
      },
    });
  };

  return (
    <div>
        <MarketPlaceContainer protectedResourcesDetails={protectedResources} strategyPlanner={true} accountDetails={account} orgId={DataManager.getDataManager().get("currentLegalEntity").entityId} spRoles={roles} marketplaceType="marketplace" purchasedProducts={purchasedProducts} updatePurchasedProducts={updatePurchasedProducts} typeList={typeList} bootbox={bootbox} userDetails={userDetails} />
    </div>
  )
}

export default MarketPlace