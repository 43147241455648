import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {BMGuidanceViewModel} from '../../../../views/help/views/properties/BMGuidanceViewModel'
import {CapabilityLibraryDetailsViewModel} from './CapabilityLibraryDetailsViewModel'
import {EcoMapDiagramMixin} from '../../../../bo/vdml/EcoMapDiagramMixin'
import { CapabilityLibraryMixin } from '../../../../bo/vdml/CapabilityLibraryMixin'
//define(["require", "jquery", "jstree", "jqueryui", "underscore", "backbone", "async", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appviews/capabilityLibrary/views/properties/CapabilityLibraryDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/vdml/EcoMapDiagramMixin", "summernote"],
//	function (require, $, jstree, jqueryui, _, Backbone, async, ko, koMapping, kb, bootbox, DataManager, global, CapabilityLibraryDetailsViewModel, BMGuidanceViewModel, EcoMapDiagramMixin) {
var path = DataManager.getDataManager().buildAppNsPath("capabilityLibrary.views.properties", global.version);
export class CapabilityLibraryViewModel {
    init(model, options) {
        var self = this;
        this.CapabilityLibraryViewModel = this;
        this.model = model;
        this.jsTreeModelData = null;
        this.selectedName = ko.observable("");
        this.selectedDescription = ko.observable("");
        this.selectedType = ko.observable("");
        this.typeChange=ko.observable(true);
        this.selectedDefinition = ko.observable("");
        this.selectedMapList = ko.observableArray([]);
        this.showCapElementDetails = ko.observable(true);
        this.enableCapComplete = ko.observable(false);
        this.childrens = ko.observableArray([]);
        this.currentPlan = ko.observable();
        this.mapList = [];
        this.mappingObject = {};
        this.mappingObject['vdml_CapabilityCategory'] = {
            name: DataManager.getDataManager().get('localeManager').get('CapabilityCategory'),
            type: "Capability Category"
        };
        this.mappingObject['vdml_CapabilityDefinition'] = {
            name: DataManager.getDataManager().get('localeManager').get('CapabilityDefinition'),
            type: "Capability Definition"
        };
        this.mappingObject['vdml:capabilityCategory'] = {
            name: DataManager.getDataManager().get('localeManager').get('CapabilityCategory'),
            type: "capability Category"
        };
        this.mappingObject['vdml:capabilityDefinition'] = {
            name: DataManager.getDataManager().get('localeManager').get('CapabilityDefinition'),
            type: "capability Definition"
        };
        this.mappingObject['vdml_CapabilityLibraryDiagram'] = {
            name: DataManager.getDataManager().get('localeManager').get('CapabilityMap'),
            type: "Capability Library"
        };
        this.mappingObject['vdml_StrategyMapDiagram'] = {
            name: DataManager.getDataManager().get('localeManager').get('StrategyMap'),
            type: "Strategy Map"
        };
        this.mappingObject['vdml_ValueStreamMapDiagram'] = {
            name: DataManager.getDataManager().get('localeManager').get('ValueStreamMap'),
            type: "Value Stream Map"
        };
        this.mappingObject['vdml_BusinessModelCompetence'] = {
            name: DataManager.getDataManager().get('localeManager').get('Competence'),
            type: "Business Model Competence"
        };
        this.mappingObject['vdml_BusinessModelActivity'] = {
            name: DataManager.getDataManager().get('localeManager').get('Activity'),
            type: "Business Model Activity"
        };
        this.mappingObject['vdml_BusinessModel'] = {
            name: DataManager.getDataManager().get('localeManager').get('BusinessModel'),
            type: "Business Map"
        };
        this.mappingObject['canvas_BMCanvasDiagram'] = {
            name: DataManager.getDataManager().get('localeManager').get('BusinessCanvas'),
            type: "BM Canvas"
        };
        this.mappingObject['vdml_Activity'] = {
            type: "Activity"
        };
        this.mappingObject['vdml_CapabilityOffer'] = {
            type: "Capability"
        };
        this.mappingObject['vdml_CapabilityLibrary']={
            name: DataManager.getDataManager().get('localeManager').get('CapabilityLibrary'),
            type: "CapabilityLibrary"
        };
        if (options && options.parentView) {
            this.encodeId = "" + options.modalId;
            this.callback = options.callback;
            this.capLibNameList = options.packages;
        }

        this.encodeId = window.utils.htmlEscape(self.model.id);
        this.options = options;
        window.utils.loadTemplate(function(name) {})
        /*if (!ko.components.isRegistered('CapabilityLibraryDetails')) {
            ko.components.register("CapabilityLibraryDetails", {
                viewModel: CapabilityLibraryDetailsViewModel,
                template: {
                    fromUrl: "js/app/version1/views/capabilityLibrary/views/properties/CapabilityLibraryDetailsTemplate.html"
                },
                synchronous: true
            });
        }*/

        if (!ko.components.isRegistered('CapabilityLibraryGuidance')) {
            self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
            ko.components.register("CapabilityLibraryGuidance", {
                viewModel: {
                    instance: self.guidanceViewInstance
                },
                template: '<div id="guidanceTab' + self.encodeId + '"></div>',
                synchronous: true
            });
        }
        this.selectedName.subscribe(function(val) {
            if (val == '') {
                self.enableCapComplete(false);
            } else {
                self.enableCapComplete(true);
            }
        });
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name', 'description', 'Complete', 'Delete', 'Guidance', 'Collaboration', 'type', 'children'
        ]);
    }

    cleanUp() {
        var self = this;
        /*if (ko.components.isRegistered('CapabilityLibraryDetails')) {
             ko.components.unregister('CapabilityLibraryDetails');
         }*/
        if (ko.components.isRegistered('CapabilityLibraryGuidance')) {
            ko.components.unregister('CapabilityLibraryGuidance');
        }
        window.utils.loadTemplate(null, true);
        if (self.spliter) {
            self.spliter.enhsplitter('remove');
        }
        if ($("#summernoteDefinition" + self.encodeId).next('.note-editor').length !== 0) {
            $("#summernoteDefinition" + self.encodeId).summernote('destroy');
            $("#summernotecss").attr("href", "");
        }
    }
    showDefinitionSection(contentDefinition) {
        var self = this;
        var summernote = $('#summernoteDefinition' + self.encodeId);
        summernote.summernote('destroy');
        summernote.summernote({
            airMode: true,
            height: null, // set editor height
            minHeight: null, // set minimum height of editor
            maxHeight: null, // set maximum height of editor
            focus: true,
            placeholder: 'write here...',
            disableResizeEditor: true, //disable resize 
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript']],
                ['para', ['style', 'ul', 'ol', 'paragraph']],
                ['height', ['height']],
                ['cleaner',['cleaner']], // The Button
                // ['insert', [ 'link', 'table', 'hr']]
            ],
            cleaner: {
                action: 'button', // both|button|paste 'button' only cleans via toolbar button, 'paste' only clean when pasting content, both does both options.
                newline: '<br>', // Summernote's default is to use '<p><br></p>'
                notStyle: 'position:absolute;top:0;left:0;right:0', // Position of Notification
                icon: '<i class="note-icon">Clear Formatting</i>',
                keepHtml: false, // Remove all Html formats
                keepOnlyTags: ['<p>', '<br>', '<ul>', '<li>', '<b>', '<strong>', '<i>', '<a>'], // If keepHtml is true, remove all tags except these
                keepClasses: false, // Remove Classes
                badTags: ['style', 'script', 'applet', 'embed', 'noframes', 'noscript', 'html'], // Remove full tags with contents
                badAttributes: ['style', 'start'], // Remove attributes from remaining tags
                limitChars: false, // 0/false|# 0/false disables option
                limitDisplay: 'both', // text|html|both
                limitStop: false // true/false
            }
        });
        summernote.find('.note-statusbar').hide();
        if (contentDefinition) {
            summernote.summernote('code', contentDefinition);
            summernote.summernote("editor.clearHistory", 'editor');
        }
        $('.note-editable').css('max-height', '300px');
        $('.note-editable').css('overflow-y', 'auto');
        $('.note-editor').css({
            "border-color": "#C1E0FF",
            "border-width": "1px",
            "border-style": "solid"
        });
        //$('.note-toolbar').hide();
        $("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
    }

    getUsedMapList(id) {
        var self = this;
        var tempId = id;
        var mapList = '';
        self.selectedMapList([]);
        var plan = DataManager.getDataManager().get('currentPlan');
        //var sample = this.model.get('id');
        var modelNode = self.getCapElementModal(tempId);
        DataManager.getDataManager().getReferencesToModel2(modelNode, function(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].predicate.indexOf("Capability-childCapability") != -1 || data[i].predicate.indexOf("CapabilityLibrary-capability") != -1 || data[i].predicate.indexOf("Capability-parentCapability") != -1 || data[i].predicate.indexOf("vdml_CapabilityMethod-capability") != -1) {
                    continue;
                } else {
                    var altId = DataManager.getDataManager().getRepositoryId(data[i].id);
                    var alternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({
                        id: altId
                    });
                    if (alternative) {
                        var alternativeName = alternative.get('name');
                        var phaseName = alternative.get('phaseAlternativeOwner').get('name');
                        data[i].namePath = phaseName + "/" + alternativeName + "/" + data[i].namePath;
                    }
                    var type = data[i].type.substring(data[i].type.lastIndexOf('_') + 1);
                    type = type.split(/(?=[A-Z])/).join(' ');
                    if (type == "Capability Library Diagram") {
                        type = self.mappingObject[data[i].type].name
                    } else {
                        type = self.mappingObject[data[i].type] ? self.mappingObject[data[i].type].type : null;
                    }
                    if (type) {
                        self.selectedMapList.push({
                            'name': data[i].name,
                            'type': type,
                            'shapeName': data[i].namePath
                        });
                    }
                    if ($('#showType').text() == "Activity" && type == "Activity") {
                        document.getElementById('showType').customId = data[i].id;
                        document.getElementById('showType').id = window.utils.htmlEscape(data[i].id);
                        $("<span class='showMappedInfo glyphicon glyphicon-info-sign glyphicon-button' style='font-size: small; color: orange;left:5px' ></span>").appendTo($('#' + window.utils.htmlEscape(data[i].id)))
                    }
                }
            }
            $('.showMappedInfo').on('click', function(view, event) {
                var options = {};
                var bmActivity = Backbone.Relational.store.getObjectByName('vdml.Activity').find({
                    id: view.currentTarget.parentElement.customId
                });

                if (bmActivity) {
                    options.mappedModal = bmActivity;
                }
                var id = window.utils.htmlEscape(window.guidGenerator());
                options.width = '800px';
                window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function(refresh) {
                    /*if (refresh && configObj.parentView.chartViewInstance && configObj.parentView.chartViewInstance.initialize) {
                    	configObj.parentView.chartViewInstance.initialize(configObj.parentView.model, true, configObj.parentView, self.getValueList(configObj.parentView.model));
                    }*/
                }, options);
            });
        }, function() {

            //callback(imageFoundList);
            self.selectedMapList.sort(function(a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else if (a.name.toLowerCase() === b.name.toLowerCase()) {

                    if (a.shapeName.toLowerCase() < b.shapeName.toLowerCase()) {
                        return -1;
                    } else if (a.shapeName.toLowerCase() > b.shapeName.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            });
        });

        if (self.mapList.length < 1) {
            plan.getAllDocumentsCollectionOfType(plan, 'ecomap_EcoMap,vdml_ValueDeliveryModel', function(results) {
                results.each(function(pack) {
                    self.mapList.push(pack);
                });
                // getReferencesToModel2(results);
            }, null, null, null, null, true);
        } else {
            // getReferencesToModel2(results);
        }
        return mapList;
        //DataManager.getDataManager().set('currentPlan', null);
    }

    /*searchInMaps(id){
        var plan = DataManager.getDataManager().get("currentPlan");
        var beepPacks = plan.getPlanPackageList(["ecomap_EcoMap"],false);
        var capPack = plan.getPlanPackageList(["vdml_ValueDeliveryModel"],true);
        for(var m=0; m < capPack.length; m++){
            if (capPack[m].beepReference.indexOf("Common") > -1) {
                beepPacks.push(capPack[m]);
            }
        }
        for(var j=0; j<beepPacks.length; j++){
            var beepId = beepPacks[j].beepReference;
            var type = beepPacks[j].beepType.replace('_', '.');
            var ecoPackage = window.utils.getElementModel(beepId,[type]);
            if(ecoPackage){
                var diagrams = ecoPackage.get('diagrams');
                if(diagrams){
                    for (var k = 0; k < diagrams.length; k++) {
                        var newXML = diagrams.at(k).get('data');
                        debugger
                        selfPackImageFound = window.utils.searchXmlData(newXML, 'mcid', id);
                        if(selfPackImageFound){
                            foundImage = true;
                            //break;
                        }
                    }
                }
            }
        }
    }*/

    getCapElementModal(id) {
        var self = this;
        var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({
            id: id
        });
        if (!capModel) {
            capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({
                id: id
            });
        }
        if (!capModel) {
            capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityLibrary').find({
                id: id
            });
        }
        return capModel;
    }
    
    deleteCapElement(view, event) {
        var self = view;
        var capModel = self.getCapElementModal(self.selectedCapId);
        if(capModel != self.model){
            bootbox.confirm(DataManager.getDataManager().get('localeManager').get('CapabilityDelete'), function (result) {
                if (result) {
                    window.utils.startSpinner('deleteCapElement', 'Deleting Capability Library Element...');
                    setTimeout(function () {
                        if (capModel) {
                            capModel.destroy();
                            self.showCapElementDetails(false);
                        }
                        self.clearAndReload();
                        window.utils.stopSpinner('deleteCapElement');
                    }, 10);
                }
            });
        }
        else{
            bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('MapDeleteAlert','Capability Library'), function(result) {
                if (result) {
                    window.utils.startSpinner('deleteCapLib', 'Deleting Capability Library...');
                    window.setTimeout(function () {	
                        self.model.destroy();
                        DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                        window.utils.stopSpinner('deleteCapLib');
                    }, 100);
                }
            });
        }        
    };

    clearAndReload() {
        var self = this;
        self.selectedName('');
        self.selectedDescription('');
        self.selectedDefinition('');
        self.childrens([]);
        //self.showCapElementDetails(false);
        self.selectedCapId = null;
        $('#jstree_div'+self.encodeId).jstree('select_node', null);
        $('#jstree_div'+self.encodeId).jstree(true).settings.core.selected = [];
        $('#jstree_div'+self.encodeId).jstree('open_node', self.model.id);
        self.jsTreeModelData = self.getModelDataInJSTreeFormat([self.model.getParent().get('capabilityLibrary').slice()]);
        var obj = self.jsTreeModelData[0];
        $('#jstree_div'+self.encodeId).jstree(true).settings.core.data = obj;
        $('#jstree_div'+self.encodeId).jstree(true).refresh(false, true);
        $('#jstree_div'+self.encodeId).jstree('open_node', window.utils.htmlEscape(self.model.id));
        self.setResizeHeight();
    }

    saveCapElement(view, event) {
        var self = this;
        var capModel = self.getCapElementModal(self.selectedCapId);
        var refresh = false;
        if (capModel) {
            if (capModel.get('name') != self.selectedName()) {
                capModel.set('name', self.selectedName());
                capModel.set('description', self.selectedDescription());
                refresh = true;
            }
            var summernote = $('#summernoteDefinition' + self.encodeId);
            var contentData = summernote.summernote('code');
            if (contentData == "<p><br></p>") {
                contentData = null;
            }
            if (contentData != self.selectedDefinition()) {
                capModel.set('definition', contentData);
                //refresh = true;
            }
        }
        if (refresh) {
            var newCap = self.selectedCapId;
            self.clearAndReload();
            setTimeout(function() {
                $('#jstree_div'+self.encodeId).jstree('select_node', (newCap));
                $('#jstree_div'+self.encodeId).jstree('open_node', (newCap));
                window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'), DataManager.getDataManager().get('viewAlternative'), self.CapabilityLibraryViewModel);
            }, 100);
            //$('#jstree_div'+self.encodeId+).on('refresh.jstree', function () {
            //     $('#jstree_div'+self.encodeId).jstree('select_node', window.utils.htmlEscape(newCap));
            //    $('#jstree_div'+self.encodeId).jstree('open_node', window.utils.htmlEscape(newCap));
            //});
            self.setResizeHeight();
        }

    };

    setResizeHeight(view, event) {
        var self = this;
        setTimeout(function() {
            var propertiesNode = 0;
            if ($('.propertiesArea').width() > 10) {
                propertiesNode = $('.propertiesArea').outerHeight();
            }
            var lhsHeight = $('#jstree_div'+self.encodeId).outerHeight() + 50;
            var windowHeight = window.innerHeight;
            var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight]);
            if (self.spliter) {
                self.spliter.css('height', baseHeight + 'px');
                if ($('.propertiesArea').width() < 5 && Math.abs($(window).width() - $('.modeler').width()) < 30) {
                    var windowWidth = $(window).width() - 15;
                    $('.splitter_bar').css('left', windowWidth);
                }
                $("#splitPanels" + self.encodeId).enhsplitter('refresh');
            }
            var rightTab = $('#rightTab' + self.encodeId);
            if (rightTab.width() > 0) {
                if ($('#rightTab' + self.encodeId + ' .active a')[0]) {
                    var propDivId = $('#rightTab' + self.encodeId + ' .active a')[0].hash;
                    if (propDivId !== null && $('#rightTab .dropdown .active a')[0]) {
                        propDivId = $('#rightTab .dropdown .active a')[0].hash;
                    }
                    var propNode = $('' + propDivId);
                }
            }
            if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/ ) {
                var dropdownDiv = rightTab.children().last();
                var appendList = dropdownDiv.find('li');
                var removedList = [];
                for (var i = 0; i < appendList.length; i++) {
                    dropdownDiv.before(appendList[i]);
                    removedList.push(appendList[i]);
                }
                if (appendList.length === removedList.length) {
                    dropdownDiv.remove();
                }
            }
            if (rightTab.width() > 10 && rightTab.width() < 415 && rightTab.children().length > 2 /* && rightTab.height() > 50*/ ) {
                var rightChildren = rightTab.children();
                var appendList = [];
                for (var i = rightChildren.length; i > 0; i--) {
                    if (!rightChildren[i - 1].classList.contains('dropdown')) {
                        appendList.push(rightChildren[i - 1]);
                        if (rightTab.width() < 375 && rightChildren[i - 2]) {
                            appendList.push(rightChildren[i - 2]);
                            break;
                        } else {
                            break;
                        }
                    }
                }
                if (!rightChildren.last()[0].classList.contains('dropdown')) {
                    rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
                }
                for (var i = 0; i < appendList.length; i++) {
                    rightTab.children().last().find('ul').prepend(appendList[i]);
                }
            }
            if (rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')) {
                var dropdownDiv = rightTab.children().last();
                var appendList = dropdownDiv.find('li');
                for (var i = 0; i < appendList.length; i++) {
                    appendList.eq(i).removeClass('active');
                }
            }
        }, 300);
    };

    afterRenderView(node, view) {
        var self = view;
        self.jsTreeModelData = self.getModelDataInJSTreeFormat([self.model.getParent().get('capabilityLibrary').slice()]);
        var pluginList = ["search"];

        var tree = $('#jstree_div'+self.encodeId).on("select_node.jstree", function(e, data) {
            if (data.selected.length) {
                var selectedNode = data.instance.get_node(data.selected[0]);
                var parentsLength = selectedNode.parents.length;
                self.childrens([]);
                var summernote = $('#summernoteDefinition' + self.encodeId);
                summernote.summernote('code', '');
                summernote.summernote('destroy');
                $("#capdef_div").hide();
                self.selectedName(selectedNode.text);
                self.selectedDescription(selectedNode.original.description);
                self.selectedType(self.mappingObject[selectedNode.original.type].name);
                self.selectedCapId = selectedNode.id;
                self.setPropertyValues(parentsLength,selectedNode);
            }
        }).jstree({
            'core': {
                'check_callback': true,
                'data': function(obj, callback) {
                    callback.call(this, []);

                }
            },
            "plugins": pluginList,
            "search": {
                'case_sensitive': false
            }
        });
        $('#jstree_div'+self.encodeId).bind('loaded.jstree', function () {
            setTimeout(function(){
                if(self.model.id){
                    $('#jstree_div'+self.encodeId).jstree(true).select_node( self.jsTreeModelData);
                    $('#jstree_div'+self.encodeId).jstree(true).open_node( self.jsTreeModelData);
                }
            },30);
        });
        //var obj = self.jsTreeModelData[0];
        //var obj = {
        //	text: 'All libraries',
        //	icon: 'img/object.png',
        //	children: self.jsTreeModelData
        //};
        $('#jstree_div'+self.encodeId).jstree(true).create_node(null, self.jsTreeModelData[0]);
        //var to = false;
        $('#treeSearch').change(function() {
            //commenting code , execution of search during timeout of 250ms. Now search is called during change instead of keyup. search will execute either onblur or with 'enter' keypress
            /*if(to) { clearTimeout(to); }
            to = setTimeout(function () {
              var v = $('#treeSearch').val();
              $('#jstree_div'+self.encodeId).jstree(true).search(v);
            }, 250);
            */
            window.utils.startSpinner('jsonSearch', 'Searching...');
            setTimeout(function() {
                var v = $('#treeSearch').val();
                $('#jstree_div'+self.encodeId).jstree(true).search(v);
                window.utils.applyFontSize($('#content'));
                window.utils.stopSpinner('jsonSearch');
                $('.jstree-anchor').css('text-overflow', 'ellipsis'); //mrajender //preventing strings going out of band
                $('.jstree-anchor').css('overflow', 'hidden');
                $('.jstree-anchor').css('max-width', '97%');
            }, 10);
        });
        $('#jstree_div'+self.encodeId).on('dblclick.jstree', function(e) {
            var text = e.target.text;
            if (text && text.length > 0) {
                var textSub = text.substr(text.indexOf(":") + 1);
                self.copyToClipboard(textSub);
            }
        });
        $('#jstree_div'+self.encodeId).on('open_node.jstree', function() {
            self.setResizeHeight();
        });
        $('#jstree_div'+self.encodeId).on('close_node.jstree', function() {
            self.setResizeHeight();
        });

        $("#selectedName" + self.encodeId).on('keyup', function(view) {
            var name = view.currentTarget.value.trim();
            self.selectedDescription(name);
            var prevName = self.selectedCapName;
            if (name === "") {
                self.enableCapComplete(false);
            } else if (name === prevName) {
                self.saveCapElement(false);
            } else {
                self.enableCapComplete(true);
            }
        });
        /*$("#capdef_div").on('keyup.selectedDef' + self.encodeId, function (e) {
            var name = e.target.value;
            var prevName = self.selectedDefinition();
            if (name === prevName) {
                self.saveCapElement(false);
            } else { self.saveCapElement(true); }
        });*/
        self.spliter = $("#splitPanels" + self.encodeId).enhsplitter({
            minSize: 0,
            onDrag: window.utils.splitterBoundary,
            collapse: 'right',
            position: '50%',
            handle: 'block',
            splitterSize: '9px',
            height: $("#js-canvas" + self.encodeId).outerHeight() + $('.table-responsive').outerHeight(),
            onDragEnd: self.setResizeHeight
        });
        $(window).resize(function(view, event) {
            this.timer = clearTimeout();
            this.timer = setTimeout(function() {
                self && self.setResizeHeight && self.setResizeHeight();
            }, 200);
        });
        $('#rightTab' + self.encodeId + ' a').click(function(e) {
            e.preventDefault();
            if ($(this).text() === "Collaboration") {
                return false;
            }
            $('.note-popover').hide()
            $(this).tab('show');
            if ($(this).text() === "Guidance") {
                if ($('#guidanceTab' + self.encodeId).is(':empty')) {
                    $.get('js/app/version1/views/help/views/properties/CapabilityLibraryGuidanceTemplate.html', function(viewStr) {
                        self.countGuidanceClicks++;
                        $('#guidanceTab' + self.encodeId).empty();
                        $('#guidanceTab' + self.encodeId).append(viewStr);
                        if (self.configObj && self.configObj.guidance) {
                            $('#guidanceTab' + self.encodeId).append(self.configObj.guidance);
                        }
                        window.utils.applyFontSize($('#guidanceTab' + self.encodeId));
                        self.setResizeHeight();
                    })
                } else {
                    self.setResizeHeight();
                }
            } else {
                self.setResizeHeight();
            }
        });

        //window.utils.applyFontSizeInDialog();

        $('#jstree_div'+self.encodeId).bind('ready.jstree', function(e, data) {
            $('#jstree_div'+self.encodeId).jstree('open_node', (self.model.id));
        });

        self.setResizeHeight();
        window.utils.stopSpinner('capabilitySpinner');
    };
    copyToClipboard(text) {
        var self = this;
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(text).select();


        // copy the selection
        var succeed;
        try {
            succeed = document.execCommand("copy");
        } catch (e) {
            succeed = false;
        }
        $temp.remove();
        return succeed;
    }

    sortByName(left, right) {
        if (left.text.match(/\d+/g) != null || right.text.match(/\d+/g) != null) {
            return ('' + left.text.toLowerCase()).localeCompare(('' + right.text.toLowerCase()), 'en', {
                numeric: true
            });
        } else {
            return left.text.toLowerCase() === right.text.toLowerCase() ? 0 : (left.text.toLowerCase() < right.text.toLowerCase() ? -1 : 1);
        }
    }
    handlePublicMenus() {
		var self = this;
		DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench", "Show Object Explorer", $.proxy(this.showobjectExplorerTree, this));
	};
    showobjectExplorerTree() {
        var self = this;
        var vdm = self.model.getNestedParent();
        var addOptions = this;
        window.getAndCreateModalDialog(true, self.encodeId, CapabilityLibraryMixin, vdm, "explorerModelJson", closeView, addOptions);
        function closeView() {
            window.cleanModalDialog(self.encodeId,CapabilityLibraryMixin);
        }
    };
    setPropertyValues(parentsLength,selectedNode){
        var self=this;
        self.showCapElementDetails(true);
        if (parentsLength > 1 && (selectedNode.original.icon == "img/object.png" || selectedNode.original.icon == "img/leaf.png")) {
            var capModel = self.getCapElementModal(selectedNode.id);
            $("#capdef_div").show();
            var annotation = capModel.get('definition') ? capModel.get('definition') : "";
            self.showDefinitionSection(annotation);
            //}
            self.getUsedMapList(selectedNode.id);
            var children =  capModel.get('childCapability');
            if(children && children.length > 0){
                for(var i=0;i<children.length;i++){
                    self.childrens.push({'id':children.at(i).id,'name':children.at(i).get('name')});
                }
            }
            if ($("#summernoteDefinition" + self.encodeId)[0].innerHTML === "<p><br></p>") {
                $("#summernoteDefinition" + self.encodeId)[0].innerHTML = "";
            }
            if ($(".note-editable")[0].innerHTML === "<p><br></p>") {
                $(".note-editable")[0].innerHTML = "";
            }
            self.setResizeHeight();
        } else {
            var children= self.model.get('capability');
            if(children && children.length > 0){
                for(var i=0;i<children.length;i++){
                    if(children.at(i).get('parentCapability').length == 0){
                        self.childrens.push({'id':children.at(i).id,'name':children.at(i).get('name')});
                    }
                }
            }
        }
    }
    getModelDataInJSTreeFormat(capabilityLibs) {
        var self = this;
        var ret = [];
        if (!capabilityLibs.length && capabilityLibs instanceof Backbone.Model) {
            //if (capabilityLibs.get('parentCapability').length == 0) {
            jsonData = {};
            ret.push(jsonData);
            jsonData.id = capabilityLibs.get('id');
            jsonData.text = capabilityLibs.get('name');
            jsonData.description = capabilityLibs.get('description');
            jsonData.type = capabilityLibs.get('type');
            jsonData.definition = capabilityLibs.get('definition');
            var typeMixinCls = DataManager.getDataManager().getModelType(capabilityLibs, true);
            jsonData.children = [];
            if (capabilityLibs instanceof Backbone.Model && capabilityLibs.get('type') !== "vdml_CapabilityLibrary" && capabilityLibs.get('childCapability').length > 0) {
                jsonData.children = jsonData.children.concat(self.getModelDataInJSTreeFormat(capabilityLibs.get('childCapability').models));
                ret[0].icon = "img/object.png";
            } else if (capabilityLibs instanceof Backbone.Model && capabilityLibs.get('type') === "vdml_CapabilityLibrary") {
                jsonData.children = jsonData.children.concat(self.getModelDataInJSTreeFormat(capabilityLibs.get('capability').models));
                ret[0].icon = "img/object.png";
            } else {
                ret[0].icon = "img/leaf.png";
            }
            //}
        } else {
            for (var i = 0; i < capabilityLibs.length; i++) {
                var jsonData = {};
                var capLibModels = capabilityLibs[i].models ? capabilityLibs[i].models : capabilityLibs[i];
                if ((!capLibModels.length && capLibModels instanceof Backbone.Model) || (capLibModels.length && capLibModels.length > 0)) {
                    ret.push(jsonData);
                }
                for (var j = 0; j < capLibModels.length; j++) {
                    var capabilityLib = capLibModels[j];
                    /*if (j > 0 && capabilityLib.get('type') == "vdml_CapabilityLibrary") {
                    	var jsonData = {};
                    	ret.push(jsonData);
                    	ret[j].icon = "img/object.png";
                    }*/
                    if (capabilityLib.get('type') == "vdml_CapabilityLibrary" && capabilityLib !== self.model) {
                        continue;
                    }
                    jsonData.id = capabilityLib.get('id');
                    jsonData.text = capabilityLib.get('name');
                    jsonData.description = capabilityLib.get('description');
                    jsonData.type = capabilityLib.get('type');
                    jsonData.definition = capabilityLib.get('definition');
                    var typeMixinCls = DataManager.getDataManager().getModelType(capabilityLib, true);
                    if (capabilityLib.get('type') !== "vdml_CapabilityLibrary") {
                        jsonData.children = [];
                        jsonData.children = jsonData.children.concat(self.addRelationsShipsForAppCls(capabilityLib, typeMixinCls));
                        if (!jsonData.children.text) {
                            jsonData.children = jsonData.children[0].children;
                        }
                    } else {
                        jsonData.children = [];
                        var caplist = capabilityLib.get('capability').models;
                        var newcaplist = [];
                        for (var k = 0; k < caplist.length; k++) {
                            var addtolist = false;
                            _.each(caplist[k].get('parentCapability').models, function(parentCap) {
                                if (parentCap.getParent() != capabilityLib) {
                                    addtolist = true;
                                }
                            });
                            if (caplist[k].get('parentCapability').length == 0 || addtolist) {
                                newcaplist.push(caplist[k]);
                            }
                        }
                        jsonData.children = self.getModelDataInJSTreeFormat(newcaplist);
                        ret[j] ? ret[j].icon = "img/object.png" : '';
                    }
                    ret[i] ? ret[i].icon = "img/object.png" : '';
                }
                if (!capLibModels.length && capLibModels instanceof Backbone.Model) {
                    jsonData.id = capLibModels.get('id');
                    if (capLibModels.get('type') != "vdml_CapabilityLibrary" && self.model != capLibModels.get('capabilityOwner')) {
                        jsonData.text = capLibModels.get('name').concat('(from:' + capLibModels.get('capabilityOwner').get('name') + ')');
                    } else {
                        jsonData.text = capLibModels.get('name');
                    }
                    jsonData.description = capLibModels.get('description');
                    jsonData.type = capLibModels.get('type');
                    jsonData.definition = capLibModels.get('definition');
                    var typeMixinCls = DataManager.getDataManager().getModelType(capLibModels, true);
                    if (capLibModels instanceof Backbone.Model && capLibModels.get('childCapability').length > 0) {
                        jsonData.children = self.getModelDataInJSTreeFormat(capLibModels.get('childCapability').models);
                        ret[i].icon = "img/object.png";
                    } else {
                        ret[i].icon = "img/leaf.png";
                    }
                }
                //if ($.isEmptyObject(jsonData)) {
                //	ret = [];
                //} 
            }

        }
        ret.sort(self.sortByName);
        return ret;
    };
    addRelationsShipsForAppCls(model, typeMixinCls) {
        var self = this;
        var ret = [];
        var value = model;
        addRelationShip(value);

        function addRelationShip(relationValue) {
            if (relationValue instanceof Backbone.Model) {
                ret.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
            } else {
                var relationValues = relationValue;
                var jsonData = {};
                ret.push(jsonData);
                if (relationValues && relationValues.length > 0) {
                    jsonData.children = [];
                }
                var valueCount = 0;

                function handleNextRelationshipValue(children) {
                    if (relationValues && valueCount < relationValues.length) {
                        var newRelationValue = relationValues.at(valueCount);
                        valueCount++;
                        //var children = jsonData.children ? jsonData.children : jsonData;
                        addRelationshipValue(newRelationValue, children);
                    }
                }

                function addRelationshipValue(relationValue, children) {
                    if (relationValue && relationValue.get('parentCapability') && relationValue.get('parentCapability').length == 0) {
                        if (typeof children.length == "undefined") {
                            children.children = [];
                            children.children.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
                        } else {
                            children.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
                        }
                        handleNextRelationshipValue();
                    } else {
                        children.push(self.getModelDataInJSTreeFormat(relationValue));
                        handleNextRelationshipValue();
                    }
                }
                handleNextRelationshipValue(jsonData.children);
            }
        }
        return ret;
    };

    addChild(view,event){
        var self = this;
        var selVal = self.model.get('capability').findWhere({ id: self.selectedCapId });
        if (!selVal) {
            selVal = Backbone.Relational.store.getObjectByName('vdml.CapabilityLibrary').find({ id: self.selectedCapId });
            if (!selVal) {
                bootbox.alert("Selected Value does not exist");
                return;
            }
        }
        var autoCompAttr = 'new-password';
        if(window.browser == "chrome"){
            autoCompAttr = 'off';
        }
        bootbox.dialog({
            title: DataManager.getDataManager().get('localeManager').get("AddChild"),
            message: "<label>Name:&nbsp;</label><input id='valchildName' autocomplete = "+autoCompAttr+" type='text'>&nbsp;<label>Type:&nbsp;</label><select id='valType'><option value='def'>Capability Definition</option> <option value='cat'>Capability Category</option></select>",
            buttons: {
                success: {
                    label: "Complete",
                    className: "btn btn-complete complete-btn",
                    callback: function () {
                        var catName = $("#valchildName").val();
                        var selValue = $("#valType :selected").val();
                        var objType = Backbone.Relational.store.getObjectByName("vdml.CapabilityDefinition");
                        if(selValue == "cat"){
                            objType = Backbone.Relational.store.getObjectByName("vdml.CapabilityCategory");
                        }
                        if (catName.trim().length > 0) {
                            var valCreated = objType.getInstance(catName, catName, self.model);
                            if(self.model == selVal){
                                selVal.get('capability').add(valCreated);
                            } else {
                                selVal.get("childCapability").add(valCreated);
                                valCreated.get("parentCapability").add(selVal);
                                //self.childrens.push({'id':valCreated.id,'name':valCreated.get('name')});
                            }
                            var newCap = self.selectedCapId;
                            self.clearAndReload();
                            setTimeout(function () {
                                $('#jstree_div'+self.encodeId).jstree('select_node', (newCap));
                                $('#jstree_div'+self.encodeId).jstree('open_node', (newCap));
                            }, 500);
                        }                            
                    }
                }
            }
        })    
    }

    static getInstance(model, options) {
        var view = new CapabilityLibraryViewModel(model, options);
        view.init(model, options);
        return view;
    };
}
path.CapabilityLibraryViewModel = CapabilityLibraryViewModel;