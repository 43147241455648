import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {Ticket} from './Ticket'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin
//, Ticket){
	
	/*if(!Ticket){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.Ticket){
			Ticket = importPath.Ticket;
		}
		else{
			require(["appbo/tickets/Ticket"],function(loadedModule){
				Ticket = loadedModule;
			});
		}
	}*/
	export class UserMixin{
	constructor(){
		var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		path.UserMixin = UserMixin;
	}
	
	defaults(){
		var ret = {
			type: "tickets_User"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"tickets_User",
			key:"reportedTicket",
			relatedModel:"tickets.Ticket",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"reporter",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
/*		{
			type :Backbone.HasOne,
			containingClass:"tickets_User",
			key:"nextRole",
			relatedModel:"transformation.Role",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"role",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
*/		])
	}
	static getCumulativeMixinRelations(){
		if (!UserMixin.cummulativeRelations) {
            UserMixin.cummulativeRelations = _.union(UserMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return UserMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "email",type : "EString",defaultValue : "null",containingClass : "tickets_User" },
			//{name : "pictureUrl",type : "EString",defaultValue : "null",containingClass : "tickets_User" },
			//{name : "icon",type : "EString",defaultValue : "null",containingClass : "tickets_User" },	// commenting it so that rdf does not store icon value
			{name : "userId",type : "EString",defaultValue : "null",containingClass : "tickets_User" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("userOwner") ? this.get("userOwner") : this.previousAttributes().userOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("userOwner") ? this.get("userOwner") : this.previousAttributes().userOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("userOwner") ? this.get("userOwner") : this.previousAttributes().userOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	static getViewProperties(type){
		return {
			templatePath : "views/tickets/views/properties/UserPropertiesTemplate.html",
			templateName : "UserPropertiesTemplate",
			viewTypeStr : "appviews/tickets/views/properties/UserViewModel",
			tabId : "UserView",
			tabName: "User"
		}
	};
	static getDialogViewProperties(type){
        if (type === "ProductList") {
            return {
                templatePath: "views/tickets/views/properties/ProductListTemplate.html",
                templateName: "ProductListTemplate",
                viewTypeStr: "appviews/tickets/views/properties/ProductListViewModel",
                tabId: "ProductListView",
                tabName: "ProductList"
            }
        } else if (type === "Partners") {
            return {
                templatePath: "views/tickets/views/properties/PartnersTemplate.html",
                templateName: "PartnersTemplate",
                viewTypeStr: "appviews/tickets/views/properties/PartnersViewModel",
                tabId: "PartnersView",
                tabName: "Partners"
            };
        } else if (type === "LECluster") {
            return {
                templatePath: "views/tickets/views/properties/LegalEntityClusterTemplate.html",
                templateName: "LegalEntityClusterTemplate",
                viewTypeStr: "appviews/tickets/views/properties/LegalEntityClusterViewModel",
                tabId: "LegalEntityClusterView",
                tabName: "LECluster"
            };
        } else if (type === "Customers") {
            return {
                templatePath: "views/tickets/views/properties/CustomersTemplate.html",
                templateName: "CustomersTemplate",
                viewTypeStr: "appviews/tickets/views/properties/CustomersViewModel",
                tabId: "CustomersView",
                tabName: "Customers"
            };
        } else if (type === "Invitation") {
            return {
                templatePath: "views/tickets/views/properties/InvitationTemplate.html",
                templateName: "InvitationTemplate",
                viewTypeStr: "appviews/tickets/views/properties/InvitationViewModel",
                tabId: "InvitationView",
                tabName: "Invitation"
            };
        } else if (type === "Contract") {
            return {
                templatePath: "views/tickets/views/properties/ContractTemplate.html",
                templateName: "ContractTemplate",
                viewTypeStr: "appviews/tickets/views/properties/ContractViewModel",
                tabId: "ContractView",
                tabName: "Contract"
            };
        } else if (type === "Product") {
            return {
                templatePath: "views/tickets/views/properties/ProductTemplate.html",
                templateName: "ProductTemplate",
                viewTypeStr: "appviews/tickets/views/properties/ProductViewModel",
                tabId: "ProductView",
                tabName: "Product"
            };
        } else if (type === "Purchase") {
            return {
                templatePath: "views/tickets/views/properties/PurchaseTemplate.html",
                templateName: "PurchaseTemplate",
                viewTypeStr: "appviews/tickets/views/properties/PurchaseViewModel",
                tabId: "PurchaseView",
                tabName: "Purchase"
            };
        } else if (type === "Invoice") {
            return {
                templatePath: "views/tickets/views/properties/InvoiceTemplate.html",
                templateName: "InvoiceTemplate",
                viewTypeStr: "appviews/tickets/views/properties/InvoiceViewModel",
                tabId: "InvoiceView",
                tabName: "Invoice"
            };
        } else if (type === "Credits") {
            return {
                templatePath: "views/tickets/views/properties/CreditsTemplate.html",
                templateName: "CreditsTemplate",
                viewTypeStr: "appviews/tickets/views/properties/CreditsViewModel",
                tabId: "CreditsView",
                tabName: "Credits"
            };
        } else if (type === "PricingRule") {
            return {
                templatePath: "views/tickets/views/properties/PricingRuleTemplate.html",
                templateName: "PricingRuleTemplate",
                viewTypeStr: "appviews/tickets/views/properties/PricingRuleViewModel",
                tabId: "PricingRuleView",
                tabName: "PricingRule"
            };
        } else if (type === "ProductCluster") {
            return {
                templatePath: "views/tickets/views/properties/ProductClusterTemplate.html",
                templateName: "ProductClusterTemplate",
                viewTypeStr: "appviews/tickets/views/properties/productClusterViewModel",
                tabId: "ProductClusterView",
                tabName: "ProductCluster"
            };
        } else if (type === "Registration") {
            return {
                templatePath: "views/tickets/views/properties/RegistrationTemplate.html",
                templateName: "RegistrationTemplate",
                viewTypeStr: "appviews/tickets/views/properties/RegistrationViewModel",
                tabId: "RegistrationView",
                tabName: "Registration"
            };
        } else if (type == "Postman") {
            return {
                templatePath: "views/tickets/views/properties/Postman.html",
                templateName: "PostmanTemplate",
                viewTypeStr: "appviews/tickets/views/properties/PostmanViewModel",
                tabId: "PostmanView",
                tabName: "PostmanView"
            }
        }else if(type == "UserStores"){
			return{
				templatePath: "views/tickets/views/properties/UserStoresTemplate.html",
				templateName: "UserStoresTemplate",
				viewTypeStr : "appviews/tickets/views/properties/UserStoresViewModel",
				tabId: "UserStoresView",
				tabName : "UserStores"
			}
		}/*else if(type == "ChangeSets"){
			return{
				templatePath: "views/tickets/views/properties/ChangeSetsTemplate.html",
				templateName: "ChangeSetsTemplate",
				viewTypeStr : "appviews/tickets/views/properties/ChangeSetsViewModel",
				tabId: "ChangeSetsView",
				tabName : "ChangeSets"
			}
		}*/
	};
	//path.UserMixin = UserMixin;
	//return UserMixin;
}