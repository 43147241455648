// Generated from ExprLang.g4 by ANTLR 4.13.2
// jshint ignore: start
import antlr4 from 'antlr4';

import ExprLangLexer from './ExprLangLexer.js'
import ExprLangParser from './ExprLangParser.js'
import ExprLangListener from './ExprLangListener.js';

// This class defines a complete listener for a parse tree produced by ExprLangParser.
export default class ExprLangToJuliaListener extends ExprLangListener {

	constructor() {
        super();
        this.exprStack = [];
        this.juliaCode = '';  // Collects the entire output
    }
	// Enter a parse tree produced by ExprLangParser#program.
	enterProgram(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#program.
    exitProgram(ctx) {
        while (this.exprStack.length > 0) {
            this.juliaCode += this.exprStack.pop() + "\n";
        }
    }


	// Enter a parse tree produced by ExprLangParser#statement.
	enterStatement(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#statement.
    exitStatement(ctx) {
        const expr = this.exprStack.pop();
        //this.juliaCode += `${expr};\n`;
        this.juliaCode += `${expr}\n`;
    }


	// Enter a parse tree produced by ExprLangParser#parenvmpExpression.
	enterParenvmpExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#parenvmpExpression.
    exitParenvmpExpression(ctx) {
        const expr = this.exprStack.pop();
        this.exprStack.push(`(${expr})`);
    }

	exitMethodFunctionCall(ctx) {
        const objectName = this.exprStack.pop();
        const methodName = ctx.IDENTIFIER().getText();

        let expr = `${methodName}(`;
        if (ctx.vmpExprList() != null) {
            expr += `${this.exprStack.pop()}`;
            expr += `, ${objectName}`;
        }else{
            expr += '${objectName}'
        }
        expr += ')';

        this.exprStack.push(expr);
    }

    exitSimpleFunctionCall(ctx) {
        const functionName = ctx.IDENTIFIER().getText();

        let expr = `${functionName}(`;
        if (ctx.vmpExprList() != null) {
            expr += this.exprStack.pop();
        }
        expr += ')';

        this.exprStack.push(expr);
    }

	// Enter a parse tree produced by ExprLangParser#number.
	enterNumber(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#number.
    exitNumber(ctx) {
        this.exprStack.push(ctx.NUMBER().getText());
    }


	// Enter a parse tree produced by ExprLangParser#string.
	enterString(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#string.
    exitString(ctx) {
        this.exprStack.push(ctx.STRING().getText());
    }


	// Enter a parse tree produced by ExprLangParser#functionCall.
	enterFunctionCall(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#functionCall.
	exitFunctionCall(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#variable.
	enterVariable(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#variable.
    exitVariable(ctx) {
        this.exprStack.push(ctx.IDENTIFIER().getText());
    }

	// Enter a parse tree produced by ExprLangParser#objectAccess.
	enterObjectAccess(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#objectAccess.
	exitObjectAccess(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#ternaryExpression.
	enterTernaryExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#ternaryExpression.
    exitTernaryExpression(ctx) {
        const falseExpr = this.exprStack.pop();
        const trueExpr = this.exprStack.pop();
        const condition = this.exprStack.pop();

        const expr = `${condition} ? ${trueExpr} : ${falseExpr}`;
        this.exprStack.push(expr);
    }


	// Enter a parse tree produced by ExprLangParser#unaryvmpExpression.
	enterUnaryvmpExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#unaryvmpExpression.
    exitUnaryvmpExpression(ctx) {
        const operand = this.exprStack.pop();
        const op = ctx.unaryOp().getText();

        const expr = `${op}${operand}`;
        this.exprStack.push(expr);
    }


	// Enter a parse tree produced by ExprLangParser#binaryvmpExpression.
	enterBinaryvmpExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#binaryvmpExpression.
    exitBinaryvmpExpression(ctx) {
        const right = this.exprStack.pop();
        const left = this.exprStack.pop();
        const op = ctx.binaryOp().getText();

        const expr = `${left} ${op} ${right}`;
        this.exprStack.push(expr);
    }


	// Enter a parse tree produced by ExprLangParser#binaryOp.
	enterBinaryOp(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#binaryOp.
	exitBinaryOp(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#unaryOp.
	enterUnaryOp(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#unaryOp.
	exitUnaryOp(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#func.
	enterFunc(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#func.
	exitFunc(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#vmpExprList.
	enterVmpExprList(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#vmpExprList.
    exitVmpExprList(ctx) {
        let args = [];
        for (let i = 0; i < ctx.vmpExpr().length; i++) {
            args.push(this.exprStack.pop());
        }
        args = args.reverse().join(', ');
        this.exprStack.push(args);
    }

	// Enter a parse tree produced by ExprLangParser#objAccess.
	enterObjAccess(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#objAccess.
    exitObjAccess(ctx) {
        let expr = '';

        const hasSelf = ctx.getText().startsWith('self');
        const startIndex = hasSelf ? 1 : 0;
        let firstToken = true;

        for (let i = startIndex; i < ctx.getChildCount(); i++) {
            const child = ctx.getChild(i);

            if (child instanceof antlr4.tree.TerminalNode) {
                const tokenStr = child.getText();

                if (
                    child.symbol.type === ExprLangLexer.IDENTIFIER ||
                    ['bm', 'vp', 'activity', 'value', 'quantity'].includes(tokenStr)
                ) {
                    if (firstToken) {
                        expr += tokenStr;
                        firstToken = false;
                    } else {
                        expr += `["${tokenStr}"]`;
                    }
                }
            } else if (child instanceof ExprLangParser.ObjAccessContext) {
                // Handle nested object access
                const nestedExpr = this.exprStack.pop();
                expr += `["${nestedExpr}"]`;
            } else if (child instanceof ExprLangParser.VmpExprContext) {
                // Handle array access like x[y] or x[1]
                const arrayIndex = this.exprStack.pop();
                expr += `[${arrayIndex}]`;
            }
        }

        this.exprStack.push(expr);
    }


    getResult() {
        return this.juliaCode.trim();
    }

}