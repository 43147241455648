import { PlansWorkspaceExplorerViewModel } from './com/vbee/filesystem/views/explorer/PlansWorkspaceExplorerViewModel';
import { PlanViewModel } from './version1/views/transformation/views/properties/PlanViewModel';
import { PlanDetailsViewModel } from './version1/views/transformation/views/properties/PlanDetailsViewModel';
import { PlanValidationViewModel } from './version1/views/transformation/views/correction/PlanValidationViewModel';
import { PlanValueDetailsViewModel } from './version1/views/transformation/views/properties/PlanValueDetailsViewModel';
import { PhaseViewModel } from './version1/views/transformation/views/properties/PhaseViewModel';
import { PhaseDetailsViewModel } from './version1/views/transformation/views/properties/PhaseDetailsViewModel';
import { AlternativeViewModel } from './version1/views/transformation/views/properties/AlternativeViewModel';
import { AlternativeDetailsViewModel } from './version1/views/transformation/views/properties/AlternativeDetailsViewModel';
import { RegistrationViewModel } from './version1/views/tickets/views/properties/RegistrationViewModel';
import { ProductListViewModel } from './version1/views/tickets/views/properties/ProductListViewModel';
import { UserStoresViewModel } from './version1/views/tickets/views/properties/UserStoresViewModel';
import { AdvancedPlanDetailsViewModel } from './version1/views/transformation/views/properties/AdvancedPlanDetailsViewModel';
import { AlternativeInputValuesViewModel } from './version1/views/transformation/views/properties/AlternativeInputValuesViewModel';
import { ValueDetailsViewModel } from './version1/views/vdml/views/properties/ValueDetailsViewModel';
import { ValueAggregationViewModel } from './version1/views/vdml/views/properties/ValueAggregationViewModel';
import { DiscoverViewModel } from './version1/views/beeppackage/views/properties/DiscoverViewModel';
import { PrototypeViewModel } from './version1/views/beeppackage/views/properties/PrototypeViewModel';
import { AdoptViewModel } from './version1/views/beeppackage/views/properties/AdoptViewModel';
import { AdminViewModel } from './version1/views/beeppackage/views/properties/AdminViewModel';
import { BMCubeViewModel } from './version1/views/vdml/views/bmcube/BMCubeViewModel';
import { BusinessModelReportViewModel } from './version1/views/vdml/views/properties/BusinessModelReportViewModel';
import { BusinessModelViewModel } from './version1/views/vdml/views/properties/BusinessModelViewModel';
import { BusinessDetailsViewModel } from './version1/views/vdml/views/properties/BusinessDetailsViewModel';
import { CustomerDetailsViewModel } from './version1/views/vdml/views/properties/CustomerDetailsViewModel';
import { NetworkPartnerViewModel } from './version1/views/vdml/views/properties/NetworkPartnerViewModel';
import { BusinessModelActivityDetailsViewModel } from './version1/views/vdml/views/properties/BusinessModelActivityDetailsViewModel';
import { BusinessModelCompetenceViewModel } from './version1/views/vdml/views/properties/BusinessModelCompetenceViewModel';
import { explorerModelJsonViewModel } from './version1/views/beeppackage/views/properties/explorerModelJsonViewModel';
import { EcoMapDesignerViewModel } from './version1/views/ecoMap/views/designer/EcoMapDesignerViewModel';
import { TargetDetailsViewModel } from './version1/views/ecoMap/views/designer/TargetDetailsViewModel';
import { ShapePropertiesViewModel } from './version1/views/ecoMap/views/designer/ShapePropertiesViewModel';
import { EcomapMappingWizardViewModel } from './version1/views/ecoMap/views/designer/EcomapMappingWizardViewModel';
import { ImageSelectorViewModel } from './version1/views/ecoMap/views/designer/ImageSelectorViewModel';
import { ChartDetailsViewModel } from './version1/views/ecoMap/views/designer/ChartDetailsViewModel';
import { MappingWizardViewModel } from './version1/views/strategyMap/views/designer/MappingWizardViewModel';
import { CapabilityMappingWizardViewModel } from './version1/views/capabilityLibrary/views/designer/CapabilityMappingWizardViewModel';
import { StrategyMapDesignerViewModel } from './version1/views/strategyMap/views/designer/StrategyMapDesignerViewModel';
import { StrategyMapDetailsViewModel } from './version1/views/strategyMap/views/designer/StrategyMapDetailsViewModel';
import { StrategyMapObjectiveViewModel } from './version1/views/strategyMap/views/designer/StrategyMapObjectiveViewModel';
import { StrategyMapValuesChart } from './version1/views/strategyMap/views/designer/StrategyMapValuesChart';
import { BusinessModelCompetencyDetailsViewModel } from './version1/views/vdml/views/properties/BusinessModelCompetencyDetailsViewModel';
//import { ValueStreamMapDetailsViewModel } from './version1/views/ValueStreamMap/views/designer/ValueStreamMapDetailsViewModel';
import { ValueStreamMapDesignerViewModel } from './version1/views/valueStreamMap/views/designer/ValueStreamMapDesignerViewModel';
import { ProcessTree } from './version1/views/valueStreamMap/views/designer/ProcessTree';
import { BMGuidanceViewModel } from './version1/views/help/views/properties/BMGuidanceViewModel';
import { ReportValuesChartViewModel } from './version1/views/report/charts/ReportValuesChartViewModel';
import { ReportDetailsViewModel } from './version1/views/report/views/properties/ReportDetailsViewModel';
import { SummernoteReportViewModel } from './version1/views/report/views/summernote/SummernoteReportViewModel';
import { CaseDetailsViewModel } from './version1/views/vdml/views/designer/caseModel/CaseDetailsViewModel';
import { CmmnDiagramViewModel } from './version1/views/vdml/views/designer/caseModel/CmmnDiagramViewModel';
import { ProcessDiagramDetailsViewModel } from './version1/views/vdml/views/designer/processModel/ProcessDiagramDetailsViewModel';
import { ProcessDiscoveryDiagramViewModel } from './version1/views/vdml/views/designer/processModel/ProcessDiscoveryDiagramViewModel';
import { ImportFromCloudViewModel } from './version1/views/transformation/views/properties/ImportFromCloudViewModel';
import { CloudViewModel } from './version1/views/transformation/views/properties/CloudViewModel';
import { DashboardViewModel } from './version1/views/dashboard/views/chart/DashboardViewModel';
import {CreateChartViewModel} from './version1/views/dashboard/views/chart/CreateChartViewModel';
import {AddAlternativeViewModel} from './version1/views/dashboard/views/chart/AddAlternativeViewModel';
import { SelectChartValuesViewModel } from './version1/views/dashboard/views/chart/SelectChartValuesViewModel';
import { ValueExplorerJsonViewModel } from './version1/views/valueLibrary/properties/ValueExplorerJsonViewModel';
import { MoveValueViewModel } from './version1/views/vdml/views/properties/MoveValueViewModel';
import { BusinessModelValidationViewModel } from './version1/views/vdml/views/correction/BusinessModelValidationViewModel';
import { ValueUnitDetailsViewModel } from './version1/views/vdml/views/properties/ValueUnitDetailsViewModel';
import { ValueExpressionDetailsViewModel } from './version1/views/vdml/views/properties/ValueExpressionDetailsViewModel';
import { CapabilityLibraryDiagramViewModel } from './version1/views/capabilityLibrary/views/designer/CapabilityLibraryDiagramViewModel';
import { CapabilityLibraryDiagramDetailsViewModel } from './version1/views/capabilityLibrary/views/designer/CapabilityLibraryDiagramDetailsViewModel';
import { CapabilityExplorerJsonViewModel } from './version1/views/capabilityLibrary/views/designer/CapabilityExplorerJsonViewModel';
import { CapabilityTreeConstructionViewModel } from './version1/views/capabilityLibrary/views/properties/CapabilityTreeConstructionViewModel';
import { UpdateCapabilityTreeViewModel } from './version1/views/capabilityLibrary/views/properties/UpdateCapabilityTreeViewModel';
import { CapabilityLibraryViewModel } from './version1/views/capabilityLibrary/views/properties/CapabilityLibraryViewModel';
import { CapabilityLibraryDetailsViewModel } from './version1/views/capabilityLibrary/views/properties/CapabilityLibraryDetailsViewModel';
import { EditValueDeliveryModelDetailsViewModel } from './version1/views/vdml/views/properties/EditValueDeliveryModelDetailsViewModel';
import {ScenarioDetailsViewModel} from '../app/version1/views/dashboard/views/chart/ScenarioDetailsViewModel';
import {ImportDataViewModel} from '../app/version1/views/dashboard/views/chart/ImportDataViewModel';
import { ConceptViewModel } from './version1/views/concept/views/properties/ConceptViewModel';
import { ValueLibraryViewModel } from './version1/views/valueLibrary/properties/ValueLibraryViewModel';
import { ValueLibraryDetailsViewModel } from './version1/views/valueLibrary/properties/ValueLibraryDetailsViewModel';
import { VocabularyDetailsViewModel } from './version1/views/concept/views/properties/VocabularyDetailsViewModel';
import { VocabularyPackageViewModel } from './version1/views/concept/views/properties/VocabularyPackageViewModel';
import { EcoMapPackageViewModel } from './version1/views/ecoMap/views/designer/EcoMapPackageViewModel';
import { DashboardPackageViewModel } from './version1/views/dashboard/views/properties/DashboardPackageViewModel';
import {PostmanViewModel} from './version1/views/tickets/views/properties/PostmanViewModel'
import {Support} from './version1/views/tickets/views/properties/Support';
import {SupportViewModel} from './version1/views/tickets/views/properties/SupportViewModel';
import {History} from './version1/views/tickets/views/properties/History';
import {HistoryViewModel} from './version1/views/tickets/views/properties/HistoryViewModel';
import {Market} from './version1/views/tickets/views/properties/Market';
import {MarketViewModel} from './version1/views/tickets/views/properties/MarketViewModel';
import {Audit} from './version1/views/tickets/views/properties/Audit';
import {AuditViewModel} from './version1/views/tickets/views/properties/AuditViewModel';
import {CanvasTargetDetailsViewModel} from './version1/views/canvas/views/designer/CanvasTargetDetailsViewModel'
import {CanvasDesignerViewModel} from './version1/views/canvas/views/designer/CanvasDesignerViewModel'
import {CanvasDetailsViewModel} from './version1/views/canvas/views/designer/CanvasDetailsViewModel'
import { EcoMap1002 } from './version1/bo/ecomap/migration/EcoMap1002';
import { EcoMap1003 } from './version1/bo/ecomap/migration/EcoMap1003';
import { EcoMap1004 } from './version1/bo/ecomap/migration/EcoMap1004';
import {ValuesViewModel} from './version1/views/transformation/views/properties/ValuesViewModel';
import {MapValidationViewModel} from './version1/views/ecoMap/views/designer/MapValidationViewModel';
import AlternativeDetailsTemplate from './version1/views/transformation/views/properties/AlternativeDetailsTemplate.html';
import PlansWorkspaceExplorerTemplate from './com/vbee/filesystem/views/explorer/PlansWorkspaceExplorerTemplate.html';
import PlanPropertiesTemplate from './version1/views/transformation/views/properties/PlanPropertiesTemplate.html';
import PlanValuesChartTemplate from './version1/views/transformation/views/chart/PlanValuesChartTemplate.html';
import PlanValueDetailsTemplate from './version1/views/transformation/views/properties/PlanValueDetailsTemplate.html';
import ColloborationPropertiesTemplate from './version1/views/tickets/views/properties/ColloborationPropertiesTemplate.html';
import planGuidancePropertiesTemplate from './version1/views/help/views/properties/planGuidancePropertiesTemplate.html';
import DiscoverTemplate from './version1/views/beeppackage/views/properties/DiscoverTemplate.html';
import SummernoteReportTemplate from './version1/views/report/views/summernote/SummernoteReportTemplate.html';
import EcoMapDesignerTemplate from './version1/views/ecoMap/views/designer/EcoMapDesignerTemplate.html';
import ValueStreamMapDesignerTemplate from './version1/views/valueStreamMap/views/designer/ValueStreamMapDesignerTemplate.html';
import StrategyMapDesignerTemplate from './version1/views/strategyMap/views/designer/StrategyMapDesignerTemplate.html'; 
import AdoptTemplate from './version1/views/beeppackage/views/properties/AdoptTemplate.html';
import DashboardTemplate from './version1/views/dashboard/views/chart/DashboardTemplate.html';
import AdminTemplate from './version1/views/beeppackage/views/properties/AdminTemplate.html';
import CapabilityLibraryDiagramTemplate from './version1/views/capabilityLibrary/views/designer/CapabilityLibraryDiagramTemplate.html';
import CapabilityLibraryDetailsTemplate from './version1/views/capabilityLibrary/views/properties/CapabilityLibraryDetailsTemplate.html';
import PrototypeTemplate from './version1/views/beeppackage/views/properties/PrototypeTemplate.html';
import BMCubeTemplate from './version1/views/vdml/views/bmcube/BMCubeTemplate.html';
import BusinessModelPropertiesTemplate from './version1/views/vdml/views/properties/BusinessModelPropertiesTemplate.html';
import BusinessDetailsPropertiesTemplate from './version1/views/vdml/views/properties/BusinessDetailsPropertiesTemplate.html';
import AdvancedPlanDetailsTemplate from './version1/views/transformation/views/properties/AdvancedPlanDetailsTemplate.html';
import PlanDetailsTemplate from './version1/views/transformation/views/properties/PlanDetailsTemplate.html';
import PhaseDetailsTemplate from './version1/views/transformation/views/properties/PhaseDetailsTemplate.html';
import ShapePropertiesTemplate from './version1/views/ecoMap/views/designer/ShapePropertiesTemplate.html';
import ImageSelectorTemplate from './version1/views/ecoMap/views/designer/ImageSelectorTemplate.html';
import BusinessModelActivityDetailsPropertiesTemplate from './version1/views/vdml/views/properties/BusinessModelActivityDetailsPropertiesTemplate.html';
import ValueDetailsPropertiesTemplate from './version1/views/vdml/views/properties/ValueDetailsPropertiesTemplate.html';
import BusinessModelCompetencyDetailsPropertiesTemplate from './version1/views/vdml/views/properties/BusinessModelCompetencyDetailsPropertiesTemplate.html';
import NetworkPartnerPropertiesTemplate from './version1/views/vdml/views/properties/NetworkPartnerPropertiesTemplate.html';
import CustomerDetailsPropertiesTemplate from './version1/views/vdml/views/properties/CustomerDetailsPropertiesTemplate.html';
import ImportFromCloudTemplate from './version1/views/transformation/views/properties/ImportFromCloudTemplate.html';
import CloudTemplate from './version1/views/transformation/views/properties/CloudTemplate.html';
import CreateChartTemplate from './version1/views/dashboard/views/chart/CreateChartTemplate.html';
import RegistrationTemplate from './version1/views/tickets/views/properties/RegistrationTemplate.html';
import CanvasTargetDetailsTemplate from './version1/views/canvas/views/designer/CanvasTargetDetailsTemplate.html';
import CapabilityLibraryTemplate from './version1/views/capabilityLibrary/views/properties/CapabilityLibraryTemplate.html';
import VocabularyPackageTemplate from './version1/views/concept/views/properties/VocabularyPackageTemplate.html';
import ConceptTemplate from './version1/views/concept/views/properties/ConceptTemplate.html';
import IntegratedReportingCanvasDesignerTemplate from './version1/views/canvas/views/designer/canvasTemplates/IntegratedReportingCanvasDesignerTemplate.html';
import TwelveDimensionsDesignerTemplate from './version1/views/canvas/views/designer/canvasTemplates/TwelveDimensionsDesignerTemplate.html';
import NineDimensionsDesignerTemplate from './version1/views/canvas/views/designer/canvasTemplates/NineDimensionsDesignerTemplate.html';
import SixDimensionsDesignerTemplate from './version1/views/canvas/views/designer/canvasTemplates/SixDimensionsDesignerTemplate.html';
import FourDimensionsDesignerTemplate from './version1/views/canvas/views/designer/canvasTemplates/FourDimensionsDesignerTemplate.html';
import DashboardDesignTemplate from './version1/views/dashboard/views/chart/DashboardDesignTemplate.html';
import DashboardScenariosTemplate from './version1/views/dashboard/views/chart/DashboardScenariosTemplate.html';
import DashboardDetailsTemplate from './version1/views/dashboard/views/chart/DashboardDetailsTemplate.html';
import DashboardValuesChartTemplate from './version1/views/dashboard/views/chart/DashboardValuesChartTemplate.html';
import DashboardGuidanceTemplate from './version1/views/help/views/properties/DashboardGuidanceTemplate.html';
import ScenarioDetailsTemplate from './version1/views/dashboard/views/chart/ScenarioDetailsTemplate.html';
import ReportDetailsTemplate from './version1/views/report/views/properties/ReportDetailsTemplate.html';
import EcoMapPackageTemplate from './version1/views/ecoMap/views/designer/EcoMapPackageTemplate.html';
import ValueAggregationPropertiesTemplate from './version1/views/vdml/views/properties/ValueAggregationPropertiesTemplate.html';
import SelectChartValuesTemplate from './version1/views/dashboard/views/chart/SelectChartValuesTemplate.html';
import CanvasDetailsTemplate from './version1/views/canvas/views/designer/CanvasDetailsTemplate.html';
import EcoMapDetailsTemplate from './version1/views/ecoMap/views/designer/EcoMapDetailsTemplate.html';
import ValueStreamMapDetailsTemplate from './version1/views/valueStreamMap/views/designer/ValueStreamMapDetailsTemplate.html';
import StrategyMapDetailsTemplate from './version1/views/strategyMap/views/designer/StrategyMapDetailsTemplate.html';
import StrategyMapValuesChartTemplate from './version1/views/strategyMap/views/designer/StrategyMapValuesChartTemplate.html';
import StrategyMapObjectiveTemplate from './version1/views/strategyMap/views/designer/StrategyMapObjectiveTemplate.html';
import ValueExplorerJsonPropertiesTemplate from './version1/views/valueLibrary/properties/ValueExplorerJsonPropertiesTemplate.html';
import CapabilityLibraryDiagramDetailsTemplate from './version1/views/capabilityLibrary/views/designer/CapabilityLibraryDiagramDetailsTemplate.html';
import BMDetailsTemplate from './version1/views/vdml/views/properties/BMDetailsTemplate.html';
import BMValuesChartTemplate from './version1/views/vdml/views/chart/BMValuesChartTemplate.html';
import BMValueDetailsTemplate from './version1/views/vdml/views/properties/BMValueDetailsTemplate.html';
import AddAlternativeTemplate from './version1/views/dashboard/views/chart/AddAlternativeTemplate.html';
import ValueLibraryTemplate from './version1/views/valueLibrary/properties/ValueLibraryTemplate.html';
import ValueLibraryDetailsTemplate from './version1/views/valueLibrary/properties/ValueLibraryDetailsTemplate.html';
import ValuesTemplate from './version1/views/transformation/views/properties/ValuesTemplate.html';
import EditValuedeliveryModelTemplate from './version1/views/vdml/views/properties/EditValuedeliveryModelTemplate.html';
import HistoryTemplate from './version1/views/tickets/views/properties/HistoryTemplate.html';
import MarketTemplate from './version1/views/tickets/views/properties/MarketTemplate.html';
import explorerModelJsonTemplate from './version1/views/beeppackage/views/properties/explorerModelJsonTemplate.html';
import ChartDetailsTemplate from './version1/views/ecoMap/views/designer/ChartDetailsTemplate.html';
import BusinessModelValidationTemplate from './version1/views/vdml/views/correction/BusinessModelValidationTemplate.html';
import AlternativeInputValuesTemplate from './version1/views/transformation/views/properties/AlternativeInputValuesTemplate.html';
import PresentationDataGrid from './version1/views/dashboard/views/chart/PresentationDataGrid.jsx';
import DashboardElement from './version1/views/dashboard/views/chart/DashboardReact.jsx';
import NavigationTree from './version1/views/dashboard/views/chart/NavigationTree.jsx';
const htmlList = {
    AlternativeDetailsTemplate,
	PlansWorkspaceExplorerTemplate,
	PlanPropertiesTemplate,
	PlanValuesChartTemplate,
	PlanValueDetailsTemplate,
	ColloborationPropertiesTemplate,
	planGuidancePropertiesTemplate,
	DiscoverTemplate,
	SummernoteReportTemplate,
	EcoMapDesignerTemplate,
	ValueStreamMapDesignerTemplate,
	StrategyMapDesignerTemplate,
	AdoptTemplate,
	DashboardTemplate,
	AdminTemplate,
	CapabilityLibraryDiagramTemplate,
    CapabilityLibraryDetailsTemplate,
	PrototypeTemplate,
	BMCubeTemplate,
	BusinessModelPropertiesTemplate,
	BusinessDetailsPropertiesTemplate,
	AdvancedPlanDetailsTemplate,
	PlanDetailsTemplate,
	PhaseDetailsTemplate,
	ShapePropertiesTemplate,
	ImageSelectorTemplate,
	BusinessModelActivityDetailsPropertiesTemplate,
	ValueDetailsPropertiesTemplate,
	BusinessModelCompetencyDetailsPropertiesTemplate,
	NetworkPartnerPropertiesTemplate,
	CustomerDetailsPropertiesTemplate,
	ImportFromCloudTemplate,
    CloudTemplate,
	CreateChartTemplate,
	RegistrationTemplate,
	CanvasTargetDetailsTemplate,
	CapabilityLibraryTemplate,
	VocabularyPackageTemplate,
	ConceptTemplate,
    IntegratedReportingCanvasDesignerTemplate,
    TwelveDimensionsDesignerTemplate,
	NineDimensionsDesignerTemplate,
	SixDimensionsDesignerTemplate,
	FourDimensionsDesignerTemplate,
	DashboardDesignTemplate,
	DashboardScenariosTemplate,
	DashboardDetailsTemplate,
	DashboardValuesChartTemplate,
	DashboardGuidanceTemplate,
	ScenarioDetailsTemplate,
	ReportDetailsTemplate,
	EcoMapPackageTemplate,
	ValueAggregationPropertiesTemplate,
	SelectChartValuesTemplate,
	CanvasDetailsTemplate,
	EcoMapDetailsTemplate,
	ValueStreamMapDetailsTemplate,
	StrategyMapDetailsTemplate,
	StrategyMapValuesChartTemplate,
	StrategyMapObjectiveTemplate,
	ValueExplorerJsonPropertiesTemplate,
	CapabilityLibraryDiagramDetailsTemplate,
	BMDetailsTemplate,
	BMValuesChartTemplate,
	BMValueDetailsTemplate,
	AddAlternativeTemplate,
	ValueLibraryTemplate,
	ValueLibraryDetailsTemplate,
	ValuesTemplate,
	EditValuedeliveryModelTemplate,
    HistoryTemplate,
    MarketTemplate,
    explorerModelJsonTemplate,
    ChartDetailsTemplate,
    BusinessModelValidationTemplate,
    AlternativeInputValuesTemplate,
}

// Use ES6 Object Literal Property Value Shorthand to maintain a map
// where the keys share the same names as the classes themselves
const classes = {
    PlansWorkspaceExplorerViewModel,
    PlanViewModel,
    PlanDetailsViewModel,
    PlanValidationViewModel,
    PhaseViewModel,
    PhaseDetailsViewModel,
    AlternativeViewModel,
    AlternativeDetailsViewModel,
    RegistrationViewModel,
    ProductListViewModel,
    UserStoresViewModel,
    PlanValueDetailsViewModel,
    AdvancedPlanDetailsViewModel,
    AlternativeInputValuesViewModel,
    ValueDetailsViewModel,
    ValueAggregationViewModel,
    DiscoverViewModel,
    PrototypeViewModel,
    AdoptViewModel,
    AdminViewModel,
    BMCubeViewModel,
    BusinessModelReportViewModel,
    BusinessModelViewModel,
    BusinessDetailsViewModel,
    CustomerDetailsViewModel,
    NetworkPartnerViewModel,
    BusinessModelActivityDetailsViewModel,
    BusinessModelCompetenceViewModel,
    EcoMapDesignerViewModel,
    TargetDetailsViewModel,
    ShapePropertiesViewModel,
    EcomapMappingWizardViewModel,
    ImageSelectorViewModel,
    MappingWizardViewModel,
    ChartDetailsViewModel,
    CapabilityMappingWizardViewModel,
    StrategyMapDesignerViewModel,
    StrategyMapDetailsViewModel,
    StrategyMapObjectiveViewModel,
    StrategyMapValuesChart,
    BusinessModelCompetencyDetailsViewModel,
    //ValueStreamMapDetailsViewModel,
    ValueStreamMapDesignerViewModel,
    ProcessTree,
    BMGuidanceViewModel,
    ReportValuesChartViewModel,
    ReportDetailsViewModel,
    SummernoteReportViewModel,
    CaseDetailsViewModel,
    CmmnDiagramViewModel,
    ProcessDiagramDetailsViewModel,
    ProcessDiscoveryDiagramViewModel,
    explorerModelJsonViewModel,
    ImportFromCloudViewModel,
    CloudViewModel,
    DashboardViewModel,
    CreateChartViewModel,
    AddAlternativeViewModel,
    SelectChartValuesViewModel,
    ValueExplorerJsonViewModel,
    MoveValueViewModel,
    BusinessModelValidationViewModel,
    ValueUnitDetailsViewModel,
    ValueExpressionDetailsViewModel,
    CapabilityLibraryDiagramViewModel,
    CapabilityLibraryDiagramDetailsViewModel,
    CapabilityExplorerJsonViewModel,
    CapabilityTreeConstructionViewModel,
    UpdateCapabilityTreeViewModel,
    CapabilityLibraryViewModel,
    CapabilityLibraryDetailsViewModel,
    EditValueDeliveryModelDetailsViewModel,
    ScenarioDetailsViewModel,
	ImportDataViewModel,
    ConceptViewModel,
    ValueLibraryViewModel,
    ValueLibraryDetailsViewModel,
    VocabularyDetailsViewModel,
    VocabularyPackageViewModel,
    EcoMapPackageViewModel,
    DashboardPackageViewModel,
    PostmanViewModel,
    Support,
    SupportViewModel,
    History,
    HistoryViewModel,
    Market,
    MarketViewModel,
    Audit,
    AuditViewModel,
    CanvasTargetDetailsViewModel,
    CanvasDesignerViewModel,
    CanvasDetailsViewModel,
    EcoMap1002,
    EcoMap1003,
    EcoMap1004,
	ValuesViewModel,
    MapValidationViewModel,
};

class DynamicClass {
    constructor (className, opts) {
        return new classes[className](opts);
    }
    static getView(className){
        return classes[className];
    }
	static getHtml(fileName){
        return htmlList[fileName];
    }
}

export default DynamicClass;