import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as jstree from '../../../../../../libs/jstree/jstree'
import { DataManager } from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","jstree","jqueryui","underscore","backbone","knockout","knockoutMapping","knockback","appcommon/com/vbee/data/DataManager","app/global"],
function(require,$,jstree,jqueryui,_, Backbone,ko, koMapping,kb,DataManager,global)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("beeppackage.views.properties",global.version);
export class explorerModelJsonViewModel {

        constructor(model, options) {
            var self = this;
            this.init(model, options);
        }
		
		htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
		}		

		afterRenderView(node, view){
            var self = view;
			self.jsTreeModelData = self.getModelDataInJSTreeFormat(self.model);
			//console.log(JSON.stringify(options.addOptions.model.toJSON()));
			
			var tree = $('#jstree_div').on("changed.jstree", function (e, data) {
				if(data.selected.length) {
					//alert('The selected node is: ' + data.instance.get_node(data.selected[0]).text);
					var selectedNode = data.instance.get_node(data.selected[0]);
					var refId = selectedNode.a_attr.refId;
					if(refId && selectedNode.id !== window.utils.htmlEscape(refId)){
						data.instance.deselect_node(selectedNode);
						data.instance.select_node(window.utils.htmlEscape(refId),true);	
                        if ($('#' + window.utils.htmlEscape(refId) + '_anchor').length <1 ){
							var refParentId = selectedNode.a_attr.refParentId;
							var refParentType = selectedNode.a_attr.refParentType;
				        	var re=/[\._]/g;
							refParentType = "appbo/" + refParentType.replace(re,"/");
							var version = selectedNode.a_attr.version;
							var repId = DataManager.getDataManager().getRepositoryId(refParentId);
							var vdmStore = DataManager.getDataManager().getVDMStore(repId);
							DataManager.getDataManager().fetchDocumentFromPackage(refParentId,refParentType,version,refParentId,refParentType,vdmStore,{
								success: function(refModel){
									$('#jstree_div').jstree(true).create_node(null,self.getModelDataInJSTreeFormat(refModel)[0]);
									data.instance.select_node(window.utils.htmlEscape(refId),true);	
								},
								error: function(){
									
								}
							});
							
						}
					}
				}
			}).on("select_node.jstree", function (e, data) {
				if(data.selected.length) {
					var selectedNode = data.instance.get_node(data.selected[0]);
					var refId = selectedNode.a_attr.refId;
					if(refId && selectedNode.id === window.utils.htmlEscape(refId) && selectedNode.children.length === 0){
						var refParentId = selectedNode.a_attr.refParentId;
						var refParentType = selectedNode.a_attr.refParentType;
						var refType = selectedNode.a_attr.refType;
			        	var re=/[\._]/g;
						refParentType = "appbo/" + refParentType.replace(re,"/");
						refType = "appbo/" + refType.replace(re,"/");
						
						var version = selectedNode.a_attr.version;
						var repId = DataManager.getDataManager().getRepositoryId(refParentId);
						var vdmStore = DataManager.getDataManager().getVDMStore(repId);
						DataManager.getDataManager().fetchDocumentFromPackage(refParentId,refParentType,version,refId,refType,vdmStore,{
							success: function(refModel){
								var children = self.getModelDataInJSTreeFormat(refModel)[0].children;
								for(var i=0;i<children.length;i++){
									data.instance.create_node(data.selected[0],children[i]);
								}
								data.instance.redraw_node(selectedNode,true);
							},
							error: function(){
								
							}
						});														
					}					
				}
					
			}).jstree({ 'core' : {
							'check_callback': true,
						    'data' : function (obj,callback) {
            					callback.call(this, []);
        					}
						},
						"plugins": ["search"],
						"search" : {
				              'case_sensitive' : false
				         }
					  });
			$('#jstree_div').jstree(true).create_node(null,self.jsTreeModelData[0]);
				//var to = false;
			  $('#treeSearch').change(function () {
				//commenting code , execution of search during timeout of 250ms. Now search is called during change instead of keyup. search will execute either onblur or with 'enter' keypress
				/*if(to) { clearTimeout(to); }
				to = setTimeout(function () {
				  var v = $('#treeSearch').val();
				  $('#jstree_div').jstree(true).search(v);
				}, 250);
				*/
				window.utils.startSpinner('jsonSearch','Searching...');
				setTimeout(function(){
					var v = $('#treeSearch').val();
					$('#jstree_div').jstree(true).search(v);
					window.utils.applyFontSize($('#content'));
					window.utils.stopSpinner('jsonSearch');
					$('.jstree-anchor').css('text-overflow','ellipsis'); //mrajender //preventing strings going out of band
						$('.jstree-anchor').css('overflow','hidden');
						$('.jstree-anchor').css('max-width','97%');
				},10);
			  });
			$('#jstree_div').on('dblclick.jstree', function(e) {
				var text = e.target.text;
				if(text && text.length > 0){
					var textSub = text.substr(text.indexOf(":") + 1); 
					self.copyToClipboard(textSub);
				}
		    });
			
		};
        copyToClipboard(text) {
             var self = this;
			 var $temp = $("<input>");
			  $("body").append($temp);
			  $temp.val(text).select();
			 
		    
		    // copy the selection
		    var succeed;
		    try {
		    	  succeed = document.execCommand("copy");
		    } catch(e) {
		        succeed = false;
		    }
		     $temp.remove();
		    /*// restore original focus
		    if (currentFocus && typeof currentFocus.focus === "function") {
		        currentFocus.focus();
		    }
		    
		    if (isInput) {
		        // restore prior selection
		        elem.setSelectionRange(origSelectionStart, origSelectionEnd);
		    } else {
		        // clear temporary content
		        target.textContent = "";
		    }*/
		    return succeed;
    }
        getModelDataInJSTreeFormat(model) {
            var self = this;
			var jsonData = {};
			var ret = [];
			ret.push(jsonData);
			jsonData.id = window.utils.htmlEscape(model.get('id'));
			var name = model.get('name');
			var val = name ? name : model.get('id');
			jsonData.text = val;
            var typeMixinCls = DataManager.getDataManager().getModelType(model, true);
			jsonData.children = this.addAttributesForAppCls(model,typeMixinCls);
			jsonData.children = jsonData.children.concat(this.addRelationsShipsForAppCls(model,typeMixinCls));
			ret[0].icon = "img/object.png";
			return ret;
		};
		
	    addAttributesForAppCls(model,typeMixinCls){
            var self = this;
            var ret = [];
	    	var debugMode = true;//DataManager.getDataManager().get('debugMode');
	    	var attributes = Object.getOwnPropertyNames(model.attributes);
			for(var i=0;i<attributes.length;i++){
				var value = model.get(attributes[i]);
				if(!debugMode){
					var exists= _.find(typeMixinCls.getProperties(), function(a){return a.name === attributes[i];})
					if((exists || attributes[i]==='id' || attributes[i]==='type') && value){
						//console.log("creating literal for:" + value);
						if(value instanceof Backbone.Model || value instanceof Backbone.Collection || attributes[i] === 's' || attributes[i] === 'label' || attributes[i] === 'definition'){
							continue;
						}
						if(typeof(value) === 'object' ){
							value = value.name;
						}
						ret.push({text:attributes[i] + " : " + value, icon:"img/leaf.png"});
	            	}
				}else{
					if(value){
						//console.log("creating literal for:" + value);
						if(value instanceof Backbone.Model || value instanceof Backbone.Collection || attributes[i] === 's' || attributes[i] === 'label' || attributes[i] === 'definition'){
							continue;
						}
						if(typeof(value) === 'object' ){
							value = value.name;
						}
						ret.push({text:attributes[i] + " : " + value, icon:"img/leaf.png"});
	            	}
				}
				

			}
			return ret;
	    };
	    isPartOfRelation(typeMixinCls,property){
            var self = this;
	    	if(!typeMixinCls){
	    		return false;
	    	}
	    	var relations = typeMixinCls.getCumulativeMixinRelations();
	    	for(var i=0;i<relations.length;i++){
	    		if(relations[i].key === property){
	    			var includeInJSON = relations[i].includeInJSON;
	    			if(!includeInJSON || includeInJSON === true){
	    				return true;
	    			}else{
	    				return false;
	    			}
	    		}
	    	}
	    	return false;
	    };	    
	    addRelationsShipsForAppCls(model,typeMixinCls,recursive){
            var self = this;
            var ret = [];
	    	
	    	var relations = Object.getOwnPropertyNames(model.attributes);
	    	var count = 0;
	
			function handleNextRelation(){
				if(count < relations.length){
	    			var relation = relations[count];	
					count++;
	    			var value = model.get(relation);
					
	    			if(value && (value instanceof Backbone.Model || value instanceof Backbone.Collection)){
	    				addRelationShip(value, relation,handleNextRelation,ret);	
	    			}else{
	    				handleNextRelation();
	    			}
				}
			}
			function addRelationShip(relationValue,relation,handleNextRelation){
				var isPartOfRelation = self.isPartOfRelation(typeMixinCls,relation);
				
				if(relation.key=== 'tag'){
					
				}
				//console.log("adding relation :" + relation.key);
	    		//var relationNode = rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbc:" + relation.containingClass + "-" + relation.key));
				if(relationValue instanceof Backbone.Model ){
					if(isPartOfRelation){
						ret.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
						handleNextRelation();
					}else{
						addTriple();		
					}

					function addTriple(){
						if(relationValue){
							var jsonData = {};
							ret.push(jsonData);	
							if(isPartOfRelation){
								jsonData.id = window.utils.htmlEscape(relationValue.get('id'));	
							}
							jsonData.icon = "img/leaf.png";
							var name = relationValue.get('name');
							var val = name ? name : relationValue.get('id');
							jsonData.text =  relation + ":" + val;
							var refParent = relationValue.getNestedParent();
							jsonData.a_attr = {refId:relationValue.get('id'),refType:relationValue.get('type'),refParentId:refParent.get('id'),refParentType:refParent.get('type'),version:refParent.get('version'), icon:"img/leaf.png"};
							//TODO add children on expand
			            }
			            handleNextRelation();
		            }
				}else{
					var relationValues = relationValue;
					if(relationValues && relationValues.length > 0){
						var jsonData = {};
						ret.push(jsonData);	
						jsonData.text =  relation;
						//jsonData.icon = "img/puzzle-triangle-28347407.jpeg";
						jsonData.children = [];							
					}					
					var valueCount = 0;
					function handleNextRelationshipValue(){
						if(relationValues && valueCount < relationValues.length){
			    			var relationValue = relationValues.at(valueCount);	
			    			valueCount++;
			    			addRelationshipValue(relationValue,jsonData.children);
						}else{
							handleNextRelation();
						}
					}
					function addRelationshipValue(relationValue,children){
						if(relationValue){
							if(isPartOfRelation){
								children.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
								handleNextRelationshipValue();	
							}else{
								var jsonChildData = {};
								children.push(jsonChildData);
								addValueTriple();
							}
							function addValueTriple(){
								if(isPartOfRelation){
									jsonChildData.id = window.utils.htmlEscape(relationValue.get('id'));
								}
								var name = relationValue.get('name');
								var val = name ? name : relationValue.get('id');
								jsonChildData.text = val;
								jsonChildData.icon = "img/leaf.png";
								var refParent = relationValue.getNestedParent();
								jsonChildData.a_attr = {refId:relationValue.get('id'),refType:relationValue.get('type'),refParentId:refParent.get('id'),refParentType:refParent.get('type'),version:refParent.get('version'),icon:"img/leaf.png"};
/*								graph.add(rdfModel.rdf.createTriple( instanceNode,
					                        relationNode,
					                         rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbi:" + relationValue.get('id')))));*/
								//if(recursive && isPartOfRelation){
								//	rdfModel.addRDFForModel(relationValue,handleNextRelationshipValue);
								//}else{
									handleNextRelationshipValue();	
								//}				                         
				            }
			           	}else{
			           		handleNextRelationshipValue();
			           	}
		           	}
		           	handleNextRelationshipValue();
				}                         
			}	
			handleNextRelation();
			return ret;
	    };	    
        cleanModal() {
            var self = this;
		   	window.cleanDialogModel(self.encodeId,self);
		};      
		
init(model, options){
    var self = this;
    this.explorerModelJsonViewModel = this; 
    this.handleNextRelationshipValue = this;
    this.model = model;
    this.jsTreeModelData = null;
    if (options && options.parentView) {
        this.parentView = options.parentView;
        this.encodeId = "" + options.modalId;
    }
    this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
        , 'name'
        , 'description'
        , 'objectExplorer'
        , 'Close']);	 


}
	
	static getInstance(model,options){
        var view = new explorerModelJsonViewModel(model, options);
        //view.init(model, options);
        return view;
	};
}
path.explorerModelJsonViewModel = explorerModelJsonViewModel;