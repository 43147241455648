import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { EcoMapDiagramMixin } from '../../../../bo/vdml/EcoMapDiagramMixin'

/*define(["require", "jquery", "jstree", "jqueryui", "underscore", "backbone","async","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global", "appbo/vdml/EcoMapDiagramMixin"],
	function (require, $, jstree, jqueryui, _, Backbone,async, ko, koMapping,kb,bootbox,DataManager,global,EcoMapDiagramMixin)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("capabilityLibrary.views.properties",global.version);
export class CapabilityExplorerJsonViewModel {
    /*constructor(model, options) {
        this.init(model, options);
    }*/
    init(model, options) {
        var self = this;
        this.CapabilityExplorerJsonViewModel = this;
        this.model = model;
        this.jsTreeModelData = null;
        this.enableComplete = ko.observable(false);
        this.showComplete = ko.observable(true);
        this.title = ko.observable('Select Capability');
        this.enableCapComplete = ko.observable(true);
        if (options && options.parentView) {
            this.encodeId = "" + options.modalId;
            this.callback = options.callback;
            this.capLibNameList = options.packages;
            this.capLibList = options.capabilityLibs.slice();
        }
        if (options && options.targetDetailsMode) {
            self.showComplete(false);
        }

        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        self.options = options;
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'Complete'
            , 'Close']);
    }
    sortByName(left, right) {
        return left.text.toLowerCase() === right.text.toLowerCase() ? 0 : (left.text.toLowerCase() < right.text.toLowerCase() ? -1 : 1);
    }
    getCapElementModal(id) {
        var self = this;
        var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: id });
        if (!capModel) {
            capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: id });
        }
        return capModel;
    }


    afterRenderView(node, view) {
        var self = view;
        if ($('#wizardModal').length > 0) {
            $('#wizardModal').addClass('push-back');
        }
        $('#editDetailsModalContent' + self.encodeId).resize(function () {
            $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
        })
        self.jsTreeModelData = self.getModelDataInJSTreeFormat(self.capLibList);
        var res = [];
        for (var i = 0; i < self.jsTreeModelData.length; i++) {
            res.push(self.jsTreeModelData[i])
        }
        //console.log(JSON.stringify(options.addOptions.model.toJSON()));
        var pluginList = ["search"];
        var tree = $('#jstree_div').on("select_node.jstree", function (e, data) {
            if (data.selected.length) {
                var htmlContent = "<span id='showCapMapped' title='Capability Details' class='glyphicon glyphicon-info-sign glyphicon-button' style='font-size: small;color:orange;margin-left:7px'></span>"
                var selectedNode = data.instance.get_node(data.selected[0]);
                var parentsLength = selectedNode.parents.length;
                if (parentsLength > 1) {
                    //if (parentsLength > 2) {
                        if ((self.options.type && self.options.type === selectedNode.original.type || self.options.type === 'vdml_Capability') || (!self.options.type)) {
                            var exists = _.filter(self.options.parentCaps, function (parentCap) { if (parentCap.id === selectedNode.original.id) { return parentCap } });
                            var mapExists = _.filter(self.options.mappedCapabilities, function (parentCap) { if (parentCap === selectedNode.original.id) { return parentCap } });
                            if (exists.length > 0 || mapExists.length > 0) {
                                self.enableComplete(false);
                                bootbox.alert(selectedNode.text + " is not allowed for mapping");
                            } else {
                                var exists = _.filter(self.options.childCaps, function (childCap) { if (childCap.id === selectedNode.original.id) { return childCap } });
                                if (exists.length > 0) {
                                    self.enableComplete(false);
                                    bootbox.alert(selectedNode.text + " is not allowed for mapping");
                                } else {
                                    self.enableComplete(true);
                                    self.selectedCapId = data.selected[0];
                                    self.selectedCapName = selectedNode.text;
                                    //self.capParentId = selectedNode.parents[parentsLength - 3];
                                    self.capDescription = selectedNode.original.description;
                                    if (!$('#showCapMapped').length) {
                                        if ($('[id^=\'' + self.selectedCapId + "_" + '\']').length == 1) {
                                            $('[id^=\'' + self.selectedCapId + "_" + '\']').append(htmlContent);
                                        }
                                    } else {
                                        $('#showCapMapped').remove();
                                        if ($('[id^=\'' + self.selectedCapId + "_" + '\']').length == 1) {
                                            $('[id^=\'' + self.selectedCapId + "_" + '\']').append(htmlContent);
                                        }
                                    }
                                    $('#showCapMapped').click(function () {
                                        var currentAltId = DataManager.getDataManager().get('viewAlternative');
                                        var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
                                        // var currentAlt = docTypeObj.find({ id: currentAltId });
                                        var capModel = self.getCapElementModal(self.selectedCapId);
                                        // var scenario = currentAlt.getMainScenario(capModel.getNestedParent());
                                        var id = window.utils.htmlEscape(window.guidGenerator());
                                        var options = {};
                                        // options.context = scenario;
                                        options.mappedModal = capModel;
                                        options.width = '800px';
                                        window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function (refresh) {
                                        }, options);
                                    })
                                }
                            }
                        } else {
                            self.enableComplete(false);
                            bootbox.alert(selectedNode.text + " is not allowed for mapping");
                        }
                    //} else {
                   //     self.enableComplete(false);
                   // }
                }
                else {
                    self.enableComplete(false);
                }
            }
        }).jstree({
            'core': {
                'check_callback': true,
                'data': res
            },
            "plugins": pluginList,
            "search": {
                'case_sensitive': false
            }
        });



        //$('#jstree_div').jstree(true).create_node(null,obj);
        //var to = false;
        $('#treeSearch').change(function () {
            //commenting code , execution of search during timeout of 250ms. Now search is called during change instead of keyup. search will execute either onblur or with 'enter' keypress
            /*if(to) { clearTimeout(to); }
            to = setTimeout(function () {
              var v = $('#treeSearch').val();
              $('#jstree_div').jstree(true).search(v);
            }, 250);
            */
            window.utils.startSpinner('jsonSearch', 'Searching...');
            setTimeout(function () {
                var v = $('#treeSearch').val();
                $('#jstree_div').jstree(true).search(v);
                window.utils.applyFontSize($('#content'));
                window.utils.stopSpinner('jsonSearch');
                $('.jstree-anchor').css('text-overflow', 'ellipsis'); //mrajender //preventing strings going out of band
                $('.jstree-anchor').css('overflow', 'hidden');
                $('.jstree-anchor').css('max-width', '97%');
            }, 10);
        });
        $('#jstree_div').on('dblclick.jstree', function (e) {
            var text = e.target.text;
            if (text && text.length > 0) {
                var textSub = text.substr(text.indexOf(":") + 1);
                self.copyToClipboard(textSub);
            }
        });
        $('#jstree_div').on('loaded.jstree', function () {
            setTimeout(function(){
                //if(self.model.id){
                    $("#jstree_div").jstree(true).open_node(self.jsTreeModelData);
                //}
            },20)
        });
        $('#jstree_div').on('ready.jstree', function (e, data) {
            if (self.options.showCapabilityId) {
                $('#jstree_div').jstree('select_node', self.options.showCapabilityId);
            }
        });
        $('#jstree_div').on('hover_node.jstree', function (e, data) {
            var toolTip;
            if (data.node.original.type && data.node.original.type == "vdml_CapabilityLibrary") {
                toolTip = DataManager.getDataManager().get('localeManager').get('CapabilityLibrary');
            }
            if (data.node.original.type && data.node.original.type == "vdml_CapabilityDefinition") {
                toolTip = DataManager.getDataManager().get('localeManager').get('CapabilityDefinition');
            } else if (data.node.original.type && data.node.original.type == "vdml_CapabilityCategory") {
                toolTip = DataManager.getDataManager().get('localeManager').get('CapabilityCategory');
            }
            data.instance.element.prop('title', toolTip);
            if (!data.node.original.type) {
                data.instance.element.removeAttr('title', toolTip)
            }
        });
    };
    copyToClipboard(text) {
        var self = this;
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(text).select();


        // copy the selection
        var succeed;
        try {
            succeed = document.execCommand("copy");
        } catch (e) {
            succeed = false;
        }
        $temp.remove();
        /*// restore original focus
        if (currentFocus && typeof currentFocus.focus === "function") {
            currentFocus.focus();
        }
        
        if (isInput) {
            // restore prior selection
            elem.setSelectionRange(origSelectionStart, origSelectionEnd);
        } else {
            // clear temporary content
            target.textContent = "";
        }*/
        return succeed;
    }
    getModelDataInJSTreeFormat(capabilityLibs) {
        var ret = [];
        if (!capabilityLibs.length && capabilityLibs instanceof Backbone.Model) {
            //if (capabilityLibs.get('parentCapability').length == 0) {
            jsonData = {};
            ret.push(jsonData);
            jsonData.id = capabilityLibs.get('id');
            jsonData.text = capabilityLibs.get('name');
            jsonData.description = capabilityLibs.get('description');
            jsonData.type = capabilityLibs.get('type');
            jsonData.definition = capabilityLibs.get('definition');
            var typeMixinCls = DataManager.getDataManager().getModelType(capabilityLibs, true);
            jsonData.children = [];
            if (capabilityLibs instanceof Backbone.Model && capabilityLibs.get('type') !== "vdml_CapabilityLibrary" && capabilityLibs.get('childCapability').length > 0) {
                jsonData.children = jsonData.children.concat(this.getModelDataInJSTreeFormat(capabilityLibs.get('childCapability').models));
                ret[0].icon = "img/object.png";
            } else if (capabilityLibs instanceof Backbone.Model && capabilityLibs.get('type') === "vdml_CapabilityLibrary") {
                jsonData.children = jsonData.children.concat(this.getModelDataInJSTreeFormat(capabilityLibs.get('capability').models));
                ret[0].icon = "img/object.png";
            }
            else {
                ret[0].icon = "img/leaf.png";
            }
            //}
        } else {
            for (var i = 0; i < capabilityLibs.length; i++) {
                var jsonData = {};
                var capLibModels = capabilityLibs[i].models ? capabilityLibs[i].models : capabilityLibs[i];
                if ((!capLibModels.length && capLibModels instanceof Backbone.Model) || (capLibModels.length && capLibModels.length > 0)) {
                    ret.push(jsonData);
                }
                for (var j = 0; j < capLibModels.length; j++) {
                    var capabilityLib = capLibModels[j];
                    if (j > 0 && capabilityLib.get('type') == "vdml_CapabilityLibrary") {
                        var jsonData = {};
                        ret.push(jsonData);
                        ret[j].icon = "img/object.png";
                    }
                    jsonData.id = capabilityLib.get('id');
                    jsonData.text = capabilityLib.get('name');
                    jsonData.description = capabilityLib.get('description');
                    jsonData.type = capabilityLib.get('type');
                    jsonData.definition = capabilityLib.get('definition');
                    var typeMixinCls = DataManager.getDataManager().getModelType(capabilityLib, true);
                    if (capabilityLib.get('type') !== "vdml_CapabilityLibrary") {
                        jsonData.children = [];
                        jsonData.children = jsonData.children.concat(this.addRelationsShipsForAppCls(capabilityLib, typeMixinCls));
                        if (!jsonData.children.text) {
                            jsonData.children = jsonData.children[0].children;
                        }
                    } else {
                        jsonData.children = [];
                        var caplist = capabilityLib.get('capability').models;
                        var newcaplist = [];
                        for (var k = 0; k < caplist.length; k++) {
                            var addtolist = false;
                            _.each(caplist[k].get('parentCapability').models, function (parentCap) {
                                if (parentCap.getParent() != capabilityLib) {
                                    addtolist = true;
                                }
                            });
                            if (caplist[k].get('parentCapability').length == 0 || addtolist) {
                                newcaplist.push(caplist[k]);
                            }
                        }
                        jsonData.children = this.getModelDataInJSTreeFormat(newcaplist);
                        ret[j] ? ret[j].icon = "img/object.png" : '';
                    }
                    ret[i] ? ret[i].icon = "img/object.png" : '';
                }
                if (!capLibModels.length && capLibModels instanceof Backbone.Model) {
                    jsonData.id = capLibModels.get('id');
                    jsonData.text = capLibModels.get('name');
                    jsonData.description = capLibModels.get('description');
                    jsonData.type = capLibModels.get('type');
                    jsonData.definition = capLibModels.get('definition');
                    var typeMixinCls = DataManager.getDataManager().getModelType(capLibModels, true);
                    if (capLibModels instanceof Backbone.Model && capLibModels.get('childCapability').length > 0) {
                        jsonData.children = this.getModelDataInJSTreeFormat(capLibModels.get('childCapability').models);
                        ret[i].icon = "img/object.png";
                    }
                    else {
                        ret[i].icon = "img/leaf.png";
                    }
                }
                //if ($.isEmptyObject(jsonData)) {
                //	ret = [];
                //}
            }

        }
        ret.sort(self.sortByName);
        return ret;
    };

    addRelationsShipsForAppCls(model, typeMixinCls) {
        var self = this;
        var ret = [];
        var value = model;
        addRelationShip(value);

        function addRelationShip(relationValue) {
            if (relationValue instanceof Backbone.Model) {
                ret.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
            } else {
                var relationValues = relationValue;
                var jsonData = {};
                ret.push(jsonData);
                if (relationValues && relationValues.length > 0) {
                    jsonData.children = [];
                }
                var valueCount = 0;
                function handleNextRelationshipValue(children) {
                    if (relationValues && valueCount < relationValues.length) {
                        var newRelationValue = relationValues.at(valueCount);
                        valueCount++;
                        //var children = jsonData.children ? jsonData.children : jsonData;
                        addRelationshipValue(newRelationValue, children);
                    }
                }
                function addRelationshipValue(relationValue, children) {
                    if (relationValue && relationValue.get('parentCapability') && relationValue.get('parentCapability').length == 0) {
                        if (typeof children.length == "undefined") {
                            children.children = [];
                            children.children.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
                        }
                        else {
                            children.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
                        }
                        handleNextRelationshipValue();
                    } else {
                        children.push(self.getModelDataInJSTreeFormat(relationValue));
                        handleNextRelationshipValue();
                    }
                }
                handleNextRelationshipValue(jsonData.children);
            }
        }
        return ret;
    };

    cleanModal() {
        var self = this;
        window.cleanDialogModel(self.encodeId, self);
        if ($('#wizardModal').length > 0) {
            $('#wizardModal').removeClass('push-back');
        }
        if ($('.slipDetailsBox').length == 0 || (self.options && self.options.infoIcon)) {
            $('.slipDetailsBox').removeClass('push-back');
        }
        if (event.currentTarget.textContent == 'Close' && $('.slipDetailsBox').length > 0) {
            $('.slipDetailsBox').removeClass('push-back');
        }
    };

    mapCap() {
        var self = this;
        var capParentId = "";
        if(self.selectedCapId){
            var capModal = this.getCapElementModal(self.selectedCapId);
            if(capModal){
                capParentId = capModal.getNestedParent().id;
            }
        }
        self.callback(self.selectedCapId, self.selectedCapName, self.capDescription, capParentId);
        self.cleanModal();

    };

    static getInstance(model, options) {
        var view = new CapabilityExplorerJsonViewModel(model, options);
        view.init(model, options);
        return view;
    }
}
    path.CapabilityExplorerJsonViewModel = CapabilityExplorerJsonViewModel;
