import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
/*define(["require", "jquery", "underscore", 'async', "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModelMixin", "appbo/vdml/ValueElementMixin"],
function (require, $, _, async, Backbone, ko, koMapping, kb, bootbox, DataManager, global, BusinessModelMixin,ValueElementMixin) {
*/
    var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer",global.version);
		export class TargetDetailsViewModel {
		constructor(model, options){
			var self = this;
			this.init(model, options);
		}
		init(model, options) {
    	var self = this;
        this.targetDetailsViewModel = this;
       	self.model = model;
       	self.id = kb.observable(self.model, 'id');
       	self.encodeId = ""+options.modalId;
       	self.mappedModal = options.addOptions.mappedModal;
		self.editValues = true;
		if(options.addOptions.disableValues){
			self.editValues = false;
		}
       	self.name = ko.observable(self.mappedModal.get('name'));
       	self.description = ko.observable(self.mappedModal.get('description'));
       	self.mappedModalType = self.mappedModal.get('type');
       	self.PNRoleColl = ko.observableArray([]);
       	self.vpNames = ko.observable("");
       	self.participantName = ko.observable("");
       	self.rolesNames = ko.observable("");
       	self.providerName = ko.observable("");
       	self.providerRoleName = ko.observable("");
       	self.recipientName = ko.observable("");
       	self.recipientRoleName = ko.observable("");
       	self.bmsNames = ko.observableArray("");
       	self.pnName = ko.observable("");
        self.packageName = ko.observable("");
        self.businessName = ko.observable("");
       	self.isPartnerOrCustomer = ko.observable(false);
       	self.showPNTable = ko.observable(false);
       	self.isVP = ko.observable(false);
       	self.isRole = ko.observable(false);
       	self.isBusinessModel = ko.observable(false);
       	self.isPN = ko.observable(false);
       	self.isPackage = ko.observable(false);
		self.isValue = ko.observable(false);	
		self.isVPAct = ko.observable(false);
		self.isCapability = ko.observable(false);
       	self.businessModels = ko.observable("");
       	this.valueList = [];
       	self.altAccordionList = ko.observableArray([]);
       	self.vpActivities=ko.observableArray([]);
		self.svgSmiliesList = window.utils.getSmiliesList();
		self.showRelatedValues = ko.observable(false);
		var titleMap = {};
		titleMap['vdml_Community'] = "Market Segment";
		titleMap['vdml_OrgUnit'] = "Enterprise";
		titleMap['vdml_Actor'] = "Individual";
		titleMap['vdml_ValueProposition'] = "Value Proposition";
		titleMap['vdml_Party'] = "Role";
		titleMap['vdml_BusinessModel'] = "Business Model";
		titleMap['vdml_BusinessNetwork'] = "Participant Network";
		titleMap['vdml_ValueDeliveryModel'] = "Package";
		titleMap['vdml_Activity'] = "Activity";
		titleMap['vdml_ValuePropositionComponent'] = "Value";
		titleMap['vdml_ValueAdd'] = "Value";
		titleMap['vdml_CapabilityOffer'] = "Competence";
		titleMap['vdml_BusinessItem'] = "Competence";
		titleMap['vdml_CapabilityDefinition'] = "Capability Definition";
		titleMap['vdml_CapabilityCategory'] = "Capability Category";
		
       	function htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
		}
       	self.afterRenderView = function(){
			if (self.mappedModalType === "vdml_ValueProposition") {
				self.loadTableData(function (data) {
					self.displayTable(data);
				});
			}
			if(self.mappedModalType === "vdml_CapabilityDefinition" || self.mappedModalType === "vdml_CapabilityCategory"){
				self.isCapability(self.mappedModal.getNestedParent().get('capabilityLibraryOwner').get('name'));
			}
			$('.modal-title span').text(titleMap[self.mappedModal.get('type')]+" Details");
			$('#wizardModal').addClass('push-back');
       	}
       	
		this.cleanModal = function () {
			//$('.slipDetailsBox').removeClass('push-back');
			if($('#wizardModal').length > 0){
       			$('#wizardModal').removeClass('push-back');
       		}
			window.cleanDialogModel(self.encodeId,self);
		}
		this.cleanUp = function () {
		    
		}
		this.getAlternatives = function () {
			var alts = [];
			var currentPlan = DataManager.getDataManager().get('currentPlan');
			var phase = currentPlan.get('phase').findWhere({ nextPhase: null });
			while (phase) {
				var phaseAltsArray = [];
				var phaseAlts = phase.get('phaseAlternative');
				var masterAlt = phase.get('master');
				phaseAlts.each(function (phaseAlt) {
					if (phaseAlt !== masterAlt) {
						phaseAltsArray.push(phaseAlt);
					}
				});
				phaseAltsArray.unshift(masterAlt);
				alts = phaseAltsArray.concat(alts);
				phase = phase.get('previousPhase');
			}
			return alts;
		};
		this.addMeasurandData = function (measure, valObj, unitName, measurandData) {
			var self = this;
			var valueData = {};
			valueData.valueName = measure.getMeasurand().get('name');
			valueData.name = measure.getMeasurand().get('name');
			valueData.valueId = measure.getParent().get('id');
			valueData.altId = measure.altId;
			valueData.displayName = valObj.namePath ? valueData.valueName + " [" + valObj.namePath + "]" : valueData.valueName;
			valueData.label = valObj.namePath ? valueData.valueName + " [" + valObj.namePath + "]" + " (" + unitName + ")" : valueData.valueName + " (" + unitName + ")";
			valueData.data = [];
			valueData.valId = measure.id;
			measurandData[unitName] = valueData;
			return measurandData;
		}

		this.loadSelectedValues = function (alt, valObj, val, measurandData, callback) {
			var self = this;
			var altObs, dataStr, data, unit;
			if (val) {
				var measure = val.get('valueMeasurement');
				var altComp = alt.getModelLinkedSync(val);
				if (altComp) {
					val = altComp;
				}
				var preAltScenario = altComp ? alt.getMainScenario(altComp.getNestedParent()) : null;
				var observedMeasure = preAltScenario ? preAltScenario.getObservedMeasureWithMeasurements(altComp.get('valueMeasurement')) : null;
				var mest = observedMeasure ? observedMeasure.get('measurements').at(0) : null;
				if (!observedMeasure) {
					observedMeasure = preAltScenario ? preAltScenario.getObservedMeasureWithMeasurements(altComp.get('satisfactionLevel')) : null;
					mest = observedMeasure ? observedMeasure.get('measurements').at(0) : null;
					var compSatisfactionMeasure = mest ? mest.get('observedMeasure').get('measure') : null;
				}
				measure.altId = alt.id;
			}
			var valData = [];
			var phaseName = alt.get('phaseAlternativeOwner').get('name');
			var altName = alt.get('name');
			var altProp = phaseName + " / " + altName;
			valData.push(altProp);
			valData.push(phaseName);
			valData['phaseAlt'] = altProp;
			if (!_.has(self.altIndex, altProp)) {
				self.altIndex[altProp] = (_.keys(self.altIndex)).length;
			}

			function fillValueData(valData) {
				var vm = val ? val.get('valueMeasurement') : null;
				var selectedInterval = preAltScenario && val ? val.getSatisfactionInterval(preAltScenario) : null;
				var smileySrc = '';
				var smileyColor = '';
				var correctiveActions = '';
				if (selectedInterval) {
					smileySrc = val.fetchSmileySrc(selectedInterval.get('smiley'));
					smileyColor = selectedInterval.get('smileyColor');
					correctiveActions = selectedInterval.get('correctiveActions');
				}
				valData.push(smileySrc);
				valData.push(smileyColor);
				valData.push(alt.id);
				valData.push(correctiveActions);
				var valueData;
				if (mest || data) {
					var unitName;
					if (unit) {
						unitName = window.utils.replaceEscapeCharacters(unit.get('name'));
					}

					valueData = measurandData[unitName];
					if (!valueData) {
						unitName = unitName ? unitName : 'Grade only';
						var measureData = self.addMeasurandData(measure, valObj, unitName, measurandData);
						valueData = measureData ? measureData[unitName] : null;
						valueData.significantDecimals = unit ? unit.get('significantDecimals') : '2';
						valueData.unit = unitName;
					}
					valueData.data.push(valData);
				}
				else{
					unitName = "-";
					measureData = self.addMeasurandData(measure, valObj, unitName, measurandData);
					valueData = measureData ? measureData[unitName] : null;
					valueData.significantDecimals = unit ? unit.get('significantDecimals') : '2';
					valueData.unit = unitName;
					valueData.data.push(valData);
				}
				callback(measurandData);
			}

			var vm = val ? val.get('valueMeasurement') : null;
			if (mest && mest.get('observedMeasure').get('measure') && (mest.get('value') !== null || mest.get('symbol') !== null)) {
				unit = mest.get('observedMeasure').get('measure').get('unit');
				if (mest.get('value') === Number.MAX_VALUE) {
					valData.push('####');
				} else if (mest.get('value') === '') {
					valData.push('-');
				} else {
					if (mest.get('value')) {
						valData.push(parseFloat(mest.get('value')));
					} else if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
						valData.push(mest.get('symbol'));
					} else {
						valData.push("-");
					}
				}
			} else {
				valData.push("-");
			}
			fillValueData(valData);


		}
		this.fetchValuesForAlt = function (callback) {
			var vpComponents = self.mappedModal ? self.mappedModal.get('component') : null;
			self.altIndex = {};
			var dataSet = {};
			if (vpComponents) {
				async.each(self.alts, function (altObj, altHandleCallback) {
					var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altObj.id });
					if (!alt) {
						altHandleCallback();
						return;
					}
					async.each(vpComponents.models, function (valObj, valHandleCallback) {
						var measurandData = {};
						var measure;
						var val = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valObj.id });
						if (!val) {
							val = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valObj.id });
						}
						if (val) {
							measure = val.get('valueMeasurement');
						}
						if (!val) {
							valHandleCallback();
							return;
						}
						self.loadSelectedValues(alt, valObj, val, measurandData, function (measurandDataCalculated) {
							measurandData = measurandDataCalculated;
							var emptyData = measurandData[""];
							if (emptyData) {
								delete measurandData[""];
							}

							Object.getOwnPropertyNames(measurandData).forEach(function (key) {
								var valueCount = 0;
								var valueData = measurandData[key];
								if (emptyData) {
									valueData.data = valueData.data.concat(emptyData.data);
								}
								for (var i = 0; i < valueData.data.length; i++) {
									if (valueData.data[i][1] != null) {
										valueCount++;
									}
								}
								var unitString = window.utils.replaceEscapeCharacters(valueData.unit);
								if (dataSet[measure.id + unitString]) {
									dataSet[measure.id + unitString].data = dataSet[measure.id + unitString].data.concat(valueData.data);
								} else {
									dataSet[measure.id + unitString] = valueData;
								}
							});
							valHandleCallback();
						});
					}, function () {
						altHandleCallback();
					});
				}, function () {
					self.datasets = dataSet;
					var values = Object.getOwnPropertyNames(self.datasets);
					var data = [];
					for (var i = 0; i < values.length; i++) {
						var valueData = self.datasets[values[i]];
						data.push(valueData);
					}
					callback && callback(data);
				});
			}
		};
		this.loadTableData = function (callback) {
			self.alts = self.getAlternatives();
			self.fetchValuesForAlt(callback);
		};

		this.displayTable = function (data) {
			if (!data || data.length === 0){
				return;
			}
			self.showRelatedValues(true);
			$('#valueTable').empty();
			var phaseAltCombos = ["valueName"];
			var tr1Headers = [];
			var uniqueDataForTR2 = [];
			for (var i = 0; i < data.length; i++) {
				for (var j = 0; j < data[i].data.length; j++) {
					uniqueDataForTR2.push(data[i].data[j]);
				}
			}
			uniqueDataForTR2 = _.uniq(uniqueDataForTR2, "phaseAlt");
			_.each(uniqueDataForTR2, function (data) {
				phaseAltCombos.push(data[0]);
				tr1Headers.push(data[1]);
			});
			phaseAltCombos = _.uniq(phaseAltCombos);
			tr1Headers = _.uniq(tr1Headers);

			var myTableDiv = $('#valueTable');

			var table = document.createElement('TABLE');
			table.id = 'table';
			table.className = "display";
			//table.className="pageResize";
			table.cellspacing = "0";
			table.width = "100%";

			var tableHeader = document.createElement('THEAD');
			table.appendChild(tableHeader);
			var headerTR1 = document.createElement('TR');
			tableHeader.appendChild(headerTR1);
			//for first values header
			var th = document.createElement('TH');
			th.appendChild(document.createTextNode("Values"));
			th.rowSpan = '2';
			headerTR1.appendChild(th);
			//for phase headers
			_.each(tr1Headers, function (name) {
				var th = document.createElement('TH');
				th.appendChild(document.createTextNode(name));
				var colSpan = phaseAltCombos.reduce(function (n, val) {
					return n + (val.substr(0, val.indexOf('/') - 1) === name);
				}, 0);
				th.colSpan = colSpan;
				headerTR1.appendChild(th);
			});

			//to create a new row for alt headers
			var headerTR2 = document.createElement('TR');
			tableHeader.appendChild(headerTR2);

			var tableBody = document.createElement('TBODY');
			table.appendChild(tableBody);

			var trTBody = {};
			for (var k = 0; k < data.length; k++) {
				trTBody[k] = document.createElement('TR');
				tableBody.appendChild(trTBody[k]);
                var td = document.createElement('TD');
                td.align = "left";
				var nameLink = document.createElement('a');
				nameLink.appendChild(document.createTextNode(data[k].label));
				nameLink.style.color = 'inherit';
				nameLink.style.cursor = 'pointer';
				nameLink.setAttribute('valueId', data[k].valueId);
				td.appendChild(nameLink);
				if(self.editValues){
					$(nameLink).click(function(event){
						self.editValueDetails(event);
					});
				}
				trTBody[k].appendChild(td);
			}

			for (var i = 1; i < headerTR1.children.length; i++) {
				for (var k = 0; k < uniqueDataForTR2.length; k++) {
					if (uniqueDataForTR2[k][1] === headerTR1.children[i].innerText) {
						th = document.createElement('TH');
						th.appendChild(document.createTextNode(uniqueDataForTR2[k][0]));
						headerTR2.appendChild(th);
					}
				}
			}
			var cumulativeColSpan = 0;
			for (var i = 1; i < headerTR1.children.length; i++) {
				for (var j = 0; j < headerTR1.children[i].colSpan; j++) {
					for (var k = 0; k < data.length; k++) {
						var found = _.find(data[k].data, function (e) { return e[1] === headerTR1.children[i].innerText && headerTR2.children[j + cumulativeColSpan].innerText === e[0]; });
						if (found) {
                            var td = document.createElement('TD');
                            td.align = "right";
							var text = window.utils.thousandSeparator(window.utils.getRoundedValues(found[2], data[k].significantDecimals));
							var a = document.createElement('a');
							a.appendChild(document.createTextNode(text));
							a.style.color = 'inherit';
							a.setAttribute('id', data[k].valueId);
							a.setAttribute('altId', found[5]);
							if(self.editValues){
								a.style.cursor = 'pointer';
								$(a).click(function (event) {
									var currentTarget = event.currentTarget;
									var obj1 = { valueId: currentTarget.getAttribute('id'), altId: currentTarget.getAttribute('altId') };
									self.showAggregationView(obj1);
								});
							}
							td.appendChild(a);
							if (found[3]) {
								var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
								svg.setAttribute("width", 21);
								svg.setAttribute("height", 20);
								svg.setAttribute("style", 'vertical-align:bottom;');
								svg.setAttribute("viewBox", "-3 -2 26 26");
								svg.setAttribute("fill", "" + found[4]);
								svg.setAttribute("correctiveActions", found[7]);
								svg.style.cursor = 'pointer';
								var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
								link.setAttribute("d", found[3]);
								svg.appendChild(link);
								$(svg).click(function (event) {
									var correctiveActions = event.currentTarget.getAttribute('correctiveActions') ? event.currentTarget.getAttribute('correctiveActions') : '';
									var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>" + correctiveActions + "</span></div></div>";
									var box = bootbox.dialog({
										message: htmlContent,
										title: "<div><img class='smallIcon' src='img/icons/icons_15.png'>&nbsp;&nbsp;&nbsp;" + DataManager.getDataManager().get('localeManager').get('CorrectiveActions') + "</div>",
										buttons: {
											cancel: {
												label: "Close",
												className: 'btn vtn-default',
												callback: function () {

												}
											}
										}
									});
									box.show();
								});
								td.appendChild(svg);
							}
							trTBody[k].appendChild(td);
						} else {
							var td = document.createElement('TD');
                            td.appendChild(document.createTextNode('-'));
                            td.align = "right";
							trTBody[k].appendChild(td);
						}
					}
				}
				cumulativeColSpan = cumulativeColSpan + headerTR1.children[i].colSpan;
			}
			myTableDiv.append(table);
			self.dataTable = $('#table').DataTable({
				//"sDom": "Rlfrtip",
				//"bLengthChange": false,
				//"pageResize": true
			});
		};
		this.fetchActivityNsPath = function(activity){
	  		var activitynsPath = "";
	        //if(self.model.get('businessModel') && self.model.get('businessModel').get('bmActivity').where({'name':activity.get('name')}).length > 1){
	        	var activityPath = activity.getPackagePath();
	            activitynsPath = "   [";
	            if (activityPath[0] && activityPath[0].name && activityPath[1]) {
	                activitynsPath = activitynsPath.concat(activityPath[0].name+"  /  "+ activity.get('performingRole').get('name'));
	            }
	            activitynsPath = activitynsPath.concat("]");
	        //}
	        return activitynsPath;
	  	};
		
		if(self.mappedModalType === "vdml_Community" || self.mappedModalType === "vdml_OrgUnit" || self.mappedModalType === "vdml_Actor"){
	       		self.isPartnerOrCustomer(true);
	       		self.showPNTable(true);
	            var roles;
	       		self.mappedModal.getBusinessModelsInvolved(function(bms){
                  	for(var i=0;i<bms.length;i++){
                  		roles = bms[i].getRolesofParticipant(self.mappedModal);
                  		/*for(var j=0;j<roles.length;j++){
			               	if(self.rolesNames() === ""){
			                  	self.rolesNames(roles[j].get('name'));
			                }else if(self.rolesNames().indexOf(roles[j].get('name')) === -1){
			                  	self.rolesNames(self.rolesNames() + ", " + roles[j].get('name'));
			                }
			            }*/
                       if (roles && roles.length > 0){
					      _.each(roles,function(role){
					            var pnName = role.get('collaborationRoleOwner').get('name');
					            var roleNames = role.get('name');
					            var PNAlreadyExists = self.PNRoleColl()[_.findLastIndex(self.PNRoleColl(), { 'pnName': pnName })];
					            if(PNAlreadyExists){
					                  var oldRolesText = PNAlreadyExists.roleNames;
					                  PNAlreadyExists['roleNames'] = oldRolesText + ", "+ roleNames;
					                  self.PNRoleColl.remove(PNAlreadyExists);
					                  self.PNRoleColl.push(PNAlreadyExists);
					            }else{
					                self.PNRoleColl.push({pnName: pnName, roleNames: roleNames});
					            }
                           });
                          self.bmsNames.push({ 'name': bms[i].get('name'), 'type': bms[i].getPaticipantBMType(bms[i], self.mappedModal) });
					   }
	                   
	                }
                });      		
	       	}else if(self.mappedModalType === "vdml_ValueProposition"){
	       		self.isVP(true);
	       		var providerRole = self.mappedModal.get('provider');
	       		if(providerRole){
	       			var providerAssignment = providerRole.get('roleAssignment').models[0];
					var providerParticipant = providerAssignment.get('participant');
					self.providerName(providerParticipant.get('name'));
					self.providerRoleName(providerRole.get('name'));	
	       		}
				var recipientRole = self.mappedModal.get('recipient');
				if(recipientRole){
				      var recipientAssignment = recipientRole.get('roleAssignment').models[0];
				      var recipientParticipant = recipientAssignment.get('participant');
				      self.recipientName(recipientParticipant.get('name'));
					  self.recipientRoleName(recipientRole.get('name'));
				}
				if(self.mappedModal.getNestedParent().get('valueStream')){
					var valueStreams = self.mappedModal.getNestedParent().get('valueStream').models;
					self.vpActivities=[];
					_.each(valueStreams,function(vs){
					      if(vs.get('persuedProposition')===self.mappedModal){			      	
					      	var activities =vs.get('valueStreamActivity').models;
					      		_.each(activities,function(act){
					      			self.vpActivities.push({id:act.id,name:act.get('name'),description:act.get('description')});
					            });
					      }
					});
				}
				if(self.vpActivities.length>0){
					self.isVPAct(true);
				}		
	       	}else if(self.mappedModalType === "vdml_Party"){
                  self.isRole(true);
                  self.pnName(self.mappedModal.get('collaborationRoleOwner').get('name'));
                  var assignment = self.mappedModal.get('roleAssignment').models[0];
                  var participant = assignment.get('participant');
                  participant.getBusinessModelsInvolved(function(bms){
                  	for(var i=0;i<bms.length;i++){
	                  	var roles = bms[i].get('bmRole').models;
	                  	for(var j=0;j<roles.length;j++){
	                  		if(roles[j].id === self.mappedModal.id){
	                  			self.bmsNames.push({'name':bms[i].get('name'),'type': bms[i].getRoleBMRelationship(bms[i],participant,self.mappedModal)});
	                  			break;
	                  		}
	                  	}
	                  }
                  });
                  self.participantName(participant.get('name'));
            }else if(self.mappedModalType === "vdml_BusinessModel"){
                  self.isBusinessModel(true);
                  self.showPNTable(true);
                  self.packageName(self.mappedModal.getNestedParent().get('name'));
                  self.businessName(self.mappedModal.get('business')?self.mappedModal.get('business').get('name'):'');
                  var roles = self.mappedModal.getRolesofParticipant(self.mappedModal.get('business'));
                  _.each(roles,function(role){
					    var pnName = role.get('collaborationRoleOwner').get('name');
					    var roleNames = role.get('name');
					    var PNAlreadyExists = self.PNRoleColl()[_.findLastIndex(self.PNRoleColl(), { 'pnName': pnName })];
					    if(PNAlreadyExists){
					          var oldRolesText = PNAlreadyExists.roleNames;
					          PNAlreadyExists['roleNames'] = oldRolesText + ", "+ roleNames;
					          self.PNRoleColl.remove(PNAlreadyExists);
					          self.PNRoleColl.push(PNAlreadyExists);
					    }else{
					        self.PNRoleColl.push({pnName: pnName, roleNames: roleNames});
					    }
				  });
                  /*for(var j=0;j<roles.length;j++){
				   	if(self.rolesNames() === ""){
				      	self.rolesNames(roles[j].get('name'));
				    }else if(self.rolesNames().indexOf(roles[j].get('name')) === -1){
				      	self.rolesNames(self.rolesNames() + ", " + roles[j].get('name'));
				    }
				}*/
            }else if(self.mappedModalType === "vdml_BusinessNetwork"){
	       		self.isPN(true);
				var roles = self.mappedModal.get('collaborationRole').models;
				for(var j=0;j<roles.length;j++){
				   	if(self.rolesNames() === ""){
				      	self.rolesNames(roles[j].get('name'));
				    }else if(self.rolesNames().indexOf(roles[j].get('name')) === -1){
				      	self.rolesNames(self.rolesNames() + ", " + roles[j].get('name'));
				    }
				}
	    	}else if(self.mappedModalType === "vdml_ValueDeliveryModel"){
	       		self.isPackage(true);
				var businessModels = self.mappedModal.get('businessModel').models;
				for(var j=0;j<businessModels.length;j++){
				   	if(self.businessModels() === ""){
				      	self.businessModels(businessModels[j].get('name'));
				    }else{
				      	self.businessModels(self.businessModels() + ", " + businessModels[j].get('name'));
				    }
				}
	    	}	    	
		function fetchSmileySrc(smileyValue){
       		//var smileyValue = smileyValue?smileyValue:'1';
			var smileySrc = '';
			for(var j=0;j<self.svgSmiliesList.length;j++){
				if(self.svgSmiliesList[j].value === smileyValue) {
					smileySrc = self.svgSmiliesList[j].src;
					break;
				}
			}
			return smileySrc;
       }
		function sortValueList(){
			self.valueList.sort(function(left, right) {
	            return left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? 0 : (left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() < right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? -1 : 1); 
	        });
		}

		this.showAggregationView = function (view, event) {
			var id = "aggregation" + htmlEscape(self.mappedModal.id);
			var valueModal = self.mappedModal;
			var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
		    var altModel = docTypeObj.find({ id: view.altId });
			if (valueModal.get('type') == 'vdml_ValueProposition') {
				valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: view.valueId });
			}
			else if (valueModal.get('type') == 'vdml_Activity') {
				valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: view.valueId });
			}
			else {
				view = { id: valueModal.id, altId: altModel.id };
			}
			if (valueModal) {
		        valueModal = altModel.getModelLinkedSync(valueModal);
				var mest, pack = valueModal.getNestedParent();
				DataManager.getDataManager().getMainScenario(pack, view.altId, function (mainScenario) {
					mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(valueModal.get('valueMeasurement'));
					if (!mest) {
						mest = valueModal;
					}
					var addOptions = { 'currentAlternativeModel': altModel, 'valuesThatCanBeRefferedTo': self.valuesThatCanBeRefferedTo, 'aggregatedColl': self.aggregatedColl };
					var ValueElementMixin = Backbone.Relational.store.getObjectByName("vdml.ValueElementMixin");
					window.getAndCreateModalDialog(true, id, ValueElementMixin, mest, "AggregationView", function (changeInValue) {
						if (changeInValue) {
							window.utils.startSpinner('refreshValues', 'Refreshing ...');
							DataManager.getDataManager().invokeValueUpdates(function () {
								self.loadTableData(function (data) {
									self.displayTable(data);
								});
								setTimeout(function () {
									window.utils.stopSpinner('refreshValues');
								}, 300);
							});
							/* DataManager.getDataManager().invokeValueUpdates(function () {
								var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: view.valueId });
								if (!valueModal) {
									valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: view.valueId });
								}
								var alt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: view.altId });
								var mainScenario = alt.getMainScenario(valueModal.getNestedParent());
								var mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(valueModal.get('valueMeasurement'));
								$('#' + view.name).text(valueModal.get('name'));
								if (mest) {
									function getRoundedValues(val, sigDecimal) {
										if (val != null && val !== '' && !isNaN(val) && val != Number.MAX_VALUE && val != Infinity) {
											var newVal = parseFloat(val).toFixed(sigDecimal);
											return newVal;
										} else if (val == Number.MAX_VALUE || val == Infinity) {
											return '####';
										}
										return val;
									}
									var unit = mest.get('observedMeasure').get('measure').get('unit');
									var significantDecimals = unit ? unit.get('significantDecimals') : 2;
									if (mest.get('value') !== '') {
										var displayValue = '' + getRoundedValues(mest.get('value'), significantDecimals);
										if (unit) {
											$('#valueMest' + view.encodeId).text(displayValue + ' ' + unit.get('name'));
										}
									}

								}
								window.utils.stopSpinner('refreshValues');
							}); */
						}
					}, addOptions);
				});
			}
		};
        
        this.correctiveActions = function(view){
        	var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>"+view.correctiveActions+"</span></div></div>";
			var box = bootbox.dialog({
				message: htmlContent,
				title: "<div><img class='smallIcon' src='img/icons/icons_15.png' >&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('CorrectiveActions')+"</div>",
				buttons: {
					cancel : {
						label: "Close",
						className : 'btn vtn-default',
						callback : function(){
							
						}
					}
				}
			});
			box.show();
        };
        
        this.getAggregatedColl = function(callback){
        	if(self.aggregatedColl){
        		callback(self.aggregatedColl);
        	}else {
        		var plan = DataManager.getDataManager().get('currentPlan');
        		if(!plan){
        			plan  = self.currentAlternativeModel.getNestedParent();
        		}
        		plan.getValuesThatCanBeRefferedTo(function(values){
	    			self.aggregatedColl = new Backbone.Collection(values);
	    			callback(self.aggregatedColl);
	    		});
        	}
        };
        
        this.saveValue = function(view,modalId,type,del){
            window.utils.startSpinner('values','Saving Value ...');
			window.setTimeout(function(){
				var parent = view.model.getParent();
	            if(parent.get('type') === 'vdml_ValueProposition'){
	            	var previousWorkspace = DataManager.getDataManager().getWorkspace();
                    DataManager.getDataManager().getWorkspaceDataWithId(view.currentAlternativeModel.id, function (wsData) {
                        var altWorkspace = wsData.get('workspace'); 
                        self.currentPlan = DataManager.getDataManager().get('currentPlan');
                        DataManager.getDataManager().set('currentPlan', view.currentAlternativeModel.getNestedParent());
	        			DataManager.getDataManager().setWorkspace(altWorkspace,function(){
	        				if(del){
	        					view.model.get('componentOwner').deleteValue([view.id()], view.scenario);
								window.cleanDialogModel(modalId,view);
								window.utils.stopSpinner('values');
								self.loadTableData(function (data) {
									self.displayTable(data);
								});
	        				}
	        				else{
				            	view.saveData(parent.getNestedParent(),parent,function(changeInValue){
				            		DataManager.getDataManager().setWorkspace(previousWorkspace,function(){
					            		if(changeInValue){
											self.changeInValue = true;
											DataManager.getDataManager().invokeValueUpdates(function(){
												var obsMeasure = view.currentAlternativeModel.getMainScenario(self.modalEle.getNestedParent()).getObservedMeasureWithMeasurements(self.modalEle.get('valueMeasurement'), null)
												if(obsMeasure){
													self.selectedValue = obsMeasure.get('measurements').at(0);
	                                            }
	                                            if (!self.currentPlan) {
	                                                DataManager.getDataManager().set('currentPlan', null);
	                                            }
												window.cleanDialogModel(modalId,view);
												window.utils.stopSpinner('values');
												self.loadTableData(function (data) {
													self.displayTable(data);
												});
											});
	                                    } else {
	                                        if (!self.currentPlan) {
	                                            DataManager.getDataManager().set('currentPlan', null);
	                                        }
											window.cleanDialogModel(modalId,view);
											window.utils.stopSpinner('values');
										}
									});
				            	},null);
			            	}
		            	});
		        	});

	            }else {
	            	var activity = parent.getParent();
	            	view.saveWhatValuesData(activity, [{modalId:window.utils.htmlEscape(activity.id),originalId:activity.id}], [], function(actValue,changeInValue){
						if(changeInValue){
				            self.changeInValue = true;
						}  
						window.cleanDialogModel(modalId,view);
						window.utils.stopSpinner('values');
	            	}, null);
	            	
	            }
            },100);
            /*if(options.parentView){
                options.parentView.saveValue(view,modalId,type);
            } */           
    	}
    	
		this.editValueDetails = function(event){
			self.currentAlternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
			var valueId = event.currentTarget.getAttribute('valueId');
			for(var i=0; i<self.mappedModal.get('component').models.length; i++){
				if(self.mappedModal.get('component').models[i].id === valueId){
					self.modalEle = self.mappedModal.get('component').models[i];
					break;
				}
			}
			var type = 'Activity';
			if(self.modalEle.get('type') === 'vdml_ValuePropositionComponent') {
				if(self.modalEle.get('componentOwner').get('isValueFormula')) {
					type = 'valueFormula';
				}
				else if(DataManager.getDataManager().getRepositoryId(self.modalEle.get('id')) === window.plansKey){
					type = 'planValues';
				}
				else {
					type = 'valueProposition';
				}
			}
			self.getAggregatedColl(function(aggregatedColl){
				var scenario = self.mest? self.mest.get('observedMeasure').get('observation').getContext() : self.currentAlternativeModel.getMainScenario(self.modalEle.getNestedParent());
				var addOptions = {};
				var alt = scenario.getAlternative();
				if(!alt) {
					alt = self.currentAlternativeModel;
				}
				if(type == "Activity"){
					var actModel = self.modalEle.get('valueAddOwner').get('containedPortOwner');
					var actObj = [{originalId:actModel.get('id'),modalId: htmlEscape(actModel.get('id')),name:actModel.get('name')}];
					addOptions = {'type':'createWhatValues','aggTo':false,'activities':actObj,'aggregatedColl':aggregatedColl,'mainScenario':scenario,view:self,'valuesColl':[],'bm':null,'currentAlternativeModel':alt,'readOnly':true};
				} else {
					var overAllSatisfaction = self.modalEle.get('componentOwner').get('overAllSatisfaction');
					if(self.modalEle.id.startsWith(self.currentAlternativeModel.id)){
	            		addOptions = {'type':type,'aggTo':false,'aggregatedColl':aggregatedColl,'mainScenario':scenario,'overAllSatisfaction':overAllSatisfaction,'readOnly':false,'currentAlternativeModel':alt,'aggregationView':true,'disableAggr':true};
            		}
					else{
						addOptions = {'type':type,'aggTo':false,'aggregatedColl':aggregatedColl,'mainScenario':scenario,'overAllSatisfaction':overAllSatisfaction,'readOnly':false,'currentAlternativeModel':alt,'disableDelete':true,'aggregationView':true,'disableAggr':true};
					}
				}
				var BusinessModelMixin = Backbone.Relational.store.getObjectByName("vdml.BusinessModelMixin");
				window.getAndCreateModalDialog(self.model,'edit'+htmlEscape(self.modalEle.get('id')),BusinessModelMixin,self.modalEle,"ValueDetails",self.saveValue,addOptions);
			});
		};

		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
		'name'
		,'description'
		,'Close'
		,'Delete'
		,'Complete'
		,'parentPackage'
		,'BusinessModels'
		,'Business'
		,'Package'
		,'Participant'
		,'ParticipantNetwork'
		,'provider'
		,'type'
		,'recipient'
		,'Activities'
		,'UserRoles'
		,'UserRoles'
        ]);
    }
    
    static getInstance(model,options){
			var view = new TargetDetailsViewModel(model, options);
			//view.init(model, options);
			return view;
		};
}
path.TargetDetailsViewModel = TargetDetailsViewModel;
