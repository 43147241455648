import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import { ScenarioMeasurement } from "../transformation/ScenarioMeasurement";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueElementMixin } from './ValueElementMixin'
import { ValueElementContext } from "../vdml/ValueElementContext";
import { Expression } from "../vdml/Expression";
import {MeasurableElementMixin} from './MeasurableElementMixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import { ProjectionProfile } from './ProjectionProfile'
import { Unit } from './Unit'

import { ValueType } from './ValueType'
import { SeasonalFactor } from './SeasonalFactor';
import { PolynomialCoefficient } from './PolynomialCoefficient';
	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class ValueElement2Mixin{

    static getMixinRelations(){
		let relations = ValueElementMixin.getMixinRelations();
		relations.splice(relations.findIndex(item => item.key === "valueMeasurement"), 1)
        return _.union(relations,[
            {
                type :Backbone.HasMany,
                containingClass:"vdml_ValueElement",
                key:"context",
                relatedModel:"vdml.ValueElementContext",
                reverseRelation: {
                    key:"contextOwner",
                    type :Backbone.HasOne,
                    includeInJSON:"id"
                }
            },
			{
                type :Backbone.HasOne,
                containingClass:"vdml_ValueElement",
                key:"unit",
                relatedModel:"vdml.Unit",
				includeInJSON: Backbone.Model.prototype.idAttribute
            },
			{
                type :Backbone.HasOne,
                containingClass:"vdml_ValueElement",
                key:"projectionProfile",
                relatedModel:"vdml.ProjectionProfile",
                reverseRelation: {
                    key:"projectionProfileOwner",
                    type :Backbone.HasOne,
                    includeInJSON:"id"
                }
            }
        ])
    }
	static getProperties(){
		let properties = ValueElementMixin.getProperties();
		properties.push({ name: "valueType", type: "EString", defaultValue: "Atomic", containingClass: "vdml_VdmlElement" });
		return properties;
	}
	static getSubModelTypes(){
		var ret = ValueElementMixin.getSubModelTypes();
        // ret['vdml_ProjectionProfile'] = 'vdml.ProjectionProfile';
		// ret['vdml_PolynomialCoefficient'] = 'vdml.PolynomialCoefficient';
		// ret['vdml_SeasonalFactor'] = 'vdml.SeasonalFactor';
		// ret['vdml_ValueElementContext'] = 'vdml.ValueElementContext';
		return ret;
	}
    static getCumulativeMixinRelations(){
        if (!ValueElement2Mixin.cummulativeRelations) {
            ValueElement2Mixin.cummulativeRelations = _.union(ValueElement2Mixin.getMixinRelations()
                ,MeasurableElementMixin.getCumulativeMixinRelations()
                ,VDMLCanvasElementMixin.getCumulativeMixinRelations()
            );
        }
        return ValueElement2Mixin.cummulativeRelations.slice();
    }

	getValueContext(alt){
		var contexts = this.get("context").models;
        var context = null;
        for(var i=0;i<contexts.length;i++){
            if(contexts[i].get("alternative").id == alt.id){
                context = contexts[i];
                break;
            }
        }
		return context;
	}

	createExpression(context, valFormula){
		var self = this;
        var expId = DataManager.getDataManager().guidGeneratorByOwner(self);
        var exp = new Expression({id:expId,formulaOwner:context,name:context.get("name") + " expression",expressionStr: valFormula});
        context.set("formula",exp);
        return exp;
    }

    createValueElementContext(viewAlternative){
		var self = this;
        var contextId = DataManager.getDataManager().guidGeneratorByOwner(self);
        var context = new ValueElementContext({id:contextId,alternative:viewAlternative,contextOwner:self,name:self.get("name") + " context"});
        self.get("context").add(context);
        return context;
    }
	createProjectionProfile(projectionProfile){
		var self = this;
		if(projectionProfile){
            var projectionProfileInstance = ProjectionProfile.getInstance(projectionProfile.trendModel,projectionProfile.rateFactor,projectionProfile.polynomialDegree,self);
            self.set("projectionProfile",projectionProfileInstance);
			// Add seasonal factors
			for(var i = 0; i<projectionProfile.seasonalFactorData.length;i++){
				var quantity = projectionProfile.seasonalFactorData[i].factor;
				let seasonalFactors = SeasonalFactor.getInstance(i+1,quantity,projectionProfileInstance);
				projectionProfileInstance.get('seasonalFactors').add(seasonalFactors);
			}
			//Add polynomial coefficients
			if(projectionProfile.polynomialDegree){
				for(var j = 0; j<projectionProfile.polynomialData.coefficients.length;j++){
					var coefficient = projectionProfile.polynomialData.coefficients[j];
					let polynomialCoefficient = PolynomialCoefficient.getInstance(j+1,coefficient,projectionProfileInstance);
					projectionProfileInstance.get('coefficients').add(polynomialCoefficient);
				}
			}
        }
	}

	updateValueElementChanges(currentScenario, period, year, name, value, valueUnit, valFormula, baseTuples, timestamp, viewAlternative, maxChange, callback, skipOtherScenarios, updateOtherScenarios){
		var self = this;
		var plan = DataManager.getDataManager().get("currentPlan");
		var defaultExecutionScenaroId = plan.get("defaultExecutionScenario");
		var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
		var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: period,year:year,periodKind : plan.get("periodKind")});
		var netBaseTuples = 0;
		var replaceMeasure = false;
		var calculate = false;
		var changeInValue = false;
		var updateOtherScenarios = updateOtherScenarios?updateOtherScenarios:false;
		var obsMesCurrentScenario;
		var obsMesScenario;
		var mestVM;
		var measureVM;
						
		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		var beepPackage = self.getNestedParent();
		var valueMeasurement = self.get('valueMeasurement');
		var calculateSat;
		if(value === '####'){
			value = Number.MAX_VALUE;
		}
		
		obsMesCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(valueMeasurement, timestamp ? true:false,timestamp);	
		var allCollMeasurements = [];	
        changeInValue = handleBaseRelationChange();
        changeInValue = self.handleNameChange(name, viewAlternative, measureVM, changeInValue);
		var context = self.getValueContext(viewAlternative);
		if(!context){
			context  = self.createValueElementContext(viewAlternative);
		}
		//context.addBaseTuple(baseTuples,viewAlternative);TODO on refered bm
        handleFormulaChange(valFormula, context, viewAlternative, self);
		if (!skipOtherScenarios && updateOtherScenarios) {
			callback(obsMesScenario,mestVM,measureVM,calculateSat,changeInValue);			
		}else{
			if(changeInValue){
				if(obsMesScenario){
					
				}else {
					var allObsMesSatScenario = scenario.getAllObservedMeasureWithMeasurements(self.get('satisfactionLevel'));
					_.each(allObsMesSatScenario,function(obsMesSatScenario){
						obsMesSatScenario & obsMesSatScenario.set('lastChange',new Date().getTime());	
					});
				}
			}				
			callback(obsMesScenario,mestVM,measureVM,calculateSat,changeInValue);
		}


		function handleBaseRelationChange() {
			handleMaxChangeChange();
				netBaseTuples = 0;
				var newTuples = 0;
				var updateTuples = 0;
				var oldTuples = 0;				
				_.each(baseTuples,function(baseTuple){
					var obsMesRelations;
					_.each(allCollMeasurements,function(collMest){
						obsMesRelations = collMest.get('baseMeasurementTo');
						if(baseTuple.new && !baseTuple.old){					
							netBaseTuples++;
							newTuples++;
						}else{						
							oldTuples++;
						}
					});
				});
				if(netBaseTuples>0){
					replaceMeasure = true;
					replaceMeasurement = true;
				}
				if(oldTuples>0 || newTuples > 0 || updateTuples> 0){
                    replaceMeasure = true;
				}
				if(oldTuples>0 || newTuples > 0 || (baseTuples.length == 0)){
					updateOtherScenarios = true;
				}
				//handleValueUnitChange();
				handleQuantityChange(periodDataset);
			if(replaceMeasure){
				calculate = true;
            }
			return calculate;
		}	
		
		/*function handleValueUnitChange(){
			if(measureVM && measureVM.get('unit')){
				var unit = beepPackage.findOrCreateUnit(valueUnit.unitName,valueUnit.unitObj);
				if(measureVM.get('unit').get('name') !== valueUnit.unitName){
					if(obsMesCurrentScenario){
						if(obsMesScenario && self.checkMeasureReuse(measureVM) || matchingMeasureExists()){
							replaceMeasure = true;
						}else{
							measureVM.set('unit',unit);
						}
					}
					rescale = true;
					changeInValue = true;
				}
				var roundingFactor = valueUnit.roundingDigits;
				var significantFactor = valueUnit.significantDecimals;
				if(roundingFactor < 0 || roundingFactor > 20){//can remove when validation in ui exists
					roundingFactor = 4;
				}
				if(significantFactor < 0 || significantFactor > 20){//can remove when validation in ui exists
					significantFactor = 2;
				}
				if(unit.get('roundingDigits') != roundingFactor){
					unit.set('roundingDigits',roundingFactor);
					calculate = true;
					calculateSat = true;
				}
				unit.set('significantDecimals',significantFactor);
			}
		}*/	

        function handleFormulaChange(valFormula, context, viewAlternative, comp){
			if(valFormula && valFormula.length > 0){
				var exp = context.get("formula");
				if(exp){
					exp.set("expressionStr",valFormula);
				} else {
					exp = self.createExpression(context, valFormula);
				}
			}
		}
		function handleQuantityChange(periodDataset){
			if(periodDataset && self.get("valueType") != ValueType.Atomic){
				var measurement = new ScenarioMeasurement(self,periodDataset);
				measurement.setValue(value);
			}
			if(mestVM){
				var unit = measureVM.get('unit');
				var sigDecimals = unit?unit.get('significantDecimals'):'2';
				var roundedVal = parseFloat(mestVM.get('value')).toFixed(sigDecimals);
				if(value !== roundedVal && mestVM.get('value') != value){
					calculate = true;
					calculateSat = true;
					changeInValue = true;
				}
				if((measureVM.get('type') !== 'smm_CollectiveMeasure' && calculate) || 
					(measureVM.get('type') === 'smm_CollectiveMeasure' && calculate && (self.get('aggregatedFrom').length === 1) && (self.id === self.get('aggregatedFrom').at(0).id))){
					if(value != null && value !== '' && !isNaN(value) && value != Number.MAX_VALUE){
						var roundDecimals = unit?unit.get('roundingDigits'):'4';
						value = parseFloat(value).toFixed(roundDecimals);
					}
					mestVM.set('value',value);	
				}
			}	
		}	
		function handleMaxChangeChange(){
			var isIterative = self.get('isIterative');
			if(isIterative && self.get('maxChange') !== maxChange){
				self.set('maxChange',maxChange);
				calculate = true;
				calculateSat = true;				
			}
		}		
	};

    primeValueMeasurement(valueMeasurement,measurand,year, period, value,unitToMatch,valFormula,accumulator,baseTuples,timestamp,viewAlternative,maxChange, isRecipient,expression,projectionProfile){
		var self = this;
		var mcCharacteristic  = self.get('valueDefinition');
		/*if(mcCharacteristic){
			charToMatch = mcCharacteristic.get('name');
		}else{
			charToMatch = isRecipient ? measurand.get('name') + " recipient" : measurand.get('name');
		}*/
		if(baseTuples && baseTuples.length > 0){
			self.set("valueType",ValueType.Aggregated);
		} else {
			self.set("valueType",ValueType.Atomic);
		}
		self.createProjectionProfile(projectionProfile);
		//var currentPackage = self.getNestedParent();
		//var measureLibrary = currentPackage.createDefaultMeasureLibrary();
		var scenario = viewAlternative.getDefaultScenario();
        var measurement = scenario.createMeasurement(self,viewAlternative,year, period, valFormula,unitToMatch,baseTuples);     // 
		//var measurement = scenario.createMeasurement(currentPackage,self,valueMeasurement,null,charToMatch,unitToMatch,valFormula,accToMatch,baseTuples,null,null,timestamp,null,isOVS,viewAlternative);
        if (!self.get('isIterative')) {
			var roundingFactor = unitToMatch.roundingDigits;
			if(value && !isNaN(value)){
				value = parseFloat(value).toFixed(roundingFactor);
			}
			//measurement.set('value',value);	
			if(measurement){
				measurement.setValue(value);	
			}
		}else{
			self.set('maxChange',maxChange);
			//measurement.get('measuredCharacteristic').getMeasurand().calculate(scenario,timestamp);
		}
		return measurement;
	};
}
utils.customExtendClass (ValueElement2Mixin,new ValueElementMixin());

path.ValueElement2Mixin = ValueElement2Mixin;
