import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VDMLDiagram} from './VDMLDiagram'
import {VDMLDiagramMixin} from './VDMLDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//import { Collaboration } from './Collaboration'
import { ValueDeliveryModel } from './ValueDeliveryModel'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VDMLDiagram","appbo/vdml/VDMLDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VDMLDiagram,VDMLDiagramMixin
, ValueDeliveryModel
, Collaboration){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValueDeliveryModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueDeliveryModel){
			ValueDeliveryModel = importPath.ValueDeliveryModel;
		}
        else {
            import('./ValueDeliveryModel').then(({ default: ValueDeliveryModel }) => {
                ValueDeliveryModel = ValueDeliveryModel;
            });
            /*require(["appbo/vdml/ValueDeliveryModel"], function (loadedModule) {
                ValueDeliveryModel = loadedModule;
			});*/
		}
	}
	//if(!Collaboration){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Collaboration){
			//Collaboration = importPath.Collaboration;
		}
        else {
            import('./Collaboration').then(({ default: Collaboration }) => {
                Collaboration = Collaboration;
            });
            /*require(["appbo/vdml/Collaboration"], function (loadedModule) {
                Collaboration = loadedModule;
			});*/
		}
	//}
	export class CollaborationDiagramMixin {
	
	defaults(){
		var ret = {
			type: "vdml_CollaborationDiagram"
		}
		return jQuery.extend(VDMLDiagram.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
			
		])
	}
	static getCumulativeMixinRelations(){
		if (!CollaborationDiagramMixin.cummulativeRelations) {
            CollaborationDiagramMixin.cummulativeRelations = _.union(CollaborationDiagramMixin.getMixinRelations()
                , VDMLDiagramMixin.getCumulativeMixinRelations()
            );
        }
		return CollaborationDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VDMLDiagram"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("valueDeliveryModel") ? this.get("valueDeliveryModel") : this.previousAttributes().valueDeliveryModel;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("valueDeliveryModel") ? this.get("valueDeliveryModel") : this.previousAttributes().valueDeliveryModel;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CollaborationDiagramPropertiesTemplate.html",
			templateName : "CollaborationDiagramPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CollaborationDiagramViewModel",
			tabId : "CollaborationDiagramView",
			tabName: "CollaborationDiagram"
		}
	}
	static migrateDependencyToImages(beepPacks){
		for(var j=0; j<beepPacks.length; j++){
			var beepId = beepPacks[j].beepReference;
			var type = beepPacks[j].beepType.replace('_', '.');
			var ecoPackage = Backbone.Relational.store.getObjectByName(type).find({id:beepId});
			if(ecoPackage){
				var diagrams = ecoPackage.get('diagrams');
				if(diagrams){
					var EcoMapMixin = Backbone.Relational.store.getObjectByName("ecomap.EcoMapMixin");
					for (var k = 0; k < diagrams.length; k++) {
						var newXML = diagrams.at(k).get('data');
						var codedContainersList = EcoMapMixin.fetchImagesFromXML(newXML);
						if(codedContainersList){
							for (var m = 0; m < codedContainersList.length; m++) {
								var codeContainer = window.utils.getElementModel(codedContainersList[m],['beeppackage.CodeContainer']);
								if(codeContainer){
									ecoPackage.get('dependentPackage').add(codeContainer);	
								}
							}
						}
					}
				}
			}
		}
	}
	getUsedColors(modeler){
        var colors = ["rgba(255,255,127,1.0)", "rgba(255,81,156,1.0)", "rgba(70,193,237,1.0)", "rgba(250,119,49,1.0)", "rgba(127,140,255,1.0)", '#ffffff', "#050505"];
		var shapes, definitions, diagram, plane, planeElements;
		if (modeler) {
			definitions = modeler._definitions;
		}
		if (definitions) {
			diagram = definitions.diagrams;
		}
		if (diagram && diagram.length) {
			plane = diagram[0].plane;
		}
		if (plane) {
			planeElements = plane.planeElement;
		}
		if(planeElements){
		planeElements.forEach(function (moddleElement) {
			if (moddleElement.get('stroke')){
				colors.push(moddleElement.get('stroke'));
			} else if (moddleElement.get('fill')){
				colors.push(moddleElement.get('fill'));
			}
		});
		}
		jQuery.unique(colors);
		return colors;
	}
	getMappedElement(mappingEleId, parentId, type, shape, callback) {
		var repId = DataManager.getDataManager().getRepositoryId(parentId);
		var vdmStore = DataManager.getDataManager().getVDMStore(repId);
		var docTypePackage, mappingName;
		var docTypeMap = {};
		docTypeMap['vdml:MarketSegment'] = ["appbo/vdml/Collaboration"];
		docTypeMap['vdml:Enterprise'] = ["appbo/vdml/OrgUnit"];
		docTypeMap['vdml:Individual'] = ["appbo/vdml/Actor"];
		docTypeMap['vdml:BusinessModel'] = ["appbo/vdml/BusinessModel"];
		docTypeMap['vdml:Role'] = ["appbo/vdml/Role"];
		docTypeMap['vdml:ValueProposition'] = ["appbo/vdml/ValueProposition"];
		docTypeMap['vdml:Activity'] = ["appbo/vdml/Activity"];
		docTypeMap['vdml:ValueStream'] = ["appbo/vdml/ValueStream"];
		docTypeMap['vdml:Capability'] = ["appbo/vdml/CapabilityOffer"];
		docTypeMap['vdml:Competency'] = ["appbo/vdml/CapabilityOffer","appbo/vdml/BusinessItem"];
		docTypeMap['vdml:Value'] = ["appbo/vdml/ValuePropositionComponent", "appbo/vdml/ValueAdd"];
		docTypeMap['vdml:CapabilityCategory'] = ["appbo/vdml/CapabilityCategory"];
		docTypeMap['vdml:CapabilityDefinition'] = ["appbo/vdml/CapabilityDefinition"];
        docTypeMap['vdml:BusinessIndicatorCategory'] = ["appbo/smm/BusinessIndicatorCategory"];
        /*docTypeMap['vdml:BusinessIndicatorDefinition'] = ["appbo/smm/BusinessIndicatorDefinition"];*/
		docTypePackage = docTypeMap[type] ? docTypeMap[type][0] : '';
		fetchModel(function (model) {
			callback(model);
		});
		function fetchModel(callback) {
			var cachedModel = DataManager.getDataManager().fetchDocumentFromStoreCache(mappingEleId, docTypePackage);
			if (cachedModel) {
				callback(cachedModel);
			} else {
				if (type === 'vdml:Value' && docTypePackage != docTypeMap[type][1]) {
					docTypePackage = docTypeMap[type][1];
					fetchModel(callback);
				} else if (type === 'vdml:Competency' && docTypePackage != docTypeMap[type][1]) {
					docTypePackage = docTypeMap[type][1];
					fetchModel(callback);
				}else{
					console.log(mappingEleId + " not found");
					if (shape){
						shape.set('mid', undefined);
						shape.set('mpid', undefined);
					}
					callback(null);
				}
			}
			/*DataManager.getDataManager().fetchDocumentFromPackage(parentId, "appbo/vdml/ValueDeliveryModel", DataManager.getDataManager().get('currentVDMVersion'), mappingEleId, docTypePackage, vdmStore, {
				success: function (model) {
					callback(model);
				},
				error: function (error) {
					if (type === 'vdml:Value' && docTypePackage != docTypeMap[type][1]) {
						docTypePackage = docTypeMap[type][1];
						fetchModel(callback);
					} else if (type === 'vdml:Competency' && docTypePackage != docTypeMap[type][1]) {
						docTypePackage = docTypeMap[type][1];
						fetchModel(callback);
					}else{
						console.log(error);
						if (shape){
							shape.set('mid', undefined);
							shape.set('mpid', undefined);
						}
						callback(null);
					}
				}
			});*/
		}
	}
	
	}
	path.CollaborationDiagramMixin = CollaborationDiagramMixin;
	//return CollaborationDiagramMixin;
//});