import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { BMDetailsViewModel } from './BMDetailsViewModel'
import { PeriodKind } from '../../../../bo/vdml/PeriodKind'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
	export class BMDetails2ViewModel extends BMDetailsViewModel {


        getPeriodsByYear(year,plan){
            var self = this;
            let selectedYear = year
            const periods = self.dataSets.filter(d=>d.year===+selectedYear)
            const periodsByPeriodKind = periods.map(d=>{ return plan.getPeriodKinds(d.year,d.period,self.periodKind)});
            self.periodMonths(periodsByPeriodKind);
        }

		
		init(model, options){
           super.init(model,options);
           var self = this;
           var dataManager = DataManager.getDataManager();
            this.periodYears =  ko.observableArray();
            this.periodMonths = ko.observableArray();
            this.selectedYear = ko.observable();
            this.selectedPeriod = ko.observable();
            this.showPeriods = ko.observable(true)
            this.periodKind = dataManager.get('currentPlan').get("periodKind");
            var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
            self.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
            var currentPlan = dataManager.get('currentPlan');
            this.dataSets = self.scenario.getPhaseAlternativeSteps(currentPlan);
            const filteredYears = this.dataSets.filter((item, index, self) =>
                index === self.findIndex((t) => t.year === item.year)
            );
            this.periodYears(filteredYears.map(d=>{return {text:d.year,value:d.year}}));
            self.selectedYear.subscribe(function(value){
                if(value!==undefined){
                    self.getPeriodsByYear(value,currentPlan);
                    self.defaultPeriod?self.selectedPeriod(self.defaultPeriod):self.selectedPeriod();
                    if(self.parentView.showPost){
                        window.vdmModelView.updateYearAndPeriod(value,self.selectedPeriod());
                    }
                    self.defaultPeriod=undefined
                }
            });

            self.selectedPeriod.subscribe(function(value){
                if(value!==undefined){
                    if(self.parentView.showPost){
                        window.vdmModelView.updateYearAndPeriod(self.selectedYear(),value);
                    }
                }
            });
            var phase = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: dataManager.get('viewAlternative')})?.getParent();
            this.phaseDatasets = self.scenario.datasetsByPhase(phase.id,self.periodKind);
            if(PeriodKind.symbols()[0].name === self.periodKind){
                this.showPeriods(false)
            }
            var defaultPeriod = self.scenario.fillDefaultPeriods(this.phaseDatasets,self.periodKind);
            self.defaultPeriod = defaultPeriod.period
            self.selectedYear(defaultPeriod.year);
        }


    static getInstance(model, options) {
        var view = new BMDetails2ViewModel(model, options);
        view.init(model, options);
        return view;
    };	
}
path.BMDetails2ViewModel = BMDetails2ViewModel;
