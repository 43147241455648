import * as _ from 'underscore'
import { BeepPackageMixin } from "./BeepPackageMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);

export class BeepPackage2Mixin {
    static getSubModelTypes(){
        var ret = BeepPackageMixin.getSubModelTypes();
        ret['transformation_PlanScenario'] = 'transformation.PlanScenario';
        ret['transformation_ScenarioExecution'] = 'transformation.ScenarioExecution';
        ret['transformation_PeriodDataset'] = 'transformation.PeriodDataset';
        ret['instdef_PlanInstantiationPackage'] = 'instdef.PlanInstantiationPackage';
        return ret;
    }
}
utils.customExtendClass (BeepPackage2Mixin,new BeepPackageMixin());

path.BeepPackage2Mixin = BeepPackage2Mixin;