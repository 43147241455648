import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);

export class ScenarioValueConstraintsMixin {

    defaults(){
        var ret = {
            type: "transformation_ScenarioValueConstraints"
        }
        return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
        ])
    }
    static getCumulativeMixinRelations(){
        if (!ScenarioValueConstraintsMixin.cummulativeRelations) {
            ScenarioValueConstraintsMixin.cummulativeRelations = _.union(ScenarioValueConstraintsMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
        return ScenarioValueConstraintsMixin.cummulativeRelations.slice();
    }
    static getSuperTypes(){
        return [
            "cmof_EObject"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "valueId",type : "EString",defaultValue : "null",containingClass : "transformation_ScenarioValueConstraints" },
            {name : "valueInstanceId",type : "EString",defaultValue : "null",containingClass : "transformation_ScenarioValueConstraints" },
            {name : "minBound",type : "double",defaultValue : "null",containingClass : "transformation_ScenarioValueConstraints" },
            {name : "maxBound",type : "double",defaultValue : "null",containingClass : "transformation_ScenarioValueConstraints" },
            {name : "equalValue",type : "double",defaultValue : "null",containingClass : "transformation_ScenarioValueConstraints" }
        ]
    }
    getParent(){
        var container;
        if(!container){
            container = this.get("ScenarioValueConstraintsOwner") ? this.get("ScenarioValueConstraintsOwner") : this.previousAttributes().ScenarioValueConstraintsOwner;
            if(container){
                return container;
            }
        }
        return this;
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        var container;
        if(!container){
            container = this.get("ScenarioValueConstraintsOwner") ? this.get("ScenarioValueConstraintsOwner") : this.previousAttributes().ScenarioValueConstraintsOwner;
            if(container){
                path.unshift({id:this.get('id'),name:this.get('name')})
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type){
        return {
            templatePath : "views/transformation/views/properties/ScenarioValueConstraintsPropertiesTemplate.html",
            templateName : "ScenarioValueConstraintsPropertiesTemplate",
            viewTypeStr : "appviews/transformation/views/properties/ScenarioValueConstraintsViewModel",
            tabId : "ScenarioValueConstraintsView",
            tabName: "ScenarioValueConstraints"
        }
    }

}
path.ScenarioValueConstraintsMixin = ScenarioValueConstraintsMixin;
