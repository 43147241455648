import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../../com/vbee/data/DataManager'
    var path = DataManager.getDataManager().buildAppNsPath("ecomap.migration", global.version);
    var EcoMap1004 = function () {
    }

    EcoMap1004.migrate = function (ecoPackage,callback) {
        var self = this;
        var altId = DataManager.getDataManager().getRepositoryId(ecoPackage.id);
        var diagrams = ecoPackage.get('diagrams');
        for (var i = 0; i < diagrams.length; i++) {
			var diagram = diagrams.at(i);
            var diagramType = diagram.get('type');
			if(diagramType == "vdml_StrategyMapDiagram"){
				var newXML = diagram.get('data');
				var xmlDoc = jQuery.parseXML(newXML);
				var $xml = jQuery(xmlDoc);
				var causalRelationshipNodes = xmlDoc.getElementsByTagName("vdml:causalRelationship");
				for(var itr=0;itr<causalRelationshipNodes.length;itr++){
					if(causalRelationshipNodes[itr].getAttribute("curveType") == null){
					   causalRelationshipNodes[itr].setAttribute("curveType", "c");
					}
				}
				newXML = (new XMLSerializer()).serializeToString(xmlDoc);
				diagram.set('data',newXML);
			}			
        }
		callback(ecoPackage);
    }
    path.EcoMap1004 = EcoMap1004;
    export {EcoMap1004};
//});