import * as _ from 'underscore'
import {ScenarioDataType} from '../vdml/ScenarioDataType'
import { EObjectMixin } from "../cmof/EObjectMixin";
import { EObject } from '../cmof/EObject';
import {DataManager} from '../../../com/vbee/data/DataManager'
var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);

export class WhatIfValueMixin{
    defaults(){
		var ret = {
			type: "transformation_WhatIfValue",
			version: DataManager.getDataManager().get('currentVDMVersion')
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
    static getMixinRelations(){
		return _.union();
	};
	static getCumulativeMixinRelations(){
		if (!WhatIfValueMixin.cummulativeRelations) {
            WhatIfValueMixin.cummulativeRelations = _.union(WhatIfValueMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return WhatIfValueMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("WhatIfValueOwner") ? this.get("WhatIfValueOwner") : this.previousAttributes().WhatIfValueOwner;
			if(container){
				return container;
			}
		}
		return this;	
    }
}
path.WhatIfValueMixin = WhatIfValueMixin;