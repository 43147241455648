// Generated from ./ExprLang.g4 by ANTLR 4.13.2
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete listener for a parse tree produced by ExprLangParser.
export default class ExprLangListener extends antlr4.tree.ParseTreeListener {

	// Enter a parse tree produced by ExprLangParser#program.
	enterProgram(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#program.
	exitProgram(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#statement.
	enterStatement(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#statement.
	exitStatement(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#parenvmpExpression.
	enterParenvmpExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#parenvmpExpression.
	exitParenvmpExpression(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#number.
	enterNumber(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#number.
	exitNumber(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#string.
	enterString(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#string.
	exitString(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#functionCall.
	enterFunctionCall(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#functionCall.
	exitFunctionCall(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#variable.
	enterVariable(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#variable.
	exitVariable(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#objectAccess.
	enterObjectAccess(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#objectAccess.
	exitObjectAccess(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#ternaryExpression.
	enterTernaryExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#ternaryExpression.
	exitTernaryExpression(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#unaryvmpExpression.
	enterUnaryvmpExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#unaryvmpExpression.
	exitUnaryvmpExpression(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#binaryvmpExpression.
	enterBinaryvmpExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#binaryvmpExpression.
	exitBinaryvmpExpression(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#binaryOp.
	enterBinaryOp(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#binaryOp.
	exitBinaryOp(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#unaryOp.
	enterUnaryOp(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#unaryOp.
	exitUnaryOp(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#simpleFunctionCall.
	enterSimpleFunctionCall(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#simpleFunctionCall.
	exitSimpleFunctionCall(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#methodFunctionCall.
	enterMethodFunctionCall(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#methodFunctionCall.
	exitMethodFunctionCall(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#vmpExprList.
	enterVmpExprList(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#vmpExprList.
	exitVmpExprList(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#objAccess.
	enterObjAccess(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#objAccess.
	exitObjAccess(ctx) {
	}



}