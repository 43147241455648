import * as ko from 'knockout'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as _ from 'underscore'
import {PlanViewModel} from '../../../transformation/views/properties/PlanViewModel'
import {PlanValues2Chart} from '../chart/PlanValues2Chart'
import {PlanValueDetails2ViewModel} from './PlanValueDetails2ViewModel'
import { PeriodKind } from '../../../../bo/vdml/PeriodKind'

var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties", global.version);

export class Plan2ViewModel extends PlanViewModel{

    createPlanValue() {
		var self = this;	
		function loadValueDialog() {
			var planCriterionSet = self.relModel.getOrCreatePlanCriterionSet();
			var id = window.utils.htmlEscape(window.guidGenerator()) + "valueProposition";
			self.relModel.getValuesThatCanBeRefferedTo(function (values) {
				self.valuesColl.add(values);
				self.valuesCollNames = [];
				var models = planCriterionSet.get('component').models;
				for (var i = 0; i < models.length; i++) {
					self.valuesCollNames.push({ name: models[i].get('name'), id: models[i].id });
				}
				var addOptions = {year:self.selectedYear(),period: self.selectedPeriod(),'type': 'planValues', 'aggregatedColl': self.valuesColl, 'valuesColl': self.valuesCollNames, planValue: true, 'currentAlternativeModel': self.currentAlternativeModel};
				var BusinessModel2Mixin = Backbone.Relational.store.getObjectByName("vdml.BusinessModel2Mixin");
				window.getAndCreateModalDialog(self.relModel, id, BusinessModel2Mixin, null, "ValueDetails", function (planValueView, modalId, type, del) {
					window.utils.startSpinner('planValues', 'Saving Plan Value ...');
					window.setTimeout(function () {
						var vp = self.relModel.getOrCreatePlanCriterionSet();
						planValueView.saveData(self.relModel, vp, function () {
							DataManager.getDataManager().invokeValueUpdates(function(){
								//self.relModel.incrementOtherAlts(planValueView.model,planValueView,function(){
									//self.planViewInstance.addNewValue(planValueView.model, self.currentAlternativeModel);
									self.planViewInstance.initialize(self.relModel,self.selectedPeriod(),self.selectedYear(),self.currentAlternativeModel);
									window.cleanDialogModel(modalId, planValueView);
									window.utils.stopSpinner('planValues');
								//});
							});
						});
					}, 100);
				}, addOptions);
			});
		}
		if (!self.currentAlternativeModel) {
			bootbox.alert(self.localeManager.get("selectalternative"));
			return false;
		} else {
			loadValueDialog();
		}
	};

	loadProgressComponents(){
		var self = this;
		self.chartViewInstance = PlanValues2Chart.getInstance(this.relModel);
		if(!ko.components.isRegistered('PlanValuesChart')){
			ko.components.register("PlanValuesChart", {
				viewModel: {instance:self.chartViewInstance},
					template: window.utils.getHtmlPage("PlanValuesChartTemplate"),
					synchronous: true
			});
		}
	}

	loadPlanValuesComponents() {
		var self = this;
		self.planViewInstance = PlanValueDetails2ViewModel.getInstance(self.relModel);
		if(!ko.components.isRegistered('PlanValueWidget')){
			ko.components.register("PlanValueWidget", {
				viewModel: {instance:self.planViewInstance},
				template : window.utils.getHtmlPage("PlanValueDetails2Template"),
			});
		}
	}

	refreshWidgetData(refresh) {
		var self = this;
		//self.chartViewInstance.initialize(self.relModel, refresh, self);
		if(self.currentAlternativeModel){
			self.planViewInstance.initialize(self.relModel,self.selectedPeriod(),self.selectedYear(),self.currentAlternativeModel);
		}
		//self.openAccordion(self.currentPhase(), self.currentAlternative());
	};

	showScenarioExplorer() {
		var self = this;
		var addOptions = this;
		var Plan2Mixin = Backbone.Relational.store.getObjectByName("transformation.Plan2Mixin");
		var defaultScenario = DataManager.getDataManager().get('defaultScenario');
		window.getAndCreateModalDialog(true, self.encodeId, Plan2Mixin, defaultScenario, "explorerModelJson", null, addOptions);
	};

	showDatasetExplorer() {
		var self = this;
		var addOptions = this;
		var Plan2Mixin = Backbone.Relational.store.getObjectByName("transformation.Plan2Mixin");
		var defaultScenario = DataManager.getDataManager().get('defaultScenario');
		var defaultExecutionScenaroId = defaultScenario.get("defaultExecutionScenario");
        var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
		window.getAndCreateModalDialog(true, self.encodeId, Plan2Mixin, defaultExecutionScenaro, "explorerModelJson", null, addOptions);
	};	

	handlePublicMenus() {
		var self = this;
		super.handlePublicMenus();
		DataManager.getDataManager().addContextBarMenu("", "Default Scenario", $.proxy(this.showScenarioExplorer, this), "Default Scenario");
		DataManager.getDataManager().addContextBarMenu("", "Scenario Execution", $.proxy(this.showDatasetExplorer, this), "Scenario Execution");
		DataManager.getDataManager().addJuliaMenuItem();
	};


	getPeriodsByYear(year){
		var self = this;
		let selectedYear = year
		const periods = self.dataSets.filter(d=>d.year===+selectedYear)
		const periodsByPeriodKind = periods.map(d=>{ return self.relModel.getPeriodKinds(d.year,d.period,self.periodKind)});
		self.periodMonths(periodsByPeriodKind);
	}

	makePhaseActiveHandler(view, event) {
		super.makePhaseActiveHandler(view,event);
		var self = this;
		var phaseId = event.currentTarget.getAttribute('phaseId');
		this.phaseDatasets = self.scenario.datasetsByPhase(phaseId,self.periodKind);
		var defaultPeriod = self.scenario.fillDefaultPeriods(this.phaseDatasets,this.periodKind);
		self.defaultPeriod = defaultPeriod.period;
		self.selectedYear.notifySubscribers(defaultPeriod.year);
	}
	
	init(model, options){
		super.init(model,options)
		var self = this
		var dataManager = DataManager.getDataManager();
		this.periodYears =  ko.observableArray();
		this.periodMonths = ko.observableArray();
		this.selectedYear = ko.observable();
		this.selectedPeriod = ko.observable();
		this.showPeriods = ko.observable(true)
		this.periodKind = dataManager.get('currentPlan').get("periodKind");
		var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
		self.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
		this.dataSets = self.scenario.getPhaseAlternativeSteps(self.relModel);
		const filteredYears = this.dataSets.filter((item, index, self) =>
			index === self.findIndex((t) => t.year === item.year)
		);
		this.periodYears(filteredYears.map(d=>{return {text:d.year,value:d.year}}));
		self.selectedYear.subscribe(function(value){
			if(value!==undefined){
				self.getPeriodsByYear(value)
				self.defaultPeriod?self.selectedPeriod(self.defaultPeriod):self.selectedPeriod();
				self.selectedYear(value)
				self.defaultPeriod=undefined
			}
		});
		self.selectedPeriod.subscribe(function(value){
			if(value!==undefined){
				self.selectedPeriod(value);
				if(window.loadedPackages[self.model.id]){
					self.refreshWidgetData(true);
				}
			}
		})
		var phase = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: options.project})?.getParent();
		if(phase){
			this.phaseDatasets = self.scenario.datasetsByPhase(phase.id,self.periodKind);
			if(PeriodKind.symbols()[0].name === self.periodKind){
				this.showPeriods(false)
			}
			var defaultPeriodList = self.scenario.fillDefaultPeriods(this.phaseDatasets,self.periodKind);
			self.defaultPeriod = defaultPeriodList.period
			self.selectedYear(defaultPeriodList.year);
			self.makePhaseActive = _.bind(self.makePhaseActiveHandler, self);
		}
	}

    static getInstance(model, options) {
        var view = new Plan2ViewModel(model, options);
        return view;
    };	

    
}



path.Plan2ViewModel = Plan2ViewModel;