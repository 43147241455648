import * as _ from 'underscore'
import { ValuePropositionComponentMixin } from "./ValuePropositionComponentMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueElement2Mixin } from './ValueElement2Mixin';
import { Unit } from './Unit';

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class ValuePropositionComponent2Mixin {
    static getMixinRelations(){
        var ret = ValuePropositionComponentMixin.getMixinRelations().filter(item =>{
            return item.key !== "percentageWeight" && item.key !== "satisfactionLevel" && item.key !== "recipientOpinion";
        });
        ret = _.union(ret,[
                {
                    type :Backbone.HasOne,
                    containingClass:"vdml_ValuePropositionComponent",
                    key:"percentageWeight",
                    relatedModel:"vdml.ValueElement",
                    reverseRelation: {
                        key:"percentageWeightOwner",
                        type :Backbone.HasOne,
                        includeInJSON:"id"
                    }
                },
                {
                    type :Backbone.HasOne,
                    containingClass:"vdml_ValuePropositionComponent",
                    key:"satisfactionLevel",
                    relatedModel:"vdml.ValueElement",
                    reverseRelation: {
                        key:"satisfactionLevelOwner",
                        type :Backbone.HasOne,
                        includeInJSON:"id"
                    }
                },
                {
                    type :Backbone.HasOne,
                    containingClass:"vdml_ValuePropositionComponent",
                    key:"recipientOpinion",
                    relatedModel:"vdml.ValueElement",
                    reverseRelation: {
                        key:"recipientOpinionOwner",
                        type :Backbone.HasOne,
                        includeInJSON:"id"
                    }
                }
            ])
        return ret;
    }
    
    static getCumulativeMixinRelations(){
		if (!ValuePropositionComponent2Mixin.cummulativeRelations) {
            ValuePropositionComponent2Mixin.cummulativeRelations = _.union(ValuePropositionComponent2Mixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("ValueElement","vdml").getCumulativeMixinRelations()
            );
        }
		return ValuePropositionComponent2Mixin.cummulativeRelations.slice();
    }
	primeSatisfaction(satisfactionLevel,satisfactionType,isDimensional,satisfactionValue,satisfactionUnit,scenario,intervalTuples,timestamp,viewAlternative){
		var self = this;
		var plan = DataManager.getDataManager().get("currentPlan");
        var defaultValLib = plan.get("defaultValueLibrary");
		var contexts = satisfactionLevel.get("context").models;
        var context = null;
        for(var i=0;i<contexts.length;i++){
            if(contexts[i].get("alternative").id == viewAlternative.id){
                context = contexts[i];
                break;
            }
        }
        if(!context){
            context = satisfactionLevel.createValueElementContext(viewAlternative);
        }
		var exp = satisfactionLevel.createExpression(context, null,JSON.stringify(intervalTuples));
		var unit = defaultValLib.get('unit').findWhere({name:satisfactionUnit.unitName});
		if(satisfactionUnit && satisfactionUnit.unitName != "" && !unit){
            unit = Unit.getInstance(satisfactionUnit.unitName,satisfactionUnit.unitName,satisfactionUnit.roundingDigits,satisfactionUnit.significantDecimals,defaultValLib);
            satisfactionLevel.set("unit",unit);
        }
		else if(satisfactionUnit && satisfactionUnit.unitName != "" && unit){
			satisfactionLevel.set('unit',unit);
		}
		if (satisfactionType === 'Grade') {
			satisfactionLevel.set("isNominal",true);
		} else if (satisfactionType === 'Ranking') {
			satisfactionLevel.set("isNominal",false);
		}
	}

    prime(year,period, value,valueUnit,valFormula,satisfactionValue,satisfactionType,isDimensional,satisfactionUnit,weight,recipient,accumulator,baseTuples,interValTuples,timestamp,viewAlternative,maxChange,currentBM,expression,projectionProfile,callback){
		var self = this;
		//var currentPackage = this.getNestedParent();
		//var measureLibrary = currentPackage.createDefaultMeasureLibrary();
		var valueMeasurement = self;
		var recipientMC = self.get('recipientOpinion');
        var satisfactionLevel = self.get('satisfactionLevel');
        var percentageWeight = self.get('percentageWeight');

		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		if(isDimensional) {
            var measurementComponent = self.primeValueMeasurement(valueMeasurement, self, year, period, value, valueUnit, valFormula, accumulator, baseTuples, timestamp, viewAlternative, maxChange, false, expression,projectionProfile);
            if (recipient && recipient != "") {
				if(!recipientMC) {
					self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
					recipientMC = self.get('recipientOpinion');
				}
                self.primeValueMeasurement(recipientMC, self, recipient, valueUnit, null, accumulator, [], timestamp, viewAlternative, maxChange, true, expression);
            }
		}else{
			if (recipient && recipient != "") {
				if(!recipientMC) {
					self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
					recipientMC = self.get('recipientOpinion');
				}
				self.primeSatisfaction(recipientMC,satisfactionType,isDimensional,recipient,null,scenario,interValTuples,timestamp,viewAlternative);
			}
		}
		
		if(interValTuples.length > 0) {
			if (!satisfactionLevel) {
				self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
				satisfactionLevel = self.get('satisfactionLevel');
			}
			self.primeSatisfaction(satisfactionLevel, satisfactionType, isDimensional, satisfactionValue, satisfactionUnit, scenario, interValTuples, timestamp,viewAlternative);
		}
        
        if (weight && weight !== "") {
			if(!percentageWeight) {
				self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
				percentageWeight = self.get('percentageWeight');
			}
            self.primeWeight(percentageWeight, weight, scenario, timestamp);
        }
		if(isDimensional) {
            if(baseTuples.length > 0) {
                self.calculate(scenario);
            }
            if(callback){
                callback(true);
            }
		}
		else {
			callback();
		}
	};

    update(period, year, name,value,valueUnit,valFormula,satisfactionType,isDimensional,satisfactionValue,satisfactionUnit,weight,recipientValue,accumulator,baseTuples,intervalTuples,timestamp,viewAlternative,maxChange,currentBM,expression,projectionProfile,callback){
		var self = this;
		var plan = DataManager.getDataManager().get("currentPlan");
        var defaultValLib = plan.get("defaultValueLibrary");
		var replaceSatMeasurement = false;
		var replaceSatMeasure = false;
		var calculateSat = false;
		var changeInValue = false;
		var obsMesScenario;
		var obsMesSatisfactionCurrentScenario;
		var obsMesSatisfactionScenario;
		var measureSatisfaction;
		var mestWeight;
		var obsMesWeightCurrentScenario;
		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		var beepPackage = self.getNestedParent();
		var valueMeasurement = null;//self.get('valueMeasurement');
		var satisfactionLevel = self.get('satisfactionLevel');
		var percentageWeight = self.get('percentageWeight');
		var recipientMC = self.get('recipientOpinion');
		var isTimeStamp = false;
		if(timestamp){
			isTimeStamp = true;
		}
		
		DataManager.getDataManager().getMainScenario(beepPackage,viewAlternative.get('id'),function(mainScenario){
            handleChanges(mainScenario);
        });

        function handleChanges(currentScenario) {
            if (!satisfactionLevel || !percentageWeight || !recipientMC) {
                self.getNestedParent().createValueDetails(self, null, intervalTuples.length > 0, weight, recipientValue);
				satisfactionLevel = self.get('satisfactionLevel');
                if (intervalTuples.length > 0 && satisfactionLevel) {
					self.primeSatisfaction(satisfactionLevel, satisfactionType, isDimensional, satisfactionValue, satisfactionUnit, scenario, intervalTuples, timestamp,viewAlternative);
                }
                if (weight && weight !== "" && percentageWeight) {
					obsMesWeightCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(percentageWeight,isTimeStamp,timestamp);	
					if(!obsMesWeightCurrentScenario){
						self.primeWeight(percentageWeight, weight, scenario, timestamp);
					}
                }
				if (recipientValue && recipientValue !== "" && recipientMC) {
					var obsMesRecipientCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(recipientMC,isTimeStamp,timestamp);	
					if(!obsMesRecipientCurrentScenario){
						self.primeValueMeasurement(recipientMC, self, recipientValue, valueUnit, null, accumulator, [], timestamp, viewAlternative, maxChange, true, expression,projectionProfile);
					}
				}
            }
            self.updateValueElementChanges(currentScenario, period, year, name, value, valueUnit, valFormula, baseTuples, timestamp, viewAlternative, maxChange, function(iObsMesScenario,iMestVM,iMeasureVM,icalculateSat,ichangeInValue){
                changeInValue = ichangeInValue;
                obsMesScenario = iObsMesScenario;
                calculateSat = icalculateSat;
                handleRecipientValueChange();
                handleSatisfactionAndWeight();
				handleWeightChange();
				callback(changeInValue);

            });
			function handleRecipientValueChange(){
				var valueObsMes = currentScenario.getObservedMeasureWithMeasurements(recipientMC,isTimeStamp,timestamp);
				var obsMesScenario = scenario.getObservedMeasureWithMeasurements(valueMeasurement,isTimeStamp,timestamp);
				if(valueObsMes){
					var recipientMeasurement = valueObsMes.get('measurements').at(0);
					var recipientMeasure = valueObsMes.get('measure');	
					var roundingFactor = valueUnit?valueUnit.roundingDigits:null;
					if(recipientValue && !isNaN(recipientValue) && roundingFactor){
						recipientValue = parseFloat(recipientValue).toFixed(roundingFactor);
					}
					if(recipientMeasurement && recipientMeasurement.get('value') !== recipientValue){
						if(recipientMeasure.get('type') === "smm_GradeMeasure"){
							recipientMeasurement.set('symbol',recipientValue);
						}else{
							recipientMeasurement.set('value',recipientValue);
						 }
					}
					if(recipientMeasure && recipientMeasure.get('unit') && recipientMeasure.get('unit').get('name') !== valueUnit.unitName){
						var unit = beepPackage.findOrCreateUnit(valueUnit.unitName,valueUnit.unitObj);
						if(!(obsMesScenario && recipientMeasure.get('observedMeasure').length > 1)){
							recipientMeasure.set('unit',unit);
						}
					}
				}
			}
            function handleSatisfactionAndWeight() {
				handleSatisfactionUnitChange(defaultValLib);
				handleSatisfactionTypeChange();
				handleSatisfactionIntervalsChange();
				removeAssociationsForSatisfactionOnly();
			}
		}
		function removeAssociationsForSatisfactionOnly() {
			if (!isDimensional && satisfactionType === "Grade") {
				var aggToColl = self.get('aggregatedTo');
				aggToColl.each(function (agg) {
					agg.get('aggregatedFrom').remove(self);
				});
			}
		}
		
        function handleSatisfactionIntervalsChange() {
			var context = satisfactionLevel.getValueContext(viewAlternative);
			var exp = context.get('formula');
			if(intervalTuples.length==0){
				exp.set('expressionConfig',null);
			}
			else{
				exp.set('expressionConfig',JSON.stringify(intervalTuples))
			}
		}
		
        function handleWeightChange() {
        	var weightCalc = false;
			if(mestWeight && mestWeight.get('value') !== weight){
				mestWeight.set('value',weight);
				weightCalc = true;
			}
			if(weightCalc){
				changeInValue = true;
			}
			if(changeInValue || weightCalc){
				if(obsMesScenario)
					obsMesScenario.set('lastChange',new Date().getTime());
				else {
					obsMesSatisfactionScenario?obsMesSatisfactionScenario.set('lastChange',new Date().getTime()):null;
				}
			}
		}		

		
		function handleSatisfactionTypeChange(){
            if(satisfactionType == 'Grade' && isDimensional){
				satisfactionLevel.set('isNominal',true);
				satisfactionLevel.set("unit",null);
			}
			else if(satisfactionType == 'Ranking' && isDimensional){
				satisfactionLevel.set('isNominal',false);
			}
		}
		
		function handleSatisfactionUnitChange(defaultValLib){
			var unit = defaultValLib.get('unit').findWhere({name:satisfactionUnit.unitName});
			if(satisfactionUnit && satisfactionUnit.unitName != "" && !unit){
				unit = Unit.getInstance(satisfactionUnit.unitName,satisfactionUnit.unitName,satisfactionUnit.roundingDigits,satisfactionUnit.significantDecimals,defaultValLib);
				satisfactionLevel.set("unit",unit);
			}
			else if(satisfactionUnit && satisfactionUnit.unitName != "" && unit){
				satisfactionLevel.set("unit",unit);
			}
		}
		
	};
}
utils.customExtendClass (ValuePropositionComponent2Mixin,new ValuePropositionComponentMixin());

path.ValuePropositionComponent2Mixin = ValuePropositionComponent2Mixin;