import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","async","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueDeliveryModel","appbo/vdml/BusinessModel","appbo/tickets/User","typeahead","appbo/smm/GradeMeasure","appbo/smm/RankingMeasure","appbo/smm/Accumulator","appbo/vdml/MeasuredCharacteristic","appbo/vdml/BusinessModelMixin", "appbo/vdml/ValueElementMixin"],
function(require,$,async,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,ValueDeliveryModel,BusinessModel,User,typeahead,GradeMeasure,RankingMeasure,Accumulator,MeasuredCharacteristic,BusinessModelMixin, ValueElementMixin
){*/
    
    var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);
    //var importPath;
    export class PlanValueDetailsViewModel {
    constructor(model,options){
		var self = this;
		this.init(model, options);
	}
    dispose() {
        var self = this;
        window.cleanViewModel(self);
    };
    fetchValueforAlt(alternative) {
        var self = this;
        var planCriterionSet = self.model.get('planCriterionSet');
        var planComponents = planCriterionSet ? planCriterionSet.get('component') : null;
        var mainScenario = alternative.getOrCreatePhaseObjectiveSet();
        //var value = [];
        if (planComponents) {
            planComponents.each(function (component) {
                var displayValue = ' ';
                var isCollective = false;
                var smileySrc = '';
                var smileyColor = '';
                var correctiveActions = '';
                if (mainScenario) {
                    var mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(component.get('valueMeasurement'));
                    var satisfaction = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(component.get('satisfactionLevel'));
                    if (mest) {
                        if (mest.get('type') === "smm_CollectiveMeasurement") {
                            isCollective = true;
                        }
                        var measure = mest.get('observedMeasure').get('measure');
                        var unit = measure ? measure.get('unit'):null;
                        var significantDecimals = unit ? unit.get('significantDecimals') : 2;
                        if (mest.get('value') !== '') {
                            displayValue = '' + window.utils.getRoundedValues(mest.get('value'), significantDecimals);
                            if (unit) {
                                displayValue = displayValue.concat(' ' + unit.get('name'));
                            }
                        }
                    }
                    else {
                        var aggFrom = component.get('aggregatedFrom');
                        for (var j = 0; j < aggFrom.length; j++) {
                            var parent = aggFrom.at(j).getNestedParent();
                            var repositoryId = DataManager.getDataManager().getRepositoryId(parent.get('id'))
                            if (repositoryId === window.plansKey) {
                                isCollective = true;
                                break;
                            }
                            else if (alternative.get('phaseDesignPart').findWhere({ 'beepReference': parent.get('id') })) {
                                isCollective = true;
                                break;
                            }
                        }
                        var compSatisfactionMeasure = satisfaction ? satisfaction.get('observedMeasure').get('measure') : null;
                        if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
                            displayValue = '' + satisfaction.get('symbol');
                        }
                    }
                    if (satisfaction && component.getParent().get('overAllSatisfaction') !== component) {
                        var selectedInterval = self.getSatSelectedInterval(satisfaction);
                        if (selectedInterval) {
                            smileySrc = self.fetchSmileySrc(selectedInterval.get('smiley'));
                            smileyColor = selectedInterval.get('smileyColor');
                            correctiveActions = selectedInterval.get('correctiveActions');
                        }
                    }
                }
                self.valueList.push({ id: component.get('id'), escapeId: self.htmlEscape(component.id), name: component.get('name'), value: displayValue, altId: alternative.get('id'), isCollective: isCollective, smileySrc: smileySrc, smileyColor: smileyColor, correctiveActions: correctiveActions});
                //value.push({id:component.get('id'),name:component.get('name'),value:displayValue,altId:alternative.get('id')});
            });
        }
        self.sortValueList();
        //return value;
    };
    htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
    cleanUpHandler() {
        var self = this;
        self.showVpList.removeAll();
        self.planValueList.removeAll();
        self.valueList.removeAll();
        self.phaseAccordionArray.length = 0;
        if (self.nextPhaseCalculate) {
            self.nextPhaseCalculate.length = 0;
        }
    };
    getSatSelectedInterval(satisfactionMeasurement) {
        var self = this;
        var selectedInterval;
        var satType = 'symbol';
        var satMeasure = satisfactionMeasurement.get('observedMeasure').get('measure');
        if (satisfactionMeasurement.get('value') && satMeasure.get('unit')) {//Ranking
            satType = 'value';
        }
        if (satMeasure) {
            var intervals = satMeasure.get('interval');
            for (var i = 0; i < intervals.length; i++) {
                if (intervals.at(i).get('' + satType) === satisfactionMeasurement.get('' + satType)) {
                    selectedInterval = intervals.at(i);
                    break;
                }
            }

        }
        return selectedInterval;
    };
    fetchSmileySrc(smileyValue) {
        var self = this;
        //var smileyValue = smileyValue?smileyValue:'1';
        var smileySrc = '';
        for (var j = 0; j < self.svgSmiliesList.length; j++) {
            if (self.svgSmiliesList[j].value === smileyValue) {
                smileySrc = self.svgSmiliesList[j].src;
                break;
            }
        }
        return smileySrc;
    };
    initialize(planModel) {
        var self = this;
        if (!self.model) {
            self.model = planModel;
        }
        self.planValueList([]);
        self.valueList([]);
        self.phaseAccordionArray = [];

        //var currentPlan = DataManager.getDataManager().get('currentPlan');
        var asIs = planModel.get('asIs');
        var goal = planModel.get('goal');
        var phase = planModel.get('phase').findWhere({ nextPhase: null });
        while (phase) {
            var phaseAlts = phase.get('phaseAlternative');
            var masterAlt = phase.get('master');
            var altAccordionList = [];
            phaseAlts.each(function (phaseAlt) {
                if (phaseAlt !== masterAlt) {
                    var value = self.fetchValueforAlt(phaseAlt);
                    var altName = phaseAlt.get('name');
                    if (phaseAlt == phase.get('primary')) {
                        altName = altName.concat(' (Primary)');
                    }
                    altAccordionList.push({ id: phaseAlt.get('id'), encodeId: self.htmlEscape(phaseAlt.get('id')), name: altName });
                }
            });
            self.fetchValueforAlt(masterAlt);
            var altName = masterAlt.get('name');
            if (masterAlt == phase.get('primary')) {
                altName = altName.concat(' (Primary)');
            }
            altAccordionList.unshift({ id: masterAlt.get('id'), encodeId: self.htmlEscape(masterAlt.get('id')), name: altName });
            var phaseName = phase.get('name');
            if (asIs == phase) {
                phaseName = phaseName.concat(' (As-Is)');
            }
            if (goal && goal.get('id') === phase.get('id')) {
                phaseName = phaseName.concat(' (Goal)');
            }
            self.phaseAccordionArray.unshift({ id: phase.get('id'), name: phaseName, encodeId: self.htmlEscape(phase.get('id')), altAccordionList: altAccordionList });
            altAccordionList = [];
            phase = phase.get('previousPhase');

            //for timesTamp
            /*phaseAlts.each(function(phaseAlt){
                var phObjSet = phaseAlt.get('phaseObjectiveSet');
                if(phObjSet) {
                    var objObservations = phObjSet.get('objectiveObservation');
                    objObservations.each(function (objObs) {
                        if (objObs.get('whenObserved') != null) {
                            var text = phaseAlt.get('name') + "(" + objObs.get('whenObserved') + ")";
                            var timeStamp = objObs.get('whenObserved');
                            var id = window.utils.getSuffix(objObs.id);
                            self.observationList.push({id:id,name:timeStamp,originalId:objObs.id});
                        }
                    });	
                }
            });*/
            //
        }
        self.planValueList(self.phaseAccordionArray);
    };
    editValueDetailsHandler(view, event) {
        var self = this;
        var planCriterionSet = self.model.get('planCriterionSet');
        var planComponents = planCriterionSet.get('component');
        var modalEle = planComponents.findWhere({ 'id': view.id });

        DataManager.getDataManager().set('viewAlternative', view.altId);
        DataManager.getDataManager().getAlternative(DataManager.getDataManager().get('viewAlternative'), function (alternative) {
            self.model.getValuesThatCanBeRefferedTo(function (values) {
				var aggregatedColl = new Backbone.Collection(values);
                var currentPlan = DataManager.getDataManager().get('currentPlan');
                currentPlan.getAllAlternateScenarios(function (altScenarios) {
                    var overAllSatisfaction = modalEle ? modalEle.get('componentOwner').get('overAllSatisfaction') : null;
                    //for timesTamp
                    //var timeStampFound = _.filter(self.observationList(), function (obj) { return obj.id === $("#selectObservationListOptions").val(); });
                    //var timeStamp = timeStampFound[0] ? timeStampFound[0].name : null;
                    //
                    var addOptions = { 'type': 'planValues', 'aggregatedColl': aggregatedColl, 'overAllSatisfaction': overAllSatisfaction, planValue: true, 'currentAlternativeModel': alternative};
                    var BusinessModelMixin = Backbone.Relational.store.getObjectByName('vdml.BusinessModelMixin');
                    window.getAndCreateModalDialog(self.model, self.htmlEscape(modalEle.get('id')), BusinessModelMixin, modalEle, "ValueDetails", self.saveValue, addOptions);
                });
            });
        });
        self.sortValueList();
    };
    showAggregationViewHandler(view) {
        var self = this;
        var id = "aggregation" + view.escapeId;
        var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: view.id });
        if (!valueModal) {
            valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: view.id });
        }
        var alternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: view.altId });
        if (valueModal) {
            var mainScenario = alternative.getOrCreatePhaseObjectiveSet();
            var mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(valueModal.get('valueMeasurement'));
        }
        if (!mest) {
            mest = valueModal;
        }
        var addOptions = { 'currentAlternativeModel': alternative };
        var ValueElementMixin = Backbone.Relational.store.getObjectByName('vdml.ValueElementMixin');
        window.getAndCreateModalDialog(self, id, ValueElementMixin, mest, "AggregationView", function (changeInValue) {
            if (changeInValue) {
                window.utils.startSpinner('refreshValues', 'Refreshing ...');
                DataManager.getDataManager().invokeValueUpdates(function () {
                    if (self.parentView) {
                        self.parentView.refreshWidgetData(true);
                        window.utils.stopSpinner('refreshValues');
                    }
                });
            }
        }, addOptions);

    };    
    correctiveActions(view){
		var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>"+view.correctiveActions+"</span></div></div>";
		var box = bootbox.dialog({
			message: htmlContent,
			title: "<div><img class='smallIcon' src='img/icons/icons_15.png'>&nbsp;&nbsp;&nbsp;Corrective Actions</div>",
			buttons: {
				cancel : {
					label: "Close",
					className : 'btn vtn-default',
					callback : function(){
						
					}
				}
			}
		});
		box.show();
    };        
    isPlanValue(valueComp) {
        var self = this;
        var planCriterionSet = self.model.get('planCriterionSet');
        var comp = planCriterionSet.get('component').findWhere({ 'id': valueComp.get('id') });
        if (comp) {
            return true;
        } else {
            return false;
        }
    };
    updateValues(compModel, recursiveModal, originalModal, updatedComps, altModel) {
        var self = this;
        if (self.isPlanValue(compModel)) {
            for (var i = 0; i < self.valueList().length; i++) {
                if (self.valueList()[i].id === compModel.get('id') && self.valueList()[i].altId === altModel.id) {
                    var mainScenario = altModel.getOrCreatePhaseObjectiveSet();
                    var displayValue = self.fetchDisplayValue(mainScenario, compModel);
                    var isCollective = false;
                    var smileySrc = '';
                    var smileyColor = '';
                    var correctiveActions = '';
                    var mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(compModel.get('valueMeasurement'));
                    if (mest && mest.get('type') === "smm_CollectiveMeasurement") {
                        isCollective = true;
                    } else {
                        var aggFrom = compModel.get('aggregatedFrom');
                        for (var j = 0; j < aggFrom.length; j++) {
                            if (altModel.get('phaseDesignPart').findWhere({ 'beepReference': aggFrom.at(j).getNestedParent().get('id') })) {
                                isCollective = true;
                                break;
                            }
                        }
                    }
                    var satisfaction = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(compModel.get('satisfactionLevel'));
                    if (satisfaction && compModel.getParent().get('overAllSatisfaction') !== compModel) {
                        var selectedInterval = self.getSatSelectedInterval(satisfaction);
                        if (selectedInterval) {
                            smileySrc = self.fetchSmileySrc(selectedInterval.get('smiley'));
                            smileyColor = selectedInterval.get('smileyColor');
                            correctiveActions = selectedInterval.get('correctiveActions');
                        }
                    }
                    var obj = { id: self.valueList()[i].id,escapeId:self.htmlEscape(compModel.get('id')), name: compModel.get('name'), value: displayValue, altId: self.valueList()[i].altId, isCollective: isCollective, smileySrc: smileySrc, smileyColor: smileyColor, correctiveActions: correctiveActions };
                    self.valueList.replace(self.valueList()[i], obj);
                    for (var j = 0; j < compModel.get('aggregatedTo').length; j++) {
                        var parent = compModel.get('aggregatedTo').at(j);
                        if (_.indexOf(updatedComps, parent) < 0) {
                            updatedComps.push(parent);
                        } else {
                            continue;
                        }
                        if (compModel.get('aggregatedTo').at(j) != compModel && compModel.get('aggregatedTo').at(j) != recursiveModal && compModel.get('aggregatedTo').at(j) != compModel) {
                            self.updateValues(compModel.get('aggregatedTo').at(j), compModel, originalModal, updatedComps, altModel);
                        }
                        if (self.nextPhaseCalculate.length > 0) {
                            break;
                        }
                    }
                    if (self.nextPhaseCalculate.length > 0) {
                        break;
                    }
                }
            }
        } else {
            for (var k = 0; k < compModel.get('aggregatedTo').length; k++) {
                var parent = compModel.get('aggregatedTo').at(k);
                if (_.indexOf(updatedComps, parent) < 0) {
                    updatedComps.push(parent);
                } else {
                    continue;
                }
                if (compModel.get('aggregatedTo').at(k) != compModel && compModel.get('aggregatedTo').at(k) != recursiveModal && compModel.get('aggregatedTo').at(k) != compModel) {
                    self.updateValues(compModel.get('aggregatedTo').at(k), compModel, originalModal, updatedComps, altModel);
                }
                if (self.nextPhaseCalculate.length > 0) {
                    break;
                }
            }
        }
    };
    saveValueHandler(view, modalId, type, del) {
        var self = this;
        window.utils.startSpinner('planValues', 'Saving Plan Value ...');
        window.setTimeout(function () { self.saveValueProposition(view, modalId, type, del); }, 100);
    };
    saveValueProposition(view, modalId, type, del) {
        var self = this;
        self.nextPhaseCalculate = [];
        var updatedComps = [];
        var currentPhase = view.currentAlternativeModel.get('phaseAlternativeOwner');
        var nextPhaseAlt = currentPhase.get('nextPhase') ? currentPhase.get('nextPhase').get('primary') : null;
        if (del) {
            view.currentAlternativeModel.getOrCreatePhaseObjectiveSet().set('localChange', true);
            view.model.get('componentOwner').deleteValue([view.id()]);
            for (var i = 0; i < self.valueList().length; i++) {
                if (self.valueList()[i].id === view.id()) {
                    self.valueList.remove(self.valueList()[i]);
					break;
                }
            }
            //if (view.aggregateToColl().length > 0) {
                DataManager.getDataManager().invokeValueUpdates(function () {
                    self.parentView.refreshWidgetData(true);
                    //self.openAccordion(currentPhase.get('id'), view.currentAlternativeModel.get('id'));
                    window.utils.stopSpinner('planValues');
                    window.cleanDialogModel(modalId, view);
                    //self.sortValueList();	
                }, true);
            /*} else {
                DataManager.getDataManager().invokeValueUpdates(function () {
                    window.utils.stopSpinner('planValues');
                    window.cleanDialogModel(modalId, view);
                });
            }*/
        }
        else {
            var compAggtoLen = view.model.get('aggregatedTo').length;
            view.saveData(self.model, view.model.get('componentOwner'), function (changeInValue) { 
                if (changeInValue) {
                    view.currentAlternativeModel.getOrCreatePhaseObjectiveSet().set('localChange', true);
                }
                
                function refreshData(incrementUpdate){
                	if (view.structuralChangeCount > 0 || compAggtoLen != view.model.get('aggregatedTo').length || incrementUpdate) {
	                    self.parentView.refreshWidgetData(true);
	                    self.openAccordion(currentPhase.get('id'), view.currentAlternativeModel.get('id'));
	                } else {
	                    self.updateValues(view.model, view.model, view.model, updatedComps, view.currentAlternativeModel);
	                    if (self.nextPhaseCalculate.length > 0 && nextPhaseAlt) {
	                        self.parentView.refreshWidgetData(true);
	                        self.openAccordion(currentPhase.get('id'), view.currentAlternativeModel.get('id'));
	                    }
	                    self.sortValueList();
	                }
	                window.utils.stopSpinner('planValues');
	                window.cleanDialogModel(modalId, view);
                }

            	DataManager.getDataManager().invokeValueUpdates(function(){
            		self.model.incrementOtherAlts(view.model,view,refreshData);
                });
            });
        }
    };
    sortValueList() {
        var self = this;
        self.valueList.sort(function (left, right) {
            return left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? 0 : (left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() < right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? -1 : 1);
        });
    }
    addNewValueHandler(comp, currentAlt) {
        var self = this;
        var plan = DataManager.getDataManager().get('currentPlan');
        for (var i = 0; i < plan.get('phase').length; i++) {
            for (var j = 0; j < plan.get('phase').at(i).get('phaseAlternative').length; j++) {
                var alt = plan.get('phase').at(i).get('phaseAlternative').at(j);
                var displayValue = ' ';
                var isCollective = false;
                var smileySrc = '';
                var smileyColor = '';
                var correctiveActions = '';
                //if (currentAlt === alt) {
                    var mainScenario = alt.getOrCreatePhaseObjectiveSet();
                    displayValue = self.fetchDisplayValue(mainScenario, comp);
                    var mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(comp.get('valueMeasurement'));
                    if (mest && mest.get('type') === "smm_CollectiveMeasurement") {
                        isCollective = true;
                    } else {
                        var aggFrom = comp.get('aggregatedFrom');
                        for (var k = 0; k < aggFrom.length; k++) {
                            if (alt.get('phaseDesignPart').findWhere({ 'beepReference': aggFrom.at(k).getNestedParent().get('id') })) {
                                isCollective = true;
                                break;
                            }
                        }
                    }
                    var satisfaction = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(comp.get('satisfactionLevel'));
                    if (satisfaction && comp.getParent().get('overAllSatisfaction') !== comp) {
                        var selectedInterval = self.getSatSelectedInterval(satisfaction);
                        if (selectedInterval) {
                            smileySrc = self.fetchSmileySrc(selectedInterval.get('smiley'));
                            smileyColor = selectedInterval.get('smileyColor');
                            correctiveActions = selectedInterval.get('correctiveActions');
                        }
                    }
                //}
                var obj = { id: comp.get('id'),escapeId:self.htmlEscape(comp.get('id')), name: comp.get('name'), value: displayValue, altId: alt.get('id'), isCollective: isCollective, smileySrc: smileySrc, smileyColor: smileyColor, correctiveActions: correctiveActions };
                self.valueList.push(obj);
            }
        }
        self.sortValueList();
    };
    fetchDisplayValue(mainScenario, compModel) {
        var self = this;
        var displayValue = ' ';
        if (mainScenario) {
            var mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(compModel.get('valueMeasurement'));
            if (mest) {
                var measure = mest.get('observedMeasure').get('measure');
                var measureRelationships = measure.get('measureRelationships');
                var currentPhase = mainScenario.getAlternative().get('phaseAlternativeOwner');
                var nextPhaseAlt = currentPhase.get('nextPhase') ? currentPhase.get('nextPhase').get('primary') : null;
                if (measureRelationships && nextPhaseAlt) {
                    for (var i = 0; i < measureRelationships.length; i++) {
                        if (measureRelationships.at(i).get('usePreviousAlternative')) {
                            /*var foundComp = false;
                            for(var j=0;j<self.nextPhaseCalculate.length;j++){
                                if(self.nextPhaseCalculate[j] === compModel){
                                    foundComp = true;
                                    break;
                                }
                            }
                            if(!foundComp) {
                                self.nextPhaseCalculate.push(compModel);
                            }*/
                            self.nextPhaseCalculate.push(compModel);
                            break;
                        }
                    }
                }
                if (self.nextPhaseCalculate.length == 0 && nextPhaseAlt && compModel.get('aggregatedTo').findWhere({ 'id': compModel.get('id') })) {
                    self.nextPhaseCalculate.push(compModel);
                }
                var unit = measure.get('unit');
                var significantDecimals = unit ? unit.get('significantDecimals') : 2;
                if (mest.get('value') !== '') {
                    displayValue = '' + window.utils.getRoundedValues(mest.get('value'), significantDecimals);
                    if (unit) {
                        displayValue = displayValue.concat(' ' + unit.get('name'));
                    }
                }
            }
            else {
                var satisfaction = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(compModel.get('satisfactionLevel'));
                var compSatisfactionMeasure = satisfaction ? satisfaction.get('observedMeasure').get('measure') : null;
                if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
                    displayValue = '' + satisfaction.get('symbol');
                }
            }
        }
        return displayValue;
    }
    openAccordion(phase, alt) {
        var self = this;
        if (!alt) {
            $('.panel-collapse:not(".in")').collapse('show');
        } else {
            $('#collapse' + self.htmlEscape(phase)).addClass('collapse in');
            $('#collapse' + self.htmlEscape(alt)).addClass('collapse in');
        }
    }
    reSizeScreen() {
        window.vdmModelView.setResizeHeightHandler();
    };
init(model,options){
    	model.planViewInstance = this;
        var self = this;
        this.PlanValueDetailsViewModel = this;
        this.parentView = model;
        this.model = model.relModel;
        this.id = ko.observable(self.model ? self.model.get('id') : null);
        this.encodeId = self.htmlEscape(this.id());
        this.showVpList = ko.observableArray([]);
        this.planValueList = ko.observableArray([]);
        this.valueList = ko.observableArray([]);
        this.selectedObservation = ko.observable();
        this.observationList = ko.observableArray([]);
        this.phaseAccordionArray = [];
        this.nextPhaseCalculate = [];
        this.svgSmiliesList = window.utils.getSmiliesList();

        this.editValueDetails = _.bind(self.editValueDetailsHandler, self);
        this.showAggregationView = _.bind(self.showAggregationViewHandler, self);
        this.saveValue = _.bind(self.saveValueHandler, self);
        this.addNewValue = _.bind(self.addNewValueHandler, self);
        this.cleanUp = _.bind(self.cleanUpHandler, self);
            /*this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
        ]);*/
       
    }
        
    static getInstance(model,options){
        var view = new PlanValueDetailsViewModel(model,options);
		return view;
    };
}
path.PlanValueDetailsViewModel = PlanValueDetailsViewModel;